import { OfferProviderNames } from 'enums/OfferProviderNames';
import { WaterCressLogo, UpgradeLogo } from 'static/images';
import { VariableValue } from 'types/VariableTypes';

import styles from './PartnerLogo.module.scss';

interface IPartnerLogoProps {
  finalOfferProviderName: OfferProviderNames;
  logoLink: VariableValue;
}

const PartnerLogo = ({ finalOfferProviderName, logoLink }: IPartnerLogoProps) => {
  switch (finalOfferProviderName) {
    case OfferProviderNames.Even:
      return logoLink ? (
        <div className={styles.logo}>
          <img src={logoLink as string} />
        </div>
      ) : null;

    case OfferProviderNames.WaterCrees:
      return (
        <div className={styles.logo}>
          <WaterCressLogo />
        </div>
      );

    case OfferProviderNames.Upgrade:
      return (
        <div className={styles.logo}>
          <UpgradeLogo />
        </div>
      );
    default:
      return null;
  }
};

export default PartnerLogo;
