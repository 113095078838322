import { ApplicationProcessActionTypes } from 'enums/ApplicationProcessActionTypes';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ApplicationProcessApi from 'api/digifi/portal/ApplicationProcessApi';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createGetApplicationStatusThunk = (api: ApplicationProcessApi) =>
  createAsyncThunk(
    ApplicationProcessActionTypes.GetApplicationStatus,
    async (applicationId: string, thunkApi) => {
      try {
        return await api.getApplicationStatus(applicationId);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
