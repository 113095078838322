import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getCurrentAccount } from 'thunks';
import { useAppSelector } from 'hooks/reduxHooks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { AppRoutes } from 'routes/AppRoutes';
import Loading from 'components/Loading';
import { getAccountData, getLoadingFields } from 'handlers/selectors';

const RequiredAuth = () => {
  const location = useLocation();
  const accountData = useAppSelector(getAccountData);
  const { getCurrentAccountLoading: loading } = useAppSelector(getLoadingFields);
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const checkSession = async () => {
    if (!accountData) {
      await dispatchWithUnwrap(getCurrentAccount());
    }
  };

  useEffect(() => {
    checkSession();
  }, [accountData]);

  if (!accountData) {
    if (loading) {
      return <Loading />;
    }

    return <Navigate to={AppRoutes.Authorization} state={{ from: location }} />;
  }

  return <Outlet />;
};

export default RequiredAuth;
