import React, { FC, useEffect, useState } from 'react';
import Title from 'components/Title';
import Paragraph from 'components/Paragraph';
import Button from 'components/Button';
import { ButtonSize, ButtonVariant } from 'components/Button/Button';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { checkOldApplications, continueWithApplication } from 'thunks';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ButtonsTranslationKeys,
  OldApplicationPageTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';

import styles from './OldApplicationPage.module.scss';
import PageLayout from 'components/PageLayout';
import Loading from 'components/Loading';
import { getApplicationProcessData, getLoadingFields } from 'handlers/selectors';

const OldApplicationPage: FC = () => {
  const { applicationId } = useParams();
  const { t: translate } = useTranslation([
    TranslationNameSpaces.Buttons,
    TranslationNameSpaces.OldApplicationPage,
  ]);
  const {
    oldApplicationInformation: { currentApplicationId, oldApplicationId },
  } = useAppSelector(getApplicationProcessData);
  const { continueWithApplicationLoading, checkOldApplicationsLoading } =
    useAppSelector(getLoadingFields);
  const dispatch = useAppDispatch();
  const [isContinueWithNewApplicationLoading, setIsContinueWithNewApplicationLoading] =
    useState<boolean>(false);
  const [isContinueWithPreviousApplicationLoading, setIsContinueWithPreviousApplicationLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if (applicationId && (!currentApplicationId || !oldApplicationId)) {
      dispatch(checkOldApplications({ applicationId }));
    }
  }, [applicationId]);

  useEffect(() => {
    if (!continueWithApplicationLoading) {
      setIsContinueWithNewApplicationLoading(continueWithApplicationLoading);
      setIsContinueWithPreviousApplicationLoading(continueWithApplicationLoading);
    }
  }, [continueWithApplicationLoading]);

  const handleContinueWithNewApplication = async () => {
    setIsContinueWithNewApplicationLoading(true);
    dispatch(
      continueWithApplication({
        applicationId: currentApplicationId,
      }),
    );
  };

  const handleContinueWithPreviousApplication = async () => {
    setIsContinueWithPreviousApplicationLoading(true);
    dispatch(
      continueWithApplication({
        applicationId: oldApplicationId,
      }),
    );
  };

  if (checkOldApplicationsLoading) {
    return <Loading />;
  }

  return (
    <PageLayout>
      <div className={styles.pageWrapper}>
        <Title className={styles.pageTitle}>
          {translate(OldApplicationPageTranslationKeys.Title, {
            ns: TranslationNameSpaces.OldApplicationPage,
          })}
        </Title>
        <Paragraph className={styles.pageDescription}>
          {translate(OldApplicationPageTranslationKeys.Description, {
            ns: TranslationNameSpaces.OldApplicationPage,
          })}
        </Paragraph>
        <div className={styles.buttonsWrapper}>
          <Button
            variant={ButtonVariant.Primary}
            size={ButtonSize.Large}
            onClick={handleContinueWithNewApplication}
            isLoading={isContinueWithNewApplicationLoading}
          >
            {translate(ButtonsTranslationKeys.StartNewApplication, {
              ns: TranslationNameSpaces.Buttons,
            })}
          </Button>
          <Button
            variant={ButtonVariant.Secondary}
            size={ButtonSize.Middle}
            onClick={handleContinueWithPreviousApplication}
            isLoading={isContinueWithPreviousApplicationLoading}
          >
            {translate(ButtonsTranslationKeys.ProceedWithPreviousApplication, {
              ns: TranslationNameSpaces.Buttons,
            })}
          </Button>
        </div>
      </div>
    </PageLayout>
  );
};

export default OldApplicationPage;
