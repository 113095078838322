import BaseApi, { FetchMethodType } from 'api/BaseApi';

interface ISessionsApi {
  logout(withoutStateReset: boolean): Promise<boolean>;
}

export default class SessionsRestApi extends BaseApi implements ISessionsApi {
  public async logout(withoutStateReset: boolean): Promise<boolean> {
    await this.fetch('/sessions', {
      method: FetchMethodType.DELETE,
    });

    return withoutStateReset;
  }
}
