import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './UploadFileInput.module.scss';
import clsx from 'clsx';
import { FileIcon, PaperClipIcon, TrashIcon } from 'static/images';
import { useTranslation } from 'react-i18next';
import { InputLabelsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import Paragraph from 'components/Paragraph';
import { TypographySize } from 'enums/TypographySize';

interface IUploadFileInputProps {
  file: File | null;
  changeFile: (file: File | null) => void;
  onDrop: (acceptedFiles: File[]) => void;
  description: string;
  error: string | null;
  setError: (message: string | null) => void;
  className?: string;
}

const ONE_BYTE = 1024;

const bytesToMegabytes = (value: number) => {
  return (value / (ONE_BYTE * ONE_BYTE)).toFixed(1);
};

const UploadFileInput: FC<IUploadFileInputProps> = ({
  file,
  changeFile,
  onDrop,
  description,
  error,
  setError,
  className,
}) => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.InputLabels,
  ]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  const removeFile = () => {
    changeFile(null);
    setError(null);
  };

  return (
    <>
      <div className={clsx(styles.dropzone, className, isDragActive && styles.draggingDropzone)} {...getRootProps()}>
        <input {...getInputProps()} />
        <PaperClipIcon />
        <Paragraph className={clsx(styles.inputDescription, isDragActive && styles.draggingInputDescription)}>
          {translate(InputLabelsTranslationKeys.VerificationDocument, {
            ns: TranslationNameSpaces.InputLabels,
          })}
        </Paragraph>
      </div>
      {description && <Paragraph>{description}</Paragraph>}
      {file && (
        <div className={styles.uploadedFileContainer}>
          <FileIcon />
          <div className={styles.fileInfo}>
            <Paragraph variant={TypographySize.Large}>{file.name}</Paragraph>
            <Paragraph className={styles.fileSize}>{bytesToMegabytes(file.size)} MB</Paragraph>
          </div>
          <TrashIcon className={styles.deleteFileButton} onClick={removeFile} />
        </div>
      )}
      {file && error && <Paragraph variant={TypographySize.Large} className={styles.errorMessage}>{error}</Paragraph>}
    </>
  );
};

export default UploadFileInput;
