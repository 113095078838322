import { Trans, useTranslation } from 'react-i18next';
import { TranslationNameSpaces, WelcomeToProFundTermTranslationKeys } from 'enums/Translation';

import Paragraph from 'components/Paragraph';
import React from 'react';
import TermWrapper from 'components/Terms/TermWrapper';
import Title from 'components/Title';
import { TypographySize } from 'enums/TypographySize';

const WelcomeToProFundTerm = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.WelcomeToProFundTerm);
  return (
    <TermWrapper termId={TranslationNameSpaces.WelcomeToProFundTerm}>
      <Title variant="h1" marginBottom="16">
        {translate(WelcomeToProFundTermTranslationKeys.Title)}
      </Title>
      <Trans
        i18nKey={WelcomeToProFundTermTranslationKeys.Description}
        t={translate}
        components={{
          P: <Paragraph variant={TypographySize.Middle} />,
          Link: <a href={translate(WelcomeToProFundTermTranslationKeys.Link)!} />,
        }}
      />
    </TermWrapper>
  );
};

export default WelcomeToProFundTerm;
