import React, { useEffect, useState } from 'react';
import PlaidBankVerificationPage from './PlaidBankVerification';
import WhatNextPage from './WhatNext/WhatNext';
import { BankConnectionType } from 'components/BankConnectionCardWrapper/BankConnectionCardWrapper';
import DwollaBankVerificationPage from './DwollaBankVerification/DwollaBankVerification';
import SelectVerification from './SelectVerification/SelectVerification';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getApplicationById, updateApplication } from 'thunks';
import { setShowOffersWithoutEven } from 'handlers/portal/offersSlice';
import { openToaster } from 'components/Toaster/Toaster';
import { useParams } from 'react-router-dom';
import { getApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { resetSelectedOffer } from 'utils/resetSelectedOffer';
import { ApplicationVariable } from 'enums/ApplicationVariable';
import Loading from 'components/Loading';
import { useAppDispatch } from 'hooks/reduxHooks';
import { IApplication } from 'api/digifi/los/ApplicationsApi';
import { getApplicationLabelIdByEnvironment } from 'utils/applicationLabelsHelpers';
import { ApplicationLabelName, ApplicationLabelType } from 'enums/ApplicationLabelId';
import { DateTimeFormat, formatDate } from 'utils/profund/dateFormat';

export enum BankVerificationProcessSteps {
  PlaidBankVerification = 'BankVerification',
  DwollaBankVerification = 'DwollaBankVerification',
  SelectBankVerification = 'SelectBankVerification',
  WhatNext = 'WhatNext',
}

const LIVE_INTERMEDIARY_MERCHANT_STATUS = 'Live';

const BankVerificationProcess = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { applicationId } = useParams();
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(BankVerificationProcessSteps.WhatNext);
  const [bankConnectionType, setBankConnectionType] = useState<BankConnectionType | null>(null);
  const [hasDwollaMicroDepositsError, setHasDwollaMicroDepositsError] = useState(false);
  const [hasPlaidError, setHasPlaidError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState<IApplication | null>(null);

  const handleSetDwollaMicroDepositsError = () => {
    setHasDwollaMicroDepositsError(true);
    setHasPlaidError(false);
    setStep(BankVerificationProcessSteps.SelectBankVerification);
  };

  const handlePlaidVerificationError = () => {
    setHasPlaidError(true);
    setHasDwollaMicroDepositsError(false);
    setStep(BankVerificationProcessSteps.SelectBankVerification);
  };

  const handleShowOffersWithoutEven = async () => {
    dispatch(setShowOffersWithoutEven(true));

    await dispatchWithUnwrap(
      updateApplication({
        applicationId: applicationId!,
        params: {
          statusId: getApplicationStatusIdByEnvironment(ApplicationStatusName.OfferSelection),
          variables: resetSelectedOffer(
            application?.variables[ApplicationVariable.InitialLoanAmount] as number,
          ),
        },
      }),
    );
  };

  useEffect(() => {
    const getApplicationVariables = async () => {
      try {
        setLoading(true);
        const currentApplication = await dispatchWithUnwrap(getApplicationById(applicationId!));

        setApplication(currentApplication);
      } catch {
        openToaster.error();
      } finally {
        setLoading(false);
      }
    };

    getApplicationVariables();
  }, []);

  const handleConnectLaterClick = async () => {
    try {
      setLoading(true);

      const currentLabelIds = application?.labels.map((label) => label.id) || [];

      await dispatchWithUnwrap(
        updateApplication({
          applicationId: applicationId!,
          params: {
            statusId: getApplicationStatusIdByEnvironment(ApplicationStatusName.Approved),
            labelsIds: [
              ...currentLabelIds,
              getApplicationLabelIdByEnvironment(ApplicationLabelName.ApprovedDocsPending),
              getApplicationLabelIdByEnvironment(ApplicationLabelName.ConnectBankLater),
            ] as ApplicationLabelType[],
            variables: {
              [ApplicationVariable.ApplicationApprovalDate]: formatDate(
                new Date().toISOString(),
                DateTimeFormat.Short,
              ),
            },
          },
        }),
      );

      const finalOfferLink = application?.variables[ApplicationVariable.FinalOfferLink];

      if (finalOfferLink) {
        window.open(finalOfferLink as string, '_self');
      }
    } catch {
      openToaster.error();
    } finally {
      setLoading(false);
    }
  };

  const hasWatercressOffers =
    application?.variables[ApplicationVariable.IntermediaryWatercressId] &&
    application?.variables[ApplicationVariable.IntermediaryWatercressMerchantStatus] ===
      LIVE_INTERMEDIARY_MERCHANT_STATUS &&
    application?.variables[ApplicationVariable.WatercressInitialOffersAmount];
  
  const hasUpgradeOffers = application?.variables[ApplicationVariable.UpgradeInitialOffersAmount];

  const fundedByEven = Boolean(application?.variables[ApplicationVariable.FundedByEven]);

  const canShowOffersWithoutEven = (hasWatercressOffers || hasUpgradeOffers) && !fundedByEven;

  if (loading) {
    return <Loading />;
  }

  const getCurrentPageByStep = () => {
    const pageByStep = {
      [BankVerificationProcessSteps.WhatNext]: (
        <WhatNextPage
          onSetStep={setStep}
          currentBankConnectionType={bankConnectionType}
          onSelectBankConnectionType={(value: BankConnectionType) => setBankConnectionType(value)}
          onShowOffersWithoutEven={handleShowOffersWithoutEven}
          canShowOffersWithoutEven={canShowOffersWithoutEven as boolean}
          fundedByEven={fundedByEven}
        />
      ),
      [BankVerificationProcessSteps.PlaidBankVerification]: (
        <PlaidBankVerificationPage onPlaidVerificationError={handlePlaidVerificationError} />
      ),
      [BankVerificationProcessSteps.DwollaBankVerification]: (
        <DwollaBankVerificationPage
          onSetDwollaMicroDepositsError={handleSetDwollaMicroDepositsError}
          onSetStep={setStep}
        />
      ),
      [BankVerificationProcessSteps.SelectBankVerification]: (
        <SelectVerification
          hasDwollaMicroDepositsError={hasDwollaMicroDepositsError}
          hasPlaidError={hasPlaidError}
          onSetStep={setStep}
          onShowOffersWithoutEven={handleShowOffersWithoutEven}
          canShowOffersWithoutEven={canShowOffersWithoutEven as boolean}
          fundedByEven={fundedByEven}
          onConnectLaterClick={handleConnectLaterClick}
        />
      ),
    };

    return pageByStep[step];
  };

  return getCurrentPageByStep();
};

export default BankVerificationProcess;
