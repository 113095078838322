import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import { AppRoutes } from 'routes/AppRoutes';
import { IApplication } from 'api/digifi/los/ApplicationsApi';
import {
  ApplicationsPageTranslationKeys,
  MenuTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import { ApplicationVariable } from 'enums/ApplicationVariable';
import { ApplicationStatusIdType } from 'enums/ApplicationStatusId';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { ApplicationLabelName } from 'enums/ApplicationLabelId';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useAppSelector } from 'hooks/reduxHooks';
import { getBorrowerApplications } from 'thunks';
import { getBorrowerApplicationData, getLoadingFields } from 'handlers/selectors';
import { matchApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';
import { getApplicationLabelIdByEnvironment } from 'utils/applicationLabelsHelpers';
import PageLayout, { LayoutSize } from 'components/PageLayout';
import Loading from 'components/Loading';
import Menu from 'components/Menu';
import Title from 'components/Title';
import ApplicationCard from 'components/ApplicationCard';
import { ArrowDownIcon } from 'static/images';
import styles from './Applications.module.scss';
import { DwollaDocumentVerificationProcess } from 'enums/DocumentVerification';
import VerifyDwollaMicroDepositsCard from 'components/VerifyDwollaMicroDepositsCard';
import { DwollaMicroDepositsStatus } from 'enums/DwollaMicrodepositsStatus';
import { getNeedNavigateToApplicationFlow } from 'utils/getNeedNavigateToApplicationFlow';

const getAreApplicationDocsPending = (application: IApplication) => {
  return (
    matchApplicationStatusIdByEnvironment(application.status.id, ApplicationStatusName.Approved) &&
    application.labels
      .map((label) => label.id)
      .includes(getApplicationLabelIdByEnvironment(ApplicationLabelName.ApprovedDocsPending))
  );
};

const Applications = () => {
  const navigate = useNavigate();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const [isPriorOpen, setIsPriorOpen] = useState(false);
  const [dwollaMicroDepositsApplication, setDwollaMicroDepositsApplication] =
    useState<IApplication | null>(null);

  const { t: translate } = useTranslation([
    TranslationNameSpaces.Menu,
    TranslationNameSpaces.ApplicationsPage,
  ]);

  const { items } = useAppSelector(getBorrowerApplicationData);
  const { isGetApplicationsInProgress } = useAppSelector(getLoadingFields);

  useEffect(() => {
    dispatchWithUnwrap(getBorrowerApplications());
  }, []);

  useEffect(() => {
    const setMicroDepositsCard = () => {
      const card = items.active.find((item) => {
        const dwollaMicroDepositsStatus = item.variables[
          ApplicationVariable.DwollaMicrodepositsStatus
        ] as DwollaMicroDepositsStatus;

        return (
          item.labels.some(
            ({ id }) =>
              id ===
              getApplicationLabelIdByEnvironment(
                ApplicationLabelName.DwollaMicroDepositsVerification,
              ),
          ) &&
          [
            DwollaMicroDepositsStatus.OnTheWay,
            DwollaMicroDepositsStatus.TransitionFailed,
            DwollaMicroDepositsStatus.PostedToBank,
          ].includes(dwollaMicroDepositsStatus)
        );
      }) as IApplication;

      setDwollaMicroDepositsApplication(card || null);
    };

    setMicroDepositsCard();
  }, [items]);

  if (isGetApplicationsInProgress) {
    return <Loading />;
  }

  const handleClick = (
    applicationId: string,
    needNavigateToApplicationFlow: boolean,
    redirectUrl?: string,
    statusId?: string,
  ) => {
    if (needNavigateToApplicationFlow) {
      const isOfferAgreementStatus = statusId && 
        matchApplicationStatusIdByEnvironment(statusId, ApplicationStatusName.OfferAgreement);
      
      if (isOfferAgreementStatus) {
        navigate(`${AppRoutes.ApplicationProcess}/${applicationId}#web-lead`);
      } else {
        navigate(`${AppRoutes.ApplicationProcess}/${applicationId}`);
      }

      return;
    }

    if (redirectUrl) {
      window.open(redirectUrl, '_self');

      return;
    }

    navigate(`${AppRoutes.Applications}/${applicationId}`);
  };

  const getHasMicroDepositsLabel = (application: IApplication) => {
    return application.labels.some(
      ({ id }) => id === getApplicationLabelIdByEnvironment(ApplicationLabelName.MicroDeposits),
    );
  };

  const renderContent = () => {
    if (!items.active.length && !items.prior.length) {
      return (
        <Title variant="h1" marginBottom="24">
          {translate(ApplicationsPageTranslationKeys.ApplicationsNotFound, {
            ns: TranslationNameSpaces.ApplicationsPage,
          })}
        </Title>
      );
    }

    return (
      <>
        <Title variant="h1" marginBottom="24">
          {translate(MenuTranslationKeys.Applications)}
        </Title>
        <div className={styles.activeApplications}>
          <Title variant="h3" marginBottom="24">
            {translate(ApplicationsPageTranslationKeys.ActiveTitle, {
              ns: TranslationNameSpaces.ApplicationsPage,
            })}
          </Title>
          {items.active.length ? (
            <div className={styles.applicationsContainer}>
              {dwollaMicroDepositsApplication ? (
                <VerifyDwollaMicroDepositsCard
                  applicationId={dwollaMicroDepositsApplication.id}
                  dwollaMicroDepositsStatus={
                    dwollaMicroDepositsApplication.variables[
                      ApplicationVariable.DwollaMicrodepositsStatus
                    ] as DwollaMicroDepositsStatus
                  }
                  setDwollaMicroDepositsApplication={setDwollaMicroDepositsApplication}
                />
              ) : null}
              {items.active.map((item) => {
                const areDocsPending = getAreApplicationDocsPending(item);
                const hasMicroDepositsLabel = getHasMicroDepositsLabel(item);
                const dwollaDocumentVerificationProcess = item.variables[
                  ApplicationVariable.DwollaDocumentVerificationProcess
                ] as DwollaDocumentVerificationProcess;

                const needNavigateToApplicationFlow = getNeedNavigateToApplicationFlow(item);

                const redirectUrl = areDocsPending
                  ? (item.variables[ApplicationVariable.FinalOfferLink] as string)
                  : undefined;

                return (
                  <ApplicationCard
                    key={item.id}
                    contractorName={
                      (item.variables[ApplicationVariable.IntermediaryDba] as string) ||
                      (item.variables[ApplicationVariable.IntermediaryName] as string)
                    }
                    updatedAt={item.updatedAt}
                    status={{
                      name: item.status.name,
                      id: item.status.id as ApplicationStatusIdType,
                    }}
                    areDocsPending={areDocsPending || false}
                    loanAmount={item.variables[ApplicationVariable.LoanAmount] as number}
                    onClick={() => handleClick(item.id, needNavigateToApplicationFlow, redirectUrl, item.status.id)}
                    isButtonLoading={false}
                    isActive
                    hasMicroDepositsLabel={hasMicroDepositsLabel}
                    dwollaDocumentVerificationProcess={dwollaDocumentVerificationProcess}
                    fundedByEven={item.variables[ApplicationVariable.FundedByEven]}
                    labelIds={item.labels.map((label) => label.id)}
                  />
                );
              })}
            </div>
          ) : (
            <p className={styles.noApplications}>
              {translate(ApplicationsPageTranslationKeys.NoOffers, {
                ns: TranslationNameSpaces.ApplicationsPage,
              })}
            </p>
          )}
        </div>
        <div>
          {items.prior.length ? (
            <Collapsible
              contentContainerTagName="div"
              transitionTime={300}
              transitionCloseTime={300}
              easing="ease-in-out"
              overflowWhenOpen="visible"
              trigger={
                <div className={styles.collapseTrigger}>
                  <Title variant="h3">
                    {translate(ApplicationsPageTranslationKeys.PriorTitle, {
                      ns: TranslationNameSpaces.ApplicationsPage,
                    })}
                  </Title>
                  <ArrowDownIcon className={clsx(isPriorOpen && styles.arrowIsOpen)} />
                </div>
              }
              open={isPriorOpen}
              handleTriggerClick={() => setIsPriorOpen(!isPriorOpen)}
            >
              <div className={styles.applicationsContainer}>
                {items.prior.map((item) => {
                  const hasMicroDepositsLabel = getHasMicroDepositsLabel(item);
                  const dwollaDocumentVerificationProcess = item.variables[
                    ApplicationVariable.DwollaDocumentVerificationProcess
                  ] as DwollaDocumentVerificationProcess;

                  const needNavigateToApplicationFlow = getNeedNavigateToApplicationFlow(item);

                  return (
                    <ApplicationCard
                      key={item.id}
                      contractorName={
                        (item.variables[ApplicationVariable.IntermediaryDba] as string) ||
                        (item.variables[ApplicationVariable.IntermediaryName] as string)
                      }
                      updatedAt={item.updatedAt}
                      status={{
                        name: item.status.name,
                        id: item.status.id as ApplicationStatusIdType,
                      }}
                      areDocsPending={false}
                      loanAmount={item.variables[ApplicationVariable.LoanAmount] as number}
                      onClick={() => handleClick(item.id, needNavigateToApplicationFlow)}
                      hasMicroDepositsLabel={hasMicroDepositsLabel}
                      dwollaDocumentVerificationProcess={dwollaDocumentVerificationProcess}
                      labelIds={item.labels.map((label) => label.id)}
                    />
                  );
                })}
              </div>
            </Collapsible>
          ) : (
            <div>
              <Title variant="h3" marginBottom="24">
                {translate(ApplicationsPageTranslationKeys.PriorTitle, {
                  ns: TranslationNameSpaces.ApplicationsPage,
                })}
              </Title>
              <p className={styles.noApplications}>
                {translate(ApplicationsPageTranslationKeys.NoOffers, {
                  ns: TranslationNameSpaces.ApplicationsPage,
                })}
              </p>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <Menu activeMenuItem={MenuTranslationKeys.Applications} />
      <PageLayout layoutSize={LayoutSize.Big}>{renderContent()}</PageLayout>
    </div>
  );
};

export default Applications;
