import React from 'react';
import { AppRoutes } from 'routes/AppRoutes';
import Checkbox from 'components/Checkbox';
import {
  LinksTranslationKeys,
  TranslationNameSpaces,
  WhatNextPageTranslationKeys,
} from 'enums/Translation';
import Link from 'components/Link';
import { useTranslation } from 'react-i18next';

interface IPortalAndDwollaTermsCheckboxProps {
  checked: boolean;
  setIsChecked: (checked: boolean) => void;
}

const PortalAndDwollaTermsCheckbox = ({
  checked,
  setIsChecked,
}: IPortalAndDwollaTermsCheckboxProps) => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.Buttons,
    TranslationNameSpaces.Links,
    TranslationNameSpaces.WhatNextPage,
  ]);

  return (
    <Checkbox
      label={
        <>
          {translate(WhatNextPageTranslationKeys.CheckboxLabelPart1, {
            ns: TranslationNameSpaces.WhatNextPage,
          })}{' '}
          <Link href={`${location.origin}${AppRoutes.TermsOfService}`} target="_blank">
            {translate(LinksTranslationKeys.OurTermsOfService, {
              ns: TranslationNameSpaces.Links,
            })}
          </Link>{' '}
          {translate(WhatNextPageTranslationKeys.CheckboxLabelAnd, {
            ns: TranslationNameSpaces.WhatNextPage,
          })}{' '}
          <Link href="https://profund.net/privacy-policy" target="_blank">
            {translate(LinksTranslationKeys.PrivacyPolicy, { ns: TranslationNameSpaces.Links })}
          </Link>{' '}
          {translate(WhatNextPageTranslationKeys.CheckboxLabelPart2, {
            ns: TranslationNameSpaces.WhatNextPage,
          })}{' '}
          <Link
            href="https://www.dwolla.com/legal/dwolla-account-terms-of-service/"
            target="_blank"
          >
            {translate(LinksTranslationKeys.DwollasTermsOfService, {
              ns: TranslationNameSpaces.Links,
            })}
          </Link>{' '}
          {translate(WhatNextPageTranslationKeys.CheckboxLabelAnd, {
            ns: TranslationNameSpaces.WhatNextPage,
          })}{' '}
          <Link href="https://www.dwolla.com/legal/privacy/" target="_blank">
            {translate(LinksTranslationKeys.PrivacyPolicy, { ns: TranslationNameSpaces.Links })}
          </Link>
          .
        </>
      }
      isChecked={checked}
      onChange={() => setIsChecked(!checked)}
    />
  );
};

export default PortalAndDwollaTermsCheckbox;
