import React from 'react';
import styles from './WhatNextBankConnectionDashboard.module.scss';
import BankConnectionCardWrapper from 'components/BankConnectionCardWrapper';
import Checkbox from 'components/Checkbox/Checkbox';
import { BankConnectionType } from 'components/BankConnectionCardWrapper/BankConnectionCardWrapper';
import { useTranslation } from 'react-i18next';
import { BankVerificationPageTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

interface IWhatNextBankConnectionDashboard {
  onSelect: (bankConnectionType: BankConnectionType) => void;
  currentBankConnectionType: BankConnectionType | null;
}

const WhatNextBankConnectionDashboard = ({
  onSelect,
  currentBankConnectionType,
}: IWhatNextBankConnectionDashboard) => {
  const { t: translate } = useTranslation(TranslationNameSpaces.BankVerificationPage);

  return (
    <div className={styles.container}>
      <BankConnectionCardWrapper bankConnectionType={BankConnectionType.Instant} showSelect>
        <Checkbox
          isChecked={currentBankConnectionType === BankConnectionType.Instant}
          onChange={() => onSelect(BankConnectionType.Instant)}
          className={styles.checkbox}
          inputWrapperClassName={styles.inputWrapperClassName}
        />
      </BankConnectionCardWrapper>
      <div className={styles.divider}>{translate(BankVerificationPageTranslationKeys.Or)}</div>
      <BankConnectionCardWrapper bankConnectionType={BankConnectionType.Standard} showSelect>
        <Checkbox
          isChecked={currentBankConnectionType === BankConnectionType.Standard}
          onChange={() => onSelect(BankConnectionType.Standard)}
          className={styles.checkbox}
          inputWrapperClassName={styles.inputWrapperClassName}
        />
      </BankConnectionCardWrapper>
    </div>
  );
};

export default WhatNextBankConnectionDashboard;
