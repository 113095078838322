import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { IApplication } from 'api/digifi/los/ApplicationsApi';

enum PaymentProcessorEndpoints {
  CreateBorrowerFundingSource = '/create-borrower-funding-source',
  CreateIntermediaryFundingSource = '/create-intermediary-funding-source',
  CreateBorrowerDwollaMicroDepositFundingSource = '/create-borrower-dwolla-micro-deposits-funding-source',
  CreateIntermediaryDwollaMicroDepositFundingSource = '/create-intermediary-dwolla-micro-deposits-funding-source',
  VerifyDwollaMicroDeposits = '/verify-dwolla-micro-deposits',
  VerifyIntermediaryDwollaMicroDeposits = '/verify-intermediary-dwolla-micro-deposits',
}

export interface ICreateBorrowerDwollaMicroDepositsFundingSourceBody {
  applicationId: string;
  nameOnAccount: string;
  bankAccountType: string;
  bankRoutingNumber: string;
  bankAccountNumber: string;
}
export interface ICreateIntermediaryDwollaMicroDepositsFundingSourceBody {
  token: string;
  nameOnAccount: string;
  bankAccountType: string;
  bankRoutingNumber: string;
  bankAccountNumber: string;
}

export interface IVerifyDwollaMicroDepositsBody {
  applicationId: string;
  firstAmount: string;
  secondAmount: string;
}

export interface IVerifyIntermediaryDwollaMicroDepositsBody extends Omit<IVerifyDwollaMicroDepositsBody, 'applicationId'> {
  token: string;
}

export default class PaymentProcessorApi extends BaseApi {
  private readonly endpointBase = '/payment-processor';

  public async createBorrowerFundingSource(applicationId: string) {
    const response = await this.fetch<{ redirectUrl: string }>(
      `${this.endpointBase}${PaymentProcessorEndpoints.CreateBorrowerFundingSource}`,
      {
        method: FetchMethodType.POST,
        body: {
          applicationId,
        },
      },
    );

    return response.redirectUrl;
  }

  public async createIntermediaryFundingSource(token: string) {
    const response = await this.fetch<{ url: string }>(
      `${this.endpointBase}${PaymentProcessorEndpoints.CreateIntermediaryFundingSource}`,
      {
        method: FetchMethodType.POST,
        body: {
          token,
        },
      },
    );

    return response.url;
  }

  public async createBorrowerDwollaMicroDepositFundingSource(
    body: ICreateBorrowerDwollaMicroDepositsFundingSourceBody,
  ) {
    const response = await this.fetch<{ redirectUrl: string }>(
      `${this.endpointBase}${PaymentProcessorEndpoints.CreateBorrowerDwollaMicroDepositFundingSource}`,
      {
        method: FetchMethodType.POST,
        body,
      },
    );

    return response.redirectUrl;
  }

  public async createIntermediaryDwollaMicroDepositFundingSource(
    body: ICreateIntermediaryDwollaMicroDepositsFundingSourceBody,
  ) {
    const response = await this.fetch<{ redirectUrl: string }>(
      `${this.endpointBase}${PaymentProcessorEndpoints.CreateIntermediaryDwollaMicroDepositFundingSource}`,
      {
        method: FetchMethodType.POST,
        body,
      },
    );

    return response.redirectUrl;
  }

  public async verifyDwollaMicroDeposits(body: IVerifyDwollaMicroDepositsBody) {
    const response = await this.fetch<IApplication | { redirectUrl: string }>(
      `${this.endpointBase}${PaymentProcessorEndpoints.VerifyDwollaMicroDeposits}`,
      {
        method: FetchMethodType.POST,
        body,
      },
    );

    return response;
  }

  public async verifyIntermediaryDwollaMicroDeposits(body: IVerifyIntermediaryDwollaMicroDepositsBody) {
    const response = await this.fetch<{ redirectUrl: string }>(
      `${this.endpointBase}${PaymentProcessorEndpoints.VerifyIntermediaryDwollaMicroDeposits}`,
      {
        method: FetchMethodType.POST,
        body,
      },
    );

    return response;
  }
}
