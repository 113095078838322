import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationProcessActionTypes } from 'enums/ApplicationProcessActionTypes';
import ApplicationProcessApi, {
  ICheckOldApplicationsBody,
} from 'api/digifi/portal/ApplicationProcessApi';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createCheckOldApplicationsThunk = (api: ApplicationProcessApi) =>
  createAsyncThunk(
    ApplicationProcessActionTypes.CheckOldApplications,
    async (body: ICheckOldApplicationsBody, thunkApi) => {
      try {
        return await api.checkOldApplications(body);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
