import React, { FC } from 'react';
import Title from 'components/Title';
import { ChevronDownIcon, ChevronUpIcon } from 'static/images';
import { OfferCategoryType } from 'pages/ApplicationProcess/OffersPage/offersUtils';

import styles from './OffersCategory.module.scss';

interface IOfferCategoryProps {
  isActive: boolean;
  categoryName: OfferCategoryType;
  setActive: (categoryName: OfferCategoryType) => void;
}

const OfferCategory: FC<IOfferCategoryProps> = ({
  categoryName,
  setActive,
  isActive,
  children,
}) => {
  return (
    <div className={styles.categoryWrapper} onClick={() => setActive(categoryName)}>
      <Title variant="h3">{children}</Title>
      {isActive ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </div>
  );
};

export default OfferCategory;
