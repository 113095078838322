import React, { useState } from 'react';
import styles from './IntermediaryDwollaVerification.module.scss';
import {
  BankVerificationPageTranslationKeys,
  DwollaInfoPopupTranslationKeys,
  DwollaMicrodepositsPopupTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import PageLayout, { LayoutSize } from 'components/PageLayout';
import AdvertiserDisclosure from 'components/AdvertiserDisclosure';
import Title from 'components/Title';
import { useTranslation } from 'react-i18next';
import { DwollaGuide, DwollaLogo, InfoCircleIcon } from 'static/images';
import DwollaBankVerificationForm from 'components/DwollaBankVerificationForm';
import { useParams } from 'react-router-dom';
import { BankVerificationProcessSteps } from 'pages/ApplicationProcess/BankVerificationProcess/BankVerificationProcess';
import Loading from 'components/Loading';
import Popup from 'components/Popup';
import DwollaPopupContent from 'components/BankConnectionCardWrapper/DwollaPopupContent';
import { createIntermediaryDwollaMicroDepositFundingSource } from 'thunks';
import { ICreateIntermediaryDwollaMicroDepositsFundingSourceBody } from 'api/digifi/portal/PaymentProcessorApi';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import DwollaMicrodepositsPopupContent from 'components/BankConnectionCardWrapper/DwollaMicrodepositsPopupContent';

interface IDwollaBankVerificationProps {
  onSetDwollaMicroDepositsError: () => void;
  onSetStep: (step: BankVerificationProcessSteps) => void;
}

const IntermediaryDwollaVerification = ({
  onSetDwollaMicroDepositsError,
  onSetStep,
}: IDwollaBankVerificationProps) => {
  const { token } = useParams();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { t: translate } = useTranslation([
    TranslationNameSpaces.BankVerificationPage,
    TranslationNameSpaces.Popups,
  ]);
  const [loading, setLoading] = useState(false);
  const [isInformationalPopupOpen, setIsInformationalPopupOpen] = useState(false);
  const [isMicrodepositsPopupOpen, setIsMicrodepositsPopupOpen] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');

  const handleSubmitForm = async (values: Record<string, string>) => {
    try {
      setLoading(true);
      
      const url = await dispatchWithUnwrap(
        createIntermediaryDwollaMicroDepositFundingSource({
          ...values,
          token,
        } as ICreateIntermediaryDwollaMicroDepositsFundingSourceBody),
      );
      
      if (url) {
        setRedirectUrl(url);
        setIsMicrodepositsPopupOpen(true);
      }
    } catch (error) {
      onSetDwollaMicroDepositsError();
    } finally {
      setLoading(false);
    }
  };

  const handleRedirect = () => {
    window.location.href = redirectUrl;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <PageLayout layoutSize={LayoutSize.Big} className={styles.pageLayoutClassName}>
        <div>
          <AdvertiserDisclosure
            onBackClick={() => onSetStep(BankVerificationProcessSteps.WhatNext)}
            showDisclosure={false}
          />
          <Title variant="h1" marginBottom="16">
            {translate(BankVerificationPageTranslationKeys.Title, {
              ns: TranslationNameSpaces.BankVerificationPage,
            })}
          </Title>
        </div>
        <div className={styles.mainContent}>
          <div className={styles.subTitle}>
            <div>
              {translate(BankVerificationPageTranslationKeys.DwollaVerificationSubtitle, {
                ns: TranslationNameSpaces.BankVerificationPage,
              })}
            </div>
            <InfoCircleIcon onClick={() => setIsInformationalPopupOpen(true)} />
          </div>
          <div className={styles.poweredBy}>
            <span>
              {translate(BankVerificationPageTranslationKeys.PoweredBy, {
                ns: TranslationNameSpaces.BankVerificationPage,
              })}
            </span>
            <DwollaLogo />
          </div>
          <div className={styles.guideContainer}>
            <DwollaGuide />
          </div>
          <DwollaBankVerificationForm onSubmitForm={handleSubmitForm} />
        </div>
        <Popup
          isOpen={isInformationalPopupOpen}
          onClose={() => setIsInformationalPopupOpen(false)}
          title={
            translate(DwollaInfoPopupTranslationKeys.Title, {
              ns: TranslationNameSpaces.Popups,
            }) as string
          }
        >
          <DwollaPopupContent />
        </Popup>
        <Popup
          isOpen={isMicrodepositsPopupOpen}
          onClose={handleRedirect}
          title={
            translate(DwollaMicrodepositsPopupTranslationKeys.Title, {
              ns: TranslationNameSpaces.Popups,
            }) as string
          }
        >
          <DwollaMicrodepositsPopupContent
            description={translate(DwollaMicrodepositsPopupTranslationKeys.Description, {
              ns: TranslationNameSpaces.Popups,
            })}
            onButtonClick={handleRedirect}
          />
        </Popup>
      </PageLayout>
    </div>
  );
};

export default IntermediaryDwollaVerification;
