import Menu from 'components/Menu';
import PageLayout, { LayoutSize } from 'components/PageLayout';
import {
  ButtonsTranslationKeys,
  InputLabelsTranslationKeys,
  MenuTranslationKeys,
  TranslationNameSpaces,
  UploadDocumentVerificationPageTranslationKeys,
} from 'enums/Translation';
import React, { FC, useState } from 'react';
import styles from './PlaidBankVerification/PlaidBankVerification.module.scss';
import UploadFileInput from 'components/UploadFileInput';
import { useNavigate, useParams } from 'react-router-dom';
import { uploadBankVerificationDocument } from 'thunks';
import SelectInput from 'components/SelectInput';
import { useTranslation } from 'react-i18next';
import Title from 'components/Title';
import Paragraph from 'components/Paragraph';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { useTranslatedDropdownOptions } from 'components/BorrowerFinancialInfo/constants';
import {
  DocumentVerificationVariable,
  DwollaDocumentVerificationProcess,
} from 'enums/DocumentVerification';
import { IOptionType } from 'components/SelectInput/SelectInput';
import { VariableValue } from 'types/VariableTypes';
import { AppRoutes } from 'routes/AppRoutes';
import { useAppDispatch } from 'hooks/reduxHooks';

const MAX_FILE_SIZE = 10485760; // 10mb
const ACCEPTED_EXTENSIONS = ['jpg', 'jpeg', 'png'];
const INCORRECT_FORMAT_ERROR = 'The uploaded file must be in a .jpg, .jpeg, or .png format';
const MAX_FILE_SIZE_ERROR = 'The uploaded file must be less than 10MB in size';

interface IUploadDocumentPageProps {
  dwollaDocumentVerificationProcess: DwollaDocumentVerificationProcess | null;
}

const UploadDocumentPage: FC<IUploadDocumentPageProps> = ({
  dwollaDocumentVerificationProcess,
}) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation([
    TranslationNameSpaces.UploadDocumentVerificationPage,
    TranslationNameSpaces.Buttons,
    TranslationNameSpaces.InputLabels,
  ]);
  const navigate = useNavigate();

  const { VERIFICATION_DOCUMENT_TYPE_OPTIONS } = useTranslatedDropdownOptions();

  const { applicationId } = useParams();

  const [documentType, setDocumentType] = useState<IOptionType | null>(null);
  const [document, setDocument] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadFileError, setUploadFileError] = useState<string | null>(null);

  const handleDocumentTypeChange = (value: VariableValue) => {
    const currentOption = VERIFICATION_DOCUMENT_TYPE_OPTIONS.find(
      (option) => option.value === value,
    );

    setDocumentType(currentOption as IOptionType);
  };

  const handleChangeDocument = (file: File | null) => {
    setDocument(file);
  };

  const uploadDocument = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', document as File);
      formData.append('applicationId', applicationId!);
      formData.append('documentType', documentType!.value);

      await dispatch(uploadBankVerificationDocument(formData));

      navigate(AppRoutes.Applications);
    } finally {
      setIsLoading(false);
    }
  };

  const getError = (uploadedFile: File) => {
    const [, extension] = uploadedFile.name.split(/\.(?=[^.]+$)/);

    if (!ACCEPTED_EXTENSIONS.includes(extension)) {
      setUploadFileError(INCORRECT_FORMAT_ERROR);

      return;
    }

    if (MAX_FILE_SIZE < uploadedFile.size) {
      setUploadFileError(MAX_FILE_SIZE_ERROR);
    }
  };

  const handleDropDocument = (acceptedFiles: File[]) => {
    setUploadFileError(null);
    handleChangeDocument(acceptedFiles[0]);
    getError(acceptedFiles[0]);
  };

  const isSubmitButtonDisabled = !document || !documentType || Boolean(uploadFileError);
  const descriptionText =
    dwollaDocumentVerificationProcess === DwollaDocumentVerificationProcess.Needed
      ? UploadDocumentVerificationPageTranslationKeys.Description
      : UploadDocumentVerificationPageTranslationKeys.RetryUploadDescription;

  return (
    <>
      <Menu activeMenuItem={MenuTranslationKeys.Applications} />
      <PageLayout layoutSize={LayoutSize.Big} className={styles.pageLayoutClassName}>
        <div>
          <Title variant="h1" marginBottom="16">
            {translate(UploadDocumentVerificationPageTranslationKeys.Title, {
              ns: TranslationNameSpaces.UploadDocumentVerificationPage,
            })}
          </Title>
          <Paragraph marginBottom="24">
            {translate(descriptionText, {
              ns: TranslationNameSpaces.UploadDocumentVerificationPage,
            })}
          </Paragraph>
          <SelectInput
            name={DocumentVerificationVariable.DocumentType}
            selectedOption={documentType}
            options={VERIFICATION_DOCUMENT_TYPE_OPTIONS}
            placeholder={translate(InputLabelsTranslationKeys.VerificationDocumentType, {
              ns: TranslationNameSpaces.InputLabels,
            })}
            onValuePicked={handleDocumentTypeChange}
            containerClassName={styles.documentTypeContainer}
          />
          <UploadFileInput
            changeFile={handleChangeDocument}
            onDrop={handleDropDocument}
            file={document}
            description={translate(
              UploadDocumentVerificationPageTranslationKeys.AvailableFileParams,
              {
                ns: TranslationNameSpaces.UploadDocumentVerificationPage,
              },
            )}
            error={uploadFileError}
            setError={setUploadFileError}
          />
          <Button
            variant={ButtonVariant.Primary}
            size={ButtonSize.Large}
            onClick={uploadDocument}
            disabled={isSubmitButtonDisabled}
            className={styles.uploadFileButton}
            isLoading={isLoading}
          >
            {translate(ButtonsTranslationKeys.UploadDocument, {
              ns: TranslationNameSpaces.Buttons,
            })}
          </Button>
        </div>
      </PageLayout>
    </>
  );
};

export default UploadDocumentPage;
