import { FormikErrors } from 'formik';
import { IProjectAddress } from 'handlers/portal/personalInformation';
import { ApplicationVariable } from 'enums/ApplicationVariable';
import { ProjectAddressValueKey } from 'enums/ProjectAddressValueKey';
import { AddressValueKey } from 'enums/AddressValueKey';
import { ProjectAddressVariable } from 'enums/ProjectAddressVariable';

export const getProjectAddress = (
  values?: IProjectAddress,
  errors?: FormikErrors<IProjectAddress>,
) => {
  if (!values || !errors) {
    return {};
  }

  const getRequiredValue = (variableName: ProjectAddressVariable) => {
    return errors[variableName] ? undefined : values[variableName];
  };

  return {
    [ApplicationVariable.ProjectAddress]: {
      [AddressValueKey.StreetName]: getRequiredValue(ProjectAddressVariable.StreetName),
      [AddressValueKey.StreetNumber]: getRequiredValue(ProjectAddressVariable.StreetNumber),
      [AddressValueKey.City]: getRequiredValue(ProjectAddressVariable.City),
      [AddressValueKey.StateOrProvince]: getRequiredValue(ProjectAddressVariable.State),
      [AddressValueKey.ZipOrPostalCode]: getRequiredValue(ProjectAddressVariable.ZipCode),
    },
    [ProjectAddressValueKey.ApartmentOrSuite]: values[ProjectAddressVariable.ApartmentOrSuite],
  };
};
