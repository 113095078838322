import { VariableValue } from 'types/VariableTypes';

export enum AddressValueKey {
  StreetName = 'street_name',
  StreetNumber = 'street_number',
  City = 'city',
  StateOrProvince = 'state_or_province',
  ZipOrPostalCode = 'zip_or_postal_code',
  Country = 'country',
}

export interface IAddressValue {
  [AddressValueKey.StreetNumber]?: string;
  [AddressValueKey.StreetName]?: string;
  [AddressValueKey.City]?: string;
  [AddressValueKey.StateOrProvince]?: string;
  [AddressValueKey.ZipOrPostalCode]?: string;
  [AddressValueKey.Country]?: string;
}

export type FormLayoutData = Record<string, VariableValue>;
