import React from 'react';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';

import styles from './FormButtons.module.scss';
import clsx from 'clsx';

interface IFormButtons {
  primaryTitle: string;
  secondaryTitle: string;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
  disablePrimary?: boolean;
  disableSecondary?: boolean;
  isPrimaryLoading?: boolean;
  isSecondaryLoading?: boolean;
  showPrimaryButton?: boolean;
  showSecondaryButton?: boolean;
  containerClassName?: string;
  primaryButtonClassName?: string;
  children?: React.ReactNode;
}

const FormButtons = ({
  primaryTitle,
  secondaryTitle,
  onPrimaryClick,
  onSecondaryClick,
  disablePrimary,
  disableSecondary,
  isPrimaryLoading,
  isSecondaryLoading,
  showPrimaryButton = true,
  showSecondaryButton = true,
  containerClassName,
  primaryButtonClassName,
  children,
}: IFormButtons) => {
  return (
    <div className={clsx(styles.container, containerClassName)}>
      {showPrimaryButton && (
        <Button
          variant={ButtonVariant.Primary}
          size={ButtonSize.Large}
          disabledWithinInputAttr={disablePrimary}
          onClick={onPrimaryClick}
          className={clsx(styles.primaryButton, primaryButtonClassName)}
          isLoading={isPrimaryLoading}
        >
          {primaryTitle}
        </Button>
      )}
      {children}
      {showSecondaryButton && (
        <Button
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Large}
          onClick={onSecondaryClick}
          className={styles.secondaryButton}
          disabledWithinInputAttr={disableSecondary}
          isLoading={isSecondaryLoading}
        >
          {secondaryTitle}
        </Button>
      )}
    </div>
  );
};

export default FormButtons;
