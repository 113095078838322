import React from 'react';
import { BrokenRobot } from 'static/images';
import styles from './BankVerificationSelectPageMessage.module.scss';
import { BankVerificationPageTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { useTranslation } from 'react-i18next';

const BankVerificationSelectPageMessage = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.BankVerificationPage);

  return (
    <div className={styles.container}>
      <BrokenRobot />
      <div className={styles.message}>
        {translate(BankVerificationPageTranslationKeys.SelectPageMessage)}
      </div>
    </div>
  );
};

export default BankVerificationSelectPageMessage;
