import { createAsyncThunk } from '@reduxjs/toolkit';
import ApplicationProcessApi from 'api/digifi/portal/ApplicationProcessApi';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';
import { PersonalInformationActionType } from 'enums/PersonalInformationActionType';

export const createGetPersonalInformationThunk = (api: ApplicationProcessApi) =>
  createAsyncThunk(
    PersonalInformationActionType.GetPersonalInformation,
    async (applicationId: string, thunkApi) => {
      try {
        return await api.getPersonalInformation(applicationId);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
