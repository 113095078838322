import React, { FC, useEffect, useState } from 'react';
import OfferCard from 'pages/ApplicationProcess/OffersPage/OffersComponents/OfferCard';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { IOffer } from 'handlers/portal/offersSlice';
import { useTranslation } from 'react-i18next';
import { ButtonsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

import styles from './DesktopOffers.module.scss';

const OFFERS_LINE_LENGTH = 3;

const DesktopOffers: FC<{ 
  offers: IOffer[], 
  isWebLead?: boolean,
 }> = ({ 
  offers, 
  isWebLead,
 }) => {
  const { t: translate } = useTranslation(TranslationNameSpaces.Buttons);
  const [offersToShow, setOffersToShow] = useState<IOffer[]>([]);

  useEffect(() => {
    setOffersToShow(offers.slice(0, 3));
  }, [offers]);

  const handleShowMore = () => {
    setOffersToShow(offers.slice(0, offersToShow.length + 3));
  };

  const handleShowLess = () => {
    setOffersToShow(offers.slice(0, offersToShow.length - 3));
  };

  const renderBottomButton = () => {
    if (offers.length <= OFFERS_LINE_LENGTH) {
      return null;
    }

    if (offersToShow.length === offers.length) {
      return (
        <Button
          onClick={handleShowLess}
          variant={ButtonVariant.WithoutOutlines}
          size={ButtonSize.Middle}
        >
          {translate(ButtonsTranslationKeys.ShowLessButton)}
        </Button>
      );
    }

    if (offersToShow.length > OFFERS_LINE_LENGTH && offersToShow.length < offers.length) {
      return (
        <div className={styles.buttonWrapper}>
          <Button
            onClick={handleShowMore}
            variant={ButtonVariant.WithoutOutlines}
            size={ButtonSize.Middle}
          >
            {translate(ButtonsTranslationKeys.ShowMoreButton)}
          </Button>
          <Button
            onClick={handleShowLess}
            variant={ButtonVariant.WithoutOutlines}
            size={ButtonSize.Middle}
          >
            {translate(ButtonsTranslationKeys.ShowLessButton)}
          </Button>
        </div>
      );
    }

    return (
      <Button
        onClick={handleShowMore}
        variant={ButtonVariant.WithoutOutlines}
        size={ButtonSize.Middle}
      >
        {translate(ButtonsTranslationKeys.ShowMoreButton)}
      </Button>
    );
  };

  return (
    <>
      <div className={styles.offersWrapper}>
        {offersToShow.map((offer, id) => (
          <OfferCard key={id} offer={offer} isWebLead={isWebLead} />
        ))}
      </div>
      {renderBottomButton()}
    </>
  );
};

export default DesktopOffers;
