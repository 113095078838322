import BaseApi from 'api/BaseApi';
import { VariableValue } from 'types/VariableTypes';

interface IIntermediary {
  id: string;
  variables: Record<string, VariableValue>;
}

export default class IntermediariesApi extends BaseApi {
  private readonly endpointBase = '/intermediaries';

  public async getById(id: string): Promise<IIntermediary> {
    return this.fetch(`${this.endpointBase}/${id}`);
  }

  public async getByToken(token: string): Promise<IIntermediary> {
    return this.fetch(`${this.endpointBase}/token/${token}`);
  }
}
