import { DateTime } from 'luxon';

export const getDateWithTime = (dateToConvert: string) => {
  const date = DateTime.fromISO(dateToConvert).toLocaleString(DateTime.DATE_MED, {
    locale: 'en-US',
  });
  const time = DateTime.fromISO(dateToConvert).toLocaleString(DateTime.TIME_SIMPLE, {
    locale: 'en-US',
  });

  return `${date} ${time}`;
};
