import PageLayout, { LayoutSize } from 'components/PageLayout/PageLayout';
import React, { useState } from 'react';
import VerifyDwollaMicroDepositsForm from 'components/VerifyDwollaMicroDepositsForm';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { DwollaVerifiedMicrodepositsPopupTranslationKeys, InputLabelsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { useErrorMessages } from 'hooks/useErrorMessages';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { verifyIntermediaryDwollaMicroDeposits } from 'thunks';
import { DwollaMicroDepositsStatus } from 'enums/DwollaMicrodepositsStatus';
import { BankVerificationProcessSteps } from 'pages/ApplicationProcess/BankVerificationProcess/BankVerificationProcess';
import Popup from 'components/Popup';
import DwollaMicrodepositsPopupContent from 'components/BankConnectionCardWrapper/DwollaMicrodepositsPopupContent';

interface IVerifyIntermediaryMicroDepositsProps {
  token: string | undefined;
  setMicrodepositsStatus: (step: DwollaMicroDepositsStatus) => void;
  onSetStep: (step: BankVerificationProcessSteps) => void;
}

enum PopupState {
  Success = 'success',
  Failed = 'failed',
}

const LAST_ATTEMPT_ERROR_MESSAGE = 'You have reached max verification attempts.';

const VerifyIntermediaryMicroDeposits = ({
  token,
  setMicrodepositsStatus,
  onSetStep,
}: IVerifyIntermediaryMicroDepositsProps) => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.InputLabels,
    TranslationNameSpaces.Popups,
  ]);
  
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const [firstAmountValue, setFirstAmountValue] = useState('');
  const [secondAmountValue, setSecondAmountValue] = useState('');
  const [firstAmountInputError, setFirstAmountInputError] = useState<string | null>(null);
  const [secondAmountInputError, setSecondAmountInputError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [popUpState, setPopUpState] = useState<PopupState | null>(null);

  const [getMessageForRequiredField] = useErrorMessages();

  const firstInputLabel = translate(InputLabelsTranslationKeys.Amount1, {
    ns: TranslationNameSpaces.InputLabels,
  });

  const secondInputLabel = translate(InputLabelsTranslationKeys.Amount2, {
    ns: TranslationNameSpaces.InputLabels,
  });

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (!firstAmountValue) {
        setFirstAmountInputError(getMessageForRequiredField(firstInputLabel));

        return;
      }

      if (!secondAmountValue) {
        setSecondAmountInputError(getMessageForRequiredField(secondInputLabel));

        return;
      }

      const response = await dispatchWithUnwrap(
        verifyIntermediaryDwollaMicroDeposits({
          token: token!,
          firstAmount: firstAmountValue,
          secondAmount: secondAmountValue,
        }),
      );

      if ('redirectUrl' in response) {
        setRedirectUrl((response as { redirectUrl: string }).redirectUrl);
        setPopUpState(PopupState.Success);

        return;
      }
    } catch (error) {
      const errorMessage = (error as { message: string }).message;
      if (errorMessage === LAST_ATTEMPT_ERROR_MESSAGE) {
        setPopUpState(PopupState.Failed);

        return;
      }

      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnPopUpSubmit = () => {
    if (popUpState === PopupState.Success) {
      window.open(redirectUrl, '_self');

      return;
    }

    setMicrodepositsStatus(DwollaMicroDepositsStatus.VerificationFailed);
    onSetStep(BankVerificationProcessSteps.PlaidBankVerification);
  };

  

  const getPopUpDescription = () => {
    const successVerifiedDescription = (
      <>
        <p>
          {translate(DwollaVerifiedMicrodepositsPopupTranslationKeys.Description1, {
                ns: TranslationNameSpaces.Popups,
              })}
        </p>
        <p>
          {translate(DwollaVerifiedMicrodepositsPopupTranslationKeys.Description2, {
                ns: TranslationNameSpaces.Popups,
              })}
        </p>
      </>
    );

    const failedVerificationDescription = (
      <p>
        {translate(DwollaVerifiedMicrodepositsPopupTranslationKeys.FailedDescription, {
              ns: TranslationNameSpaces.Popups,
            })}
      </p>
    );

    return popUpState === PopupState.Success ? successVerifiedDescription : failedVerificationDescription;
    };

  return (
    <div>
      <PageLayout layoutSize={LayoutSize.Big}>
        <VerifyDwollaMicroDepositsForm
          firstAmountValue={firstAmountValue}
          secondAmountValue={secondAmountValue}
          setFirstAmountValue={setFirstAmountValue}
          setSecondAmountValue={setSecondAmountValue}
          firstAmountInputError={firstAmountInputError}
          secondAmountInputError={secondAmountInputError}
          firstInputLabel={firstInputLabel}
          secondInputLabel={secondInputLabel}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          isIntermediaryForm={true}
        />
        <Popup
          isOpen={popUpState !== null}
          onClose={handleOnPopUpSubmit}
          title={
            translate(DwollaVerifiedMicrodepositsPopupTranslationKeys.Title, {
              ns: TranslationNameSpaces.Popups,
            }) as string
          }
        >
          <DwollaMicrodepositsPopupContent
            description={getPopUpDescription()}
            onButtonClick={handleOnPopUpSubmit}
          />
        </Popup>
      </PageLayout>
    </div>
  );
};

export default VerifyIntermediaryMicroDeposits;
