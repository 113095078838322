import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { ApplicationStatusIdType } from 'enums/ApplicationStatusId';
import { AuthorizationEndpoints } from './PortalEndpoints';

export interface IAuthorizationInformation {
  statusId: ApplicationStatusIdType;
  phone: string;
  borrowerId: string;
  hasAccount: boolean;
  applicationExpired: boolean;
  redirectApplicationId: string;
  applicationNotFound: boolean;
  email?: string;
}

export interface ICreateAccountRequest {
  phone: string;
  borrowerId: string;
  applicationId: string;
  email?: string;
}

interface ICreateAccountResponse {
  phone: string;
}

export interface ISearchAccountsRequest {
  borrowerId: string;
  phone: string;
}

export interface IRejectApplicationBody {
  applicationId: string;
}

interface IAuthorizationApi {
  getAuthorizationInformation(applicationId: string): Promise<IAuthorizationInformation>;
  searchAccounts(searchAccountsBody: ISearchAccountsRequest): Promise<void>;
  createAccount(createAccountBody: ICreateAccountRequest): Promise<ICreateAccountResponse>;
}

export default class AuthorizationApi extends BaseApi implements IAuthorizationApi {
  public getAuthorizationInformation(applicationId: string): Promise<IAuthorizationInformation> {
    return this.fetch(`${AuthorizationEndpoints.GetAuthorizationInformation}/${applicationId}`);
  }

  public searchAccounts(body: ISearchAccountsRequest): Promise<void> {
    return this.fetch(AuthorizationEndpoints.SearchAccounts, {
      method: FetchMethodType.POST,
      body,
    });
  }

  public createAccount(body: ICreateAccountRequest): Promise<ICreateAccountResponse> {
    return this.fetch(AuthorizationEndpoints.CreateAccount, {
      method: FetchMethodType.POST,
      body,
    });
  }

  public rejectApplication(body: IRejectApplicationBody): Promise<void> {
    return this.fetch(AuthorizationEndpoints.RejectApplication, {
      method: FetchMethodType.POST,
      body,
    });
  }
}
