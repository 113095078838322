import { createAsyncThunk } from '@reduxjs/toolkit';
import PaymentProcessorApi from 'api/digifi/portal/PaymentProcessorApi';
import { BankVerificationActionType } from 'enums/BankVerificationActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createCreateBorrowerFundingSourceThunk = (api: PaymentProcessorApi) =>
  createAsyncThunk(
    BankVerificationActionType.CreateBorrowerFundingSource,
    async (applicationId: string, thunkApi) => {
      try {
        return await api.createBorrowerFundingSource(applicationId);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
