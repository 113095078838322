import { NON_DIGITS_PATTERN } from './profund/formats';

export const unformatBorrowerPhone = (phone: string) => {
  const reg = new RegExp(NON_DIGITS_PATTERN, 'g');
  const replaced = phone.replace(reg, '');

  if (!replaced) {
    return '';
  }

  return '+' + phone.replace(reg, '');
};
