import { createAsyncThunk } from '@reduxjs/toolkit';
import { IBorrowersApi } from 'api/digifi/los/BorrowersApi';
import { BorrowersActionType } from 'enums/BorrowerActionTypes';

export const createGetBorrowerThunk = (api: IBorrowersApi) =>
  createAsyncThunk(BorrowersActionType.GetBorrowerData, async () => {
    const response = await api.getBorrower();

    return [response];
  });
