import React from 'react';
import styles from './WhatNextPageDescription.module.scss';
import { Trans } from 'react-i18next';
import { WhatNextPageTranslationKeys } from 'enums/Translation';

interface IWhatNextPageDescriptionProps {
  showSeeOffersLink: boolean;
  setIsProjectAccountPopupOpen: (isOpen: boolean) => void;
  handleSwitchToNotEvenOffersPage: () => void;
}

const WhatNextPageDescription = ({
  showSeeOffersLink,
  setIsProjectAccountPopupOpen,
  handleSwitchToNotEvenOffersPage,
}: IWhatNextPageDescriptionProps) => {
  return (
    <div className={styles.container}>
      <Trans i18nKey={WhatNextPageTranslationKeys.MainDescription}>
        <span className={styles.bold}>Your Project Account:</span> Your contractor uses ProFund™ to
        facilitate payments due on your home improvement project.{' '}
        <span className={styles.bold}>
          This includes the creation of your Project Account to hold your loan proceeds until you
          approve payment(s) to your contractor.
        </span>{' '}
        <span className={styles.additionalInfo}>
          Learn more about your{' '}
          <span className={styles.activeText} onClick={() => setIsProjectAccountPopupOpen(true)}>
            Project Account
          </span>
          {showSeeOffersLink ? (
            <>
              {' '}
              or{' '}
              <span className={styles.activeText} onClick={handleSwitchToNotEvenOffersPage}>
                See Offers
              </span>{' '}
              not requiring a Project Account.
            </>
          ) : (
            <>.</>
          )}
        </span>
      </Trans>
    </div>
  );
};

export default WhatNextPageDescription;
