import { CommonTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

import AccessAndUseOfTheServicesTerm from 'components/Terms/AccessAndUseOfTheServicesTerm';
import ConditionsOfUseTerm from 'components/Terms/ConditionsOfUseTerm';
import IntellectualPropertyRights from 'components/Terms/IntellectualPropertyRights';
import PageLayout from 'components/PageLayout';
import Paragraph from 'components/Paragraph';
import React from 'react';
import TermWrapper from 'components/Terms/TermWrapper';
import TermsMenu from 'components/Terms/TermsMenu';
import Title from 'components/Title';
import { TypographySize } from 'enums/TypographySize';
import WelcomeToProFundTerm from 'components/Terms/WelcomeToProFundTerm';
import { useTranslation } from 'react-i18next';

const TermsOfServicePage = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.Common);
  return (
    <>
      <TermsMenu />
      <PageLayout>
        <TermWrapper>
          <Title variant="h1" marginBottom="16">
            {translate(CommonTranslationKeys.TermsOfServiceTitle)}
          </Title>
          <Paragraph variant={TypographySize.Middle}>
            {translate(CommonTranslationKeys.LastRevisionDate)}
          </Paragraph>
        </TermWrapper>
        <WelcomeToProFundTerm />
        <AccessAndUseOfTheServicesTerm />
        <ConditionsOfUseTerm />
        <IntellectualPropertyRights />
      </PageLayout>
    </>
  );
};

export default TermsOfServicePage;
