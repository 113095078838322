import { createAsyncThunk } from '@reduxjs/toolkit';
import BankVerificationApi, {
  ISaveIntermediaryTokenData,
} from 'api/digifi/portal/BankVerificationApi';
import { BankVerificationActionType } from 'enums/BankVerificationActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createSaveIntermediaryTokenThunk = (api: BankVerificationApi) =>
  createAsyncThunk(
    BankVerificationActionType.SaveIntermediaryToken,
    async (data: ISaveIntermediaryTokenData, thunkApi) => {
      try {
        return await api.saveIntermediaryToken(data);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
