import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { ChevronDownIcon, ChevronUpIcon } from 'static/images';
import { TABLET_WIDTH } from 'pages/ApplicationProcess/OffersPage/offersUtils';

import styles from './CollapseWrapper.module.scss';
import useWindowSize from 'hooks/useWindowSize';

interface ICollapsibleProps {
  activeItem: React.ReactElement | React.ReactNode;
  items: React.ReactElement[] | React.ReactNode[];
}

const Collapsible: FC<ICollapsibleProps> = ({ activeItem, items }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCollapsible, setIsCollapsible] = useState<boolean>(true);
  const { width } = useWindowSize();

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const renderSteps = () => {
    if (isCollapsible && isOpen) {
      return items;
    }
    if (isCollapsible && !isOpen) {
      return activeItem;
    }
    if (!isCollapsible) {
      return items;
    }
  };

  useEffect(() => {
    if (width && width > TABLET_WIDTH) {
      setIsCollapsible(false);
    } else {
      setIsCollapsible(true);
    }
  }, [width]);

  return (
    <div className={clsx(styles.collapsibleContainer, isOpen && styles.active)}>
      <div className={styles.itemsWrapper}>{renderSteps()}</div>
      {isCollapsible && (
        <button className={styles.openButton} onClick={handleOpen}>
          {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </button>
      )}
    </div>
  );
};

export default Collapsible;
