import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getPersonalInformation, updateApplication, updateBorrower } from 'thunks';
import { convertMonetaryValueToIntegerNumber } from 'utils/profund/formats';
import Title from 'components/Title';
import Paragraph from 'components/Paragraph';
import NumberInput from 'components/NumberInput';
import FormButtons from 'components/FormButtons';
import TextInput from 'components/TextInput';
import PageLayout from 'components/PageLayout';
import AddressInputs from 'components/AddressInputs';
import Checkbox from 'components/Checkbox';
import Link from 'components/Link';
import DisclosuresBox from 'components/DisclosuresBox';
import ParticipatingLenderInfo from 'components/InformationBox/ParticipatingLenderInfo';
import Stepper from 'components/Stepper';
import AdvertiserDisclosure from 'components/AdvertiserDisclosure';
import Loading from 'components/Loading';
import { LayoutSize } from 'components/PageLayout/PageLayout';
import { BorrowerVariable } from 'enums/BorrowerVariable';
import { PersonalInformationVariable } from 'enums/PersonalInformation';
import { InputPrefixAndSuffixType } from 'enums/InputPrefixAndSuffixType';
import { PersonalInformationLabel } from 'enums/PersonalInformationLabel';
import { ApplicationVariable } from 'enums/ApplicationVariable';
import { ApplicationStatusIdType } from 'enums/ApplicationStatusId';
import { StrategyName } from 'enums/StrategyId';
import { DeclineReason } from 'enums/DeclineReason';
import { ApplicationLabelName } from 'enums/ApplicationLabelId';
import {
  ButtonsTranslationKeys,
  PersonalInformationPageTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import { useStrategyApi } from 'providers/ApiServiceProvider';

import { usePersonalInformationValidationSchema } from './validationSchema';
import { getBorrowerData } from './getBorrowerData';
import UnableToVerify from './UnableToVerify';
import styles from './PersonalInformation.module.scss';
import { AppRoutes } from 'routes/AppRoutes';
import { getAccountData, getLoadingFields, getPersonalInformationData } from 'handlers/selectors';
import ProjectAddress from 'components/ProjectAddress';
import { getProjectAddress } from './getProjectAddress';
import { IProjectAddress, removeProjectAddress } from 'handlers/portal/personalInformation';
import {
  getApplicationStatusIdByEnvironment,
  matchApplicationStatusIdByEnvironment,
} from 'utils/applicationStatusIdHelpers';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { getApplicationLabelIdByEnvironment } from 'utils/applicationLabelsHelpers';
import { getStrategyIdByEnvironment, matchStrategyIdByEnvironment } from 'utils/strategyIdHelpers';
import { ProjectAddressVariable } from 'enums/ProjectAddressVariable';
import { isWaterCressEnabled } from 'utils/isWatercressEnabled';

const LOAN_AMOUNT_MAX_LENGTH = 12;
export const TEXT_INPUT_MAX_LENGTH = 50;

interface IPersonalInformationFormProps {
  statusId: ApplicationStatusIdType;
}

const PersonalInformationForm = ({ statusId }: IPersonalInformationFormProps) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const strategyApi = useStrategyApi();
  const { t: translate } = useTranslation([
    TranslationNameSpaces.Buttons,
    TranslationNameSpaces.PersonalInformationPage,
  ]);
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const projectAddressFormRef = useRef<FormikProps<IProjectAddress>>(null);

  const [isContinueInProgress, setIsContinueInProgress] = useState(false);
  const [isFinishLaterInProgress, setFinishLaterInProgress] = useState(false);
  const [isProjectAddressOpen, setIsProjectAddressOpen] = useState(false);
  const [isProjectAddressFormValid, setIsProjectAddressFormValid] = useState(false);
  const [hasCheckboxError, setHasCheckboxError] = useState(false);

  const getIsProjectAddressFormValid = () => {
    if (isProjectAddressOpen && isWaterCressEnabled) {
      return isProjectAddressFormValid;
    }

    return true;
  };

  const projectFormValues = projectAddressFormRef?.current?.values;

  const projectFormErrors = projectAddressFormRef?.current?.errors;

  const accountData = useAppSelector(getAccountData);

  const { data } = useAppSelector(getPersonalInformationData);
  const { isPersonalInfoLoading } = useAppSelector(getLoadingFields);

  const projectAddressData = {
    [ProjectAddressVariable.StreetName]: data[ProjectAddressVariable.StreetName],
    [ProjectAddressVariable.StreetNumber]: data[ProjectAddressVariable.StreetNumber],
    [ProjectAddressVariable.ApartmentOrSuite]: data[ProjectAddressVariable.ApartmentOrSuite],
    [ProjectAddressVariable.City]: data[ProjectAddressVariable.City],
    [ProjectAddressVariable.State]: data[ProjectAddressVariable.State],
    [ProjectAddressVariable.ZipCode]: data[ProjectAddressVariable.ZipCode],
  };

  const personalInfoData = {
    [PersonalInformationVariable.LoanAmount]: data[PersonalInformationVariable.LoanAmount],
    [PersonalInformationVariable.FirstName]: data[PersonalInformationVariable.FirstName],
    [PersonalInformationVariable.MiddleName]: data[PersonalInformationVariable.MiddleName],
    [PersonalInformationVariable.LastName]: data[PersonalInformationVariable.LastName],
    [PersonalInformationVariable.StreetName]: data[PersonalInformationVariable.StreetName],
    [PersonalInformationVariable.StreetNumber]: data[PersonalInformationVariable.StreetNumber],
    [PersonalInformationVariable.ApartmentOrSuite]:
      data[PersonalInformationVariable.ApartmentOrSuite],
    [PersonalInformationVariable.City]: data[PersonalInformationVariable.City],
    [PersonalInformationVariable.State]: data[PersonalInformationVariable.State],
    [PersonalInformationVariable.ZipCode]: data[PersonalInformationVariable.ZipCode],
    [PersonalInformationVariable.Email]: data[PersonalInformationVariable.Email],
  };

  useEffect(() => {
    dispatchWithUnwrap(getPersonalInformation(applicationId!));
  }, []);

  useEffect(() => {
    if (isWaterCressEnabled) {
      const isProjectAddressFormValues = Object.values(projectAddressData).some((value) =>
        Boolean(value),
      );

      setIsProjectAddressOpen(isProjectAddressFormValues);
    }
  }, [data]);

  useEffect(() => {
    if (!isProjectAddressOpen && isWaterCressEnabled) {
      dispatch(removeProjectAddress());
    }
  }, [isProjectAddressOpen]);

  const [checked, setChecked] = useState(false);
  const validationSchema = usePersonalInformationValidationSchema();

  const handleCheckBox = () => {
    setChecked(!checked);
    setHasCheckboxError(false);
  };

  if (isPersonalInfoLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Stepper
        currentStep={getApplicationStatusIdByEnvironment(ApplicationStatusName.PersonalInformation)}
      />
      <PageLayout layoutSize={LayoutSize.Big}>
        <AdvertiserDisclosure />
        <Title variant="h1" marginBottom="24">
          {translate(PersonalInformationPageTranslationKeys.Title, {
            ns: TranslationNameSpaces.PersonalInformationPage,
          })}
        </Title>
        {matchApplicationStatusIdByEnvironment(statusId, ApplicationStatusName.Rejected) ? (
          <UnableToVerify />
        ) : (
          <>
            <Paragraph marginBottom="32">
              {translate(PersonalInformationPageTranslationKeys.Description, {
                ns: TranslationNameSpaces.PersonalInformationPage,
              })}
            </Paragraph>
            <Formik
              initialValues={personalInfoData}
              onSubmit={() => {}}
              validationSchema={validationSchema}
              validateOnMount
              validateOnBlur
              validateOnChange
              enableReinitialize
            >
              {({
                values,
                handleBlur,
                errors,
                handleChange,
                isValid,
                touched,
                setFieldValue,
                validateForm,
              }) => {
                const getErrorMessage = (name: PersonalInformationVariable) =>
                  !!touched[name] && errors[name] ? errors[name] : undefined;

                const isFormValid = isValid && checked && getIsProjectAddressFormValid();

                const handleContinue = async () => {
                  if (!checked) {
                    setHasCheckboxError(true);
                  }
                  
                  if (!isFormValid) {
                    await validateForm(values);

                    if (isProjectAddressOpen && isWaterCressEnabled) {
                      projectAddressFormRef.current?.submitForm();
                    }

                    return;
                  }

                  setIsContinueInProgress(true);

                  try {
                    await dispatchWithUnwrap(
                      updateApplication({
                        applicationId: applicationId as string,
                        params: {
                          variables: {
                            [ApplicationVariable.LoanAmount]: convertMonetaryValueToIntegerNumber(
                              values[PersonalInformationVariable.LoanAmount],
                            ),
                            ...getBorrowerData(values, errors, false),
                            ...getProjectAddress(projectFormValues, projectFormErrors),
                          },
                        },
                      }),
                    );

                    await dispatchWithUnwrap(
                      updateBorrower({ variables: { ...getBorrowerData(values, errors, false) } }),
                    );

                    const strategyResult = await strategyApi.run({
                      decisions: [
                        {
                          strategyId: getStrategyIdByEnvironment(StrategyName.FraudCheck),
                          inputs: { [BorrowerVariable.PhoneNumber]: accountData?.phone },
                        },
                      ],
                      applicationId: applicationId as string,
                    });

                    const currentStrategy = strategyResult.results?.find((item) =>
                      matchStrategyIdByEnvironment(item.strategyId, StrategyName.FraudCheck),
                    )!;

                    const scoreVariables = {
                      [ApplicationVariable.TelesignRiskScore]:
                        currentStrategy.outputs[ApplicationVariable.TelesignRiskScore],
                      [ApplicationVariable.SeonScore]:
                        currentStrategy.outputs[ApplicationVariable.SeonScore],
                    };

                    if (!currentStrategy.passed) {
                      await dispatchWithUnwrap(
                        updateApplication({
                          applicationId: applicationId as string,
                          params: {
                            statusId: getApplicationStatusIdByEnvironment(
                              ApplicationStatusName.Rejected,
                            ),
                            declineReasons: [DeclineReason.FraudCheckFailed],
                            labelsIds: [
                              getApplicationLabelIdByEnvironment(
                                ApplicationLabelName.FraudCheckFailed,
                              ),
                            ],
                            variables: scoreVariables,
                          },
                        }),
                      );
                      return;
                    }

                    await dispatchWithUnwrap(
                      updateApplication({
                        applicationId: applicationId as string,
                        params: {
                          variables: scoreVariables,
                          statusId: getApplicationStatusIdByEnvironment(
                            ApplicationStatusName.FinancialInformation,
                          ),
                        },
                      }),
                    );

                    setIsContinueInProgress(false);
                  } finally {
                    setIsContinueInProgress(false);
                  }
                };

                const finishLater = async () => {
                  setFinishLaterInProgress(true);
                  try {
                    await dispatchWithUnwrap(
                      updateApplication({
                        applicationId: applicationId as string,
                        params: {
                          variables: {
                            [ApplicationVariable.LoanAmount]: errors[
                              PersonalInformationVariable.LoanAmount
                            ]
                              ? Number(data[PersonalInformationVariable.LoanAmount])
                              : convertMonetaryValueToIntegerNumber(
                                  values[PersonalInformationVariable.LoanAmount],
                                ),
                            ...getBorrowerData(values, errors, true),
                            ...getProjectAddress(projectFormValues, projectFormErrors),
                          },
                        },
                      }),
                    );

                    await dispatchWithUnwrap(
                      updateBorrower({ variables: { ...getBorrowerData(values, errors, true) } }),
                    );
                    setFinishLaterInProgress(false);

                    navigate(AppRoutes.Applications);
                  } finally {
                    setFinishLaterInProgress(false);
                  }
                };

                return (
                  <Form>
                    <div className={styles.form}>
                      <NumberInput
                        label={PersonalInformationLabel.LoanAmount}
                        name={PersonalInformationVariable.LoanAmount}
                        prefix={InputPrefixAndSuffixType.Monetary}
                        onChange={handleChange}
                        value={values[PersonalInformationVariable.LoanAmount]}
                        error={getErrorMessage(PersonalInformationVariable.LoanAmount)}
                        thousandSeparator
                        allowNegative={false}
                        onBlur={handleBlur}
                        maxLength={LOAN_AMOUNT_MAX_LENGTH}
                        decimalScale={0}
                      />
                      <TextInput
                        label={PersonalInformationLabel.FirstName}
                        name={PersonalInformationVariable.FirstName}
                        error={getErrorMessage(PersonalInformationVariable.FirstName)}
                        onChange={handleChange}
                        value={values[PersonalInformationVariable.FirstName]}
                        onBlur={handleBlur}
                        maxLength={TEXT_INPUT_MAX_LENGTH}
                      />
                      <TextInput
                        label={PersonalInformationLabel.MiddleName}
                        name={PersonalInformationVariable.MiddleName}
                        error={getErrorMessage(PersonalInformationVariable.MiddleName)}
                        onChange={handleChange}
                        value={values[PersonalInformationVariable.MiddleName]}
                        onBlur={handleBlur}
                        maxLength={TEXT_INPUT_MAX_LENGTH}
                      />
                      <TextInput
                        label={PersonalInformationLabel.LastName}
                        name={PersonalInformationVariable.LastName}
                        error={getErrorMessage(PersonalInformationVariable.LastName)}
                        onChange={handleChange}
                        value={values[PersonalInformationVariable.LastName]}
                        onBlur={handleBlur}
                        maxLength={TEXT_INPUT_MAX_LENGTH}
                      />
                      <AddressInputs
                        value={{
                          streetName: values[PersonalInformationVariable.StreetName],
                          streetNumber: values[PersonalInformationVariable.StreetNumber],
                          apartmentOrSuite: values[PersonalInformationVariable.ApartmentOrSuite],
                          city: values[PersonalInformationVariable.City],
                          state: values[PersonalInformationVariable.State],
                          zipCode: values[PersonalInformationVariable.ZipCode],
                        }}
                        variable={{
                          streetName: PersonalInformationVariable.StreetName,
                          streetNumber: PersonalInformationVariable.StreetNumber,
                          apartmentOrSuite: PersonalInformationVariable.ApartmentOrSuite,
                          city: PersonalInformationVariable.City,
                          state: PersonalInformationVariable.State,
                          zipCode: PersonalInformationVariable.ZipCode,
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        getErrorMessage={getErrorMessage}
                      />
                      <TextInput
                        label={PersonalInformationLabel.Email}
                        name={PersonalInformationVariable.Email}
                        error={getErrorMessage(PersonalInformationVariable.Email)}
                        onChange={handleChange}
                        value={values[PersonalInformationVariable.Email]}
                        onBlur={handleBlur}
                        maxLength={TEXT_INPUT_MAX_LENGTH}
                      />
                    </div>
                    {isWaterCressEnabled && (
                      <ProjectAddress
                        formRef={projectAddressFormRef}
                        isActive={isProjectAddressOpen}
                        setIsActive={setIsProjectAddressOpen}
                        setIsFormValid={setIsProjectAddressFormValid}
                        value={projectAddressData}
                      />
                    )}
                    <Checkbox
                      label={
                        <>
                          {translate(PersonalInformationPageTranslationKeys.IHaveRead, {
                            ns: TranslationNameSpaces.PersonalInformationPage,
                          })}{' '}
                          <Link
                            href={`${location.origin}${AppRoutes.CreditAuthorization}`}
                            target="_blank"
                          >
                            {translate(PersonalInformationPageTranslationKeys.CreditAuthorization, {
                              ns: TranslationNameSpaces.PersonalInformationPage,
                            })}
                          </Link>
                          ,{' '}
                          <Link
                            href={`${location.origin}${AppRoutes.TermsOfService}`}
                            target="_blank"
                          >
                            {translate(PersonalInformationPageTranslationKeys.TermsOfService, {
                              ns: TranslationNameSpaces.PersonalInformationPage,
                            })}
                          </Link>
                          ,{' '}
                          <Link href="https://profund.net/privacy-policy" target="_blank">
                            {translate(PersonalInformationPageTranslationKeys.PrivacyPolicy, {
                              ns: TranslationNameSpaces.PersonalInformationPage,
                            })}{' '}
                          </Link>
                          ,{' '}
                          <Link
                            href={`${location.origin}${AppRoutes.ElectronicConsent}`}
                            target="_blank"
                          >
                            {translate(PersonalInformationPageTranslationKeys.ElectronicConsent, {
                              ns: TranslationNameSpaces.PersonalInformationPage,
                            })}
                          </Link>
                          .
                        </>
                      }
                      isChecked={checked}
                      isError={hasCheckboxError}
                      onChange={handleCheckBox}
                    />
                    <FormButtons
                      primaryTitle={translate(ButtonsTranslationKeys.AgreeAndContinue, {
                        ns: TranslationNameSpaces.Buttons,
                      })}
                      secondaryTitle={translate(ButtonsTranslationKeys.FinishLater, {
                        ns: TranslationNameSpaces.Buttons,
                      })}
                      onPrimaryClick={handleContinue}
                      onSecondaryClick={finishLater}
                      disablePrimary={!isFormValid}
                      isPrimaryLoading={isContinueInProgress}
                      isSecondaryLoading={isFinishLaterInProgress}
                      disableSecondary={isFinishLaterInProgress}
                    />
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
        <DisclosuresBox />
        <ParticipatingLenderInfo />
      </PageLayout>
    </div>
  );
};

export default PersonalInformationForm;
