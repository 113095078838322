import { createAsyncThunk } from '@reduxjs/toolkit';
import PaymentProcessorApi, {
  ICreateBorrowerDwollaMicroDepositsFundingSourceBody,
} from 'api/digifi/portal/PaymentProcessorApi';
import { BankVerificationActionType } from 'enums/BankVerificationActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createCreateBorrowerDwollaMicroDepositFundingSource = (api: PaymentProcessorApi) =>
  createAsyncThunk(
    BankVerificationActionType.CreateBorrowerDwollaMicroDepositFundingSource,
    async (body: ICreateBorrowerDwollaMicroDepositsFundingSourceBody, thunkApi) => {
      try {
        return await api.createBorrowerDwollaMicroDepositFundingSource(body);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
