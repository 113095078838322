import { EnvironmentVariable } from 'enums/EnvironmentVariable';
import { env } from 'env';

export const getSecretValue = (key: EnvironmentVariable) => {
  const value = env[key];

  if (value === undefined) {
    throw new Error(`Environment variable ${key} is missing`);
  }

  return value;
};
