import React from 'react';
import { useTranslation } from 'react-i18next';
import DisclosuresBox from 'components/DisclosuresBox';
import { PopupsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

import styles from './AdvertiserDisclosure.module.scss';
import BackButton from 'components/BackButton';
import clsx from 'clsx';

interface IAdvertiserDisclosureProps {
  isInBackButtonContainer?: boolean;
  onBackClick?: () => void;
  showDisclosure?: boolean;
  backButtonTitle?: string | null;
}

const AdvertiserDisclosure = ({
  onBackClick,
  showDisclosure = true,
  backButtonTitle,
}: IAdvertiserDisclosureProps) => {
  const { t: translate } = useTranslation(TranslationNameSpaces.Popups);

  const title = translate(PopupsTranslationKeys.AdvertiserDisclosureTitle);
  const content = translate(PopupsTranslationKeys.AdvertiserDisclosureDescription);

  return (
    <div className={clsx(styles.container, !onBackClick && styles.containerWithoutBackButton)}>
      {onBackClick && <BackButton onBackClick={onBackClick} title={backButtonTitle}/>}
      {showDisclosure && (
        <DisclosuresBox
          singleDisclosure
          content={[
            {
              title,
              content,
            },
          ]}
        />
      )}
    </div>
  );
};

export default AdvertiserDisclosure;
