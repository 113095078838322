import { RootState } from 'store';

export const getBorrowerApplicationData = (state: RootState) => {
  return state.borrowerApplications;
};

export const getAuthorizationInformationData = (state: RootState) => {
  return state.authorization;
};

export const getLoadingFields = (state: RootState) => {
  return state.loading.loadingFields;
};

export const getVerificationData = (state: RootState) => {
  return state.verification;
};

export const getOffersData = (state: RootState) => {
  return state.offersInformation;
};

export const getFinancialInformationData = (state: RootState) => {
  return state.financialInformation;
};

export const getPersonalInformationData = (state: RootState) => {
  return state.personalInformation;
};

export const getAccountData = (state: RootState) => {
  return state.auth.accountData;
};

export const getApplicationProcessData = (state: RootState) => {
  return state.applicationProcess;
};

export const getGlobalLoading = (state: RootState) => {
  return state.loading.globalLoading;
};
