import { toast, ToastContainer } from 'react-toastify';
import styles from './Toaster.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';

const TOASTER_TTL = 5000; //5s
const DEFAULT_TOAST_ERROR_MESSAGE = 'Something Went Wrong';

export const getErrorToast = (message = DEFAULT_TOAST_ERROR_MESSAGE) => (
  <div className={styles.textBody}>
    <div>{message}</div>
  </div>
);

export const openToaster = {
  error: (message?: string) => {
    toast.error(getErrorToast(message));
  },
};

const Toaster = () => {
  return (
    <ToastContainer
      className={styles.toastContainer}
      position="bottom-right"
      autoClose={TOASTER_TTL}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      closeButton={() => <></>}
    />
  );
};

export default Toaster;
