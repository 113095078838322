import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplicationDetailsPageTranslationKeys,
  ButtonsTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import { AccountActionType } from 'components/ProjectBalance';
import Popup from 'components/Popup';
import TitleWithInfoTooltip from 'components/TitleWithInfoTooltip';
import PopupButtons from 'components/PopupButtons';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { reopenAccount, updateApplication } from 'thunks';
import { ApplicationLabelName } from 'enums/ApplicationLabelId';
import { setSelectedApplication } from 'handlers/portal/applicationsSlice';
import { getLoadingFields } from 'handlers/selectors';
import { TooltipId } from 'enums/TooltipId';
import { getApplicationLabelIdByEnvironment } from 'utils/applicationLabelsHelpers';

import styles from './ReopenAccount.module.scss';

interface IReopenAccountProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  applicationId: string;
  setSuccessMessage: (isMessage: AccountActionType) => void;
}

const ReopenAccount = ({
  isOpen,
  setIsOpen,
  applicationId,
  setSuccessMessage,
}: IReopenAccountProps) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();

  const { isUpdateApplicationInProgress, isReopenAccountLoading } =
    useAppSelector(getLoadingFields);

  const { t: translate } = useTranslation([TranslationNameSpaces.ApplicationDetailsPage]);

  const handleReopenAccount = async () => {
    const updatedApplication = await dispatchWithUnwrap(
      updateApplication({
        applicationId: applicationId as string,
        params: {
          labelsIds: [getApplicationLabelIdByEnvironment(ApplicationLabelName.ReopenAccount)],
        },
      }),
    );

    await dispatchWithUnwrap(reopenAccount({ applicationId }));

    dispatch(setSelectedApplication(updatedApplication));
    setSuccessMessage(AccountActionType.Reopen);
    setIsOpen(false);
  };

  return (
    <Popup isOpen={isOpen} closeIcon={false} isNotParagraph>
      <TitleWithInfoTooltip
        title={translate(ApplicationDetailsPageTranslationKeys.ReopenAccountPopupTitle, {
          ns: TranslationNameSpaces.ApplicationDetailsPage,
        })}
        tooltip={''}
        className={styles.popupTitle}
        id={TooltipId.ReopenAccount}
      />
      <div className={styles.popupContentContainer}>
        <div className={styles.popupParagraph}>
          {translate(ApplicationDetailsPageTranslationKeys.ReopenAccountPopupContent, {
            ns: TranslationNameSpaces.ApplicationDetailsPage,
          })}
        </div>
        <PopupButtons
          secondaryTitle={translate(ButtonsTranslationKeys.Cancel, {
            ns: TranslationNameSpaces.Buttons,
          })}
          primaryTitle={translate(ButtonsTranslationKeys.Confirm, {
            ns: TranslationNameSpaces.Buttons,
          })}
          onPrimaryClick={handleReopenAccount}
          onSecondaryClick={() => setIsOpen(false)}
          isPrimaryLoading={isUpdateApplicationInProgress || isReopenAccountLoading}
          isPrimaryDisabled={isUpdateApplicationInProgress || isReopenAccountLoading}
        />
      </div>
    </Popup>
  );
};

export default ReopenAccount;
