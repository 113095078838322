import React, { FC, useEffect, useState } from 'react';
import InputWrapper, { IInputWrapperProps } from 'components/InputWrapper/InputWrapper';
import formatPhoneNumber, { PHONE_NUMBER_FORMAT, PHONE_NUMBER_MASK } from 'utils/formatPhoneNumber';
import { unformatBorrowerPhone } from 'utils/unformatBorrowerPhone';
import { PatternFormat, PatternFormatProps } from 'react-number-format';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { InfoCircleIcon } from 'static/images';
import { useTranslation } from 'react-i18next';
import { AuthorizationProcessTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

const PHONE_DEFAULT_VALUE = '+1–___–___–____';

interface IPhoneNumberInputProps {
  value: string;
  isValid?: boolean;
  setValue: (value: string) => void;
  setError?: (error: string) => void;
  setIsValid?: (isValid: boolean) => void;
  disabled?: boolean;
}

const PhoneNumberInput: FC<
  IPhoneNumberInputProps & IInputWrapperProps & Partial<PatternFormatProps>
> = ({
  value,
  label,
  error,
  isValid,
  disabled,
  wrapperClassName,
  setError,
  setIsValid,
  setValue,
  tooltipText,
  ...props
}) => {
  const { t: translate } = useTranslation(TranslationNameSpaces.AuthorizationProcess);
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(
    formatPhoneNumber(value) || PHONE_DEFAULT_VALUE,
  );

  const validatePhoneNumber = () => {
    const unformattedPhoneNumber = unformatBorrowerPhone(formattedPhoneNumber);
    const isPhoneNumberValid = isValidPhoneNumber(unformattedPhoneNumber, 'US');
    setIsValid?.(isPhoneNumberValid);
  };

  const handleBlur = () => {
    validatePhoneNumber();
  };

  const handleFocus = () => {
    if (!value) {
      setFormattedPhoneNumber(PHONE_DEFAULT_VALUE);
    }
  };

  const handleChange = ({ formattedValue: inputValue }: { formattedValue: string }) => {
    setError?.('');
    setIsValid?.(true);
    setValue(unformatBorrowerPhone(inputValue));
  };

  useEffect(() => {
    setFormattedPhoneNumber(formatPhoneNumber(value));
  }, [value]);

  return (
    <>
      <InputWrapper
        wrapperClassName={wrapperClassName}
        error={error}
        inputIcon={<InfoCircleIcon />}
        label={label}
        tooltipText={
          tooltipText || translate(AuthorizationProcessTranslationKeys.PhoneNumberTooltipText)!
        }
      >
        <PatternFormat
          format={PHONE_NUMBER_FORMAT}
          mask={PHONE_NUMBER_MASK}
          placeholder={label as string}
          disabled={disabled}
          value={formattedPhoneNumber}
          onValueChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          {...props}
        />
      </InputWrapper>
    </>
  );
};

export default PhoneNumberInput;
