import { ApplicationStatusId, ProdApplicationStatusId } from 'enums/ApplicationStatusId';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { Environment } from 'enums/Environment';
import { environmentSecret } from 'secrets';

export const getApplicationStatusIdByEnvironment = (
  applicationStatusName: ApplicationStatusName,
) => {
  if (environmentSecret !== Environment.Production) {
    return ApplicationStatusId[applicationStatusName];
  }

  return ProdApplicationStatusId[applicationStatusName];
};

export const matchApplicationStatusIdByEnvironment = (
  statusId: string | null,
  applicationStatusName: ApplicationStatusName,
) => {
  if (!statusId) {
    return null;
  }
  if (environmentSecret !== Environment.Production) {
    return statusId === ApplicationStatusId[applicationStatusName];
  }

  return statusId === ProdApplicationStatusId[applicationStatusName];
};
