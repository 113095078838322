import PageLayout, { LayoutSize } from 'components/PageLayout/PageLayout';
import React, { FC } from 'react';
import styles from './StubWithDescription.module.scss';
import { BankVerificationPageTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { useTranslation } from 'react-i18next';

interface IStubWithDescriptionProps {
  description: string;
}

const StubWithDescription: FC<IStubWithDescriptionProps> = ({
  description,
}) => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.BankVerificationPage,
    TranslationNameSpaces.IntermediaryBankVerification,
  ]);

  return (
    <PageLayout layoutSize={LayoutSize.Big}>
      <div className={styles.title}>
        {translate(BankVerificationPageTranslationKeys.Title, {
          ns: TranslationNameSpaces.BankVerificationPage,
        })}
      </div>
      <div className={styles.description}>
        {description}
      </div>
    </PageLayout>
  );
};

export default StubWithDescription;
