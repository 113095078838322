import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import {
  ButtonsTranslationKeys,
  NotFoundPageTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';

import { AppRoutes } from 'routes/AppRoutes';
import React from 'react';
import Title from 'components/Title';
import { getCurrentAccount } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './NotFoundPage.module.scss';

const NotFoundPage = () => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.Buttons,
    TranslationNameSpaces.NotFoundPage,
  ]);

  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      await dispatchWithUnwrap(getCurrentAccount());
      return navigate(AppRoutes.Applications);
    } catch (e) {
      return navigate(AppRoutes.Authorization);
    }
  };

  return (
    <div className={styles.pageWrapper} data-bg-message="Error">
      <p className={styles.errorCode}>
        {translate(NotFoundPageTranslationKeys.Code, { ns: TranslationNameSpaces.NotFoundPage })}
      </p>
      <Title variant="h3">
        {translate(NotFoundPageTranslationKeys.Message, { ns: TranslationNameSpaces.NotFoundPage })}
      </Title>
      <Button
        variant={ButtonVariant.WithoutOutlines}
        size={ButtonSize.Middle}
        onClick={handleClick}
      >
        {translate(ButtonsTranslationKeys.GoHome, { ns: TranslationNameSpaces.Buttons })}
      </Button>
    </div>
  );
};

export default NotFoundPage;
