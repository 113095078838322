import { createSlice } from '@reduxjs/toolkit';
import { PersonalInformationVariable } from 'enums/PersonalInformation';
import { ProjectAddressVariable } from 'enums/ProjectAddressVariable';
import { getPersonalInformation } from 'thunks';

export interface IPersonalInformation {
  [PersonalInformationVariable.LoanAmount]: string;
  [PersonalInformationVariable.FirstName]: string;
  [PersonalInformationVariable.MiddleName]: string;
  [PersonalInformationVariable.LastName]: string;
  [PersonalInformationVariable.StreetName]: string;
  [PersonalInformationVariable.StreetNumber]: string;
  [PersonalInformationVariable.ApartmentOrSuite]: string;
  [PersonalInformationVariable.City]: string;
  [PersonalInformationVariable.State]: string;
  [PersonalInformationVariable.ZipCode]: string;
  [PersonalInformationVariable.Email]: string;
}

export interface IProjectAddress {
  [ProjectAddressVariable.StreetName]: string;
  [ProjectAddressVariable.StreetNumber]: string;
  [ProjectAddressVariable.ApartmentOrSuite]: string;
  [ProjectAddressVariable.City]: string;
  [ProjectAddressVariable.State]: string;
  [ProjectAddressVariable.ZipCode]: string;
}

export interface IPersonalInformationState {
  data: IPersonalInformation & IProjectAddress;
}

const projectAddressInitialState = {
  [ProjectAddressVariable.StreetName]: '',
  [ProjectAddressVariable.StreetNumber]: '',
  [ProjectAddressVariable.ApartmentOrSuite]: '',
  [ProjectAddressVariable.City]: '',
  [ProjectAddressVariable.State]: '',
  [ProjectAddressVariable.ZipCode]: '',
};

const initialState: IPersonalInformationState = {
  data: {
    [PersonalInformationVariable.LoanAmount]: '',
    [PersonalInformationVariable.FirstName]: '',
    [PersonalInformationVariable.MiddleName]: '',
    [PersonalInformationVariable.LastName]: '',
    [PersonalInformationVariable.StreetName]: '',
    [PersonalInformationVariable.StreetNumber]: '',
    [PersonalInformationVariable.ApartmentOrSuite]: '',
    [PersonalInformationVariable.City]: '',
    [PersonalInformationVariable.State]: '',
    [PersonalInformationVariable.ZipCode]: '',
    [PersonalInformationVariable.Email]: '',
    ...projectAddressInitialState,
  },
};

const personalInformationSlice = createSlice({
  name: 'personalInformation',
  initialState,
  reducers: {
    removeProjectAddress: (state) => {
      state.data = { ...state.data, ...projectAddressInitialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPersonalInformation.fulfilled, (state, { payload }) => {
      state.data = payload;
    });
  },
});

export const { removeProjectAddress } = personalInformationSlice.actions;

export default personalInformationSlice.reducer;
