import { ErrorMessagesTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { useTranslation } from 'react-i18next';

export const useErrorMessages = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.ErrorMessages);

  const getMessageForRequiredFields = (inputName: string) =>
    translate(ErrorMessagesTranslationKeys.Required, {
      inputName,
    });

  const getMessageForInvalidFields = (inputName: string) =>
    translate(ErrorMessagesTranslationKeys.Invalid, {
      inputName,
    });

  const getMessageForConfirmFields = (inputName: string) =>
    translate(ErrorMessagesTranslationKeys.ShouldBeEqual, {
      inputName,
    });

  return [getMessageForRequiredFields, getMessageForInvalidFields, getMessageForConfirmFields];
};
