import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationDetailsPageTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { TypographySize } from 'enums/TypographySize';
import { convertToMonetary } from 'utils/profund/formats';
import Paragraph from 'components/Paragraph';
import { ManageFundsType } from 'components/ManageFunds';

import styles from './SuccessMessage.module.scss';

interface ISuccessMessageProps {
  mangeType: ManageFundsType;
  amount: number;
}

const SuccessMessage = ({ mangeType, amount }: ISuccessMessageProps) => {
  const { t: translate } = useTranslation([TranslationNameSpaces.ApplicationDetailsPage]);

  const getContent = () => {
    switch (mangeType) {
      case ManageFundsType.AddFunds:
        return {
          title: translate(ApplicationDetailsPageTranslationKeys.AddFundsSuccessMessageTitle, {
            ns: TranslationNameSpaces.ApplicationDetailsPage,
          }),
          subtitle: translate(
            ApplicationDetailsPageTranslationKeys.AddFundsSuccessMessageDescription,
            {
              ns: TranslationNameSpaces.ApplicationDetailsPage,
            },
          ),
        };

      case ManageFundsType.RequestFunds:
        return {
          title: translate(ApplicationDetailsPageTranslationKeys.RequestFundsSuccessMessageTitle, {
            ns: TranslationNameSpaces.ApplicationDetailsPage,
          }),
          subtitle: translate(
            ApplicationDetailsPageTranslationKeys.RequestFundsSuccessMessageDescription,
            {
              ns: TranslationNameSpaces.ApplicationDetailsPage,
            },
          ),
        };
    }
  };

  return (
    <div className={styles.container}>
      <Paragraph variant={TypographySize.Large} marginBottom="8">
        {getContent().title}
      </Paragraph>
      <p className={styles.amount}>{convertToMonetary(amount)}!</p>
      <Paragraph variant={TypographySize.Large}>{getContent().subtitle}</Paragraph>
    </div>
  );
};

export default SuccessMessage;
