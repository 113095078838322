import React, { FC } from 'react';
import clsx from 'clsx';

import styles from './PageLayout.module.scss';

export enum LayoutSize {
  Big = 'big',
  Small = 'small',
}

const getLayoutClassName = (layoutSize?: LayoutSize) => {
  switch (layoutSize) {
    case LayoutSize.Big:
      return styles.bigPadding;
    case LayoutSize.Small:
      return styles.smallPadding;
    default:
      return styles.bigPadding;
  }
};

interface IPageLayoutProps {
  layoutSize?: LayoutSize;
  className?: string;
  removePaddingTop?: boolean;
  removePaddingBottom?: boolean;
}

const PageLayout: FC<IPageLayoutProps> = ({
  layoutSize,
  children,
  className,
  removePaddingTop,
  removePaddingBottom,
}) => {
  return (
    <div
      className={clsx(
        styles.container,
        getLayoutClassName(layoutSize),
        className,
        removePaddingTop && styles.removePaddingTop,
        removePaddingBottom && styles.removePaddingBottom,
      )}
    >
      {children}
    </div>
  );
};

export default PageLayout;
