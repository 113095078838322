import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getApplicationById, getBorrowerLinkToken, updateApplication } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useQueryParam from 'hooks/useQueryParam';
import Loading from 'components/Loading';
import PageLayout, { LayoutSize } from 'components/PageLayout';
import Title from 'components/Title';
import { BankVerificationPageTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { AppRoutes } from 'routes/AppRoutes';
import { ApplicationVariable } from 'enums/ApplicationVariable';

const ResetPlaidLink = () => {
  const navigate = useNavigate();
  const applicationId = useQueryParam('applicationId');
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { t: translate } = useTranslation([TranslationNameSpaces.BankVerificationPage]);

  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');

  const handleOnSuccess = async () => {
    try {
      await dispatchWithUnwrap(
        updateApplication({
          applicationId: applicationId!,
          params: {
            variables: {
              [ApplicationVariable.IsPlaidUpdateModeInProgress]: null,
            },
          },
        }),
      );

      setLoading(true);
    } catch (error) {
      setLoading(false);
    }

    navigate(AppRoutes.Applications, { replace: true });
  };

  const handleExit = async () => {
    navigate(AppRoutes.Applications);
  };

  const { open, ready } = usePlaidLink({
    token,
    onSuccess: handleOnSuccess,
    onExit: handleExit,
  });

  const handleGetLinkToken = async () => {
    try {
      const linkToken = await dispatchWithUnwrap(
        getBorrowerLinkToken({ applicationId: applicationId!, isUpdateMode: true }),
      );
      setToken(linkToken);
    } finally {
      setLoading(false);
    }
  };

  const checkIsPlaidUpdateModeInProgress = async () => {
    try {
      setLoading(true);
      const { variables } = await dispatchWithUnwrap(getApplicationById(applicationId!));

      if (!variables[ApplicationVariable.IsPlaidUpdateModeInProgress]) {
        navigate(AppRoutes.Applications);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkIsPlaidUpdateModeInProgress();
    handleGetLinkToken();
  }, []);

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready]);

  if (!ready || loading) {
    return <Loading />;
  }

  return (
    <div>
      <PageLayout layoutSize={LayoutSize.Big}>
        <Title variant="h1" marginBottom="16">
          {translate(BankVerificationPageTranslationKeys.Title)}
        </Title>
      </PageLayout>
    </div>
  );
};

export default ResetPlaidLink;
