import * as Yup from 'yup';
import { CoborrowerVariable } from 'enums/CoborrowerVariable';
import { countryStates } from 'pages/ApplicationProcess/PersonalInformation/stateSearchOptions';
import { useTranslation } from 'react-i18next';
import { InputLabelsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { useErrorMessages } from 'hooks/useErrorMessages';
import {
  DATE_MATCH_REGEXP,
  SSN_MATCH_REGEXP,
} from 'components/BorrowerFinancialInfo/validationSchema';
import { DIGIFI_EMAIL_PATTERN } from 'pages/ApplicationProcess/PersonalInformation/validationSchema';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { isWaterCressEnabled } from 'utils/isWatercressEnabled';

export const useCoborrowerFormValidationSchema = () => {
  const [getMessageForRequiredFields, getMessageForInvalidFields] = useErrorMessages();
  const { t: translate } = useTranslation(TranslationNameSpaces.InputLabels);

  const watercressFieldsToValidate = isWaterCressEnabled
    ? {
        [CoborrowerVariable.PropertyStatus]: Yup.string()
          .nullable()
          .required(
            getMessageForRequiredFields(translate(InputLabelsTranslationKeys.PropertyStatus)),
          ),
        [CoborrowerVariable.Email]: Yup.string()
          .required(getMessageForRequiredFields(translate(InputLabelsTranslationKeys.Email)))
          .nullable()
          .matches(
            DIGIFI_EMAIL_PATTERN,
            getMessageForInvalidFields(translate(InputLabelsTranslationKeys.Email)),
          ),
        [CoborrowerVariable.SSN]: Yup.string()
          .required(getMessageForRequiredFields(translate(InputLabelsTranslationKeys.SSN)))
          .nullable()
          .matches(
            SSN_MATCH_REGEXP,
            getMessageForInvalidFields(translate(InputLabelsTranslationKeys.SSN)),
          ),
        [CoborrowerVariable.Phone]: Yup.string()
          .required(getMessageForRequiredFields(translate(InputLabelsTranslationKeys.PhoneNumber)))
          .nullable()
          .test(
            'isPhoneValid',
            getMessageForInvalidFields(translate(InputLabelsTranslationKeys.PhoneNumber)),
            (value = '') => {
              return isValidPhoneNumber(value!, 'US');
            },
          ),
      }
    : null;

  const coborrowerFormValidationSchema = Yup.object().shape({
    [CoborrowerVariable.FirstName]: Yup.string().required(
      getMessageForRequiredFields(translate(InputLabelsTranslationKeys.FirstName)),
    ),
    [CoborrowerVariable.LastName]: Yup.string().required(
      getMessageForRequiredFields(translate(InputLabelsTranslationKeys.LastName)),
    ),
    [CoborrowerVariable.DateOfBirth]: Yup.string()
      .required(getMessageForRequiredFields(translate(InputLabelsTranslationKeys.DateOfBirth)))
      .nullable()
      .matches(
        DATE_MATCH_REGEXP,
        getMessageForInvalidFields(translate(InputLabelsTranslationKeys.DateOfBirth)),
      ),
    [CoborrowerVariable.StreetName]: Yup.string().required(
      getMessageForRequiredFields(translate(InputLabelsTranslationKeys.HomeAddress)),
    ),
    [CoborrowerVariable.City]: Yup.string().required(
      getMessageForRequiredFields(translate(InputLabelsTranslationKeys.City)),
    ),
    [CoborrowerVariable.State]: Yup.string()
      .required(getMessageForRequiredFields(translate(InputLabelsTranslationKeys.State)))
      .test(
        'isStateValid',
        getMessageForInvalidFields(translate(InputLabelsTranslationKeys.State)),
        (value) => {
          return countryStates.includes(value as string);
        },
      ),
    [CoborrowerVariable.ZipCode]: Yup.string().required(
      getMessageForRequiredFields(translate(InputLabelsTranslationKeys.ZipCode)),
    ),
    [CoborrowerVariable.AnnualIncome]: Yup.string().required(
      getMessageForRequiredFields(translate(InputLabelsTranslationKeys.AnnualIncome)),
    ),
    ...watercressFieldsToValidate,
  });

  return coborrowerFormValidationSchema;
};
