import React, { useState } from 'react';
import styles from './DwollaBankVerification.module.scss';
import Menu from 'components/Menu';
import {
  BankVerificationPageTranslationKeys,
  DwollaInfoPopupTranslationKeys,
  MenuTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import PageLayout, { LayoutSize } from 'components/PageLayout';
import AdvertiserDisclosure from 'components/AdvertiserDisclosure';
import Title from 'components/Title';
import DisclosuresBox from 'components/DisclosuresBox';
import ParticipatingLenderInfo from 'components/InformationBox/ParticipatingLenderInfo';
import { useTranslation } from 'react-i18next';
import { DwollaGuide, DwollaLogo, InfoCircleIcon } from 'static/images';
import DwollaBankVerificationForm from 'components/DwollaBankVerificationForm';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useNavigate, useParams } from 'react-router-dom';
import { createBorrowerDwollaMicroDepositFundingSource, logout } from 'thunks';
import { ICreateBorrowerDwollaMicroDepositsFundingSourceBody } from 'api/digifi/portal/PaymentProcessorApi';
import { BankVerificationProcessSteps } from 'pages/ApplicationProcess/BankVerificationProcess/BankVerificationProcess';
import Loading from 'components/Loading';
import Popup from 'components/Popup';
import DwollaPopupContent from 'components/BankConnectionCardWrapper/DwollaPopupContent';
import { AppRoutes } from 'routes/AppRoutes';

interface IDwollaBankVerificationProps {
  onSetDwollaMicroDepositsError: () => void;
  onSetStep: (step: BankVerificationProcessSteps) => void;
}

const DwollaBankVerification = ({
  onSetDwollaMicroDepositsError,
  onSetStep,
}: IDwollaBankVerificationProps) => {
  const navigate = useNavigate();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { t: translate } = useTranslation([
    TranslationNameSpaces.BankVerificationPage,
    TranslationNameSpaces.Popups,
  ]);
  const { applicationId } = useParams();
  const [loading, setLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSubmitForm = async (values: Record<string, string>) => {
    try {
      setLoading(true);
      const redirectUrl = await dispatchWithUnwrap(
        createBorrowerDwollaMicroDepositFundingSource({
          ...values,
          applicationId: applicationId!,
        } as ICreateBorrowerDwollaMicroDepositsFundingSourceBody),
      );

      if (redirectUrl) {
        window.open(redirectUrl, '_self');
        dispatchWithUnwrap(logout({ withoutStateReset: true }));

        return;
      }

      navigate(AppRoutes.Applications);
    } catch (error) {
      onSetDwollaMicroDepositsError();
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <Menu activeMenuItem={MenuTranslationKeys.Applications} />
      <PageLayout layoutSize={LayoutSize.Big} className={styles.pageLayoutClassName}>
        <div>
          <AdvertiserDisclosure
            onBackClick={() => onSetStep(BankVerificationProcessSteps.WhatNext)}
          />
          <Title variant="h1" marginBottom="16">
            {translate(BankVerificationPageTranslationKeys.Title, {
              ns: TranslationNameSpaces.BankVerificationPage,
            })}
          </Title>
        </div>
        <div className={styles.mainContent}>
          <div className={styles.subTitle}>
            <div>
              {translate(BankVerificationPageTranslationKeys.DwollaVerificationSubtitle, {
                ns: TranslationNameSpaces.BankVerificationPage,
              })}
            </div>
            <InfoCircleIcon onClick={() => setIsPopupOpen(true)} />
          </div>
          <div className={styles.poweredBy}>
            <span>
              {translate(BankVerificationPageTranslationKeys.PoweredBy, {
                ns: TranslationNameSpaces.BankVerificationPage,
              })}
            </span>
            <DwollaLogo />
          </div>
          <div className={styles.guideContainer}>
            <DwollaGuide />
          </div>
          <DwollaBankVerificationForm onSubmitForm={handleSubmitForm} />
        </div>
        <div>
          <DisclosuresBox />
          <ParticipatingLenderInfo />
        </div>
        <Popup
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          title={
            translate(DwollaInfoPopupTranslationKeys.Title, {
              ns: TranslationNameSpaces.Popups,
            }) as string
          }
        >
          <DwollaPopupContent />
        </Popup>
      </PageLayout>
    </div>
  );
};

export default DwollaBankVerification;
