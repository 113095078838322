export enum TooltipId {
  RequestFunds = 'requestFunds',
  AddFunds = 'addFunds',
  CloseAccount = 'closeAccount',
  CloseAccountPopUp = 'closeAccountPopUp',
  ReopenAccount = 'reopenAccount',
  JobAmount = 'jobAmount',
  ProjectAddress = 'projectAddress',
  ProjectAccountBalance = 'projectAccountBalance',
}
