import { ApplicationVariable } from 'enums/ApplicationVariable';

export const resetSelectedOffer = (
  initialLoanAmount: number, 
  restParams: Partial<Record<ApplicationVariable, null>> = {},
) => {
  return {
    [ApplicationVariable.FinalOfferProviderName]: null,
    [ApplicationVariable.FinalOfferLenderName]: null,
    [ApplicationVariable.FinalOfferId]: null,
    [ApplicationVariable.FinalOfferAmount]: null,
    [ApplicationVariable.FinalFinancialServicesPartnerLogo]: null,
    [ApplicationVariable.FinalOfferDesignation]: null,
    [ApplicationVariable.FinalOfferMonthlyPaymentAmount]: null,
    [ApplicationVariable.FinalOfferTermLength]: null,
    [ApplicationVariable.FinalOfferTermUnit]: null,
    [ApplicationVariable.FinalOfferAprAmount]: null,
    [ApplicationVariable.FinalOfferLink]: null,
    [ApplicationVariable.FinalOfferDisclaimer]: null,
    [ApplicationVariable.FinalOfferRecommendationScore]: null,
    [ApplicationVariable.FinalOfferMonthlyPaymentDescription]: null,
    [ApplicationVariable.FinalOfferTermDescription]: null,
    [ApplicationVariable.FinalOfferAprDescription]: null,
    [ApplicationVariable.FinalOfferProductName]: null,
    [ApplicationVariable.FinalOfferInterestRate]: null,
    [ApplicationVariable.FinalOfferDiscountPercent]: null,
    [ApplicationVariable.FinalOfferDisbursementCount]: null,
    [ApplicationVariable.FinalOfferExpirationDate]: null,
    [ApplicationVariable.FinalOfferCreditLimit]: null,
    [ApplicationVariable.FinalOfferApplicationName]: null,
    [ApplicationVariable.ApplicationApprovalDate]: null,
    [ApplicationVariable.LoanAmount]: initialLoanAmount,
    [ApplicationVariable.AgreedJobAmount]: initialLoanAmount,
    [ApplicationVariable.SalespersonFinalizeOfferEmail]: null,
    ...restParams,
  };
};
