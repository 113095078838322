import { EnvironmentVariable } from 'enums/EnvironmentVariable';
import BaseSecret from './BaseSecret';

class WatercressEnabledSecret extends BaseSecret {
  constructor() {
    super(EnvironmentVariable.REACT_APP_WATERCRESS_ENABLED);
  }
}

export default WatercressEnabledSecret;
