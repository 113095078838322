import Loading from 'components/Loading';
import PageLayout from 'components/PageLayout';
import { LayoutSize } from 'components/PageLayout/PageLayout';
import Title from 'components/Title';
import { DwollaMicroDepositsStatus } from 'enums/DwollaMicrodepositsStatus';
import { BankVerificationPageTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { BankVerificationProcessSteps } from 'pages/ApplicationProcess/BankVerificationProcess/BankVerificationProcess';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlaidLinkOnSuccessMetadata, usePlaidLink } from 'react-plaid-link';
import { profundFrontendUrlSecret } from 'secrets';
import {
  createIntermediaryFundingSource,
  getIntermediaryLinkToken,
  saveIntermediaryToken,
} from 'thunks';

const MICRODEPOSITS_PENDING_STATUS = 'pending_automatic_verification';

interface IPlaidBankVerificationProps {
  token: string;
  microdepositsStatus: DwollaMicroDepositsStatus;
  onPlaidVerificationError: () => void;
  onSetIsDwollaError: (isError: boolean) => void;
  onSetStep: (step: BankVerificationProcessSteps) => void;
}

const IntermediaryBankVerificationPage = ({
  token,
  microdepositsStatus,
  onPlaidVerificationError,
  onSetIsDwollaError,
  onSetStep,
}: IPlaidBankVerificationProps) => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.BankVerificationPage,
    TranslationNameSpaces.IntermediaryBankVerification,
  ]);
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const [loading, setLoading] = useState(true);
  const [plaidToken, setPlaidToken] = useState('');

  const handleOnSuccess = async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
    setLoading(true);

    try {
      const isMicroDepositsMode =
        metadata.accounts[0].verification_status === MICRODEPOSITS_PENDING_STATUS;

      await dispatchWithUnwrap(
        saveIntermediaryToken({
          intermediaryToken: token!,
          token: public_token,
          accountId: metadata.accounts[0].id,
          isMicroDepositsMode,
        }),
      );

      if (!isMicroDepositsMode) {
        await dispatchWithUnwrap(createIntermediaryFundingSource(token!));
      }

      window.location.replace(`${profundFrontendUrlSecret}/account`);
    } catch {
      onSetIsDwollaError(true);
      onPlaidVerificationError();
    } finally {
      setLoading(false);
    }
  };

  const getPlaidToken = async () => {
    try {
      const plaidTokenValue = await dispatchWithUnwrap(getIntermediaryLinkToken(token!));
      setPlaidToken(plaidTokenValue);
    } catch {
      onSetIsDwollaError(true);
    }
  };

  const { open, ready } = usePlaidLink({
    token: plaidToken,
    onSuccess: handleOnSuccess,
    onExit: () => {
      if (microdepositsStatus === DwollaMicroDepositsStatus.VerificationFailed) {
        window.open(profundFrontendUrlSecret, '_self');

        return;
      }

      onSetStep(BankVerificationProcessSteps.SelectBankVerification);
    },
  });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready]);

  useEffect(() => {
    getPlaidToken();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <PageLayout layoutSize={LayoutSize.Big}>
      <Title variant="h1" marginBottom="16">
        {translate(BankVerificationPageTranslationKeys.Title, {
          ns: TranslationNameSpaces.BankVerificationPage,
        })}
      </Title>
    </PageLayout>
  );
};

export default IntermediaryBankVerificationPage;
