import { Navigate, Route, Routes } from 'react-router-dom';

import { AppRoutes } from './AppRoutes';
import { AppRoutesWithParams } from './AppRoutesWithParams';
import ApplicationProcess from 'pages/ApplicationProcess';
import Applications from 'pages/Applications';
import AuthorizationProcess from 'pages/AuthorizationProcess/AuthorizationProcess';
import CreditAuthorizationPage from 'pages/CreditAuthorizationPage';
import ElectronicConsentPage from 'pages/ElectronicConsentPage';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import IntermediaryBankVerificationPage from 'pages/IntermediaryBankVerification/IntermediaryBankVerification';
import MainLayout from 'layout/ProFundMainLayout';
import NotFoundPage from 'pages/NotFoundPage';
import Profile from 'pages/Profile';
import RequiredAuth from 'components/RequiredAuth';
import TermsOfServicePage from 'pages/TermsOfServicePage';
import ApplicationDetails from 'pages/ApplicationDetails';
import ResetPlaidLink from 'pages/ResetPlaidLink/ResetPlaidLink';
import history from './historyRouter';

const Router = () => (
  <HistoryRouter history={history}>
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path={AppRoutes.Authorization} element={<AuthorizationProcess />} />
        <Route element={<RequiredAuth />}>
          <Route path={AppRoutesWithParams.ApplicationProcess} element={<ApplicationProcess />} />
          <Route path={AppRoutes.Profile} element={<Profile />} />
          <Route path={AppRoutes.Applications} element={<Applications />} />
          <Route path={AppRoutesWithParams.ApplicationDetails} element={<ApplicationDetails />} />
          <Route path={AppRoutes.ResetPlaidLink} element={<ResetPlaidLink />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
        <Route path={AppRoutes.TermsOfService} element={<TermsOfServicePage />} />
        <Route path={AppRoutes.CreditAuthorization} element={<CreditAuthorizationPage />} />
        <Route path={AppRoutes.ElectronicConsent} element={<ElectronicConsentPage />} />
        <Route path={AppRoutes.Intermediary} element={<IntermediaryBankVerificationPage />} />
        <Route index element={<Navigate to={AppRoutes.Authorization} />} />
      </Route>
    </Routes>
  </HistoryRouter>
);

export default Router;
