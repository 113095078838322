import React from 'react';
import { ModalCloseIcon } from 'static/images';

import styles from './CloseIcon.module.scss';
import clsx from 'clsx';

interface ICloseIconProps {
  onClick: () => void;
  className?: string;
}

const CloseIcon = ({ onClick, className }: ICloseIconProps) => {
  return (
    <div className={clsx(styles.container, className)}>
      <ModalCloseIcon className={styles.closeIcon} onClick={onClick} />
    </div>
  );
};

export default CloseIcon;
