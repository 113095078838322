import React from 'react';
import styles from './PlaidPopupContent.module.scss';
import { useTranslation } from 'react-i18next';
import { PlaidInfoPopupTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

const PlaidPopupContent = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.Popups);

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        {translate(PlaidInfoPopupTranslationKeys.Description)}
      </div>
      <div className={styles.info}>{translate(PlaidInfoPopupTranslationKeys.Info)}</div>
      <div className={styles.steps}>
        <div>{translate(PlaidInfoPopupTranslationKeys.Step1)}</div>
        <div>{translate(PlaidInfoPopupTranslationKeys.Step2)}</div>
        <div>{translate(PlaidInfoPopupTranslationKeys.Step3)}</div>
      </div>
    </div>
  );
};

export default PlaidPopupContent;
