import { createSlice } from '@reduxjs/toolkit';
import { logout, sendVerificationCode, verifyCode } from 'thunks';

export interface IVerificationState {
  phone: string;
  isVerified: boolean;
}

const initialState: IVerificationState = {
  phone: '',
  isVerified: false,
};

const verificationSlice = createSlice({
  name: 'verification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendVerificationCode.fulfilled, (state, { payload }) => {
        state.phone = payload.phone;
      })
      .addCase(verifyCode.fulfilled, (state) => {
        state.isVerified = true;
      })
      .addCase(logout.fulfilled, (state, { payload }) => {
        if (payload) {
          return state;
        }

        return initialState;
      });
  },
});

export default verificationSlice.reducer;
