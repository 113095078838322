import { FinancialInformationPageTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { useTranslation } from 'react-i18next';

const getMappedDropdownVariables = (valueList: string[], labelList: string[]) =>
  valueList.map((value, index) => ({
    value,
    label: labelList[index],
  }));

export const DEFAULT_PROPERTY_STATUS_VALUE = 'Own with Mortgage';
export const DEFAULT_EMPLOYMENT_STATUS_VALUE = 'Employed Full Time';

export const useTranslatedDropdownOptions = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.FinancialInformationPage);

  const PROPERTY_INPUT_OPTIONS = getMappedDropdownVariables(
    ['Own Outright', 'Own with Mortgage', 'Rent'],
    translate(FinancialInformationPageTranslationKeys.PropertyLabels, {
      returnObjects: true,
    }) as string[],
  );

  const CREDIT_RATING_OPTIONS = getMappedDropdownVariables(
    [
      'More Than 720',
      'From 660 To 719',
      'From 620 To 659',
      'Less Than 620',
      'Limited / No History',
    ],
    translate(FinancialInformationPageTranslationKeys.CreditRatingLabels, {
      returnObjects: true,
    }) as string[],
  );

  const EMPLOYMENT_STATUS_OPTIONS = getMappedDropdownVariables(
    [
      'Employed Full Time',
      'Employed Part Time',
      'Military',
      'Not Employed',
      'Self Employed',
      'Retired',
      'Other',
    ],
    translate(FinancialInformationPageTranslationKeys.EmploymentStatusLabels, {
      returnObjects: true,
    }) as string[],
  );

  const PAY_FREQUENCY_OPTIONS = getMappedDropdownVariables(
    ['Weekly', 'Biweekly', 'Twice Monthly', 'Monthly'],
    translate(FinancialInformationPageTranslationKeys.PayFrequencyLabels, {
      returnObjects: true,
    }) as string[],
  );

  const HIGHEST_EDUCATION_OPTIONS = getMappedDropdownVariables(
    ['High School', 'Associate', 'Bachelors', 'Masters', 'Other Graduate Degree', 'Other'],
    translate(FinancialInformationPageTranslationKeys.HighestEducationLabels, {
      returnObjects: true,
    }) as string[],
  );

  const VERIFICATION_DOCUMENT_TYPE_OPTIONS = getMappedDropdownVariables(
    ['passport', 'license', 'idCard'],
    translate(FinancialInformationPageTranslationKeys.VerificationDocumentTypeLabels, {
      returnObjects: true,
    }) as string[],
  );

  return {
    PROPERTY_INPUT_OPTIONS,
    CREDIT_RATING_OPTIONS,
    EMPLOYMENT_STATUS_OPTIONS,
    PAY_FREQUENCY_OPTIONS,
    HIGHEST_EDUCATION_OPTIONS,
    VERIFICATION_DOCUMENT_TYPE_OPTIONS,
  };
};

export const SSN_TEST_EXCEPTIONS = [
  '666-00-0035',
  '666-14-5383',
  '666-36-2166',
  '666-51-3276',
  '666-56-0517',
  '666-66-2178',
  '666-50-6751',
  '666-51-7989',
  '666-33-6723',
  '666-62-6883',
  '666-33-1503',
  '666-44-8401',
  '666-37-9560',
  '666-61-0440',
  '666-37-7967',
  '666-12-6606',
  '666-10-3179',
  '666-60-0381',
  '666-60-0382',
  '666-60-0383',
  '666-60-0384',
  '666-60-0385',
  '666-60-0386',
];
