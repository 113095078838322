import { ButtonsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsIcon } from 'static/images';

import styles from './ManageButton.module.scss';

export interface IManageButtonMenu {
  title: string;
  icon: JSX.Element;
  onClick: () => void;
}

interface IManageButtonProps {
  menu: IManageButtonMenu[];
}

const ManageButton = ({ menu }: IManageButtonProps) => {
  const { t: translate } = useTranslation(TranslationNameSpaces.Buttons);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.container} onClick={() => setIsOpen(!isOpen)}>
      <SettingsIcon /> <p className={styles.title}>{translate(ButtonsTranslationKeys.Manage)}</p>
      {isOpen && (
        <div className={styles.menu}>
          {menu.map((item) => (
            <div key={item.title} className={styles.menuItemContainer} onClick={item.onClick}>
              {item.icon} <p className={styles.menuItemTitle}>{item.title}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ManageButton;
