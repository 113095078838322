import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import {
  ApplicationsPageTranslationKeys,
  ButtonsTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import { getDisplayVariableValue } from 'utils/profund/getDisplayVariableValue';
import Title from 'components/Title';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { ApplicationStatusIdType } from 'enums/ApplicationStatusId';
import { matchApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';

import styles from './ApplicationCard.module.scss';
import { InfoCircleIcon } from 'static/images';
import { DwollaDocumentVerificationProcess } from 'enums/DocumentVerification';
import { VariableValue } from 'types/VariableTypes';
import { getApplicationLabelIdByEnvironment } from 'utils/applicationLabelsHelpers';
import { ApplicationLabelName } from 'enums/ApplicationLabelId';

interface IApplicationCard {
  contractorName: string;
  updatedAt: Date;
  hasMicroDepositsLabel: boolean;
  status: {
    name: string;
    id: ApplicationStatusIdType;
  };
  areDocsPending: boolean;
  dwollaDocumentVerificationProcess: DwollaDocumentVerificationProcess;
  labelIds: string[];
  fundedByEven?: VariableValue;
  loanAmount?: number;
  isActive?: boolean;
  onClick?: () => void;
  isButtonLoading?: boolean;
}

const ApplicationCard = ({
  contractorName,
  updatedAt,
  status,
  areDocsPending,
  loanAmount,
  isActive,
  onClick,
  isButtonLoading,
  hasMicroDepositsLabel,
  dwollaDocumentVerificationProcess,
  fundedByEven,
  labelIds,
}: IApplicationCard) => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.ApplicationsPage,
    TranslationNameSpaces.Buttons,
  ]);

  const getButtonText = () => {
    if (
      isActive &&
      matchApplicationStatusIdByEnvironment(status.id, ApplicationStatusName.Approved) &&
      !fundedByEven &&
      [
        getApplicationLabelIdByEnvironment(ApplicationLabelName.FundedOutsideProfund),
        getApplicationLabelIdByEnvironment(ApplicationLabelName.ConnectBankLater),
      ].some((label) => labelIds.includes(label))
    ) {
      return ButtonsTranslationKeys.Manage;
    }

    return ButtonsTranslationKeys.Continue;
  };

  const isButtonDisabled =
    hasMicroDepositsLabel ||
    dwollaDocumentVerificationProcess === DwollaDocumentVerificationProcess.Review;

  const getNotificationText = () => {
    if (hasMicroDepositsLabel) {
      return (
        <>
          <p>Bank verification is in progress.</p>
          <p>Thank you for your patience!</p>
        </>
      );
    }

    switch (dwollaDocumentVerificationProcess) {
      case DwollaDocumentVerificationProcess.Review: {
        return (
          <>
            <p>Document verification is in progress.</p>
            <p>Thank you for your patience!</p>
          </>
        );
      }
      case DwollaDocumentVerificationProcess.Needed: {
        return (
          <p>
            Our payment processor requires one extra step of verification to confirm your identity
            to ensure your account is setup safely and securely.
          </p>
        );
      }
      case DwollaDocumentVerificationProcess.Failed: {
        return (
          <p>
            Your attempt to upload and deliver your personal document for account verification has
            failed. Please try again to complete your account setup.
          </p>
        );
      }
    }

    return '';
  };

  const Notification = () => (
    <div className={styles.notification}>
      <InfoCircleIcon />
      <div className={styles.notificationText}>{getNotificationText()}</div>
    </div>
  );

  return (
    <div
      className={clsx(styles.container, !isActive && styles.priorContainer)}
      onClick={!isActive ? onClick : undefined}
    >
      <div className={styles.blockContainer}>
        <div className={styles.contractorNameContainer}>
          <Title variant="h3">{getDisplayVariableValue(contractorName)}</Title>
          <p className={clsx(styles.label, isActive ? styles.activeLabel : styles.inactiveLabel)}>
            {isActive
              ? translate(ApplicationsPageTranslationKeys.ActiveStatus, {
                  ns: TranslationNameSpaces.ApplicationsPage,
                })
              : translate(ApplicationsPageTranslationKeys.InactiveStatus, {
                  ns: TranslationNameSpaces.ApplicationsPage,
                })}
          </p>
        </div>
        <p className={styles.updatedAt}>
          {translate(ApplicationsPageTranslationKeys.UpdatedTitle, {
            ns: TranslationNameSpaces.ApplicationsPage,
          })}{' '}
          {DateTime.fromISO(updatedAt as unknown as string).toLocaleString(DateTime.DATE_MED)}{' '}
        </p>
      </div>
      {Boolean(getNotificationText()) && <Notification />}
      <div className={clsx(styles.status, isActive ? styles.activeStatus : styles.inactiveStatus)}>
        {areDocsPending ? 'Pre-Aproved - Docs Pending' : status.name}
      </div>
      <div className={styles.bottomBoxContainer}>
        <div className={styles.variableContainer}>
          <p className={styles.variableTitle}>
            {translate(ApplicationsPageTranslationKeys.LoanAmount, {
              ns: TranslationNameSpaces.ApplicationsPage,
            })}
          </p>
          <p className={styles.variableValue}>
            {loanAmount
              ? `$${new Intl.NumberFormat().format(Number(loanAmount))}`
              : getDisplayVariableValue(loanAmount)}
          </p>
        </div>
        {isActive && onClick && (
          <Button
            variant={ButtonVariant.Primary}
            size={ButtonSize.Large}
            className={styles.continueButton}
            onClick={isButtonDisabled ? undefined : onClick}
            isLoading={isButtonLoading}
            disabled={isButtonDisabled}
          >
            {translate(getButtonText(), { ns: TranslationNameSpaces.Buttons })}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ApplicationCard;
