import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthorizationActionTypes } from 'enums/AuthorizationActionTypes';
import AuthorizationApi, { ICreateAccountRequest } from 'api/digifi/portal/AuthorizationApi';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createCreateAccountThunk = (api: AuthorizationApi) =>
  createAsyncThunk(
    AuthorizationActionTypes.CreateAccount,
    async (createAccountBody: ICreateAccountRequest, thunkApi) => {
      try {
        return await api.createAccount(createAccountBody);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
