import { TranslationNameSpaces, WhatNextPageTranslationKeys } from 'enums/Translation';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeftIcon, ArrowRightIcon } from 'static/images';
import styles from './WhatNextSteps.module.scss';

const WhatNextSteps = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.WhatNextPage);

  return (
    <div className={styles.container}>
      <div className={styles.step}>{translate(WhatNextPageTranslationKeys.Step2)}</div>
      <div className={styles.arrows}>
        <ArrowLeftIcon className={styles.arrowIcon} />
        <ArrowRightIcon className={styles.arrowIcon} />
      </div>
      <div className={styles.step}>{translate(WhatNextPageTranslationKeys.Step3)}</div>
    </div>
  );
};

export default WhatNextSteps;
