import { ConditionsOfUseTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

import React from 'react';
import Title from 'components/Title';
import conditionsOfUseTermsItems from './conditionsOfUseTermsItems';
import { getTermComponent } from 'components/Terms/getTermComponent';
import { useTranslation } from 'react-i18next';

const ConditionsOfUseTerm = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.ConditionsOfUse);
  return (
    <>
      <Title id={TranslationNameSpaces.ConditionsOfUse} variant="h1" marginBottom="16">
        {translate(ConditionsOfUseTranslationKeys.Title)}
      </Title>
      {conditionsOfUseTermsItems.map(getTermComponent)}
    </>
  );
};

export default ConditionsOfUseTerm;
