import { ElectronicConsentTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

import Paragraph from 'components/Paragraph';
import React from 'react';
import TermWrapper from 'components/Terms/TermWrapper';
import Title from 'components/Title';
import { TypographySize } from 'enums/TypographySize';
import { useTranslation } from 'react-i18next';

const ElectronicConsentTerm = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.ElectronicConsent);

  return (
    <>
      <TermWrapper>
        <Title variant="h1">{translate(ElectronicConsentTranslationKeys.Title)}</Title>
      </TermWrapper>
      {(translate(ElectronicConsentTranslationKeys.Items, { returnObjects: true }) as string[]).map(
        (item) => (
          <Paragraph key={item} variant={TypographySize.Middle} marginBottom="16">
            {item}
          </Paragraph>
        ),
      )}
    </>
  );
};

export default ElectronicConsentTerm;
