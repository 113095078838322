import React, { useState } from 'react';
import styles from './VerifyDwollaMicroDepositsCard.module.scss';
import Title from 'components/Title';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { useTranslation } from 'react-i18next';
import {
  ApplicationsPageTranslationKeys,
  ButtonsTranslationKeys,
  InputLabelsTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import { useErrorMessages } from 'hooks/useErrorMessages';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { verifyDwollaMicroDeposits } from 'thunks';
import { DwollaMicroDepositsStatus } from 'enums/DwollaMicrodepositsStatus';
import { IApplication } from 'api/digifi/los/ApplicationsApi';
import { toast } from 'react-toastify';
import VerifyDwollaMicroDepositsForm from 'components/VerifyDwollaMicroDepositsForm';

const LAST_ATTEMPT_ERROR_MESSAGE = 'You have reached max verification attempts.';

const VerifyDwollaMicroDepositsCard = ({
  applicationId,
  dwollaMicroDepositsStatus,
  setDwollaMicroDepositsApplication,
}: {
  applicationId: string;
  dwollaMicroDepositsStatus: DwollaMicroDepositsStatus;
  setDwollaMicroDepositsApplication: (application: IApplication | null) => void;
}) => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.InputLabels,
    TranslationNameSpaces.ApplicationsPage,
    TranslationNameSpaces.Buttons,
  ]);

  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const [firstAmountValue, setFirstAmountValue] = useState('');
  const [secondAmountValue, setSecondAmountValue] = useState('');

  const [firstAmountInputError, setFirstAmountInputError] = useState<string | null>(null);
  const [secondAmountInputError, setSecondAmountInputError] = useState<string | null>(null);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const [isVerificationStep, setIsVerificationStep] = useState(false);

  const [getMessageForRequiredField] = useErrorMessages();

  const firstInputLabel = translate(InputLabelsTranslationKeys.Amount1, {
    ns: TranslationNameSpaces.InputLabels,
  });

  const secondInputLabel = translate(InputLabelsTranslationKeys.Amount2, {
    ns: TranslationNameSpaces.InputLabels,
  });

  const handleSubmit = async () => {
    try {
      setSubmitButtonLoading(true);
      if (!firstAmountValue) {
        setFirstAmountInputError(getMessageForRequiredField(firstInputLabel));

        return;
      }

      if (!secondAmountValue) {
        setSecondAmountInputError(getMessageForRequiredField(secondInputLabel));

        return;
      }

      const response = await dispatchWithUnwrap(
        verifyDwollaMicroDeposits({
          applicationId: applicationId!,
          firstAmount: firstAmountValue,
          secondAmount: secondAmountValue,
        }),
      );

      if ('redirectUrl' in response) {
        window.open((response as { redirectUrl: string }).redirectUrl, '_self');

        return;
      }

      setDwollaMicroDepositsApplication(null);
    } catch (error) {
      const errorMessage = (error as { message: string }).message;
      if (errorMessage === LAST_ATTEMPT_ERROR_MESSAGE) {
        setDwollaMicroDepositsApplication(null);
      }

      toast.error(errorMessage);
    } finally {
      setSubmitButtonLoading(false);
    }
  };

  const shouldDisableVerifyButton =
    dwollaMicroDepositsStatus !== DwollaMicroDepositsStatus.PostedToBank;

  return (
    <div className={styles.container}>
      {isVerificationStep ? (
        <VerifyDwollaMicroDepositsForm
          firstAmountValue={firstAmountValue}
          secondAmountValue={secondAmountValue}
          setFirstAmountValue={setFirstAmountValue}
          setSecondAmountValue={setSecondAmountValue}
          firstAmountInputError={firstAmountInputError}
          secondAmountInputError={secondAmountInputError}
          firstInputLabel={firstInputLabel}
          secondInputLabel={secondInputLabel}
          isLoading={submitButtonLoading}
          handleSubmit={handleSubmit}
        />
      ) : (
        <>
          <div className={styles.blockContainer}>
            <div className={styles.contractorNameContainer}>
              <Title variant="h3">
                {translate(ApplicationsPageTranslationKeys.MicroDepositsVerificationCardTitle, {
                  ns: TranslationNameSpaces.ApplicationsPage,
                })}
              </Title>
            </div>
          </div>
          <div className={styles.status}>
            {translate(ApplicationsPageTranslationKeys.MicroDepositsVerificationCardStatus, {
              ns: TranslationNameSpaces.ApplicationsPage,
            })}
          </div>
          <div className={styles.bottomBoxContainer}>
            <Button
              variant={ButtonVariant.Primary}
              size={ButtonSize.Large}
              onClick={() => setIsVerificationStep(true)}
              disabled={shouldDisableVerifyButton}
              disabledWithinInputAttr={shouldDisableVerifyButton}
            >
              {translate(ButtonsTranslationKeys.Verify, {
                ns: TranslationNameSpaces.Buttons,
              })}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default VerifyDwollaMicroDepositsCard;
