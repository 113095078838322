import {
  ChangeToYourContactInformationTranslationKeys,
  ConsentingToDoBusinessElectronicallyTranslationKeys,
  ContactByTelephoneTranslationKeys,
  DisclaimerOfWarrantiesTranslationKeys,
  DisputeResolutionTranslationKeys,
  GeneralTranslationKeys,
  HardwareAndSoftwareRequirementsTranslationKeys,
  IndemnityAndReleaseTranslationKeys,
  IntroductionTranslationKeys,
  LimitationOfLiabilityTranslationKeys,
  OptionForPaperOrNonElectronicRecordsTranslationKeys,
  QuestionsTranslationKeys,
  ScopeOfConsentTranslationKeys,
  ServiceContentSoftwareAndTrademarksTranslationKeys,
  TerminationTranslationKeys,
  ThirdPartyMaterialTranslationKeys,
  ThirdPartyWebsitesTranslationKeys,
  TranslationNameSpaces,
  UserContentTransmittedThroughTheServiceTranslationKeys,
  UserDisputesTranslationKeys,
  WithdrawingConsentTranslationKeys,
  YourPrivacyTranslationKeys,
} from 'enums/Translation';

import { ITermItem } from 'components/Terms/getTermComponent';

const intellectualPropertyRightsTermsItems: ITermItem[] = [
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: ServiceContentSoftwareAndTrademarksTranslationKeys.Title,
    itemsKey: ServiceContentSoftwareAndTrademarksTranslationKeys.Items,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: ThirdPartyMaterialTranslationKeys.Title,
    descriptionKey: ThirdPartyMaterialTranslationKeys.Description,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: UserContentTransmittedThroughTheServiceTranslationKeys.Title,
    itemsKey: UserContentTransmittedThroughTheServiceTranslationKeys.Items,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: ThirdPartyWebsitesTranslationKeys.Title,
    descriptionKey: ThirdPartyWebsitesTranslationKeys.Description,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: IndemnityAndReleaseTranslationKeys.Title,
    itemsKey: IndemnityAndReleaseTranslationKeys.Items,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: IntroductionTranslationKeys.Title,
    descriptionKey: IntroductionTranslationKeys.Description,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: OptionForPaperOrNonElectronicRecordsTranslationKeys.Title,
    descriptionKey: OptionForPaperOrNonElectronicRecordsTranslationKeys.Description,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: ScopeOfConsentTranslationKeys.Title,
    descriptionKey: ScopeOfConsentTranslationKeys.Description,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: ConsentingToDoBusinessElectronicallyTranslationKeys.Title,
    descriptionKey: ConsentingToDoBusinessElectronicallyTranslationKeys.Description,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: HardwareAndSoftwareRequirementsTranslationKeys.Title,
    descriptionKey: HardwareAndSoftwareRequirementsTranslationKeys.Description,
    linkKey: HardwareAndSoftwareRequirementsTranslationKeys.Link,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: WithdrawingConsentTranslationKeys.Title,
    descriptionKey: WithdrawingConsentTranslationKeys.Description,
    linkKey: WithdrawingConsentTranslationKeys.Link,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: ChangeToYourContactInformationTranslationKeys.Title,
    itemsKey: ChangeToYourContactInformationTranslationKeys.Items,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: ContactByTelephoneTranslationKeys.Title,
    itemsKey: ContactByTelephoneTranslationKeys.Items,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: DisclaimerOfWarrantiesTranslationKeys.Title,
    itemsKey: DisclaimerOfWarrantiesTranslationKeys.Items,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: LimitationOfLiabilityTranslationKeys.Title,
    itemsKey: LimitationOfLiabilityTranslationKeys.Items,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: DisputeResolutionTranslationKeys.Title,
    descriptionKey: DisputeResolutionTranslationKeys.Description,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: TerminationTranslationKeys.Title,
    descriptionKey: TerminationTranslationKeys.Description,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: UserDisputesTranslationKeys.Title,
    descriptionKey: UserDisputesTranslationKeys.Description,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: GeneralTranslationKeys.Title,
    descriptionKey: GeneralTranslationKeys.Description,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: YourPrivacyTranslationKeys.Title,
    descriptionKey: YourPrivacyTranslationKeys.Description,
    linkKey: YourPrivacyTranslationKeys.Link,
  },
  {
    nameSpaceKey: TranslationNameSpaces.IntellectualPropertyRights,
    titleKey: QuestionsTranslationKeys.Title,
    descriptionKey: QuestionsTranslationKeys.Description,
    linkKey: QuestionsTranslationKeys.Link,
  },
];

export default intellectualPropertyRightsTermsItems;
