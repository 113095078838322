export interface IRequestError {
  message: string;
  responseStatus: number;
  errorType?: string;
  data?: Record<string, unknown>;
}

export class RequestError extends Error {
  constructor(
    message: string,
    readonly responseStatus: number,
    readonly errorType?: string,
    readonly data?: Record<string, unknown>,
  ) {
    super(message);
  }
}
