import AdvertiserDisclosure from 'components/AdvertiserDisclosure';
import FormButtons from 'components/FormButtons';
import Menu from 'components/Menu';
import PageLayout from 'components/PageLayout';
import { LayoutSize } from 'components/PageLayout/PageLayout';
import Title from 'components/Title';
import {
  ButtonsTranslationKeys,
  MenuTranslationKeys,
  FinalizeTheOfferPageTranslationsKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'routes/AppRoutes';
import { OfferPagesContext } from 'pages/ApplicationProcess/OffersPage/OffersPage';
import styles from './FinalizeOffer.module.scss';
import { logout } from 'thunks';

const FinalizeOffer = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation([
    TranslationNameSpaces.Buttons,
    TranslationNameSpaces.FinalizeTheOfferPage,
  ]);
  const dispatch = useDispatch();
  const { setCurrentStep, prevStep } = useContext(OfferPagesContext);

  const handleGoToProfile = () => {
    navigate(AppRoutes.Applications);
  };

  const handleExit = () => {
    dispatch(logout());
    navigate(AppRoutes.Authorization, { replace: true });
  };

  const handleBackClick = () => {
    setCurrentStep(prevStep!);
  };

  return (
    <>
      <Menu activeMenuItem={MenuTranslationKeys.Applications} />
      <PageLayout layoutSize={LayoutSize.Big}>
        <AdvertiserDisclosure onBackClick={handleBackClick} showDisclosure={false} />
        <Title variant="h1" marginBottom="16">
          {translate(FinalizeTheOfferPageTranslationsKeys.Title, {
            ns: TranslationNameSpaces.FinalizeTheOfferPage,
          })}
        </Title>
        <div className={styles.info}>
          {translate(FinalizeTheOfferPageTranslationsKeys.DescriptionPartOne, {
            ns: TranslationNameSpaces.FinalizeTheOfferPage,
          })}
        </div>
        <FormButtons
          primaryTitle={translate(ButtonsTranslationKeys.GoToProfileButton, {
            ns: TranslationNameSpaces.Buttons,
          })}
          secondaryTitle={translate(ButtonsTranslationKeys.Exit, {
            ns: TranslationNameSpaces.Buttons,
          })}
          onPrimaryClick={handleGoToProfile}
          onSecondaryClick={handleExit}
        />
      </PageLayout>
    </>
  );
};

export default FinalizeOffer;
