export enum BorrowerVariable {
  FirstName = 'borrower_first_name',
  LastName = 'borrower_last_name',
  MiddleName = 'borrower_middle_name',
  PhoneNumber = 'borrower_phone',
  Email = 'borrower_email',
  Password = 'borrower_password',
  CompanyName = 'borrower_company_name',
  HomeAddress = 'borrower_home_address',
  ApartmentOrSuite = 'borrower_apartment_or_suite',
  DateOfBirth = 'borrower_date_of_birth',
  SSN = 'borrower_personal_id_number',
}
