import { DateTime } from 'luxon';
import { DateTimeFormat } from './dateFormat';

const CLOSE_ACCOUNT_EXPIRATION = 168; // hours

export const getIsReopenAccountTimeExpired = (date: string | undefined) => {
  if (!date) {
    return true;
  }

  const closeAccountRequestDate = DateTime.fromFormat(date, DateTimeFormat.Long);

  if (closeAccountRequestDate.invalidReason) {
    return true;
  }

  const hoursPassed = DateTime.now().diff(closeAccountRequestDate, 'hour').hours;

  return hoursPassed > CLOSE_ACCOUNT_EXPIRATION;
};
