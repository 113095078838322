import { EnvironmentVariable } from 'enums/EnvironmentVariable';
import BaseSecret from './BaseSecret';

class AppEnvironmentSecret extends BaseSecret {
  constructor() {
    super(EnvironmentVariable.REACT_APP_ENVIRONMENT);
  }
}

export default AppEnvironmentSecret;
