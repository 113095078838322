import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationProcessActionTypes } from 'enums/ApplicationProcessActionTypes';
import { IContinueWithApplicationBody } from 'api/digifi/portal/ApplicationProcessApi';
import ApplicationProcessApi from 'api/digifi/portal/ApplicationProcessApi';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createContinueWithApplicationThunk = (api: ApplicationProcessApi) =>
  createAsyncThunk(
    ApplicationProcessActionTypes.ContinueWithApplication,
    async (body: IContinueWithApplicationBody, thunkApi) => {
      try {
        return await api.continueWithApplication(body);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
