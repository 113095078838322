import React, { FC } from 'react';
import Loading from 'components/Loading';
import clsx from 'clsx';
import styles from './Button.module.scss';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  WithoutOutlines = 'withoutOutlines',
  MenuButton = 'menuButton',
  LinkLikeButton = 'linkLikeButton',
}

export enum ButtonSize {
  Large = 'large',
  Middle = 'middle',
  LinkLike = 'linkLikeSize',
}

interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: ButtonVariant;
  size?: ButtonSize;
  isLoading?: boolean;
  disabledWithinInputAttr?: boolean;
}

const Button: FC<IButtonProps> = ({
  children,
  variant = ButtonVariant.Primary,
  size,
  className,
  isLoading,
  disabled,
  disabledWithinInputAttr,
  ...props
}) => {
  const buttonClassNames = clsx(
    styles.button,
    styles[variant],
    size && styles[size],
    disabled && styles.disabled,
    className,
  );

  return (
    <button className={buttonClassNames} {...props} disabled={disabledWithinInputAttr}>
      {isLoading ? <Loading className={styles.loading} /> : children}
    </button>
  );
};

export default Button;
