import { EnvironmentVariable } from 'enums/EnvironmentVariable';
import { getSecretValue } from 'utils/getSecretValue';

class BaseSecret {
  private envValue: string;

  constructor(envKey: EnvironmentVariable) {
    this.envValue = getSecretValue(envKey);
  }
  
  get() {
    return this.envValue;
  }
}

export default BaseSecret;
