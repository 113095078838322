import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthorizationActionTypes } from 'enums/AuthorizationActionTypes';
import AuthorizationApi from 'api/digifi/portal/AuthorizationApi';
import { IRejectApplicationBody } from 'api/digifi/portal/AuthorizationApi';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createRejectApplicationThunk = (api: AuthorizationApi) =>
  createAsyncThunk(
    AuthorizationActionTypes.RejectApplication,
    async (rejectApplicationBody: IRejectApplicationBody, thunkApi) => {
      try {
        const result = await api.rejectApplication(rejectApplicationBody);
        return result;
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
