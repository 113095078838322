import { DateTime } from 'luxon';

import { getParsedDate } from './dateFormat';

const APPLICATION_EXPIRATION_DAYS_AMOUNT = 183;

export const getIsApplicationActive = (applicationApprovalDate: string) => {
  if (!applicationApprovalDate) {
    throw new Error('Application Approval Date missing');
  }

  const [month, day, year] = getParsedDate(applicationApprovalDate as string);

  const daysPassed = DateTime.now().diff(
    DateTime.local(Number(year), Number(month), Number(day)),
    'days',
  ).days;

  return daysPassed < APPLICATION_EXPIRATION_DAYS_AMOUNT;
};
