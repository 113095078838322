import * as Yup from 'yup';
import { useErrorMessages } from 'hooks/useErrorMessages';
import { useTranslation } from 'react-i18next';
import { InputLabelsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { countryStates } from 'pages/ApplicationProcess/PersonalInformation/stateSearchOptions';
import { ProjectAddressVariable } from 'enums/ProjectAddressVariable';

export const useProjectAddressValidationSchema = () => {
  const [getMessageForRequiredFields, getMessageForInvalidFields] = useErrorMessages();
  const { t: translate } = useTranslation([
    TranslationNameSpaces.InputLabels,
    TranslationNameSpaces.ErrorMessages,
  ]);

  const validationSchema = Yup.object().shape({
    [ProjectAddressVariable.ZipCode]: Yup.string().required(
      getMessageForRequiredFields(translate(InputLabelsTranslationKeys.ZipCode)),
    ),
    [ProjectAddressVariable.StreetName]: Yup.string().required(
      getMessageForRequiredFields(translate(InputLabelsTranslationKeys.HomeAddress)),
    ),
    [ProjectAddressVariable.City]: Yup.string().required(
      getMessageForRequiredFields(translate(InputLabelsTranslationKeys.City)),
    ),
    [ProjectAddressVariable.State]: Yup.string()
      .required(getMessageForRequiredFields(translate(InputLabelsTranslationKeys.State)))
      .test(
        'isStateValid',
        getMessageForInvalidFields(translate(InputLabelsTranslationKeys.State)),
        (value) => {
          if (!value) {
            return true;
          }

          return countryStates.includes(value as string);
        },
      ),
  });

  return validationSchema;
};
