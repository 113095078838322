import React, { FC } from 'react';

import styles from './TermsWrapper.module.scss';

const TermWrapper: FC<{ termId?: string }> = ({ children, termId }) => {
  return (
    <div id={termId} className={styles.termWrapper}>
      {children}
    </div>
  );
};

export default TermWrapper;
