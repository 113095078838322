import { GeocodeResult } from 'use-places-autocomplete';
import { IAddressType } from './AddressInputs';

enum GoogleAddressComponentType {
  StreetNumber = 'street_number',
  StreetName = 'route',
  Locality = 'locality',
  Sublocality = 'sublocality',
  State = 'administrative_area_level_1',
  Country = 'country',
  PostalCode = 'postal_code',
}

type GeocodeAddressType = Omit<IAddressType<string>, 'apartmentOrSuite'>;

export const getAddressByGeocodeResult = (geocodeResult: GeocodeResult): GeocodeAddressType => {
  return geocodeResult.address_components.reduce((address, component) => {
    if (component.types.includes(GoogleAddressComponentType.StreetNumber)) {
      return { ...address, streetNumber: component.long_name };
    }

    if (component.types.includes(GoogleAddressComponentType.StreetName)) {
      return { ...address, streetName: component.long_name };
    }

    if (component.types.includes(GoogleAddressComponentType.Locality)) {
      return { ...address, city: component.long_name };
    }

    if (component.types.includes(GoogleAddressComponentType.State)) {
      return {
        ...address,
        state: component.short_name,
      };
    }

    if (component.types.includes(GoogleAddressComponentType.PostalCode)) {
      return {
        ...address,
        zipCode: component.long_name,
      };
    }

    return address;
  }, {} as GeocodeAddressType);
};
