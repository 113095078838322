import { OfferCardTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { useTranslation } from 'react-i18next';
import { isBooleanFalse, isBooleanTrue } from 'utils/profund/isBoolean';

export type TOffersStatus = boolean | undefined | null;

export const useGetOfferStatusToDisplay = (
  preQualifiedValue: TOffersStatus,
  preApprovedValue: TOffersStatus,
) => {
  const { t: translate } = useTranslation(TranslationNameSpaces.OffersPage);

  const isPrequalified = isBooleanTrue(preQualifiedValue) && isBooleanFalse(preApprovedValue);

  return translate(
    isPrequalified ? OfferCardTranslationKeys.PreQualified : OfferCardTranslationKeys.PreApproved,
  );
};
