import { createAsyncThunk } from '@reduxjs/toolkit';
import VerificationApi, { IVerifyCode } from 'api/digifi/portal/VerificationApi';
import { VerificationActionTypes } from 'enums/VerificationActionsType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createVerifyCodeThunk = (api: VerificationApi) =>
  createAsyncThunk(VerificationActionTypes.VerifyCode, async ({ code }: IVerifyCode, thunkApi) => {
    try {
      await api.verifyCode({
        code,
      });
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
