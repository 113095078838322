import React, { FC } from 'react';
import PageLayout from 'components/PageLayout';
import { ApplicationExpiredPageTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { useTranslation } from 'react-i18next';
import styles from './ExpiredApplication.module.scss';

const ApplicationExpiredPage: FC = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.ApplicationExpiredPage);

  return (
    <PageLayout>
      <div className={styles.pageWrapper}>
        <h1 className={styles.pageTitle}>
          {translate(ApplicationExpiredPageTranslationKeys.Title)}
        </h1>
        <p className={styles.pageDescription}>
          {translate(ApplicationExpiredPageTranslationKeys.Description)}
        </p>
      </div>
    </PageLayout>
  );
};

export default ApplicationExpiredPage;
