import { createAsyncThunk } from '@reduxjs/toolkit';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';
import { IApplicationsApi, IUpdateApplicationParams } from 'api/digifi/los/ApplicationsApi';
import { ApplicationActionType } from 'enums/ApplicationActionType';

export const createUpdateApplicationThunk = (api: IApplicationsApi) =>
  createAsyncThunk(
    ApplicationActionType.Update,
    async (
      { applicationId, params }: { applicationId: string; params: IUpdateApplicationParams },
      thunkApi,
    ) => {
      try {
        return await api.updateApplication(applicationId, params);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
