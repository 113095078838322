import { DwollaBankVerificationVariable } from 'enums/DwollaBankVerificationVariable';
import { InputLabelsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { useErrorMessages } from 'hooks/useErrorMessages';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidUSRoutingNumber } from 'utils/validateRoutingNumber';
import * as Yup from 'yup';

const MIN_ACCOUNT_NUMBER_LENGTH = 4;
const MAX_ACCOUNT_NUMBER_LENGTH = 17;

const isValidRoutingNumber = (value: string | null | undefined) => {
  return value ? isValidUSRoutingNumber(value) : false;
};

const isValidAccountNumber = (value: string | null | undefined) => {
  const valueLength = value?.length ?? 0;

  return valueLength > MIN_ACCOUNT_NUMBER_LENGTH && valueLength < MAX_ACCOUNT_NUMBER_LENGTH;
};

export const useDwollaBankVerificationValidationSchema = () => {
  const [getMessageForRequiredFields, getMessageForInvalidFields, getMessageForConfirmFields] =
    useErrorMessages();
  const { t: translate } = useTranslation(TranslationNameSpaces.InputLabels);
  return useMemo(() => {
    const dwollaBankVerificationValidationSchema = Yup.object().shape({
      [DwollaBankVerificationVariable.NameOnAccount]: Yup.string().required(
        getMessageForRequiredFields(translate(InputLabelsTranslationKeys.NameOnAccount)),
      ),
      [DwollaBankVerificationVariable.BankAccountType]: Yup.string()
        .nullable()
        .required(
          getMessageForRequiredFields(translate(InputLabelsTranslationKeys.BankAccountType)),
        ),
      [DwollaBankVerificationVariable.BankRoutingNumber]: Yup.string()
        .required(
          getMessageForRequiredFields(translate(InputLabelsTranslationKeys.BankRoutingNumber)),
        )
        .nullable()
        .test(
          'isValidBankRoutingNumber',
          getMessageForInvalidFields(translate(InputLabelsTranslationKeys.BankRoutingNumber)),
          (value: string | null | undefined) => {
            return isValidRoutingNumber(value);
          },
        ),
      [DwollaBankVerificationVariable.BankConfirmRoutingNumber]: Yup.string()
        .required(
          getMessageForRequiredFields(
            translate(InputLabelsTranslationKeys.BankConfirmRoutingNumber),
          ),
        )
        .test(
          'isValidBankConfirmRoutingNumber',
          getMessageForInvalidFields(
            translate(InputLabelsTranslationKeys.BankConfirmRoutingNumber),
          ),
          (value: string | null | undefined) => {
            return isValidRoutingNumber(value);
          },
        )
        .test(
          'isEqualToBankRoutingNumber',
          getMessageForConfirmFields(translate(InputLabelsTranslationKeys.BankRoutingNumber)),
          (value: string | null | undefined, context: Yup.TestContext) => {
            return context.parent[DwollaBankVerificationVariable.BankRoutingNumber] === value;
          },
        ),
      [DwollaBankVerificationVariable.BankAccountNumber]: Yup.string()
        .required(
          getMessageForRequiredFields(translate(InputLabelsTranslationKeys.BankAccountNumber)),
        )
        .nullable()
        .test(
          'isValidAccountNumber',
          getMessageForInvalidFields(translate(InputLabelsTranslationKeys.BankAccountNumber)),
          (value) => {
            return isValidAccountNumber(value);
          },
        ),
      [DwollaBankVerificationVariable.BankConfirmAccountNumber]: Yup.string()
        .required(
          getMessageForRequiredFields(
            translate(InputLabelsTranslationKeys.BankConfirmAccountNumber),
          ),
        )
        .test(
          'isValid',
          getMessageForInvalidFields(
            translate(InputLabelsTranslationKeys.BankConfirmAccountNumber),
          ),
          (value) => {
            return isValidAccountNumber(value);
          },
        )
        .test(
          'isEqualToBankAccountNumber',
          getMessageForConfirmFields(translate(InputLabelsTranslationKeys.BankAccountNumber)),
          (value: string | null | undefined, context: Yup.TestContext) => {
            return context.parent[DwollaBankVerificationVariable.BankAccountNumber] === value;
          },
        ),
    });

    return dwollaBankVerificationValidationSchema;
  }, []);
};
