import React from 'react';
import InputWrapper from 'components/InputWrapper';
import styles from 'components/NumberInput/NumberInput.module.scss';
import { IInputWrapperProps } from 'components/InputWrapper/InputWrapper';
import { PatternFormat } from 'react-number-format';

const DATE_INPUT_FORMAT = '##/##/####';
const DATE_INPUT_MASK = ' ';
const DATE_INPUT_PLACEHOLDER = 'MM/DD/YYYY';

const DateInput = ({
  error,
  label,
  inputIcon,
  tooltipText,
  value,
  onChange,
  name,
  onBlur,
}: IInputWrapperProps & React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <InputWrapper
      error={error}
      label={label}
      inputIcon={inputIcon}
      tooltipText={tooltipText}
      wrapperClassName={styles.inputContainer}
    >
      <PatternFormat
        format={DATE_INPUT_FORMAT}
        mask={DATE_INPUT_MASK}
        value={value as string}
        onChange={onChange}
        placeholder={DATE_INPUT_PLACEHOLDER}
        name={name}
        onBlur={onBlur}
      />
    </InputWrapper>
  );
};

export default DateInput;
