import clsx from 'clsx';
import React, { FC } from 'react';
import styles from './Title.module.scss';

type TitleMarginBottom = '8' | '16' | '24';

interface ITitleProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  variant?: 'h1' | 'h2' | 'h3' | 'h4';
  marginBottom?: TitleMarginBottom;
}

const getMarginStyle = (marginBottom: TitleMarginBottom) => {
  switch (marginBottom) {
    case '8':
      return styles.marginBottomExtraSmall;
    case '16':
      return styles.marginBottomSmall;
    case '24':
      return styles.marginBottomMiddle;
    default:
      return null;
  }
};

const Title: FC<ITitleProps> = ({ children, variant, marginBottom, className, ...props }) => {
  const getElementByVariant = () => {
    switch (variant) {
      case 'h1':
        return (
          <h1
            className={clsx(styles.h1, marginBottom && getMarginStyle(marginBottom), className)}
            {...props}
          >
            {children}
          </h1>
        );
      case 'h2':
        return (
          <h2
            className={clsx(styles.h2, marginBottom && getMarginStyle(marginBottom), className)}
            {...props}
          >
            {children}
          </h2>
        );
      case 'h3':
        return (
          <h3
            className={clsx(styles.h3, marginBottom && getMarginStyle(marginBottom), className)}
            {...props}
          >
            {children}
          </h3>
        );
      case 'h4':
        return (
          <h4
            className={clsx(styles.h4, marginBottom && getMarginStyle(marginBottom), className)}
            {...props}
          >
            {children}
          </h4>
        );
      default:
        return (
          <h1
            className={clsx(styles.h1, marginBottom && getMarginStyle(marginBottom), className)}
            {...props}
          >
            {children}
          </h1>
        );
    }
  };

  return getElementByVariant();
};

export default Title;
