import React from 'react';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';

import styles from './PopupButtons.module.scss';

interface IPopupButtonsProps {
  primaryTitle: string;
  secondaryTitle: string;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
  isPrimaryLoading?: boolean;
  isPrimaryDisabled?: boolean;
}

const PopupButtons = ({
  primaryTitle,
  secondaryTitle,
  onPrimaryClick,
  onSecondaryClick,
  isPrimaryLoading,
  isPrimaryDisabled,
}: IPopupButtonsProps) => {
  return (
    <div className={styles.container}>
      <Button
        variant={ButtonVariant.LinkLikeButton}
        size={ButtonSize.LinkLike}
        onClick={onSecondaryClick}
        className={styles.secondaryButton}
      >
        {secondaryTitle}
      </Button>
      <Button
        variant={ButtonVariant.Primary}
        size={ButtonSize.Large}
        onClick={onPrimaryClick}
        className={styles.primaryButton}
        isLoading={isPrimaryLoading}
        disabled={isPrimaryDisabled}
        disabledWithinInputAttr={isPrimaryDisabled}
      >
        {primaryTitle}
      </Button>
    </div>
  );
};

export default PopupButtons;
