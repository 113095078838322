import { IManageAccountApi, IManageFundsBody } from 'api/digifi/portal/ManageAccountApi';

import { ManageAccountActionTypes } from 'enums/ManageAccountActionTypes';
import { createAsyncThunk } from '@reduxjs/toolkit';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createAddFundsThunk = (api: IManageAccountApi) =>
  createAsyncThunk(ManageAccountActionTypes.AddFunds, async (body: IManageFundsBody, thunkApi) => {
    try {
      return await api.addFunds(body);
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
