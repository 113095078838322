import {
  CommercialUseNotPermittedTranslationKeys,
  FeedbackTranslationKeys,
  ProtectedAreasTranslationKeys,
  TranslationNameSpaces,
  UserConductTranslationKeys,
} from 'enums/Translation';

import { ITermItem } from 'components/Terms/getTermComponent';

const conditionsOfUseTermsItems: ITermItem[] = [
  {
    nameSpaceKey: TranslationNameSpaces.ConditionsOfUse,
    titleKey: UserConductTranslationKeys.Title,
    itemsKey: UserConductTranslationKeys.Items,
  },
  {
    nameSpaceKey: TranslationNameSpaces.ConditionsOfUse,
    titleKey: CommercialUseNotPermittedTranslationKeys.Title,
    descriptionKey: CommercialUseNotPermittedTranslationKeys.Description,
  },
  {
    nameSpaceKey: TranslationNameSpaces.ConditionsOfUse,
    titleKey: ProtectedAreasTranslationKeys.Title,
    descriptionKey: ProtectedAreasTranslationKeys.Description,
  },
  {
    nameSpaceKey: TranslationNameSpaces.ConditionsOfUse,
    titleKey: FeedbackTranslationKeys.Title,
    itemsKey: FeedbackTranslationKeys.Items,
  },
];

export default conditionsOfUseTermsItems;
