import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationsActionType } from 'enums/ApplicationsActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';
import ApplicationsRestApi from 'api/digifi/los/ApplicationsApi';

export const createGetApplicationsThunk = (api: ApplicationsRestApi) =>
  createAsyncThunk(ApplicationsActionType.GetApplications, async (_, thunkApi) => {
    try {
      return await api.getBorrowerApplications();
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
