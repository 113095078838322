import React, { FC } from 'react';
import styles from './VerifyDwollaMicroDepositsForm.module.scss';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import NumberInput from 'components/NumberInput';
import { useTranslation } from 'react-i18next';
import {
  ApplicationsPageTranslationKeys,
  ButtonsTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import clsx from 'clsx';

interface IVerifyDwollaMicroDepositsFormProps {
  firstAmountValue: string;
  secondAmountValue: string;
  setFirstAmountValue: (value: string) => void;
  setSecondAmountValue: (value: string) => void;
  firstAmountInputError: string | null;
  secondAmountInputError: string | null;
  firstInputLabel: string;
  secondInputLabel: string;
  isLoading: boolean;
  handleSubmit: () => void;
  isIntermediaryForm?: boolean;
}

const VerifyDwollaMicroDepositsForm: FC<IVerifyDwollaMicroDepositsFormProps> = ({
  firstAmountValue,
  secondAmountValue,
  setFirstAmountValue,
  setSecondAmountValue,
  firstAmountInputError,
  secondAmountInputError,
  firstInputLabel,
  secondInputLabel,
  isLoading,
  handleSubmit,
  isIntermediaryForm,
}) => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.ApplicationsPage,
    TranslationNameSpaces.Buttons,
  ]);

  const inputClassName = clsx(
    isIntermediaryForm ? styles.intermediaryFormInput : styles.borrowerFormInput,
  );

  return (
    <div className={clsx(isIntermediaryForm ? styles.intermediaryContainer : styles.container)}>
      <div className={clsx(isIntermediaryForm ? styles.intermediaryTitle : styles.title)}>
        {translate(ApplicationsPageTranslationKeys.MicroDepositsVerificationCardSubTitle, {
          ns: TranslationNameSpaces.ApplicationsPage,
        })}
      </div>
      <div
        className={clsx(isIntermediaryForm ? styles.intermediaryDescription : styles.description)}
      >
        {translate(ApplicationsPageTranslationKeys.MicroDepositsVerificationCardDescription, {
          ns: TranslationNameSpaces.ApplicationsPage,
        })}
      </div>
      <div
        className={clsx(
          isIntermediaryForm ? styles.intermediaryBottomBoxContainer : styles.bottomBoxContainer,
        )}
      >
        <div className={clsx(isIntermediaryForm && styles.intermediaryFormInputsContainer)}>
          <NumberInput
            label={firstInputLabel}
            onChange={(event) => setFirstAmountValue(event.target.value)}
            value={firstAmountValue}
            error={firstAmountInputError}
            allowNegative={false}
            wrapperClassName={inputClassName}
          />
          <NumberInput
            label={secondInputLabel}
            onChange={(event) => setSecondAmountValue(event.target.value)}
            value={secondAmountValue}
            error={secondAmountInputError}
            allowNegative={false}
            wrapperClassName={inputClassName}
          />
        </div>
        <Button
          variant={ButtonVariant.Primary}
          size={ButtonSize.Large}
          onClick={handleSubmit}
          isLoading={isLoading}
          className={clsx(isIntermediaryForm && styles.intermediaryFormButton)}
          disabled={!firstAmountValue || !secondAmountValue}
        >
          {translate(ButtonsTranslationKeys.Submit, {
            ns: TranslationNameSpaces.Buttons,
          })}
        </Button>
      </div>
    </div>
  );
};

export default VerifyDwollaMicroDepositsForm;
