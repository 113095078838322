import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  addFunds,
  closeAccount,
  createAccount,
  createOffers,
  getAuthorizationInformation,
  getBorrower,
  getOffers,
  rejectApplication,
  reopenAccount,
  requestFunds,
  searchAccounts,
  sendVerificationCode,
  updateApplication,
  updateBorrower,
  verifyCode,
  getCurrentAccount,
  getPersonalInformation,
  getBorrowerApplications,
  checkOldApplications,
  continueWithApplication,
  getApplicationStatus,
  getApplicationById,
} from 'thunks';
import { ISliceStateWithLoadingFields, LoadingFields } from 'types/SliceWithLoadingFields';
import { getLoadingValueByAction, matchLoadingActions } from 'utils/sliceWithLoadingFieldsHelpers';
import { ApplicationActionType } from 'enums/ApplicationActionType';
import { BorrowersActionType } from 'enums/BorrowerActionTypes';
import { ManageAccountActionTypes } from 'enums/ManageAccountActionTypes';
import { AuthorizationActionTypes } from 'enums/AuthorizationActionTypes';
import { VerificationActionTypes } from 'enums/VerificationActionsType';
import { OffersInformationActionType } from 'enums/OffersInformationActionType';
import { AccountsActionType } from 'enums/AccountActionType';
import { ApplicationProcessActionTypes } from 'enums/ApplicationProcessActionTypes';
import { PersonalInformationActionType } from 'enums/PersonalInformationActionType';
import { ApplicationsActionType } from 'enums/ApplicationsActionType';

type TLoadingActions =
  | ApplicationActionType.Update
  | BorrowersActionType
  | ManageAccountActionTypes
  | AuthorizationActionTypes
  | VerificationActionTypes
  | OffersInformationActionType
  | ApplicationProcessActionTypes
  | PersonalInformationActionType
  | ApplicationsActionType
  | AccountsActionType.GetCurrentAccount;

const LOADING_TRIGGER_ACTIONS = [
  updateApplication,
  updateBorrower,
  getBorrower,
  requestFunds,
  addFunds,
  closeAccount,
  reopenAccount,
  getAuthorizationInformation,
  searchAccounts,
  createAccount,
  rejectApplication,
  sendVerificationCode,
  verifyCode,
  getOffers,
  createOffers,
  getCurrentAccount,
  getPersonalInformation,
  getBorrowerApplications,
  checkOldApplications,
  continueWithApplication,
  getApplicationStatus,
  getApplicationById,
];

export interface ILoadingState extends ISliceStateWithLoadingFields {
  globalLoading: boolean;
  loadingFields: {
    isUpdateApplicationInProgress: boolean;
    isUpdateBorrowerInProgress: boolean;
    isGetBorrowerDataInProgress: boolean;
    isRequestFundsLoading: boolean;
    isAddFundsLoading: boolean;
    isCloseAccountLoading: boolean;
    isReopenAccountLoading: boolean;
    searchAccountsLoading: boolean;
    createAccountLoading: boolean;
    getAuthorizationInformationLoading: boolean;
    rejectApplicationLoading: boolean;
    sendVerificationCodeLoading: boolean;
    verifyCodeLoading: boolean;
    getOffersByApplicationIdLoading: boolean;
    generateOffersLoading: boolean;
    sendOfferAgreementLoading: boolean;
    getCurrentAccountLoading: boolean;
    checkOldApplicationsLoading: boolean;
    continueWithApplicationLoading: boolean;
    getApplicationLoading: boolean;
    getApplicationStatusLoading: boolean;
    cleanApplicationsLoading: boolean;
    isPersonalInfoLoading: boolean;
    isGetApplicationsInProgress: boolean;
    isGetApplicationByIdInProgress: boolean;
  };
}

const initialState: ILoadingState = {
  globalLoading: false,
  loadingFields: {
    isUpdateApplicationInProgress: false,
    isUpdateBorrowerInProgress: false,
    isGetBorrowerDataInProgress: false,
    isAddFundsLoading: false,
    isCloseAccountLoading: false,
    isRequestFundsLoading: false,
    isReopenAccountLoading: false,
    getAuthorizationInformationLoading: false,
    searchAccountsLoading: false,
    createAccountLoading: false,
    rejectApplicationLoading: false,
    sendVerificationCodeLoading: false,
    verifyCodeLoading: false,
    getOffersByApplicationIdLoading: false,
    generateOffersLoading: false,
    sendOfferAgreementLoading: false,
    getCurrentAccountLoading: true,
    checkOldApplicationsLoading: false,
    continueWithApplicationLoading: false,
    getApplicationLoading: false,
    getApplicationStatusLoading: false,
    cleanApplicationsLoading: false,
    isPersonalInfoLoading: false,
    isGetApplicationsInProgress: true,
    isGetApplicationByIdInProgress: false,
  },
};

const loadingFields: LoadingFields<TLoadingActions, ILoadingState> = {
  [ApplicationActionType.Update]: 'isUpdateApplicationInProgress',
  [BorrowersActionType.UpdateBorrower]: 'isUpdateBorrowerInProgress',
  [BorrowersActionType.GetBorrowerData]: 'isGetBorrowerDataInProgress',
  [ManageAccountActionTypes.RequestFunds]: 'isRequestFundsLoading',
  [ManageAccountActionTypes.AddFunds]: 'isAddFundsLoading',
  [ManageAccountActionTypes.CloseAccount]: 'isCloseAccountLoading',
  [ManageAccountActionTypes.ReopenAccount]: 'isReopenAccountLoading',
  [AuthorizationActionTypes.GetAuthorizationInformation]: 'getAuthorizationInformationLoading',
  [AuthorizationActionTypes.SearchAccounts]: 'searchAccountsLoading',
  [AuthorizationActionTypes.CreateAccount]: 'createAccountLoading',
  [AuthorizationActionTypes.RejectApplication]: 'rejectApplicationLoading',
  [VerificationActionTypes.SendVerificationCode]: 'sendVerificationCodeLoading',
  [VerificationActionTypes.VerifyCode]: 'verifyCodeLoading',
  [OffersInformationActionType.Get]: 'getOffersByApplicationIdLoading',
  [OffersInformationActionType.Create]: 'generateOffersLoading',
  [OffersInformationActionType.Send]: 'sendOfferAgreementLoading',
  [AccountsActionType.GetCurrentAccount]: 'getCurrentAccountLoading',
  [ApplicationProcessActionTypes.CheckOldApplications]: 'checkOldApplicationsLoading',
  [ApplicationProcessActionTypes.ContinueWithApplication]: 'continueWithApplicationLoading',
  [ApplicationProcessActionTypes.GetApplication]: 'getApplicationLoading',
  [ApplicationProcessActionTypes.GetApplicationStatus]: 'getApplicationStatusLoading',
  [ApplicationProcessActionTypes.CleanApplications]: 'cleanApplicationsLoading',
  [PersonalInformationActionType.GetPersonalInformation]: 'isPersonalInfoLoading',
  [ApplicationsActionType.GetApplications]: 'isGetApplicationsInProgress',
  [ApplicationsActionType.GetById]: 'isGetApplicationByIdInProgress',
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setGlobalLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.globalLoading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(matchLoadingActions(...LOADING_TRIGGER_ACTIONS), (state, { type }) => {
      const { loadingFieldKey, loadingValue } = getLoadingValueByAction<
        TLoadingActions,
        ILoadingState
      >({ type }, loadingFields);
      state.loadingFields[loadingFieldKey] = loadingValue;
    });
  },
});

export const { setGlobalLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
