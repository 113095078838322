import React from 'react';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';

import styles from './UnableToVerify.module.scss';
import { useTranslation } from 'react-i18next';
import {
  ButtonsTranslationKeys,
  TranslationNameSpaces,
  UnableToVerifyPageTranslationKeys,
} from 'enums/Translation';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'routes/AppRoutes';

const UnableToVerify = () => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.UnableToVerifyPage,
    TranslationNameSpaces.Buttons,
  ]);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(AppRoutes.Applications);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h3 className={styles.title}>
          {translate(UnableToVerifyPageTranslationKeys.Title, {
            ns: TranslationNameSpaces.UnableToVerifyPage,
          })}
        </h3>
        <p className={styles.paragraph}>
          {translate(UnableToVerifyPageTranslationKeys.Description, {
            ns: TranslationNameSpaces.UnableToVerifyPage,
          })}
        </p>
        <Button variant={ButtonVariant.Secondary} size={ButtonSize.Large} onClick={handleClick}>
          {translate(ButtonsTranslationKeys.GoToProfileButton, {
            ns: TranslationNameSpaces.Buttons,
          })}
        </Button>
      </div>
    </div>
  );
};

export default UnableToVerify;
