import React from 'react';
import Transition from 'components/Transition';

interface ICollapsiblePanelProps {
  isActive: boolean;
  headerContent?: string | JSX.Element;
  bodyContent?: string | JSX.Element;
  headerClassName?: string;
  contentClassName?: string;
  onClick?: () => void;
}

const CollapsiblePanel = ({
  isActive,
  headerContent,
  bodyContent,
  headerClassName,
  contentClassName,
}: ICollapsiblePanelProps) => {
  return (
    <div className={contentClassName}>
      <div className={headerClassName}>{headerContent}</div>
      <Transition isActive={isActive}>{isActive ? bodyContent : null}</Transition>
    </div>
  );
};

export default CollapsiblePanel;
