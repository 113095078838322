export type RequestBody = string | { [key: string]: any } | FormData;

interface IErrorMessageObject {
  message: string;
  statusCode: number;
  error: string;
}

interface IRequestErrorProps {
  message: string | IErrorMessageObject;
  type?: string;
}

export const getBasicHeaders = () => {
  const headers = new Headers();

  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');

  return headers;
};

export const stringifyRequestBody = (body?: RequestBody): string | FormData | undefined => {
  if (typeof body === 'string' || body instanceof FormData || typeof body === 'undefined') {
    return body;
  }
  return JSON.stringify(body);
};

export const getResponseErrorData = async (response: Response): Promise<IRequestErrorProps> => {
  try {
    const message = await getErrorMessage(response);
    
    const type = getErrorType(response);

    return { message, type };
  } catch (error) {
    return { message: response.statusText };
  }
};

const getErrorMessage = async (response: Response) => {
  const body = JSON.parse(await response.text());

  if (typeof body.message === 'string') {
    return body.message;
  }

  if ('message' in (body.message as IErrorMessageObject)) {
    return (body.message as IErrorMessageObject).message;
  }
  
  return response.statusText;
};

const getErrorType = (body: Response) => {
  if (!body.type) {
    return;
  }

  return body.type;
};
