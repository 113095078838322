import React, { useState } from 'react';
import {
  AccessAndUseOfTheServicesTranslationKeys,
  ConditionsOfUseTranslationKeys,
  IntellectualPropertyRightsTranslationKeys,
  TranslationNameSpaces,
  WelcomeToProFundTermTranslationKeys,
} from 'enums/Translation';
import { ChevronDownIcon, ChevronUpIcon } from 'static/images';

import Collapsible from 'react-collapsible';
import SubTitle from 'components/SubTitle';
import { TABLET_WIDTH } from 'pages/ApplicationProcess/OffersPage/offersUtils';
import Title from 'components/Title';
import { TypographySize } from 'enums/TypographySize';
import { useTranslation } from 'react-i18next';

import styles from './TermsMenu.module.scss';

const menuItems = [
  {
    translationKey: WelcomeToProFundTermTranslationKeys.Title,
    id: TranslationNameSpaces.WelcomeToProFundTerm,
  },
  {
    translationKey: AccessAndUseOfTheServicesTranslationKeys.Title,
    id: TranslationNameSpaces.AccessAndUseOfTheServices,
  },
  {
    translationKey: ConditionsOfUseTranslationKeys.Title,
    id: TranslationNameSpaces.ConditionsOfUse,
  },
  {
    translationKey: IntellectualPropertyRightsTranslationKeys.Title,
    id: TranslationNameSpaces.IntellectualPropertyRights,
  },
];

interface IMenuTriggerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const MenuTrigger = ({ isOpen, setIsOpen }: IMenuTriggerProps) => {
  return (
    <div className={styles.triggerWrapper} onClick={() => setIsOpen(!isOpen)}>
      <SubTitle variant={TypographySize.Middle}>Contents</SubTitle>
      {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </div>
  );
};

const TermsMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t: translate } = useTranslation([
    TranslationNameSpaces.WelcomeToProFundTerm,
    TranslationNameSpaces.AccessAndUseOfTheServices,
    TranslationNameSpaces.ConditionsOfUse,
    TranslationNameSpaces.IntellectualPropertyRights,
  ]);

  return (
    <Collapsible
      className={styles.wrapper}
      openedClassName={styles.wrapper}
      trigger={<MenuTrigger isOpen={isOpen} setIsOpen={setIsOpen} />}
      open={window.innerWidth > TABLET_WIDTH}
    >
      <div className={styles.itemsWrapper}>
        {menuItems.map((menuItem) => (
          <Title marginBottom="24" key={menuItem.id} variant="h4" className={styles.menuItem}>
            <a href={`#${menuItem.id}`}>
              {translate(menuItem.translationKey, { ns: menuItem.id })}
            </a>
          </Title>
        ))}
      </div>
    </Collapsible>
  );
};

export default TermsMenu;
