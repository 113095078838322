import { createSlice } from '@reduxjs/toolkit';
import { getCurrentAccount, logout } from 'thunks';

interface IAccountParams {
  email: string;
  phone: string;
  isEmailVerified: boolean | null;
  isMfaComplete: boolean | null;
  borrowerId: string;
  isPhoneVerified: boolean | null;
}

export interface IAuthState {
  accountData?: IAccountParams;
}

const initialState: IAuthState = {
  accountData: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentAccount.fulfilled, (state, { payload }) => {
        state.accountData = payload;
      })
      .addCase(logout.fulfilled, (state, { payload }) => {
        if (payload) {
          return state;
        }

        return initialState;
      });
  },
});

export default authSlice.reducer;
