export function isValidUSRoutingNumber(routingNumber: string) {
  if (!/^\d{9}$/.test(routingNumber)) {
    return false;
  }

  const firstTwoDigits = parseInt(routingNumber.substr(0, 2));
  if (
    !(
      (firstTwoDigits >= 1 && firstTwoDigits <= 12) ||
      (firstTwoDigits >= 21 && firstTwoDigits <= 32)
    )
  ) {
    return false;
  }

  const digits = routingNumber.split('').map(Number);
  const weights = [3, 7, 1, 3, 7, 1, 3, 7, 1];
  const checksum = digits.reduce((sum, digit, index) => sum + digit * weights[index], 0) % 10;

  if (checksum !== 0) {
    return false;
  }

  return true;
}
