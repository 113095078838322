import PageLayout, { LayoutSize } from 'components/PageLayout/PageLayout';
import React from 'react';
import styles from './VerificationOnTheWay.module.scss';
import { BankVerificationPageTranslationKeys, IntermediaryBankVerificationTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { useTranslation } from 'react-i18next';

const VerificationOnTheWay = () => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.BankVerificationPage,
    TranslationNameSpaces.IntermediaryBankVerification,
  ]);

  return (
    <PageLayout layoutSize={LayoutSize.Big}>
      <div className={styles.title}>
        {translate(BankVerificationPageTranslationKeys.Title, {
          ns: TranslationNameSpaces.BankVerificationPage,
        })}
      </div>
      <div className={styles.description}>
      {translate(IntermediaryBankVerificationTranslationKeys.Description3, {
          ns: TranslationNameSpaces.IntermediaryBankVerification,
        })}
      </div>
    </PageLayout>
  );
};

export default VerificationOnTheWay;
