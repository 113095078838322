import { FC } from 'react';
import clsx from 'clsx';
import { ApplicationStatusIdType } from 'enums/ApplicationStatusId';
import { StepCompletedIcon } from 'static/images';
import CollapseWrapper from 'components/CollapseWrapper';
import { useTranslation } from 'react-i18next';
import { StepperTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { getApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';

import styles from './Stepper.module.scss';

interface IStep {
  applicationStatusId: ApplicationStatusIdType;
  stepName: StepperTranslationKeys;
}

interface IStepperProps {
  currentStep: IStep['applicationStatusId'];
}

const steps: IStep[] = [
  {
    applicationStatusId: getApplicationStatusIdByEnvironment(ApplicationStatusName.Approved),
    stepName: StepperTranslationKeys.VerificationStep,
  },
  {
    applicationStatusId: getApplicationStatusIdByEnvironment(
      ApplicationStatusName.PersonalInformation,
    ),
    stepName: StepperTranslationKeys.PersonalInformationStep,
  },
  {
    applicationStatusId: getApplicationStatusIdByEnvironment(
      ApplicationStatusName.FinancialInformation,
    ),
    stepName: StepperTranslationKeys.FinancialInformationStep,
  },
];

const Stepper: FC<IStepperProps> = ({ currentStep }) => {
  const { t: translate } = useTranslation(TranslationNameSpaces.Stepper);
  const currentStepIndex = steps.findIndex((step) => step.applicationStatusId === currentStep);

  const currentStepName = steps.find((step) => step.applicationStatusId === currentStep)?.stepName;

  const renderStep = ({ stepName }: IStep, index: number) => {
    const isStepCompleted = index < currentStepIndex;
    const isCurrentStep = currentStepName === stepName;

    const classNames = clsx(
      styles.stepWrapper,
      isCurrentStep && styles.current,
      isStepCompleted && styles.completed,
    );

    return (
      <div className={classNames} key={stepName}>
        <p className={styles.stepNumber}>{isStepCompleted ? <StepCompletedIcon /> : index + 1}</p>
        <p className={styles.stepText}>{translate(stepName)}</p>
      </div>
    );
  };

  const renderCurrentStep = () => {
    return (
      <div className={clsx(styles.stepWrapper, styles.current)}>
        <p className={styles.stepNumber}>{currentStepIndex + 1}</p>
        <p className={styles.stepText}>{translate(currentStepName!)}</p>
      </div>
    );
  };

  return <CollapseWrapper activeItem={renderCurrentStep()} items={steps.map(renderStep)} />;
};

export default Stepper;
