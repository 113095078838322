import React, { FC } from 'react';
import Paragraph from 'components/Paragraph';
import { TypographySize } from 'enums/TypographySize';
import { ModalCloseIcon } from 'static/images';

import styles from './InputTooltip.module.scss';
import clsx from 'clsx';

interface IInputTooltipProps {
  isShown: boolean;
  setIsShown: (isShown: boolean) => void;
}

const InputTooltip: FC<IInputTooltipProps> = ({ isShown, setIsShown, children }) => {
  if (!isShown) {
    return null;
  }

  return (
    <div className={clsx(styles.tooltipWrapper)}>
      <ModalCloseIcon className={styles.closeIcon} onClick={() => setIsShown(false)} />
      <Paragraph variant={TypographySize.Middle}>{children}</Paragraph>
    </div>
  );
};

export default InputTooltip;
