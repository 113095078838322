import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGenerateOffersBody } from 'api/digifi/portal/ApplicationProcessApi';
import LoanOffersApi from 'api/digifi/portal/LoanOffersApi';
import { OffersInformationActionType } from 'enums/OffersInformationActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createCreateOffersThunk = (api: LoanOffersApi) =>
  createAsyncThunk(
    OffersInformationActionType.Create,
    async (body: IGenerateOffersBody, thunkApi) => {
      try {
        await api.create(body);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
