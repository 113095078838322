import {
  AuthorizationProcessTranslationKeys,
  ButtonsTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import { AuthorizationStep, setAuthorizationStep } from 'handlers/portal/authorizationSlice';
import { BackArrowIcon, CheckIcon, ResendArrowIcon } from 'static/images';
import React, { FC, useEffect, useState } from 'react';
import { rejectApplication, sendVerificationCode, verifyCode } from 'thunks';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

import { LayoutSize } from 'components/PageLayout/PageLayout';
import Loading from 'components/Loading';
import PageLayout from 'components/PageLayout';
import Paragraph from 'components/Paragraph';
import Title from 'components/Title';
import VerificationCodeInput from 'components/VerificationCodeInput';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { removeNonDigits } from 'utils/stringUtils';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useQueryParam from 'hooks/useQueryParam';
import { useTranslation } from 'react-i18next';
import {
  getAuthorizationInformationData,
  getLoadingFields,
  getVerificationData,
} from 'handlers/selectors';
import { matchApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';

import styles from './VerificationCodePage.module.scss';

const MAX_VERIFICATION_ATTEMPTS = 3;
const VERIFICATION_CODE_LENGTH = 6;
const STANDARD_TIMER_DURATION = 60;

const VerificationCodePage: FC = () => {
  const applicationId = useQueryParam('applicationId');
  const { t: translate } = useTranslation([
    TranslationNameSpaces.AuthorizationProcess,
    TranslationNameSpaces.Buttons,
  ]);
  const {
    authorizationInformation: { statusId },
  } = useAppSelector(getAuthorizationInformationData);
  const { phone, isVerified } = useAppSelector(getVerificationData);

  const { rejectApplicationLoading, sendVerificationCodeLoading, verifyCodeLoading } =
    useAppSelector(getLoadingFields);

  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();

  const [counter, setCounter] = useState<number>(STANDARD_TIMER_DURATION);
  const [inputError, setInputError] = useState<string>('');
  const [verifyAttempt, setVerifyAttempt] = useState<number>(0);

  const isLoading = sendVerificationCodeLoading || verifyCodeLoading || rejectApplicationLoading;

  const handleSendCode = async () => {
    try {
      setInputError('');
      await dispatchWithUnwrap(sendVerificationCode({ phone }));
      setCounter(STANDARD_TIMER_DURATION);
    } catch (error) {
      const errorMessage = (error as Error).message;
      setCounter(+removeNonDigits(errorMessage));
    }
  };

  const handleVerifyCode = async (code: string) => {
    try {
      setInputError('');
      await dispatchWithUnwrap(verifyCode({ code }));
    } catch (error) {
      if (applicationId) {
        setVerifyAttempt(verifyAttempt + 1);
      }
    }
  };

  const handleRejectApplication = async (id: string) => {
    try {
      await dispatchWithUnwrap(rejectApplication({ applicationId: id }));

      dispatch(setAuthorizationStep(AuthorizationStep.UnableToVerifyPage));
    } catch (error) {
      setInputError((error as Error).message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter(counter - 1);
    }, 1000);

    if (!counter) {
      setInputError('');
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [counter]);

  useEffect(() => {
    if (
      verifyAttempt === MAX_VERIFICATION_ATTEMPTS &&
      applicationId &&
      matchApplicationStatusIdByEnvironment(statusId, ApplicationStatusName.NewApplication)
    ) {
      handleRejectApplication(applicationId);
    }
  }, [verifyAttempt]);

  const handleChange = (value: string) => {
    if (value.length === VERIFICATION_CODE_LENGTH) {
      handleVerifyCode(value);
    }
  };

  const handleBackClick = () => {
    dispatch(setAuthorizationStep(AuthorizationStep.SignIn));
  };

  const renderPageTitle = () => {
    return (
      <Title variant="h1" className={styles.pageTitle}>
        {!applicationId
          ? translate(AuthorizationProcessTranslationKeys.SimpleLoginTitle, {
              ns: TranslationNameSpaces.AuthorizationProcess,
            })
          : translate(AuthorizationProcessTranslationKeys.SignUpTitle, {
              ns: TranslationNameSpaces.AuthorizationProcess,
            })}
      </Title>
    );
  };

  const renderBottomElement = () => {
    if (isVerified) {
      return (
        <Paragraph>
          <CheckIcon />{' '}
          {translate(AuthorizationProcessTranslationKeys.VerificationVerified, {
            ns: TranslationNameSpaces.AuthorizationProcess,
          })}
        </Paragraph>
      );
    }
    if (!counter) {
      return (
        <a className={styles.link} onClick={handleSendCode}>
          <ResendArrowIcon />{' '}
          {translate(AuthorizationProcessTranslationKeys.VerificationResendCode, {
            ns: TranslationNameSpaces.AuthorizationProcess,
          })}
        </a>
      );
    }
    return (
      <Paragraph>
        {translate(AuthorizationProcessTranslationKeys.VerificationWaitingDescription, {
          seconds: counter,
          ns: TranslationNameSpaces.AuthorizationProcess,
        })}
      </Paragraph>
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <PageLayout layoutSize={LayoutSize.Small}>
      <div className={styles.pageWrapper}>
        <a className={styles.link} onClick={handleBackClick}>
          <BackArrowIcon className={styles.linkIcon} />{' '}
          {translate(ButtonsTranslationKeys.Back, { ns: TranslationNameSpaces.Buttons })}
        </a>
        {renderPageTitle()}
        <Paragraph className={styles.pageDescription}>
          {translate(AuthorizationProcessTranslationKeys.VerificationDescription, {
            ns: TranslationNameSpaces.AuthorizationProcess,
          })}
          <br />
          <span className={styles.phoneNumber}>{formatPhoneNumber(phone)}</span>
        </Paragraph>
        <VerificationCodeInput handleOnChange={handleChange} error={inputError} />
        {renderBottomElement()}
      </div>
    </PageLayout>
  );
};

export default VerificationCodePage;
