import React, { FC, useEffect, ReactNode, useRef, useState } from 'react';
import clsx from 'clsx';
import styles from './Transition.module.scss';

interface ITransitionComponentProps {
  children: ReactNode;
  className?: string;
  isActive?: boolean;
}

const DEFAULT_INITIAL_HEIGHT = 0;

enum OverflowValue {
  Hidden = 'hidden',
  Visible = 'visible',
}

const TransitionComponent: FC<ITransitionComponentProps> = ({ className, children, isActive }) => {
  const childrenContainerRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [blockHeight, setBlockHeight] = useState(DEFAULT_INITIAL_HEIGHT);

  const [overflow, setOverflow] = useState(OverflowValue.Hidden);

  useEffect(() => {
    const getContainerHeight = () => {
      if (!children) {
        return 0;
      }

      const contextualViewElement = childrenContainerRef.current;
      const rect = contextualViewElement?.getBoundingClientRect();

      return rect?.height || 0;
    };

    setBlockHeight(getContainerHeight());
  }, [children]);

  useEffect(() => {
    const handleOverflowChange = () => {
      setOverflow(isActive ? OverflowValue.Visible : OverflowValue.Hidden);
    };
    ref?.current?.addEventListener('transitionend', handleOverflowChange);

    return () => ref?.current?.removeEventListener('transitionend', handleOverflowChange);
  }, [isActive]);

  return (
    <div
      className={clsx(styles.container, className, styles.test)}
      style={{
        height: blockHeight,
        overflow,
      }}
      ref={ref}
    >
      <div ref={childrenContainerRef}>{children}</div>
    </div>
  );
};

export default TransitionComponent;
