export const NON_DIGITS_PATTERN = /\D/g;

const defaultIntlDecimalOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const getFormatterByValueType = (value: number, options: Record<string, string | number>) => {
  return new Intl.NumberFormat('en-US', options).format(value);
};

export const convertMonetaryValueToIntegerNumber = (value: string | undefined) => {
  if (!value) {
    return null;
  }

  const formattedValue = value?.replace(NON_DIGITS_PATTERN, '');

  return Number(formattedValue);
};

export const convertToMonetary = (value: number, fractionDigits?: number) => {
  const getDecimal = () =>
    fractionDigits !== undefined
      ? { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }
      : defaultIntlDecimalOptions;

  return getFormatterByValueType(value, {
    style: 'currency',
    currency: 'USD',
    ...getDecimal(),
  });
};

export const convertToPercentage = (value: number) => {
  return getFormatterByValueType(value, {
    style: 'percent',
    ...defaultIntlDecimalOptions,
  });
};

export const convertOriginationFeeToPercentage = (value: number) =>
  getFormatterByValueType(value, {
    style: 'percent',
  });

export const termFormatter = (value: number, unit: string) => {
  return getFormatterByValueType(value, {
    style: 'unit',
    unit,
    unitDisplay: 'long',
  });
};

export const addDecimalSeparatorToNumberValue = (amount: number) => {
  return new Intl.NumberFormat().format(Number(amount));
};
