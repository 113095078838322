import {
  AuthorizationProcessTranslationKeys,
  ButtonsTranslationKeys,
  InputLabelsTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import { AuthorizationStep, setAuthorizationStep } from 'handlers/portal/authorizationSlice';
import { ButtonSize, ButtonVariant } from 'components/Button/Button';
import React, { FC, useEffect, useState } from 'react';
import { createAccount, searchAccounts, sendVerificationCode } from 'thunks';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

import Button from 'components/Button';
import Link from 'components/Link';
import PageLayout from 'components/PageLayout';
import Paragraph from 'components/Paragraph';
import PhoneNumberInput from 'components/PhoneNumberInput';
import Title from 'components/Title';
import isPhoneNumberComplete from 'utils/isPhoneNumberComplete';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useQueryParam from 'hooks/useQueryParam';
import { useTranslation } from 'react-i18next';
import { SUPPORT_CENTER_LINK } from 'layout/ProFundMainLayout/ProFundMainLayout';

import styles from './SignInPage.module.scss';
import { getAuthorizationInformationData, getLoadingFields } from 'handlers/selectors';

const EMAIL_USED_ERROR_MESSAGE = 'Email is already used.';

interface IEmailUsedErrorHelperTextProps {
  messageText: string;
  linkText: string;
}

const EmailUsedErrorHelperText = ({ messageText, linkText }: IEmailUsedErrorHelperTextProps) => (
  <>
    {messageText}
    <Link
      className={styles.supportCenterLink}
      target="_blank"
      rel="noopener noreferrer"
      href={SUPPORT_CENTER_LINK}
    >
      {linkText}
    </Link>
  </>
);

const SignInPage: FC = () => {
  const applicationId = useQueryParam('applicationId');
  const { t: translate } = useTranslation([
    TranslationNameSpaces.Buttons,
    TranslationNameSpaces.AuthorizationProcess,
    TranslationNameSpaces.InputLabels,
  ]);
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();
  const {
    authorizationInformation: { hasAccount, phone, email, borrowerId },
  } = useAppSelector(getAuthorizationInformationData);
  const { createAccountLoading, searchAccountsLoading, sendVerificationCodeLoading } =
    useAppSelector(getLoadingFields);

  const [phoneNumber, setPhoneNumber] = useState<string>(phone);
  const [error, setError] = useState<string | React.ReactNode>('');
  const [isValid, setIsValid] = useState<boolean>(true);

  const isLoading = createAccountLoading || searchAccountsLoading || sendVerificationCodeLoading;
  const isButtonDisabled = !(phoneNumber && isPhoneNumberComplete(phoneNumber) && isValid);

  useEffect(() => {
    if (!isValid) {
      setError(
        translate(
          phoneNumber ? 
            AuthorizationProcessTranslationKeys.InvalidPhoneNumberErrorMessage : 
            AuthorizationProcessTranslationKeys.PhoneNumberRequiredErrorMessage, 
          {
            ns: TranslationNameSpaces.AuthorizationProcess,
          },
        ) as string,
      );

      return;
    }

    setError('');
  }, [isValid]);

  const handleClick = async () => {
    if (isButtonDisabled) {
      if (!phoneNumber) {
        setError(
          translate(AuthorizationProcessTranslationKeys.PhoneNumberRequiredErrorMessage, {
            ns: TranslationNameSpaces.AuthorizationProcess,
          }) as string,
        );
      }

      return;
    }

    try {
      setError('');
      if (applicationId) {
        await dispatchWithUnwrap(
          searchAccounts({
            borrowerId,
            phone: phoneNumber,
          }),
        );
        if (!hasAccount) {
          await dispatchWithUnwrap(
            createAccount({
              borrowerId,
              email,
              phone: phoneNumber,
              applicationId,
            }),
          );
        }
      }
      await dispatchWithUnwrap(sendVerificationCode({ phone: phoneNumber }));
      dispatch(setAuthorizationStep(AuthorizationStep.VerifyCode));
    } catch (e) {
      if ((e as Error).message === EMAIL_USED_ERROR_MESSAGE) {
        setError(
          <EmailUsedErrorHelperText
            messageText={translate(
              AuthorizationProcessTranslationKeys.EmailAlreadyUsedErrorMessage,
              { ns: TranslationNameSpaces.AuthorizationProcess },
            )}
            linkText={translate(AuthorizationProcessTranslationKeys.EmailAlreadyUsedErrorLink, {
              ns: TranslationNameSpaces.AuthorizationProcess,
            })}
          />,
        );
      }
    }
  };

  const renderPageTitle = () => {
    return (
      <Title variant="h1" className={styles.pageTitle}>
        {!applicationId
          ? translate(AuthorizationProcessTranslationKeys.SimpleLoginTitle, {
              ns: TranslationNameSpaces.AuthorizationProcess,
            })
          : translate(AuthorizationProcessTranslationKeys.SignUpTitle, {
              ns: TranslationNameSpaces.AuthorizationProcess,
            })}
      </Title>
    );
  };

  const renderPageDescription = () => {
    if (!applicationId) {
      return null;
    }

    return (
      <Paragraph className={styles.pageDescription}>
        {hasAccount
          ? translate(AuthorizationProcessTranslationKeys.LoginDescription, {
              ns: TranslationNameSpaces.AuthorizationProcess,
            })
          : translate(AuthorizationProcessTranslationKeys.SignUpDescription, {
              ns: TranslationNameSpaces.AuthorizationProcess,
            })}
      </Paragraph>
    );
  };

  const renderPageButton = () => {
    return (
      <Button
        variant={ButtonVariant.Primary}
        size={ButtonSize.Large}
        onClick={handleClick}
        disabled={isButtonDisabled}
        isLoading={isLoading}
      >
        {!applicationId
          ? translate(ButtonsTranslationKeys.SignIn, { ns: TranslationNameSpaces.Buttons })
          : translate(ButtonsTranslationKeys.Continue, { ns: TranslationNameSpaces.Buttons })}
      </Button>
    );
  };

  return (
    <PageLayout>
      {renderPageTitle()}
      {renderPageDescription()}
      <PhoneNumberInput
        value={phoneNumber}
        setValue={setPhoneNumber}
        isValid={isValid}
        setIsValid={setIsValid}
        setError={setError}
        disabled={hasAccount}
        wrapperClassName={styles.pageInput}
        label={
          translate(InputLabelsTranslationKeys.MobilePhoneNumber, {
            ns: TranslationNameSpaces.InputLabels,
          }) as string
        }
        error={error}
      />
      {renderPageButton()}
    </PageLayout>
  );
};

export default SignInPage;
