import { createGetApplicationStatusThunk } from './createGetApplicationStatusThunk';
import { createContinueWithApplicationThunk } from './createContinueWithApplicationThunk';
import { createCheckOldApplicationsThunk } from './createCheckOldApplicationsThunk';
import AuthorizationApi from 'api/digifi/portal/AuthorizationApi';
import VerificationApi from 'api/digifi/portal/VerificationApi';
import ApplicationProcessApi from 'api/digifi/portal/ApplicationProcessApi';
import ApplicationsRestApi from 'api/digifi/los/ApplicationsApi';
import BorrowersRestApi from 'api/digifi/los/BorrowersApi';
import { createVerifyCodeThunk } from './createVerifyCodeThunk';
import { createAuthorizationInformationThunk } from './createAuthorizationInformationThunk';
import { createSearchAccountsThunk } from './createSearchAccountsThunk';
import { createCreateAccountThunk } from './createCreateAccountThunk';
import { createSendVerificationCodeThunk } from './createSendVerificationCodeThunk';
import { createGetPersonalInformationThunk } from './createGetPersonalInformationThunk';
import { createUpdateApplicationThunk } from './createUpdateApplicationThunk';
import { createUpdateBorrowerThunk } from './createUpdateBorrowerThunk';
import { createGetFinancialInformationThunk } from './createGetFinancialInformationThunk';
import { createSendOfferAgreementEmail } from './createSendOfferAgreementEmail';
import { createGetOffersThunk } from './createGetOffersThunk';
import { createGetBorrowerLinkTokenThunk } from './createGetBorrowerLinkTokenThunk';
import BankVerificationApi from 'api/digifi/portal/BankVerificationApi';
import { createCreateBorrowerFundingSourceThunk } from './createCreateBorrowerFundingSourceThunk';
import { createCreateOffersThunk } from './createCreateOffersThunk';
import { createGetApplicationsThunk } from './createGetBorrowerApplications';
import IntermediariesApi from 'api/digifi/portal/IntermediariesApi';
import { createGetIntermediaryByIdThunk } from './createGetIntermediaryByIdThunk';
import { createGetIntermediaryByTokenThunk } from './createGetIntermediaryByTokenThunk';
import { createGetIntermediaryLinkTokenThunk } from './createGetIntermediaryLinkToken';
import { createCreateIntermediaryFundingSourceThunk } from './createCreateIntermediaryFundingSourceThunk';
import { createGetApplicationByIdThunk } from './createGetApplicationByIdThunk';
import { createCleanApplicationsThunk } from './createCleanApplicationsThunk';
import { createGetCurrentAccountThunk } from './createGetCurrentAccountThunk';
import AccountsRestApi from 'api/digifi/auth/AccountsApi';
import { createLogoutThunk } from './createLogoutThunk';
import SessionsRestApi from 'api/digifi/auth/SessionsApi';
import { createGetBorrowerThunk } from './createGetBorrowerThunk';
import { createRejectApplicationThunk } from './createRejectApplicationThunk';
import ManageAccountApi from 'api/digifi/portal/ManageAccountApi';
import { createRequestFundsThunk } from './createRequestFundsThunk';
import { createAddFundsThunk } from './createAddFundsThunk';
import { createCloseAccountThunk } from './createCloseAccountThunk';
import { createReopenAccountThunk } from './createReopenAccountThunk';
import LoanOffersApi from 'api/digifi/portal/LoanOffersApi';
import { createSaveBorrowerTokenThunk } from './createSaveBorrowerTokenThunk';
import { createSaveIntermediaryTokenThunk } from './createSaveIntermediaryTokenThunk';
import PaymentProcessorApi from 'api/digifi/portal/PaymentProcessorApi';
import { createUploadBankVerificationDocument } from './createUploadBankVerificationDocument';
import { createSavePlaidEventToVariableThunk } from './createSavePlaidEventToVariableThunk';
import { createCreateBorrowerDwollaMicroDepositFundingSource } from './createCreateBorrowerDwollaMicroDepositFundingSource';
import { createVerifyDwollaMicroDepositsThunk } from './createVerifyDwollaMicroDepositsThunk';
import { createCreateIntermediaryDwollaMicroDepositFundingSource } from './createCreateIntermediaryDwollaMicroDepositFundingSource';
import { createVerifyIntermediaryDwollaMicroDepositsThunk } from './createVerifyIntermediaryDwollaMicroDepositsThunk';

const authorizationApi = new AuthorizationApi();
const verificationApi = new VerificationApi();
const applicationProcessApi = new ApplicationProcessApi();
const applicationApi = new ApplicationsRestApi();
const borrowerApi = new BorrowersRestApi();
const bankVerificationApi = new BankVerificationApi();
const intermediariesApi = new IntermediariesApi();
const accountsApi = new AccountsRestApi();
const sessionsApi = new SessionsRestApi();
const manageAccountApi = new ManageAccountApi();
const loanOffersApi = new LoanOffersApi();
const paymentProcessorApi = new PaymentProcessorApi();

export const getOffers = createGetOffersThunk(loanOffersApi);

export const createOffers = createCreateOffersThunk(loanOffersApi);

export const getAuthorizationInformation = createAuthorizationInformationThunk(authorizationApi);

export const searchAccounts = createSearchAccountsThunk(authorizationApi);

export const createAccount = createCreateAccountThunk(authorizationApi);

export const rejectApplication = createRejectApplicationThunk(authorizationApi);

export const sendVerificationCode = createSendVerificationCodeThunk(verificationApi);

export const verifyCode = createVerifyCodeThunk(verificationApi);

export const checkOldApplications = createCheckOldApplicationsThunk(applicationProcessApi);

export const continueWithApplication = createContinueWithApplicationThunk(applicationProcessApi);

export const getApplicationStatus = createGetApplicationStatusThunk(applicationProcessApi);

export const getPersonalInformation = createGetPersonalInformationThunk(applicationProcessApi);

export const updateApplication = createUpdateApplicationThunk(applicationApi);

export const updateBorrower = createUpdateBorrowerThunk(borrowerApi);

export const getFinancialInformation = createGetFinancialInformationThunk(applicationProcessApi);

export const sendOfferAgreementEmail = createSendOfferAgreementEmail(applicationProcessApi);

export const getBorrowerLinkToken = createGetBorrowerLinkTokenThunk(bankVerificationApi);

export const createBorrowerFundingSource =
  createCreateBorrowerFundingSourceThunk(paymentProcessorApi);

export const getBorrowerApplications = createGetApplicationsThunk(applicationApi);

export const getApplicationById = createGetApplicationByIdThunk(applicationApi);

export const getIntermediaryById = createGetIntermediaryByIdThunk(intermediariesApi);

export const getIntermediaryByToken = createGetIntermediaryByTokenThunk(intermediariesApi);

export const getIntermediaryLinkToken = createGetIntermediaryLinkTokenThunk(bankVerificationApi);

export const createIntermediaryFundingSource =
  createCreateIntermediaryFundingSourceThunk(paymentProcessorApi);

export const cleanApplications = createCleanApplicationsThunk(applicationProcessApi);

export const getCurrentAccount = createGetCurrentAccountThunk(accountsApi);

export const logout = createLogoutThunk(sessionsApi);

export const getBorrower = createGetBorrowerThunk(borrowerApi);

export const requestFunds = createRequestFundsThunk(manageAccountApi);

export const addFunds = createAddFundsThunk(manageAccountApi);

export const closeAccount = createCloseAccountThunk(manageAccountApi);

export const reopenAccount = createReopenAccountThunk(manageAccountApi);

export const saveBorrowerToken = createSaveBorrowerTokenThunk(bankVerificationApi);

export const saveIntermediaryToken = createSaveIntermediaryTokenThunk(bankVerificationApi);

export const uploadBankVerificationDocument =
  createUploadBankVerificationDocument(bankVerificationApi);

export const savePlaidEventToVariable = createSavePlaidEventToVariableThunk(applicationProcessApi);

export const createBorrowerDwollaMicroDepositFundingSource =
  createCreateBorrowerDwollaMicroDepositFundingSource(paymentProcessorApi);

export const createIntermediaryDwollaMicroDepositFundingSource = 
  createCreateIntermediaryDwollaMicroDepositFundingSource(paymentProcessorApi);

export const verifyDwollaMicroDeposits = createVerifyDwollaMicroDepositsThunk(paymentProcessorApi);
export const verifyIntermediaryDwollaMicroDeposits = createVerifyIntermediaryDwollaMicroDepositsThunk(paymentProcessorApi);
