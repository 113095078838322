import React, { FC } from 'react';
import styles from './AmountConfirmationPopUpContent.module.scss';
import { useTranslation } from 'react-i18next';
import { AmountConfirmationPopUpTranslationKeys, ButtonsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';

interface IAmountConfirmationPopUpContentProps {
  annualIncome: string;
  onConfirmClick: () => void;
  onEditClick: () => void;
}

const AmountConfirmationPopUpContent: FC<IAmountConfirmationPopUpContentProps> = ({
  annualIncome,
  onConfirmClick,
  onEditClick,
}) => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.Popups,
    TranslationNameSpaces.Buttons,
  ]);

  return (
    <div className={styles.container}>
      <p className={styles.description}>
        {translate(AmountConfirmationPopUpTranslationKeys.Description, {
          ns: TranslationNameSpaces.Popups,
        })}
      </p>
      <p className={styles.annualValue}>
        <div>{`${translate(AmountConfirmationPopUpTranslationKeys.Amount, {
            ns: TranslationNameSpaces.Popups,
          })} ${annualIncome}`}</div>
      </p>
      <div className={styles.buttonsContainer}>
        <Button
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Large}
          onClick={onEditClick}
          className={styles.secondaryButton}
        >
          {translate(ButtonsTranslationKeys.Edit, {
            ns: TranslationNameSpaces.Buttons,
          })}
        </Button>
        <Button
          variant={ButtonVariant.Primary}
          size={ButtonSize.Large}
          onClick={onConfirmClick}
          className={styles.primaryButton}
        >
          {translate(ButtonsTranslationKeys.Confirm, {
            ns: TranslationNameSpaces.Buttons,
          })}
        </Button>
      </div>
    </div>
  );
};

export default AmountConfirmationPopUpContent;
