import React, { FC } from 'react';
import PopupWrapper from 'components/PopupWrapper';
import Title from 'components/Title';
import Paragraph from 'components/Paragraph';
import { ModalCloseIcon } from 'static/images';
import { TypographySize } from 'enums/TypographySize';
import styles from './Popup.module.scss';

interface IPopupProps {
  isOpen: boolean;
  title?: string;
  closeIcon?: boolean;
  isNotParagraph?: boolean;
  onClose?: () => void;
}

const Popup: FC<IPopupProps> = ({
  children,
  isOpen,
  title,
  onClose,
  isNotParagraph,
  closeIcon = true,
}) => {
  return (
    <PopupWrapper isOpen={isOpen} onClickOutside={onClose}>
      <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
        {closeIcon && (
          <button className={styles.closeButton} onClick={onClose}>
            <ModalCloseIcon />
          </button>
        )}
        {title && (
          <Title variant="h3" marginBottom="24">
            {title}
          </Title>
        )}
        {isNotParagraph ? (
          children
        ) : (
          <Paragraph variant={TypographySize.Middle}>{children}</Paragraph>
        )}
      </div>
    </PopupWrapper>
  );
};

export default Popup;
