import { EnvironmentVariable } from 'enums/EnvironmentVariable';
import BaseSecret from './BaseSecret';

class ProfundFrontendUrlSecret extends BaseSecret {
  constructor() {
    super(EnvironmentVariable.REACT_APP_PROFUND_FRONTEND_URL);
  }
}

export default ProfundFrontendUrlSecret;
