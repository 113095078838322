import React from 'react';
import InformationBox from 'components/InformationBox';
import { useTranslation } from 'react-i18next';
import { ParticipatingLenderInfoTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

const ParticipatingLenderInfo = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.ParticipatingLenderInfo);

  return (
    <InformationBox
      content={
        translate(ParticipatingLenderInfoTranslationKeys.Options, {
          returnObjects: true,
        }) as string[]
      }
    />
  );
};

export default ParticipatingLenderInfo;
