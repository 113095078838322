import CreditAuthorizationTerm from 'components/Terms/CreditAuthorizationTerm';
import PageLayout from 'components/PageLayout';
import React from 'react';

const CreditAuthorizationPage = () => {
  return (
    <PageLayout>
      <CreditAuthorizationTerm />
    </PageLayout>
  );
};

export default CreditAuthorizationPage;
