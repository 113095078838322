import Checkbox from 'components/Checkbox';
import { WhatNextPageTranslationKeys } from 'enums/Translation';
import React, { SyntheticEvent } from 'react';
import { Trans } from 'react-i18next';
import styles from './ProjectAccountTermsCheckbox.module.scss';

interface IProjectAccountTermsCheckboxProps {
  onChange: (checked: boolean) => void;
  checked: boolean;
  openProjectAccountInfoPopup: () => void;
}

const ProjectAccountTermsCheckbox = ({
  checked,
  onChange,
  openProjectAccountInfoPopup,
}: IProjectAccountTermsCheckboxProps) => {
  const handleOpenProjectAccountInfoPopup = (event: SyntheticEvent) => {
    event.stopPropagation();
    openProjectAccountInfoPopup();
  };

  const label = (
    <Trans i18nKey={WhatNextPageTranslationKeys.ProjectAccountCheckboxLabel}>
      By checking this box you authorize the creation and use of your Project Account and
      acknowledge you have read and reviewed the{' '}
      <span className={styles.activeText} onClick={handleOpenProjectAccountInfoPopup}>
        Project Account
      </span>{' '}
      information.
    </Trans>
  );

  return (
    <Checkbox
      label={label}
      isChecked={checked}
      onChange={() => onChange(!checked)}
      className={styles.marginBottom8}
    />
  );
};

export default ProjectAccountTermsCheckbox;
