import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { getApplicationStatus } from 'thunks';
import { getApplicationProcessData, getLoadingFields } from 'handlers/selectors';
import { AppRoutes } from 'routes/AppRoutes';
import { ApplicationLabelName, ApplicationLabelType } from 'enums/ApplicationLabelId';
import { ApplicationStatusIdType } from 'enums/ApplicationStatusId';
import Loading from 'components/Loading';
import EligibilityCheckFailedPage from './EligibilityCheckFailedPage';
import FinancialInformationPage from './FinancialInformationPage';
import NoOffers from './OffersPage/OffersPages/NoOffers';
import OffersPage from './OffersPage';
import OldApplicationPage from './OldApplicationPage';
import PersonalInformationForm from './PersonalInformation';
import { getApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { getApplicationLabelIdByEnvironment } from 'utils/applicationLabelsHelpers';
import UploadDocumentPage from './BankVerificationProcess/UploadDocumentPage';
import { DwollaCustomerStatus } from 'enums/DwollaCustomerStatus';
import { DwollaDocumentVerificationProcess } from 'enums/DocumentVerification';
import BankVerificationProcess from './BankVerificationProcess/BankVerificationProcess';
import {
  getIsLastDwollaMicroDepositsAttempt,
  getShouldResumeByLabels,
} from 'utils/getNeedNavigateToApplicationFlow';
import { DwollaMicroDepositsStatus } from 'enums/DwollaMicrodepositsStatus';

const RedirectToDefaultPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(AppRoutes.Applications);
  }, []);

  return null;
};

const getRejectedStatusStep = (labels: ApplicationLabelType[]) => {
  if (labels.includes(getApplicationLabelIdByEnvironment(ApplicationLabelName.FraudCheckFailed))) {
    return (
      <PersonalInformationForm
        statusId={getApplicationStatusIdByEnvironment(ApplicationStatusName.Rejected)}
      />
    );
  }

  if (
    labels.includes(getApplicationLabelIdByEnvironment(ApplicationLabelName.EligibilityCheckFailed))
  ) {
    return <EligibilityCheckFailedPage />;
  }

  if (labels.includes(getApplicationLabelIdByEnvironment(ApplicationLabelName.NoOffers))) {
    return <NoOffers />;
  }

  return null as never;
};

const getApplicationProcessStep = (
  status: ApplicationStatusIdType | null,
  labels: ApplicationLabelType[],
  dwollaCustomerStatus: string | null,
  dwollaDocumentVerificationProcess: DwollaDocumentVerificationProcess | null,
  fundedByEven: boolean | null,
  dwollaMicroDepositsStatus: DwollaMicroDepositsStatus | null,
  dwollaVerificationCount: number | null,
) => {
  switch (status) {
    case getApplicationStatusIdByEnvironment(ApplicationStatusName.NewApplication):
      return <OldApplicationPage />;
    case getApplicationStatusIdByEnvironment(ApplicationStatusName.PersonalInformation):
      return <PersonalInformationForm statusId={status} />;
    case getApplicationStatusIdByEnvironment(ApplicationStatusName.FinancialInformation):
      return <FinancialInformationPage />;
    case getApplicationStatusIdByEnvironment(ApplicationStatusName.OfferSelection):
      return <OffersPage />;
    case getApplicationStatusIdByEnvironment(ApplicationStatusName.OfferAgreement):
      return <OffersPage />;
    case getApplicationStatusIdByEnvironment(ApplicationStatusName.BankVerification): {
      if (dwollaCustomerStatus !== DwollaCustomerStatus.Document) {
        return <BankVerificationProcess />;
      }

      if (dwollaDocumentVerificationProcess === DwollaDocumentVerificationProcess.Review) {
        return <RedirectToDefaultPage />;
      }

      return (
        <UploadDocumentPage dwollaDocumentVerificationProcess={dwollaDocumentVerificationProcess} />
      );
    }
    case getApplicationStatusIdByEnvironment(ApplicationStatusName.Rejected):
      return getRejectedStatusStep(labels);
    case getApplicationStatusIdByEnvironment(ApplicationStatusName.Approved): {
      const isLastDwollaMicroDepositsAttempt = getIsLastDwollaMicroDepositsAttempt(
        dwollaMicroDepositsStatus,
        dwollaVerificationCount,
        labels,
      );

      const shouldResumeByLabels = getShouldResumeByLabels(
        fundedByEven,
        dwollaMicroDepositsStatus,
        labels,
      );

      if (isLastDwollaMicroDepositsAttempt || shouldResumeByLabels) {
        return <BankVerificationProcess />;
      }

      return <RedirectToDefaultPage />;
    }
    default:
      return <RedirectToDefaultPage />;
  }
};

const ApplicationProcess = () => {
  const { applicationId } = useParams();
  const {
    applicationInfo: {
      statusId,
      labelsIds,
      dwollaCustomerStatus,
      dwollaDocumentVerificationProcess,
      fundedByEven,
      dwollaMicroDepositsStatus,
      dwollaVerificationCount,
    },
    oldApplicationInformation: { currentApplicationId },
  } = useAppSelector(getApplicationProcessData);

  const { getApplicationStatusLoading } = useAppSelector(getLoadingFields);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getApplicationStatus(applicationId!));
  }, []);

  useEffect(() => {
    if (currentApplicationId && currentApplicationId !== applicationId) {
      navigate(`${AppRoutes.ApplicationProcess}/${currentApplicationId}`);
    }
  }, [currentApplicationId]);

  if (getApplicationStatusLoading) {
    return <Loading />;
  }

  if (!statusId) {
    return null;
  }

  return getApplicationProcessStep(
    statusId,
    labelsIds,
    dwollaCustomerStatus,
    dwollaDocumentVerificationProcess,
    fundedByEven,
    dwollaMicroDepositsStatus,
    dwollaVerificationCount,
  );
};

export default ApplicationProcess;
