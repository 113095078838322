import BaseApi from 'api/BaseApi';

interface IGetCurrentAccountResponseParams {
  email: string;
  phone: string;
  isEmailVerified: boolean;
  isMfaComplete: boolean;
  borrowerId: string;
  isPhoneVerified: boolean;
}

interface IAccountsApi {
  getCurrentAccount(): Promise<IGetCurrentAccountResponseParams>;
}

export default class AccountsRestApi extends BaseApi implements IAccountsApi {
  public getCurrentAccount(): Promise<IGetCurrentAccountResponseParams> {
    return this.fetch('/accounts');
  }
}
