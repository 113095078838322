import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getApplicationById, getBorrowerApplications } from 'thunks';
import { IApplication } from 'api/digifi/los/ApplicationsApi';

export interface IBorrowerApplicationProcessState {
  items: {
    active: IApplication[];
    prior: IApplication[];
  };
  selectedApplication: IApplication | null;
}

const initialState: IBorrowerApplicationProcessState = {
  items: {
    active: [],
    prior: [],
  },
  selectedApplication: null,
};

const borrowerApplicationsSlice = createSlice({
  name: 'applicationProcess',
  initialState,
  reducers: {
    setSelectedApplication: (state, { payload }: PayloadAction<null | IApplication>) => {
      state.selectedApplication = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBorrowerApplications.fulfilled, (state, { payload }) => {
        state.items = payload;
      })
      .addCase(getApplicationById.fulfilled, (state, { payload }) => {
        state.selectedApplication = payload;
      });
  },
});

export const { setSelectedApplication } = borrowerApplicationsSlice.actions;

export default borrowerApplicationsSlice.reducer;
