import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ApplicationDetailsPageTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { convertToMonetary } from 'utils/profund/formats';

import styles from './PaymentHistoryItem.module.scss';

export interface IPaymentHistoryItem {
  date: string;
  type: string;
  status: string;
  amount: number;
  transactionId: string;
}

export enum PaymentsHistoryType {
  RequestFunds = 'Request Funds',
}

export enum PaymentsHistoryStatus {
  Successful = 'Successful',
}

const PaymentHistoryItem = ({ date, type, status, amount }: IPaymentHistoryItem) => {
  const { t: translate } = useTranslation([TranslationNameSpaces.ApplicationDetailsPage]);

  return (
    <div className={styles.container}>
      <div className={styles.variableContainer}>
        <p className={styles.variableName}>
          {translate(ApplicationDetailsPageTranslationKeys.PaymentHistoryDate, {
            ns: TranslationNameSpaces.ApplicationDetailsPage,
          })}
        </p>
        <p className={styles.variableValue}>{date}</p>
      </div>
      <div className={styles.variableContainer}>
        <p className={styles.variableName}>
          {translate(ApplicationDetailsPageTranslationKeys.PaymentHistoryType, {
            ns: TranslationNameSpaces.ApplicationDetailsPage,
          })}
        </p>
        <p className={clsx(styles.variableValue, styles.paymentType)}>{type}</p>
      </div>
      <div className={styles.variableContainer}>
        <p className={styles.variableName}>
          {translate(ApplicationDetailsPageTranslationKeys.PaymentHistoryStatus, {
            ns: TranslationNameSpaces.ApplicationDetailsPage,
          })}
        </p>
        <p className={styles.variableValue}>{status}</p>
      </div>
      <div className={styles.variableContainer}>
        <p className={styles.variableName}>
          {translate(ApplicationDetailsPageTranslationKeys.PaymentHistoryAmount, {
            ns: TranslationNameSpaces.ApplicationDetailsPage,
          })}
        </p>
        <p className={clsx(styles.variableValue, styles.amount)}>{convertToMonetary(amount, 0)}</p>
      </div>
    </div>
  );
};

export default PaymentHistoryItem;
