export enum ApplicationLabelId {
  Canceled = '0183795e4eac00010a8c0a71',
  FraudCheckFailed = '0184be3f33d000010a8c0c29',
  EligibilityCheckFailed = '0183795e866100010a8c0c29',
  NoOffers = '0184be3f8d6600010a8c0ba7',
  CloseAccount = '0184be4420e500010a8c0a19',
  AddFunds = '0184be454f5500010a8c0a6b',
  ReopenAccount = '0184c3d1873e00010a8c0a19',
  RequestFunds = '0184be44ec5d00010a8c0a6b',
  MoreThanThreeAttemptsOfPhoneVerification = '0184be3efe3e00010a8c0a6b',
  ApplicationExpired = '0183795ee5ff00010a8c0c29',
  PendingLenderDecision = '0186c54e2d3200010a8c0c25',
  ApprovedDocsPending = '0186c54e2d3200010a8c0c25',
  MicroDeposits = '64b6d6717042995549e3eee4',
  FundedOutsideProfund = '64e306d5740908ebece231f1',
  ConnectBankLater = '64f73d7c6f0292d6a6e97550',
  DwollaMicroDepositsVerification = '64edd59244aff0491d029cbf',
  WebLeadApplication = '66be0d3d6a3edb8c2ad89de8',
}

export enum ProdApplicationLabelId {
  Canceled = '0186ca80dbd700010a8c0a67',
  FraudCheckFailed = '01857214d32d00010a8c0cd5',
  EligibilityCheckFailed = '01857214633100010a8c0cd5',
  NoOffers = '0185721492b800010a8c0cae',
  CloseAccount = '01857213e1e600010a8c0cd5',
  AddFunds = '01857213988b00010a8c0cd5',
  ReopenAccount = '01857212f1fd00010a8c0cd5',
  RequestFunds = '01857213bc1d00010a8c0cae',
  MoreThanThreeAttemptsOfPhoneVerification = '01857215517100010a8c0cae',
  ApplicationExpired = '0185721423c400010a8c0cae',
  PendingLenderDecision = '0186ca7f0b0c00010a8c0c25',
  ApprovedDocsPending = '0186ca7f0b0c00010a8c0c25',
  MicroDeposits = '64c1358468865e412aa904e6',
  FundedOutsideProfund = '64e8af7071e960eb20d0c00d',
  ConnectBankLater = '64f857e007240919650c83e1',
  DwollaMicroDepositsVerification = '64f858691558928701463fae',
  WebLeadApplication = '673df7824f134fa944cb71e3',
}

export enum ApplicationLabelName {
  Canceled = 'Canceled',
  FraudCheckFailed = 'FraudCheckFailed',
  EligibilityCheckFailed = 'EligibilityCheckFailed',
  NoOffers = 'NoOffers',
  CloseAccount = 'CloseAccount',
  AddFunds = 'AddFunds',
  ReopenAccount = 'ReopenAccount',
  RequestFunds = 'RequestFunds',
  MoreThanThreeAttemptsOfPhoneVerification = 'MoreThanThreeAttemptsOfPhoneVerification',
  ApplicationExpired = 'ApplicationExpired',
  PendingLenderDecision = 'PendingLenderDecision',
  ApprovedDocsPending = 'ApprovedDocsPending',
  MicroDeposits = 'MicroDeposits',
  FundedOutsideProfund = 'FundedOutsideProfund',
  ConnectBankLater = 'ConnectBankLater',
  DwollaMicroDepositsVerification = 'DwollaMicroDepositsVerification',
  WebLeadApplication = 'WebLeadApplication',
}

export type ApplicationLabelType = ApplicationLabelId | ProdApplicationLabelId;
