import { UpgradePlanCategory } from './UpgradePlanCategory';

export enum TranslationNameSpaces {
  Common = 'common',
  Buttons = 'buttons',
  Stepper = 'stepper',
  Menu = 'menu',
  Links = 'links',
  AuthorizationProcess = 'authorizationProcess',
  ApplicationExpiredPage = 'applicationExpiredPage',
  OldApplicationPage = 'oldApplicationPage',
  EligibilityCheckFailedPage = 'eligibilityCheckFailedPage',
  OfferConfirmationPage = 'offerConfirmationPage',
  SaveForLaterPage = 'saveForLaterPage',
  OffersPage = 'offersPage',
  WhatNextPage = 'whatNextPage',
  Popups = 'popups',
  FinancialInformationPage = 'financialInformationPage',
  InputLabels = 'inputLabels',
  BankVerificationPage = 'bankVerificationPage',
  SomethingWentWrongPage = 'somethingWentWrongPage',
  NotFoundPage = 'notFoundPage',
  WelcomeToProFundTerm = 'welcomeToProFundTerm',
  AccessAndUseOfTheServices = 'accessAndUseOfTheServices',
  ConditionsOfUse = 'conditionsOfUse',
  IntellectualPropertyRights = 'intellectualPropertyRights',
  CreditAuthorization = 'creditAuthorization',
  ElectronicConsent = 'electronicConsent',
  ApplicationsPage = 'applicationsPage',
  IntermediaryBankVerification = 'intermediaryBankVerification',
  ApplicationDetailsPage = 'applicationDetailsPage',
  EditPhoneModal = 'editPhoneModal',
  AcceptedIncomeSources = 'acceptedIncomeSources',
  ParticipatingLenderInfo = 'participatingLenderInfo',
  ErrorMessages = 'errorMessages',
  UnableToVerifyPage = 'unableToVerifyPage',
  PersonalInformationPage = 'personalInformationPage',
  UploadDocumentVerificationPage = 'uploadDocumentVerificationPage',
  FinalizeTheOfferPage = 'finalizeTheOfferPage',
}

export enum EditPhoneModalTranslationKeys {
  Title = 'title',
  Description = 'description',
}

export enum ElectronicConsentTranslationKeys {
  Title = 'title',
  Items = 'items',
}

export enum IntellectualPropertyRightsTranslationKeys {
  Title = 'title',
}

export enum CreditAuthorizationTranslationKeys {
  Title = 'title',
  Description = 'description',
}

export enum ServiceContentSoftwareAndTrademarksTranslationKeys {
  Title = 'serviceContentSoftwareAndTrademarks.title',
  Items = 'serviceContentSoftwareAndTrademarks.items',
}

export enum ThirdPartyMaterialTranslationKeys {
  Title = 'thirdPartyMaterial.title',
  Description = 'thirdPartyMaterial.description',
}

export enum UserContentTransmittedThroughTheServiceTranslationKeys {
  Title = 'userContentTransmittedThroughTheService.title',
  Items = 'userContentTransmittedThroughTheService.items',
}

export enum ThirdPartyWebsitesTranslationKeys {
  Title = 'thirdPartyWebsites.title',
  Description = 'thirdPartyWebsites.description',
}

export enum IndemnityAndReleaseTranslationKeys {
  Title = 'indemnityAndRelease.title',
  Items = 'indemnityAndRelease.items',
}

export enum IntroductionTranslationKeys {
  Title = 'introduction.title',
  Description = 'introduction.description',
}

export enum OptionForPaperOrNonElectronicRecordsTranslationKeys {
  Title = 'optionForPaperOrNonElectronicRecords.title',
  Description = 'optionForPaperOrNonElectronicRecords.description',
}

export enum ScopeOfConsentTranslationKeys {
  Title = 'scopeOfConsent.title',
  Description = 'scopeOfConsent.description',
}

export enum ConsentingToDoBusinessElectronicallyTranslationKeys {
  Title = 'consentingToDoBusinessElectronically.title',
  Description = 'consentingToDoBusinessElectronically.description',
}

export enum HardwareAndSoftwareRequirementsTranslationKeys {
  Title = 'hardwareAndSoftwareRequirements.title',
  Description = 'hardwareAndSoftwareRequirements.description',
  Link = 'hardwareAndSoftwareRequirements.link',
}

export enum WithdrawingConsentTranslationKeys {
  Title = 'withdrawingConsent.title',
  Description = 'withdrawingConsent.description',
  Link = 'withdrawingConsent.link',
}

export enum ChangeToYourContactInformationTranslationKeys {
  Title = 'changeToYourContactInformation.title',
  Items = 'changeToYourContactInformation.items',
}

export enum ContactByTelephoneTranslationKeys {
  Title = 'contactByTelephone.title',
  Items = 'contactByTelephone.items',
}

export enum DisclaimerOfWarrantiesTranslationKeys {
  Title = 'disclaimerOfWarranties.title',
  Items = 'disclaimerOfWarranties.items',
}

export enum LimitationOfLiabilityTranslationKeys {
  Title = 'limitationOfLiability.title',
  Items = 'limitationOfLiability.items',
}

export enum DisputeResolutionTranslationKeys {
  Title = 'disputeResolution.title',
  Description = 'disputeResolution.description',
}

export enum TerminationTranslationKeys {
  Title = 'termination.title',
  Description = 'termination.description',
}

export enum UserDisputesTranslationKeys {
  Title = 'userDisputes.title',
  Description = 'userDisputes.description',
}

export enum GeneralTranslationKeys {
  Title = 'general.title',
  Description = 'general.description',
}

export enum YourPrivacyTranslationKeys {
  Title = 'yourPrivacy.title',
  Description = 'yourPrivacy.description',
  Link = 'yourPrivacy.link',
}

export enum QuestionsTranslationKeys {
  Title = 'questions.title',
  Description = 'questions.description',
  Link = 'questions.link',
}

export enum WelcomeToProFundTermTranslationKeys {
  Title = 'title',
  Description = 'description',
  Link = 'link',
}

export enum AccessAndUseOfTheServicesTranslationKeys {
  Title = 'title',
}

export enum ConditionsOfUseTranslationKeys {
  Title = 'title',
}

export enum UserConductTranslationKeys {
  Title = 'userConduct.title',
  Items = 'userConduct.items',
}

export enum CommercialUseNotPermittedTranslationKeys {
  Title = 'commercialUseNotPermitted.title',
  Description = 'commercialUseNotPermitted.description',
}

export enum ProtectedAreasTranslationKeys {
  Title = 'protectedAreas.title',
  Description = 'protectedAreas.description',
}

export enum FeedbackTranslationKeys {
  Title = 'feedback.title',
  Items = 'feedback.items',
}

export enum ServicesDescriptionTranslationKeys {
  Title = 'servicesDescription.title',
  Items = 'servicesDescription.items',
  Link = 'servicesDescription.link',
  Email = 'servicesDescription.email',
}

export enum YourRegistrationObligationsTranslationKeys {
  Title = 'yourRegistrationObligations.title',
  Description = 'yourRegistrationObligations.description',
  Link = 'yourRegistrationObligations.link',
}

export enum ModificationsToServicesTranslationKeys {
  Title = 'modificationsToServices.title',
  Description = 'modificationsToServices.description',
}

export enum GeneralPracticesRegardingUseAndStorageTranslationKeys {
  Title = 'generalPracticesRegardingUseAndStorage.title',
  Description = 'generalPracticesRegardingUseAndStorage.description',
}

export enum MobileServicesTranslationKeys {
  Title = 'mobileServices.title',
  Description = 'mobileServices.description',
}

export enum ElectronicNoticesAndCommunicationTranslationKeys {
  Title = 'electronicNoticesAndCommunication.title',
  Description = 'electronicNoticesAndCommunication.description',
  Link = 'electronicNoticesAndCommunication.link',
}

export enum StepperTranslationKeys {
  VerificationStep = 'verificationStep',
  PersonalInformationStep = 'personalInformationStep',
  FinancialInformationStep = 'financialInformationStep',
  OfferSelectionStep = 'offersSelectionStep',
}

export enum ButtonsTranslationKeys {
  SignIn = 'signInButton',
  Continue = 'continueButton',
  Back = 'backButton',
  StartNewApplication = 'startNewApplicationButton',
  ProceedWithPreviousApplication = 'proceedWithPreviousApplicationButton',
  GoToProfileButton = 'goToProfileButton',
  BackToOffers = 'backToOffers',
  Exit = 'exit',
  Confirm = 'confirm',
  SaveForLater = 'saveForLater',
  AgreeAndContinue = 'agreeAndContinue',
  FinishLater = 'finishLater',
  SelectOfferButton = 'selectOfferButton',
  DisclaimerButton = 'disclaimerButton',
  ShowMoreButton = 'showMoreButton',
  ShowLessButton = 'showLessButton',
  GotItButton = 'gotItButton',
  TakeToAccountButton = 'takeToAccountButton',
  Verify = 'verify',
  GoHome = 'goHomeButton',
  Cancel = 'cancelButton',
  Support = 'supportCenterButton',
  Manage = 'manage',
  Edit = 'edit',
  SupportCenter = 'supportCenter',
  UploadDocument = 'uploadDocumentButton',
  AllOffers = 'allOffers',
  Connect = 'connect',
  TryAgain = 'tryAgain',
  SeeOffers = 'seeOffers',
  ConnectLater = 'connectLater',
  Submit = 'submit',
  ProceedWithOffer = 'proceedWithOffer',
}

export enum NotFoundPageTranslationKeys {
  Code = 'code',
  Message = 'message',
}

export enum MenuTranslationKeys {
  Applications = 'applications',
  Profile = 'profile',
  SignOut = 'signOut',
}

export enum LinksTranslationKeys {
  OurTermsOfService = 'ourTermsOfService',
  PrivacyPolicy = 'privacyPolicy',
  DwollasTermsOfService = 'dwollasTermsOfService',
}

export enum InputLabelsTranslationKeys {
  MobilePhoneNumber = 'mobilePhoneNumber',
  AnnualIncome = 'annualIncome',
  SSN = 'socialSecurityNumber',
  DateOfBirth = 'dateOfBirth',
  PropertyStatus = 'propertyStatus',
  CreditScore = 'creditScore',
  EmploymentStatus = 'employmentStatus',
  PayFrequency = 'payFrequency',
  HighestEducationLevel = 'highestEducationLevel',
  FirstName = 'firstName',
  LastName = 'lastName',
  HomeAddress = 'homeAddress',
  ApartmentOrSuite = 'apartmentOrSuite',
  City = 'city',
  State = 'state',
  ZipCode = 'zipCode',
  LoanAmount = 'loanAmount',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  ProjectAddress = 'projectAddress',
  VerificationDocumentType = 'verificationDocumentType',
  VerificationDocument = 'verificationDocument',
  NameOnAccount = 'nameOnAccount',
  BankAccountType = 'bankAccountType',
  BankRoutingNumber = 'bankRoutingNumber',
  BankConfirmRoutingNumber = 'bankConfirmRoutingNumber',
  BankAccountNumber = 'bankAccountNumber',
  BankConfirmAccountNumber = 'bankConfirmAccountNumber',
  Amount1 = 'amount1',
  Amount2 = 'amount2',
}

export enum AuthorizationProcessTranslationKeys {
  SignUpTitle = 'signUpPageTitle',
  SimpleLoginTitle = 'simpleLoginPageTitle',
  SignUpDescription = 'signUpPageDescription',
  LoginDescription = 'loginPageDescription',
  VerificationDescription = 'verificationPageDescription',
  UnableToVerifyDescription = 'unableToVerifyDescription',
  VerificationWaitingDescription = 'verificationWaitingDescription',
  VerificationResendCode = 'verificationResendCode',
  VerificationVerified = 'verificationVerified',
  InvalidPhoneNumberErrorMessage = 'invalidPhoneNumberErrorMessage',
  PhoneNumberRequiredErrorMessage = 'phoneNumberRequiredErrorMessage',
  NumberIsTakenErrorMessage = 'numberIsTakenErrorMessage',
  IncorrectCodeErrorMessage = 'incorrectCodeErrorMessage',
  PhoneNumberTooltipText = 'phoneNumberTooltipText',
  EmailAlreadyUsedErrorMessage = 'emailAlreadyUsedErrorMessage',
  EmailAlreadyUsedErrorLink = 'emailAlreadyUsedErrorLink',
}

export enum ApplicationExpiredPageTranslationKeys {
  Title = 'pageTitle',
  Description = 'pageDescription',
}

export enum OldApplicationPageTranslationKeys {
  Title = 'pageTitle',
  Description = 'pageDescription',
}

export enum EligibilityCheckFailedPageTranslationKeys {
  Title = 'pageTitle',
  Description = 'pageDescription',
  Requirements = 'requirementItems',
}

export enum OfferConfirmationPageTranslationKeys {
  Details = 'details',
  Description = 'description',
  OriginationFeeDescription = 'originationFeeDescription',
  waterCressDescription = 'waterCressDescription',
  LabelText = 'labelText',
}

export enum SaveForLaterPageTranslationsKeys {
  Title = 'title',
  DescriptionPartOne = 'description.part1',
  DescriptionPartTwo = 'description.part2',
}

export enum FinalizeTheOfferPageTranslationsKeys {
  Title = 'title',
  DescriptionPartOne = 'description.part1',
}

export enum OffersPageTranslationKeys {
  Title = 'pageTitle',
  Description = 'pageDescription',
  AdditionalDescription = 'pageAdditionalDescription',
}

export enum OfferCardTranslationKeys {
  LoanAmount = 'offerCard.loanAmount',
  PreApproved = 'offerCard.preApproved',
  PreQualified = 'offerCard.preQualified',
  MonthlyPayment = 'offerCard.monthlyPayment',
  TermOfLoan = 'offerCard.termOfLoan',
  FixedApr = 'offerCard.fixedApr',
  DisclaimerAndMoreInformation = 'offerCard.disclaimerAndMoreInformation',
  OriginationFee = 'offerCard.originationFee',
  ProductDescriptionC5054 = 'offerCard.productDescriptionC5054',
  ProductDescriptionC8023 = 'offerCard.productDescriptionC8023',
}

export const UpgradePlanCategoryKeys = {
  [UpgradePlanCategory.NO_INTEREST_NO_PAYMENT]: 'offerCard.upgradePlanCategory.noInterestNoPayment',
  [UpgradePlanCategory.NO_INTEREST_YES_PAYMENT]: 'offerCard.upgradePlanCategory.noInterestYesPayment',
  [UpgradePlanCategory.REDUCED_RATE]: 'offerCard.upgradePlanCategory.reducedRate',
  [UpgradePlanCategory.ZERO_INTEREST_RATE]: 'offerCard.upgradePlanCategory.zeroInterestRate',
  [UpgradePlanCategory.NO_INTEREST_NO_PAYMENT_ZERO_BILL]: 'offerCard.upgradePlanCategory.noInterestNoPaymentZeroBill',
  [UpgradePlanCategory.NO_INTEREST_YES_PAYMENT_ZERO_BILL]: 'offerCard.upgradePlanCategory.noInterestYesPaymentZeroBill',
  [UpgradePlanCategory.REDUCED_RATE_ZERO_BILL]: 'offerCard.upgradePlanCategory.reducedRateZeroBill',
  [UpgradePlanCategory.DEFERRED_INTEREST_NO_PAYMENT]: 'offerCard.upgradePlanCategory.deferredInterestNoPayment',
  [UpgradePlanCategory.DEFERRED_INTEREST_YES_PAYMENT]: 'offerCard.upgradePlanCategory.deferredInterestYesPayment',
  [UpgradePlanCategory.DEFERRED_INTEREST_NO_PAYMENT_ZERO_BILL]: 'offerCard.upgradePlanCategory.deferredInterestNoPaymentZeroBill',
  [UpgradePlanCategory.DEFERRED_INTEREST_YES_PAYMENT_ZERO_BILL]: 'offerCard.upgradePlanCategory.deferredInterestYesPaymentZeroBill',
};

export enum OnboardingPopupsTranslationKeys {
  CategoriesPopup = 'onboardingPopups.categoriesPopup',
  SwipePopup = 'onboardingPopups.swipePopup',
}

export enum OffersCategoriesTranslationKeys {
  LowFixedRate = 'offersCategories.lowFixedRate',
  LowPayments = 'offersCategories.lowPayments',
  Promotional = 'offersCategories.promotional',
}

export enum NoOffersTranslationKeys {
  Title = 'noOffersPage.pageTitle',
  Description = 'noOffersPage.pageDescription',
  SubDescription = 'noOffersPage.pageSubDescription',
}

export enum WhatNextPageTranslationKeys {
  MainDescription = 'mainDescription',
  DescriptionPart1 = 'description.part1',
  DescriptionPart2 = 'description.part2',
  DescriptionPart3 = 'description.part3',
  DescriptionPart4 = 'description.part4',
  CheckboxLabelPart1 = 'checkboxLabel.part1',
  CheckboxLabelPart2 = 'checkboxLabel.part2',
  CheckboxLabelAnd = 'checkboxLabel.and',
  Step2 = 'steps.step2',
  Step3 = 'steps.step3',
  ProjectAccountCheckboxLabel = 'projectAccountCheckboxLabel',
  Title = 'title',
  Info = 'info',
  IntermediaryInfo = 'intermediaryInfo',
}

export enum PopupsTranslationKeys {
  DisclaimerAndMoreInformationPart1 = 'disclaimerAndMoreInformation.part1',
  DisclaimerAndMoreInformationPart2 = 'disclaimerAndMoreInformation.part2',
  DisclaimerAndMoreInformationPart3 = 'disclaimerAndMoreInformation.part3',
  AdvertiserDisclosureTitle = 'advertiserDisclosure.title',
  AdvertiserDisclosureDescription = 'advertiserDisclosure.description',
  APRDisclosureTitle = 'APRDisclosure.title',
  APRDisclosureDescription = 'APRDisclosure.description',
  PartnerGuaranteeDisclosureTitle = 'PartnerGuaranteeDisclosure.title',
  PartnerGuaranteeDisclosureDescription = 'PartnerGuaranteeDisclosure.description',
}

export enum CommonTranslationKeys {
  FooterDescription = 'footerDescription',
  FooterLink = 'footerLinkText',
  WaitingExperienceTitle = 'waitingExperienceTitle',
  TermsOfServiceTitle = 'termsOfServiceTitle',
  LastRevisionDate = 'lastRevisionDate',
}

export enum FinancialInformationPageTranslationKeys {
  Title = 'title',
  Description = 'description',
  CheckboxLabel1 = 'checkboxLabel.label1',
  CheckboxLabel2 = 'checkboxLabel.label2',
  AnnualIncomeTooltip = 'annualIncomeTooltipText',
  SSNTooltipText = 'ssnInputTooltipText',
  CoborrowerAnnualIncomeTooltipText = 'coborrowerAnnualIncomeTooltipText',
  Coborrower = 'coborrowerTab.coBorrower',
  Remove = 'coborrowerTab.remove',
  AddCoborrower = 'coborrowerTab.addCoborrower',
  PropertyLabels = 'dropdownLabels.property',
  CreditRatingLabels = 'dropdownLabels.creditRating',
  EmploymentStatusLabels = 'dropdownLabels.employmentStatus',
  PayFrequencyLabels = 'dropdownLabels.payFrequency',
  HighestEducationLabels = 'dropdownLabels.highestEducation',
  VerificationDocumentTypeLabels = 'dropdownLabels.documentType',
  MobilePhoneTooltipText = 'mobilePhoneTooltipText',
  CoownerSSNTooltipText = 'coownerSsnTooltipText',
}

export enum BankVerificationPageTranslationKeys {
  Title = 'title',
  DwollaVerificationSubtitle = 'dwollaVerificationSubtitle',
  PoweredBy = 'poweredBy',
  SelectPageMessage = 'selectPageMessage',
  Or = 'or',
  Select = 'select',
  InstantConnectionType = 'connectionTypes.instant',
  StandardConnectionType = 'connectionTypes.standard',
  InstantConnectionDescription = 'connectionDescriptions.instant',
  StandardConnectionDescription = 'connectionDescriptions.standard',
  Option1 = 'option1',
  Option2 = 'option2',
}

export enum SomethingWentWrongPageTranslationKeys {
  Title = 'title',
  Description1 = 'description1',
  Description2 = 'description2',
  Description3 = 'description3',
}

export enum ApplicationsPageTranslationKeys {
  ActiveTitle = 'applicationType.active',
  PriorTitle = 'applicationType.prior',
  ActiveStatus = 'applicationsStatus.active',
  InactiveStatus = 'applicationsStatus.inactive',
  UpdatedTitle = 'updated',
  LoanAmount = 'loanAmount',
  NoOffers = 'noOffers',
  MicroDepositsVerificationCardTitle = 'microDepositsVerificationCard.title',
  MicroDepositsVerificationCardSubTitle = 'microDepositsVerificationCard.subTitle',
  MicroDepositsVerificationCardDescription = 'microDepositsVerificationCard.description',
  MicroDepositsVerificationCardStatus = 'microDepositsVerificationCard.status',
  ApplicationsNotFound = 'applicationsNotFound',
}

export enum IntermediaryBankVerificationTranslationKeys {
  Description1 = 'description1',
  Description2 = 'description2',
  Description3 = 'description3',
}

export enum ApplicationDetailsPageTranslationKeys {
  UpdatedTitle = 'updated',
  CreatedTitle = 'created',
  ProjectAccountBalance = 'projectAccountBalance',
  AgreedJobAmount = 'agreedJobAmount',
  PaymentHistoryTitle = 'paymentHistoryTitle',
  PaymentHistoryDate = 'paymentHistory.date',
  PaymentHistoryType = 'paymentHistory.type',
  PaymentHistoryStatus = 'paymentHistory.status',
  PaymentHistoryAmount = 'paymentHistory.amount',
  LoanAmount = 'variables.loanAmount',
  MonthlyPayment = 'variables.monthlyPayment',
  TermOfLoan = 'variables.termOfLoan',
  FixedApr = 'variables.fixedApr',
  ProjectAccountBalanceTooltip = 'tooltips.projectAccountBalance',
  RequestFundsTooltip = 'tooltips.requestFunds',
  CloseAccountRequestTooltip = 'tooltips.closeAccountRequest',
  AddFundsTooltip = 'tooltips.addFunds',
  AgreedJobAmountTooltip = 'tooltips.agreedJobAmount',
  AvailableForRequest = 'availableForRequest',
  RequestFundsInputTitle = 'manageFundsInputTitle.requestFunds',
  AddFundsInputTitle = 'manageFundsInputTitle.addFunds',
  RequestFundsLabel = 'labels.requestFunds',
  AddFundsLabel = 'labels.addFunds',
  MinRequestAmountLabel = 'inputLabel.minRequestAmount',
  MaxRequestAmountLabel = 'inputLabel.maxRequestAmount',
  MinAddFundsAmountLabel = 'inputLabel.minAddFundsAmount',
  MaxAddFundsAmountLabel = 'inputLabel.maxAddFundsAmount',
  RequestFundsSuccessMessageTitle = 'manageFundsSuccessMessage.requestFunds.title',
  RequestFundsSuccessMessageDescription = 'manageFundsSuccessMessage.requestFunds.description',
  AddFundsSuccessMessageTitle = 'manageFundsSuccessMessage.addFunds.title',
  AddFundsSuccessMessageDescription = 'manageFundsSuccessMessage.addFunds.description',
  CloseAccountTitle = 'closeAccount.title',
  CloseAccountPopupTitle = 'closeAccount.popup.title',
  CloseAccountDescriptionPart1 = 'closeAccount.description.part1',
  CloseAccountDescriptionPart2 = 'closeAccount.description.part2',
  CloseAccountPopupAreYouSure = 'closeAccount.popup.areYouSure',
  CloseAccountPopupDescriptionPart1 = 'closeAccount.popup.description.part1',
  CloseAccountPopupDescriptionPart2 = 'closeAccount.popup.description.part2',
  CloseAccountPopupDescriptionPart3 = 'closeAccount.popup.description.part3',
  CloseAccountPopupDescriptionPart4 = 'closeAccount.popup.description.part4',
  CloseAccountPopupDescriptionPart5 = 'closeAccount.popup.description.part5',
  CloseAccountSuccessMessage = 'closeAccount.successMessage',
  CloseAccountLabel = 'closeAccount.label',
  ReopenAccountSuccessMessage = 'reopenAccount.successMessage',
  ReopenAccountPopupTitle = 'reopenAccount.popup.title',
  ReopenAccountPopupContent = 'reopenAccount.popup.content',
  ReopenAccountLabel = 'reopenAccount.label',
  AddFundsInputPlaceholder = 'inputPlaceholder.addFunds',
  RequestFundsPlaceholder = 'inputPlaceholder.requestFunds',
  NoPaymentHistory = 'noPaymentHistory',
}

export enum AcceptedIncomeSourcesTranslationKeys {
  Sources = 'sources',
  Title = 'title',
}

export enum ParticipatingLenderInfoTranslationKeys {
  Options = 'options',
}

export enum ErrorMessagesTranslationKeys {
  Invalid = 'invalid',
  Required = 'required',
  MinimumLoanAmount = 'minimumLoanAmount',
  ShouldBeEqual = 'shouldBeEqual',
  CheckboxRequired = 'checkboxRequired',
}

export enum UnableToVerifyPageTranslationKeys {
  Title = 'title',
  Description = 'description',
}

export enum PersonalInformationPageTranslationKeys {
  Title = 'title',
  Description = 'description',
  IHaveRead = 'iHaveRead',
  CreditAuthorization = 'creditAuthorization',
  TermsOfService = 'termsOfService',
  PrivacyPolicy = 'privacyPolicy',
  ElectronicConsent = 'electronicConsent',
  ProjectAddressToggleText = 'projectAddressToggleText',
  ProjectAddressTooltipText = 'projectAddressTooltipText',
}

export enum UploadDocumentVerificationPageTranslationKeys {
  Title = 'title',
  Description = 'description',
  RetryUploadDescription = 'retryUploadDescription',
  AvailableFileParams = 'availableFileParams',
}

export enum PlaidInfoPopupTranslationKeys {
  Title = 'plaidInfoPopup.title',
  Description = 'plaidInfoPopup.description',
  Info = 'plaidInfoPopup.info',
  Step1 = 'plaidInfoPopup.steps.step1',
  Step2 = 'plaidInfoPopup.steps.step2',
  Step3 = 'plaidInfoPopup.steps.step3',
}

export enum DwollaInfoPopupTranslationKeys {
  Title = 'dwollaInfoPopup.title',
  Description = 'dwollaInfoPopup.description',
  Step1 = 'dwollaInfoPopup.steps.step1',
  SubStep1 = 'dwollaInfoPopup.steps.subStep1',
  Step2 = 'dwollaInfoPopup.steps.step2',
  Step3 = 'dwollaInfoPopup.steps.step3',
}

export enum DwollaMicrodepositsPopupTranslationKeys {
  Title = 'dwollaMicrodepositsPopup.title',
  Description = 'dwollaMicrodepositsPopup.description',
}

export enum DwollaVerifiedMicrodepositsPopupTranslationKeys {
  Title = 'dwollaVerifiedMicrodepositsPopup.title',
  Description1 = 'dwollaVerifiedMicrodepositsPopup.description1',
  Description2 = 'dwollaVerifiedMicrodepositsPopup.description2',
  FailedDescription = 'dwollaVerifiedMicrodepositsPopup.failedDescription',
}

export enum AmountConfirmationPopUpTranslationKeys {
  Title = 'amountConfirmationPopUp.title',
  Description = 'amountConfirmationPopUp.description',
  Amount = 'amountConfirmationPopUp.amount',
}
