import React from 'react';
import { BackArrowIcon } from 'static/images';
import { useTranslation } from 'react-i18next';
import { ButtonsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

import styles from './BackButton.module.scss';

interface IBackButtonProps {
  onBackClick?: () => void;
  title?: string | null;
}

const BackButton = ({ onBackClick, title }: IBackButtonProps) => {
  const { t: translate } = useTranslation(TranslationNameSpaces.Buttons);

  return (
    <div className={styles.container}>
      {onBackClick && (
        <div className={styles.back} onClick={onBackClick}>
          <BackArrowIcon /> {title || translate(ButtonsTranslationKeys.Back)}
        </div>
      )}
    </div>
  );
};

export default BackButton;
