import React from 'react';
import styles from './DwollaPopupContent.module.scss';
import guide from './guide.png';
import { useTranslation } from 'react-i18next';
import { DwollaInfoPopupTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

const IMG_ALT = 'Guide';

const DwollaPopupContent = () => {
  const { t: translate } = useTranslation([TranslationNameSpaces.Popups]);

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        {translate(DwollaInfoPopupTranslationKeys.Description)}
      </div>
      <div className={styles.steps}>
        <div>{translate(DwollaInfoPopupTranslationKeys.Step1)}</div>
        <div className={styles.subStep}>{translate(DwollaInfoPopupTranslationKeys.SubStep1)}</div>
        <img className={styles.guide} src={guide} alt={IMG_ALT} />
        <div>{translate(DwollaInfoPopupTranslationKeys.Step2)}</div>
        <div>{translate(DwollaInfoPopupTranslationKeys.Step3)}</div>
      </div>
    </div>
  );
};

export default DwollaPopupContent;
