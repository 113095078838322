import EnvironmentSecret from './EnvironmentSecret';
import GoogleApiKeySecret from './GoogleApiKeySecret';
import ProfundFrontendUrlSecret from './ProfundFrontendUrlSecret';
import SentryDsnSecret from './SentryDsnSecret';
import WatercressEnabledSecret from './WatercressEnabledSecret';

const environmentSecret = new EnvironmentSecret().get();
const googleApiKeySecret = new GoogleApiKeySecret().get();
const sentryDsnSecret = new SentryDsnSecret().get();
const watercressEnabledSecret = new WatercressEnabledSecret().get();
const profundFrontendUrlSecret = new ProfundFrontendUrlSecret().get();

export {
  environmentSecret,
  googleApiKeySecret,
  sentryDsnSecret,
  watercressEnabledSecret,
  profundFrontendUrlSecret,
};
