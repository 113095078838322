import * as Yup from 'yup';
import { PersonalInformationVariable } from 'enums/PersonalInformation';
import { countryStates } from './stateSearchOptions';
import { convertMonetaryValueToIntegerNumber } from 'utils/profund/formats';
import { checkIfValueStartsWithZero } from 'utils/profund/checkIfValueStartsWithZero';
import { useErrorMessages } from 'hooks/useErrorMessages';
import { useTranslation } from 'react-i18next';
import {
  ErrorMessagesTranslationKeys,
  InputLabelsTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';

export const DIGIFI_EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i;
const GEORGIA_MIN_LOAN_AMOUNT = 3100;
const MASSACHUSETTS_MIN_LOAN_AMOUNT = 6100;

export const usePersonalInformationValidationSchema = () => {
  const [getMessageForRequiredFields, getMessageForInvalidFields] = useErrorMessages();
  const { t: translate } = useTranslation([
    TranslationNameSpaces.InputLabels,
    TranslationNameSpaces.ErrorMessages,
  ]);

  const loanAmountLabel = translate(InputLabelsTranslationKeys.LoanAmount, {
    ns: TranslationNameSpaces.InputLabels,
  });

  const getMinLoanAmountErrorMessage = (state: string, amount: string) => {
    return translate(ErrorMessagesTranslationKeys.MinimumLoanAmount, {
      state,
      amount,
      ns: TranslationNameSpaces.ErrorMessages,
    }) as string;
  };

  const validationSchema = Yup.object().shape({
    [PersonalInformationVariable.LoanAmount]: Yup.string()
      .required(getMessageForRequiredFields(loanAmountLabel))
      .test('should be not zero', getMessageForInvalidFields(loanAmountLabel), (value) => {
        return checkIfValueStartsWithZero(value);
      })
      .when(PersonalInformationVariable.State, {
        is: (state: string) => state === 'GA',
        then: () => {
          return Yup.string().test(
            'should meet Georgia minimum loan amount',
            getMinLoanAmountErrorMessage('Georgia', '3100'),
            (value) => {
              if (!value) {
                return true;
              }
              return convertMonetaryValueToIntegerNumber(value)! >= GEORGIA_MIN_LOAN_AMOUNT;
            },
          );
        },
        otherwise: Yup.string().required(getMessageForRequiredFields(loanAmountLabel)),
      })
      .when(PersonalInformationVariable.State, {
        is: (state: string) => state === 'MA',
        then: () => {
          return Yup.string().test(
            'isMassachusettsLoanAmountValid',
            getMinLoanAmountErrorMessage('Massachusetts', '6100'),
            (value) => {
              if (!value) {
                return true;
              }
              return convertMonetaryValueToIntegerNumber(value)! >= MASSACHUSETTS_MIN_LOAN_AMOUNT;
            },
          );
        },
        otherwise: Yup.string().required(getMessageForRequiredFields(loanAmountLabel)),
      }),

    [PersonalInformationVariable.FirstName]: Yup.string().required(
      getMessageForRequiredFields(translate(InputLabelsTranslationKeys.FirstName)),
    ),
    [PersonalInformationVariable.LastName]: Yup.string().required(
      getMessageForRequiredFields(translate(InputLabelsTranslationKeys.LastName)),
    ),
    [PersonalInformationVariable.StreetName]: Yup.string().required(
      getMessageForRequiredFields(translate(InputLabelsTranslationKeys.HomeAddress)),
    ),
    [PersonalInformationVariable.City]: Yup.string().required(
      getMessageForRequiredFields(translate(InputLabelsTranslationKeys.City)),
    ),
    [PersonalInformationVariable.State]: Yup.string()
      .required(getMessageForRequiredFields(translate(InputLabelsTranslationKeys.State)))
      .test(
        'isStateValid',
        getMessageForInvalidFields(translate(InputLabelsTranslationKeys.State)),
        (value) => {
          if (!value) {
            return true;
          }

          return countryStates.includes(value as string);
        },
      ),
    [PersonalInformationVariable.ZipCode]: Yup.string().required(
      getMessageForRequiredFields(translate(InputLabelsTranslationKeys.ZipCode)),
    ),
    [PersonalInformationVariable.Email]: Yup.string()
      .required(getMessageForRequiredFields(translate(InputLabelsTranslationKeys.Email)))
      .matches(
        DIGIFI_EMAIL_PATTERN,
        getMessageForInvalidFields(translate(InputLabelsTranslationKeys.Email)),
      ),
  });

  return validationSchema;
};
