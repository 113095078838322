import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { StrategyIdType } from 'enums/StrategyId';
import { VariableValue } from 'types/VariableTypes';

enum StrategyEndpoints {
  Run = '/strategy/run',
}

interface IStrategyRunBody {
  decisionName?: string;
  applicationId?: string;
  decisionClientId?: string;
  ignoreUndefined?: boolean;
  returnOnlyPassed?: boolean;
  commonInputs?: Record<string, VariableValue>;
  decisions: Array<{
    strategyId: StrategyIdType;
    inputs: Record<string, VariableValue>;
  }>;
  strict?: boolean;
}

export enum StrategyModuleType {
  Requirements = 'requirements',
  Formula = 'formula',
  Score = 'score',
  DataIntegration = 'dataIntegration',
  Decision = 'decision',
}

export enum DecisionRunResult {
  Passed = 'passed',
  Failed = 'failed',
  Error = 'error',
}

export interface IDecisionResultCompiledModuleInfo {
  id: string;
  type: StrategyModuleType;
  name: string;
}

interface IDecisionResult {
  id: string;
  name: string;
  resultType: DecisionRunResult;
  strategyId: string;
  decision: string;
  strategyName: string;
  organizationId: string;
  modules: IDecisionResultCompiledModuleInfo[];
  inputs: Record<string, VariableValue>;
  outputs: Record<string, VariableValue>;
  passed: boolean;
  errorMessages: string[];
  declineReasons: string[];
  executionTime: number;
  testing?: boolean;
  createdAt: Date;
}

interface IRunStrategyResult {
  decisionId: string;
  resultsCount: number;
  statusCode: number;
  statusMessage: string;
  decisionClientId?: string;
  applicationId?: string;
  requestDate: Date;
  responseDate: Date;
  results?: IDecisionResult[];
}

interface IStrategyApi {
  run(body: IStrategyRunBody): Promise<IRunStrategyResult>;
}

export default class StrategyApi extends BaseApi implements IStrategyApi {
  public async run(body: IStrategyRunBody): Promise<IRunStrategyResult> {
    return this.fetch(StrategyEndpoints.Run, {
      method: FetchMethodType.POST,
      body,
    });
  }
}
