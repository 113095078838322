import React from 'react';
import clsx from 'clsx';
import Title from 'components/Title';
import InfoTooltip from 'components/InfoTooltip';
import ManageButton, { IManageButtonMenu } from 'components/ManageButton';

import styles from './TitleWithInfoTooltip.module.scss';
import { TooltipId } from 'enums/TooltipId';

interface ITitleWithInfoTooltip {
  title: string;
  id: TooltipId;
  tooltip?: string | null;
  manageMenu?: IManageButtonMenu[];
  className?: string;
  titleClassName?: string;
}

const TitleWithInfoTooltip = ({
  title,
  tooltip,
  manageMenu,
  className,
  id,
  titleClassName,
}: ITitleWithInfoTooltip) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.titleContainer}>
        <Title className={clsx(styles.title, titleClassName)} variant="h3">
          {title}
        </Title>
        {tooltip && (
          <div className={styles.tooltip}>
            <InfoTooltip tooltip={tooltip} id={id} />
          </div>
        )}
      </div>
      {manageMenu && <ManageButton menu={manageMenu} />}
    </div>
  );
};

export default TitleWithInfoTooltip;
