import React, { FC, useState } from 'react';
import { useAppSelector } from 'hooks/reduxHooks';
import { OfferCategoryType } from 'pages/ApplicationProcess/OffersPage/offersUtils';
import Collapsible from 'react-collapsible';
import OfferCategory from 'pages/ApplicationProcess/OffersPage/OffersComponents/OffersCategory';
import OffersComponentByDeviceWidth from 'pages/ApplicationProcess/OffersPage/OffersComponents/OffersComponentByDeviceWidth';
import { OffersCategoriesTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { useTranslation } from 'react-i18next';

import styles from './OffersCategories.module.scss';
import { getBorrowerApplicationData, getOffersData } from 'handlers/selectors';
import { matchApplicationLabelByEnvironment } from 'utils/applicationLabelsHelpers';
import { ApplicationLabelName } from 'enums/ApplicationLabelId';

interface IOfferCategory {
  categoryName: OfferCategoryType;
  translationKeyName: OffersCategoriesTranslationKeys;
}

const offerCategories: IOfferCategory[] = [
  { categoryName: 'lowPayments', translationKeyName: OffersCategoriesTranslationKeys.LowPayments },
  {
    categoryName: 'lowFixedRate',
    translationKeyName: OffersCategoriesTranslationKeys.LowFixedRate,
  },
  { categoryName: 'promotional', translationKeyName: OffersCategoriesTranslationKeys.Promotional },
];

const OfferCategories: FC = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.OffersPage);
  const { offers } = useAppSelector(getOffersData);
  const { selectedApplication } = useAppSelector(getBorrowerApplicationData);
  const [categoriesOpened, setCategoriesOpened] = useState<{ [key in OfferCategoryType]: boolean }>(
    {
      lowPayments: true,
      lowFixedRate: false,
      promotional: false,
    },
  );

  const handleActiveCategory = (categoryName: OfferCategoryType) => {
    setCategoriesOpened((prev) => ({
      ...prev,
      [categoryName]: !prev[categoryName],
    }));
  };

  const checkIsWebLead = () => {
    return selectedApplication?.labels.some(label => matchApplicationLabelByEnvironment(label.id, ApplicationLabelName.WebLeadApplication))
  }

  return (
    <>
      {offerCategories.map(({ categoryName, translationKeyName }, id) => (
        <Collapsible
          classParentString={styles.offersCategoriesWrapper}
          key={id}
          trigger={
            <OfferCategory
              categoryName={categoryName}
              setActive={handleActiveCategory}
              isActive={categoriesOpened[categoryName]}
            >
              {translate(translationKeyName)}
            </OfferCategory>
          }
          transitionTime={500}
          transitionCloseTime={500}
          easing="ease-in-out"
          open={categoriesOpened[categoryName]}
          overflowWhenOpen="visible"
        >
          <OffersComponentByDeviceWidth offers={offers!} categoryName={categoryName} isWebLead={checkIsWebLead()} />
        </Collapsible>
      ))}
    </>
  );
};

export default OfferCategories;
