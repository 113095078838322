import { AddressValueKey, IAddressValue } from 'api/Types';
import { IUpdateApplicationParams } from 'api/digifi/los/ApplicationsApi';
import { ApplicationLabelName } from 'enums/ApplicationLabelId';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { ApplicationVariable } from 'enums/ApplicationVariable';
import { BorrowerVariable } from 'enums/BorrowerVariable';
import { CoborrowerVariable } from 'enums/CoborrowerVariable';
import { DeclineReason } from 'enums/DeclineReason';
import { FinancialInformationVariable } from 'enums/FinancialInformationVariable';
import { FormikErrors } from 'formik';
import { IFinancialInformation } from 'handlers/portal/financialInformation';
import { getApplicationLabelIdByEnvironment } from 'utils/applicationLabelsHelpers';
import { getApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';
import { DateTimeFormat, formatDate } from 'utils/profund/dateFormat';
import { setItemToSessionStorage } from 'utils/profund/sessionStorage';

const isIncorrect = (errors: FormikErrors<IFinancialInformation>, field: string): boolean => {
  return field in errors;
};

const getMapWithCorrectFields = (
  data: IFinancialInformation,
  errors: FormikErrors<IFinancialInformation>,
) => {
  return Object.keys(data).reduce((acc, item: string) => {
    const value = data[item as keyof IFinancialInformation];

    return isIncorrect(errors, item) || !value ? acc : { ...acc, [item]: value };
  }, {} as IFinancialInformation);
};

const formatSSNToLosType = (ssn?: string) => {
  return ssn?.replaceAll('-', '') ?? null;
};

export const getApplicationDataToUpdate = (
  values: IFinancialInformation,
  needCoborrowerData: boolean,
  errors: FormikErrors<IFinancialInformation>,
  needCompletionData?: boolean,
): IUpdateApplicationParams['variables'] => {
  const currentDate = formatDate(undefined, DateTimeFormat.Short);

  const validValues = getMapWithCorrectFields(values, errors);

  const coborrowerData = {
    [CoborrowerVariable.FirstName]: validValues[CoborrowerVariable.FirstName],
    [CoborrowerVariable.LastName]: validValues[CoborrowerVariable.LastName],
    [CoborrowerVariable.DateOfBirth]: validValues[CoborrowerVariable.DateOfBirth],
    [CoborrowerVariable.ApartmentOrSuite]: validValues[CoborrowerVariable.ApartmentOrSuite],
    [CoborrowerVariable.Email]: validValues[CoborrowerVariable.Email] || null,
    [CoborrowerVariable.PropertyStatus]: validValues[CoborrowerVariable.PropertyStatus],
    [CoborrowerVariable.Phone]: validValues[CoborrowerVariable.Phone] || null,
    [CoborrowerVariable.SSN]: formatSSNToLosType(validValues[CoborrowerVariable.SSN]),
    [CoborrowerVariable.HomeAddress]: {
      [AddressValueKey.City]: validValues[CoborrowerVariable.City],
      [AddressValueKey.StateOrProvince]: validValues[CoborrowerVariable.State],
      [AddressValueKey.ZipOrPostalCode]: validValues[CoborrowerVariable.ZipCode],
      [AddressValueKey.StreetName]: validValues[CoborrowerVariable.StreetName],
      [AddressValueKey.StreetNumber]: validValues[CoborrowerVariable.StreetNumber],
    } as IAddressValue,
  };

  const resetCoborrowerData = {
    [CoborrowerVariable.FirstName]: null,
    [CoborrowerVariable.LastName]: null,
    [CoborrowerVariable.DateOfBirth]: null,
    [CoborrowerVariable.ApartmentOrSuite]: null,
    [CoborrowerVariable.Email]: null,
    [CoborrowerVariable.PropertyStatus]: null,
    [CoborrowerVariable.Phone]: null,
    [CoborrowerVariable.SSN]: null,
    [CoborrowerVariable.HomeAddress]: null,
  };

  const completetionData = {
    [ApplicationVariable.FrcaConsentOptionComplete]: true,
    [ApplicationVariable.TcpaConsentOptionComplete]: true,
    [ApplicationVariable.TcpaConsentOptionCompleteDate]: currentDate,
    [ApplicationVariable.FrcaConsentOptionCompleteDate]: currentDate,
  };

  return {
    [BorrowerVariable.DateOfBirth]: validValues[FinancialInformationVariable.DateOfBirth],
    [BorrowerVariable.SSN]: formatSSNToLosType(
      validValues[FinancialInformationVariable.SocialSecurityNumber],
    ),
    [ApplicationVariable.PropertyStatus]: validValues[FinancialInformationVariable.PropertyStatus],
    [ApplicationVariable.EmploymentStatus]:
      validValues[FinancialInformationVariable.EmploymentStatus],
    [ApplicationVariable.EmploymentPayFrequency]:
      validValues[FinancialInformationVariable.PayFrequency],
    [ApplicationVariable.HighestEducationLevel]:
      validValues[FinancialInformationVariable.HighestEducationLevel],
    ...(needCompletionData ? completetionData : {}),
    ...(needCoborrowerData ? coborrowerData : resetCoborrowerData),
  };
};

export const getRejectedApplicationDataToUpdate = () => {
  return {
    statusId: getApplicationStatusIdByEnvironment(ApplicationStatusName.Rejected),
    declineReasons: [DeclineReason.EligibilityCheckFailed],
    labelsIds: [getApplicationLabelIdByEnvironment(ApplicationLabelName.EligibilityCheckFailed)],
  };
};

export const handleSetDataToSessionStorage = (data: IFinancialInformation) => {
  Object.keys(data).forEach((key) => {
    if (data[key as keyof IFinancialInformation]) {
      setItemToSessionStorage(key, data[key as keyof IFinancialInformation] as string);
    }
  });
};
