import { CoborrowerVariable } from 'enums/CoborrowerVariable';
import { InputPrefixAndSuffixType } from 'enums/InputPrefixAndSuffixType';
import React, { RefObject, useEffect, useMemo } from 'react';
import { InfoCircleIcon, PlusIcon } from 'static/images';
import AddressInputs from 'components/AddressInputs';
import CollapsiblePanel from 'components/Collapsible';
import DateInput from 'components/DateInput';
import NumberInput from 'components/NumberInput';
import TextInput from 'components/TextInput';
import styles from './CoborrowerInfo.module.scss';
import { IFinancialInformation, removeCoborrower } from 'handlers/portal/financialInformation';
import { Formik, FormikProps } from 'formik';
import { useCoborrowerFormValidationSchema } from 'components/CoborrowerInfo/validationSchema';
import { useTranslation } from 'react-i18next';
import {
  InputLabelsTranslationKeys,
  FinancialInformationPageTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import { useAppDispatch } from 'hooks/reduxHooks';
import { removeItemFromSessionStorage } from 'utils/profund/sessionStorage';
import PhoneNumberInput from 'components/PhoneNumberInput';
import SelectInput from 'components/SelectInput';
import { useTranslatedDropdownOptions } from 'components/BorrowerFinancialInfo/constants';
import { getSelectedOption } from 'components/BorrowerFinancialInfo/BorrowerFinancialInfo';
import { TEXT_INPUT_MAX_LENGTH } from 'pages/ApplicationProcess/PersonalInformation/PersonalInformationForm';
import { TABLET_WIDTH } from 'pages/ApplicationProcess/OffersPage/offersUtils';
import useWindowSize from 'hooks/useWindowSize';
import { isWaterCressEnabled } from 'utils/isWatercressEnabled';

const ANNUAL_INCOME_MAX_LENGTH = 12;

interface ICoborrowerInfo {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  coborrowerData: Partial<IFinancialInformation>;
  formRef: RefObject<FormikProps<Partial<IFinancialInformation>>>;
  setIsFormValid: (value: boolean) => void;
}

const CoborrowerInfo = ({
  isOpen,
  setOpen,
  coborrowerData,
  formRef,
  setIsFormValid,
}: ICoborrowerInfo) => {
  const dispatch = useAppDispatch();

  const { width = 0 } = useWindowSize();

  const isMobile = useMemo(() => {
    return width < TABLET_WIDTH;
  }, [width]);

  const { t: translate } = useTranslation([
    TranslationNameSpaces.InputLabels,
    TranslationNameSpaces.FinancialInformationPage,
    TranslationNameSpaces.AuthorizationProcess,
  ]);

  const { PROPERTY_INPUT_OPTIONS } = useTranslatedDropdownOptions();
  const coborrowerFormValidationSchema = useCoborrowerFormValidationSchema();

  const handleRemove = () => {
    setOpen(false);
    removeItemFromSessionStorage(CoborrowerVariable.AnnualIncome);
    dispatch(removeCoborrower());
  };

  const headerContent = isOpen ? (
    <div className={styles.headerControlsActive}>
      <div className={styles.coborrowerTitle}>
        {translate(FinancialInformationPageTranslationKeys.Coborrower, {
          ns: TranslationNameSpaces.FinancialInformationPage,
        })}
      </div>
      <div onClick={handleRemove} className={styles.removeButton}>
        {translate(FinancialInformationPageTranslationKeys.Remove, {
          ns: TranslationNameSpaces.FinancialInformationPage,
        })}
      </div>
    </div>
  ) : (
    <div className={styles.headerControls} onClick={() => setOpen(!isOpen)}>
      <PlusIcon />{' '}
      <div className={styles.addCoborrower}>
        {translate(FinancialInformationPageTranslationKeys.AddCoborrower, {
          ns: TranslationNameSpaces.FinancialInformationPage,
        })}
      </div>
    </div>
  );

  return (
    <CollapsiblePanel
      isActive={isOpen}
      headerContent={headerContent}
      bodyContent={
        <Formik
          initialValues={coborrowerData}
          onSubmit={() => {}}
          validationSchema={coborrowerFormValidationSchema}
          validateOnBlur
          validateOnChange
          validateOnMount
          enableReinitialize
          innerRef={formRef}
        >
          {({ values, handleChange, handleBlur, setFieldValue, touched, errors, isValid }) => {
            const getErrorMessage = (
              name: Exclude<CoborrowerVariable, CoborrowerVariable.HomeAddress>,
            ) => (!!touched[name] && errors[name] ? errors[name] : undefined);

            useEffect(() => {
              setIsFormValid(isValid);
            }, [isValid]);

            return (
              <>
                <div className={styles.content}>
                  <TextInput
                    label={translate(InputLabelsTranslationKeys.FirstName, {
                      ns: TranslationNameSpaces.InputLabels,
                    })}
                    name={CoborrowerVariable.FirstName}
                    error={getErrorMessage(CoborrowerVariable.FirstName)}
                    onChange={handleChange}
                    value={values[CoborrowerVariable.FirstName] as string}
                    onBlur={handleBlur}
                    maxLength={TEXT_INPUT_MAX_LENGTH}
                  />
                  <TextInput
                    label={translate(InputLabelsTranslationKeys.LastName, {
                      ns: TranslationNameSpaces.InputLabels,
                    })}
                    name={CoborrowerVariable.LastName}
                    error={getErrorMessage(CoborrowerVariable.LastName)}
                    onChange={handleChange}
                    value={values[CoborrowerVariable.LastName] as string}
                    onBlur={handleBlur}
                    maxLength={TEXT_INPUT_MAX_LENGTH}
                  />
                  <AddressInputs
                    value={{
                      streetName: values[CoborrowerVariable.StreetName] as string,
                      streetNumber: values[CoborrowerVariable.StreetNumber] as string,
                      apartmentOrSuite: values[CoborrowerVariable.ApartmentOrSuite] as string,
                      city: values[CoborrowerVariable.City] as string,
                      state: values[CoborrowerVariable.State] as string,
                      zipCode: values[CoborrowerVariable.ZipCode] as string,
                    }}
                    variable={{
                      streetName: CoborrowerVariable.StreetName,
                      streetNumber: CoborrowerVariable.StreetNumber,
                      apartmentOrSuite: CoborrowerVariable.ApartmentOrSuite,
                      city: CoborrowerVariable.City,
                      state: CoborrowerVariable.State,
                      zipCode: CoborrowerVariable.ZipCode,
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    getErrorMessage={getErrorMessage}
                    stateAndZipInputsCombined={!isMobile}
                  />
                  {isWaterCressEnabled && (
                    <TextInput
                      label={translate(InputLabelsTranslationKeys.Email, {
                        ns: TranslationNameSpaces.InputLabels,
                      })}
                      name={CoborrowerVariable.Email}
                      error={getErrorMessage(CoborrowerVariable.Email)}
                      onChange={handleChange}
                      value={values[CoborrowerVariable.Email]}
                      onBlur={handleBlur}
                      maxLength={TEXT_INPUT_MAX_LENGTH}
                    />
                  )}
                  {isWaterCressEnabled && (
                    <PhoneNumberInput
                      value={values[CoborrowerVariable.Phone]!}
                      setValue={(value: string) => {
                        setFieldValue(CoborrowerVariable.Phone, value);
                      }}
                      wrapperClassName={styles.pageInput}
                      name={CoborrowerVariable.Phone}
                      onBlur={handleBlur}
                      label={
                        translate(InputLabelsTranslationKeys.MobilePhoneNumber, {
                          ns: TranslationNameSpaces.InputLabels,
                        }) as string
                      }
                      error={getErrorMessage(CoborrowerVariable.Phone)}
                      tooltipText={
                        translate(FinancialInformationPageTranslationKeys.MobilePhoneTooltipText, {
                          ns: TranslationNameSpaces.FinancialInformationPage,
                        })!
                      }
                    />
                  )}
                  <NumberInput
                    label={translate(InputLabelsTranslationKeys.AnnualIncome, {
                      ns: TranslationNameSpaces.InputLabels,
                    })}
                    name={CoborrowerVariable.AnnualIncome}
                    prefix={InputPrefixAndSuffixType.Monetary}
                    error={getErrorMessage(CoborrowerVariable.AnnualIncome)}
                    onChange={handleChange}
                    value={values[CoborrowerVariable.AnnualIncome]}
                    thousandSeparator
                    allowNegative={false}
                    onBlur={handleBlur}
                    decimalScale={0}
                    maxLength={ANNUAL_INCOME_MAX_LENGTH}
                    tooltipText={
                      translate(
                        FinancialInformationPageTranslationKeys.CoborrowerAnnualIncomeTooltipText,
                        {
                          ns: TranslationNameSpaces.FinancialInformationPage,
                        },
                      )!
                    }
                    inputIcon={<InfoCircleIcon />}
                  />
                  {isWaterCressEnabled && (
                    <NumberInput
                      label={translate(InputLabelsTranslationKeys.SSN, {
                        ns: TranslationNameSpaces.InputLabels,
                      })}
                      name={CoborrowerVariable.SSN}
                      onChange={handleChange}
                      value={values[CoborrowerVariable.SSN]}
                      error={getErrorMessage(CoborrowerVariable.SSN)}
                      allowNegative={false}
                      onBlur={handleBlur}
                      maxLength={11}
                      format="###-##-####"
                      mask=""
                      tooltipText={
                        translate(FinancialInformationPageTranslationKeys.CoownerSSNTooltipText, {
                          ns: TranslationNameSpaces.FinancialInformationPage,
                        })!
                      }
                      inputIcon={<InfoCircleIcon />}
                    />
                  )}
                  <DateInput
                    label={translate(InputLabelsTranslationKeys.DateOfBirth, {
                      ns: TranslationNameSpaces.InputLabels,
                    })}
                    name={CoborrowerVariable.DateOfBirth}
                    error={getErrorMessage(CoborrowerVariable.DateOfBirth)}
                    onChange={handleChange}
                    value={values[CoborrowerVariable.DateOfBirth]}
                    onBlur={handleBlur}
                  />

                  {isWaterCressEnabled && (
                    <SelectInput
                      selectedOption={getSelectedOption(
                        PROPERTY_INPUT_OPTIONS,
                        values[CoborrowerVariable.PropertyStatus] as string,
                      )}
                      options={PROPERTY_INPUT_OPTIONS}
                      placeholder={translate(InputLabelsTranslationKeys.PropertyStatus, {
                        ns: TranslationNameSpaces.InputLabels,
                      })}
                      name={CoborrowerVariable.PropertyStatus}
                      onValuePicked={(data) => {
                        setFieldValue(CoborrowerVariable.PropertyStatus, data);
                      }}
                      errorMessage={getErrorMessage(CoborrowerVariable.PropertyStatus)}
                    />
                  )}
                </div>
              </>
            );
          }}
        </Formik>
      }
    />
  );
};

export default React.memo(CoborrowerInfo);
