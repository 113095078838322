import { createAsyncThunk } from '@reduxjs/toolkit';
import BankVerificationApi from 'api/digifi/portal/BankVerificationApi';
import { BankVerificationActionType } from 'enums/BankVerificationActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createGetBorrowerLinkTokenThunk = (api: BankVerificationApi) =>
  createAsyncThunk(
    BankVerificationActionType.GetBorrowerLinkToken,
    async (data: { applicationId: string; isUpdateMode?: boolean }, thunkApi) => {
      try {
        return await api.getBorrowerLinkToken(data.applicationId, data.isUpdateMode);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
