import React from 'react';
import styles from './BankVerificationBankConnectionDashboard.module.scss';
import BankConnectionCardWrapper from 'components/BankConnectionCardWrapper';
import { BankConnectionType } from 'components/BankConnectionCardWrapper/BankConnectionCardWrapper';
import Button, { ButtonSize } from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import {
  BankVerificationPageTranslationKeys,
  ButtonsTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import { BankVerificationProcessSteps } from 'pages/ApplicationProcess/BankVerificationProcess/BankVerificationProcess';

interface IBankVerificationBankConnectionDashboardProps {
  hasPlaidError: boolean;
  onSetStep: (step: BankVerificationProcessSteps) => void;
  hasDwollaMicroDepositsError: boolean;
}

const BankVerificationBankConnectionDashboard = ({
  hasPlaidError,
  onSetStep,
  hasDwollaMicroDepositsError,
}: IBankVerificationBankConnectionDashboardProps) => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.Buttons,
    TranslationNameSpaces.BankVerificationPage,
  ]);

  return (
    <div className={styles.container}>
      <BankConnectionCardWrapper
        bankConnectionType={BankConnectionType.Instant}
        additionalText={
          translate(BankVerificationPageTranslationKeys.Option1, {
            ns: TranslationNameSpaces.BankVerificationPage,
          }) as string
        }
      >
        <Button
          size={ButtonSize.Middle}
          className={styles.button}
          onClick={() => onSetStep(BankVerificationProcessSteps.PlaidBankVerification)}
        >
          {translate(
            hasPlaidError ? ButtonsTranslationKeys.TryAgain : ButtonsTranslationKeys.Connect,
            {
              ns: TranslationNameSpaces.Buttons,
            },
          )}
        </Button>
      </BankConnectionCardWrapper>
      <div className={styles.divider}>
        {translate(BankVerificationPageTranslationKeys.Or, {
          ns: TranslationNameSpaces.BankVerificationPage,
        })}
      </div>
      <BankConnectionCardWrapper
        bankConnectionType={BankConnectionType.Standard}
        additionalText={
          translate(BankVerificationPageTranslationKeys.Option2, {
            ns: TranslationNameSpaces.BankVerificationPage,
          }) as string
        }
      >
        <Button
          size={ButtonSize.Middle}
          className={styles.button}
          onClick={() => onSetStep(BankVerificationProcessSteps.DwollaBankVerification)}
        >
          {translate(
            hasDwollaMicroDepositsError
              ? ButtonsTranslationKeys.TryAgain
              : ButtonsTranslationKeys.Connect,
            {
              ns: TranslationNameSpaces.Buttons,
            },
          )}
        </Button>
      </BankConnectionCardWrapper>
    </div>
  );
};

export default BankVerificationBankConnectionDashboard;
