import React, { FC } from 'react';
import OfferCard from 'pages/ApplicationProcess/OffersPage/OffersComponents/OfferCard';
import Slider, { Settings } from 'react-slick';
import { IOffer } from 'handlers/portal/offersSlice';

import 'slick-carousel/slick/slick.scss';
import './MobileOffer.scss';

const settings: Settings = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '34px',
  arrows: false,
  infinite: false,
  swipeToSlide: true,
};

const MobileOffers: FC<{ 
  offers: IOffer[], 
  isWebLead?: boolean,
 }> = ({ 
  offers, 
  isWebLead,
 }) => {
  return (
    <Slider {...settings}>
      {offers.map((offer, id) => (
        <OfferCard key={id} offer={offer} isWebLead={isWebLead} />
      ))}
    </Slider>
  );
};

export default MobileOffers;
