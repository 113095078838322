import { createAsyncThunk } from '@reduxjs/toolkit';
import PaymentProcessorApi, {
  IVerifyIntermediaryDwollaMicroDepositsBody,
} from 'api/digifi/portal/PaymentProcessorApi';
import { BankVerificationActionType } from 'enums/BankVerificationActionType';

export const createVerifyIntermediaryDwollaMicroDepositsThunk = (api: PaymentProcessorApi) =>
  createAsyncThunk(
    BankVerificationActionType.VerifyIntermediaryDwollaMicroDeposits,
    async (body: IVerifyIntermediaryDwollaMicroDepositsBody) => {
      return api.verifyIntermediaryDwollaMicroDeposits(body);
    },
  );
