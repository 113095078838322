import { FormikErrors } from 'formik';
import { BorrowerVariable } from 'enums/BorrowerVariable';
import { PersonalInformationVariable } from 'enums/PersonalInformation';
import { AddressValueKey, IAddressValue } from 'api/Types';
import { IPersonalInformation } from 'handlers/portal/personalInformation';

export const getBorrowerData = (
  values: IPersonalInformation,
  errors: FormikErrors<IPersonalInformation>,
  validate: boolean,
) => {
  const getRequiredValue = (variableName: PersonalInformationVariable) => {
    if (validate) {
      return errors[variableName] ? null : values[variableName];
    }

    return values[variableName];
  };

  return {
    [BorrowerVariable.FirstName]: getRequiredValue(PersonalInformationVariable.FirstName),
    [BorrowerVariable.MiddleName]: values[PersonalInformationVariable.MiddleName] || null,
    [BorrowerVariable.LastName]: getRequiredValue(PersonalInformationVariable.LastName),
    [BorrowerVariable.HomeAddress]: {
      [AddressValueKey.StreetName]: getRequiredValue(PersonalInformationVariable.StreetName),
      [AddressValueKey.StreetNumber]: getRequiredValue(PersonalInformationVariable.StreetNumber),
      [AddressValueKey.City]: getRequiredValue(PersonalInformationVariable.City),
      [AddressValueKey.StateOrProvince]: getRequiredValue(PersonalInformationVariable.State),
      [AddressValueKey.ZipOrPostalCode]: getRequiredValue(PersonalInformationVariable.ZipCode),
    } as IAddressValue,
    [BorrowerVariable.ApartmentOrSuite]: getRequiredValue(
      PersonalInformationVariable.ApartmentOrSuite,
    ),
    [BorrowerVariable.Email]: getRequiredValue(PersonalInformationVariable.Email),
  };
};
