import BankVerificationBankConnectionDashboard from 'components/BankVerificationBankConnectionDashboard';
import BankVerificationSelectPageMessage from 'components/BankVerificationSelectPageMessage';
import DisclosuresBox from 'components/DisclosuresBox';
import ParticipatingLenderInfo from 'components/InformationBox/ParticipatingLenderInfo';
import Menu from 'components/Menu';
import PageLayout, { LayoutSize } from 'components/PageLayout/PageLayout';
import {
  ButtonsTranslationKeys,
  MenuTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import React from 'react';
import styles from './SelectVerification.module.scss';
import { BankVerificationProcessSteps } from 'pages/ApplicationProcess/BankVerificationProcess/BankVerificationProcess';
import { useTranslation } from 'react-i18next';
import FormButtons from 'components/FormButtons';
import AdvertiserDisclosure from 'components/AdvertiserDisclosure';

interface ISelectVerificationProps {
  hasDwollaMicroDepositsError: boolean;
  hasPlaidError: boolean;
  onSetStep: (step: BankVerificationProcessSteps) => void;
  onShowOffersWithoutEven: () => void;
  canShowOffersWithoutEven: boolean;
  fundedByEven: boolean;
  onConnectLaterClick: () => void;
}

const SelectVerification = ({
  hasDwollaMicroDepositsError,
  hasPlaidError,
  onSetStep,
  onShowOffersWithoutEven,
  canShowOffersWithoutEven,
  fundedByEven,
  onConnectLaterClick,
}: ISelectVerificationProps) => {
  const { t: translate } = useTranslation(TranslationNameSpaces.Buttons);

  return (
    <div>
      <Menu activeMenuItem={MenuTranslationKeys.Applications} />
      <PageLayout layoutSize={LayoutSize.Big}>
        <AdvertiserDisclosure
          onBackClick={() => onSetStep(BankVerificationProcessSteps.WhatNext)}
        />
        <div className={styles.mainContent}>
          <BankVerificationSelectPageMessage />
          <BankVerificationBankConnectionDashboard
            hasPlaidError={hasPlaidError}
            onSetStep={onSetStep}
            hasDwollaMicroDepositsError={hasDwollaMicroDepositsError}
          />
        </div>
        {hasDwollaMicroDepositsError ? (
          <FormButtons
            primaryTitle={translate(ButtonsTranslationKeys.SeeOffers)}
            secondaryTitle={translate(ButtonsTranslationKeys.ConnectLater)}
            onPrimaryClick={onShowOffersWithoutEven}
            onSecondaryClick={onConnectLaterClick}
            showPrimaryButton={!!canShowOffersWithoutEven}
            showSecondaryButton={!fundedByEven}
          />
        ) : null}
        <DisclosuresBox />
        <ParticipatingLenderInfo />
      </PageLayout>
    </div>
  );
};

export default SelectVerification;
