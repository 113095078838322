import { createAsyncThunk } from '@reduxjs/toolkit';
import ApplicationProcessApi, {
  ISavePlaidEventToVariableBody,
} from 'api/digifi/portal/ApplicationProcessApi';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';
import { ApplicationActionType } from 'enums/ApplicationActionType';

export const createSavePlaidEventToVariableThunk = (api: ApplicationProcessApi) =>
  createAsyncThunk(
    ApplicationActionType.SavePlaidEventToVariable,
    async (body: ISavePlaidEventToVariableBody, thunkApi) => {
      try {
        return await api.savePlaidEventToVariable(body);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
