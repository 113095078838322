import { combineReducers } from '@reduxjs/toolkit';
import authReducer from 'handlers/authSlice';
import borrowersReducer from 'handlers/borrowersSlice';
import { IReduxState } from 'types/ReduxState';

import authorizationReducer from 'handlers/portal/authorizationSlice';
import verificationReducer from 'handlers/portal/verificationSlice';
import applicationProcessReducer from './portal/applicationProcessSlice';
import personalInformationReducer from './portal/personalInformation';
import financialInformationReducer from './portal/financialInformation';
import offersInformationReducer from './portal/offersSlice';
import loadingReducer from './portal/loadingSlice';
import borrowerApplicationsReducer from './portal/applicationsSlice';

const appReducer = combineReducers<IReduxState>({
  auth: authReducer,
  borrowers: borrowersReducer,

  authorization: authorizationReducer,
  verification: verificationReducer,
  applicationProcess: applicationProcessReducer,
  personalInformation: personalInformationReducer,
  financialInformation: financialInformationReducer,
  offersInformation: offersInformationReducer,
  loading: loadingReducer,
  borrowerApplications: borrowerApplicationsReducer,
});

export default appReducer;
