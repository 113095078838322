import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApplicationsApi } from 'api/digifi/los/ApplicationsApi';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';
import { ApplicationsActionType } from 'enums/ApplicationsActionType';

export const createGetApplicationByIdThunk = (api: IApplicationsApi) =>
  createAsyncThunk(ApplicationsActionType.GetById, async (applicationId: string, thunkApi) => {
    try {
      return await api.findById(applicationId);
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
