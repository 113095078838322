import { AuthorizationStep, setAuthorizationStep } from 'handlers/portal/authorizationSlice';
import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

import ApplicationExpiredPage from './ApplicationExpiredPage';
import Loading from 'components/Loading';
import SignInPage from './SignInPage';
import Stepper from 'components/Stepper';
import UnableToVerifyPage from './UnableToVerifyPage';
import VerificationCodePage from './VerificationCodePage';
import { getAuthorizationInformation } from 'thunks';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useQueryParam from 'hooks/useQueryParam';
import {
  getAuthorizationInformationData,
  getLoadingFields,
  getVerificationData,
} from 'handlers/selectors';
import NotFoundPage from 'pages/NotFoundPage';
import { getApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { AppRoutes } from 'routes/AppRoutes';
import styles from './AuthorizationProcess.module.scss';

const getRedirectUrl = (from: Location, applicationId?: string | null) => {
  if (from.pathname === AppRoutes.Authorization || from.pathname === '/') {
    return applicationId
      ? `${AppRoutes.ApplicationProcess}/${applicationId}`
      : AppRoutes.Applications;
  }

  return from.pathname ? `${from.pathname}${from.search || ''}` : AppRoutes.Applications;
};

const componentByAuthorizationStep = {
  [AuthorizationStep.SignIn]: <SignInPage />,
  [AuthorizationStep.VerifyCode]: <VerificationCodePage />,
  [AuthorizationStep.UnableToVerifyPage]: <UnableToVerifyPage />,
  [AuthorizationStep.ExpiredApplicationPage]: <ApplicationExpiredPage />,
};

const AuthorizationProcess: FC = () => {
  const {
    authorizationStep,
    authorizationInformation: { applicationExpired, redirectApplicationId, applicationNotFound },
  } = useAppSelector(getAuthorizationInformationData);
  const { isVerified } = useAppSelector(getVerificationData);
  const { getAuthorizationInformationLoading: isLoading } = useAppSelector(getLoadingFields);
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { from } = (location.state as { from: Location }) || { from: { pathname: '/' } };
  const queryApplicationId = useQueryParam('applicationId');
  const applicationId = redirectApplicationId || queryApplicationId;

  useEffect(() => {
    if (applicationId && authorizationStep === AuthorizationStep.SignIn) {
      dispatch(getAuthorizationInformation(applicationId));
    }
  }, [authorizationStep, applicationId]);

  useEffect(() => {
    if (applicationExpired) {
      dispatch(setAuthorizationStep(AuthorizationStep.ExpiredApplicationPage));
    }
  }, [applicationExpired]);

  useEffect(() => {
    if (applicationId) {
      navigate(`?applicationId=${applicationId}`);
    }
  }, [applicationId]);

  if (isVerified) {
    return <Navigate to={getRedirectUrl(from, applicationId)} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  const Component = componentByAuthorizationStep[authorizationStep];

  if (!Component) {
    return null;
  }

  if (applicationNotFound) {
    return <NotFoundPage />;
  }

  return (
    <>
      {applicationId && !applicationExpired && (
        <Stepper
          currentStep={getApplicationStatusIdByEnvironment(ApplicationStatusName.NewApplication)}
        />
      )}
      <div className={styles.pageWrapper}>{Component}</div>
    </>
  );
};

export default AuthorizationProcess;
