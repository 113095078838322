import { EnvironmentVariable } from 'enums/EnvironmentVariable';
import BaseSecret from './BaseSecret';

class GoogleApiKeySecret extends BaseSecret {
  constructor() {
    super(EnvironmentVariable.REACT_APP_GOOGLE_API_KEY);
  }
}

export default GoogleApiKeySecret;
