import { ApplicationVariable } from 'enums/ApplicationVariable';
import { FinalDesignationType } from 'enums/FinalDesignationType';
import { OfferProviderNames } from 'enums/OfferProviderNames';
import { IOffer } from 'handlers/portal/offersSlice';
import { VariableValue } from 'types/VariableTypes';
import { DateTimeFormat, formatDate } from './profund/dateFormat';

export const getVariablesToUpdateAfterOfferConfirmation = (
  offer: IOffer,
  agreedJobAmount: number | null,
): Record<string, VariableValue> => {
  const {
    originatorName,
    apr,
    partnerLogoUrl,
    disclaimer,
    id,
    link,
    monthlyPayment,
    recommendationScore,
    termLength,
    termsUnit,
    loanAmount,
    preApproved,
    preQualified,
    offerProvider,
    termDescription,
    aprDescription,
    monthlyPaymentDescription,
    productName,
    interestRate,
    discountPercent,
    disbursementCount,
    expirationDate,
    creditLimit,
    applicationName,
    upgradePlanDescription,
    upgradePlanCategory,
    upgradePlanNumber,
    upgradeDiscount,
    upgradePromotion,
  } = offer;

  const getAgreedJobAmount = () => {
    return agreedJobAmount && loanAmount < agreedJobAmount
      ? { [ApplicationVariable.AgreedJobAmount]: loanAmount }
      : {};
  };

  const variables: Record<string, VariableValue> = {
    [ApplicationVariable.FinalOfferProviderName]: offerProvider,
    [ApplicationVariable.FinalOfferLenderName]: originatorName,
    [ApplicationVariable.FinalOfferId]: id,
    [ApplicationVariable.FinalOfferAmount]: loanAmount,
    [ApplicationVariable.LoanAmount]: loanAmount,
    [ApplicationVariable.FinalFinancialServicesPartnerLogo]: partnerLogoUrl,
    [ApplicationVariable.FinalOfferDesignation]: getFinalOfferDesignation({
      preApproved,
      preQualified,
    }),
    [ApplicationVariable.FinalOfferMonthlyPaymentAmount]: monthlyPayment,
    [ApplicationVariable.FinalOfferTermLength]: termLength,
    [ApplicationVariable.FinalOfferTermUnit]: termsUnit,
    [ApplicationVariable.FinalOfferAprAmount]: apr / 100,
    [ApplicationVariable.FinalOfferLink]: link,
    [ApplicationVariable.FinalOfferDisclaimer]: disclaimer,
    [ApplicationVariable.FinalOfferRecommendationScore]: recommendationScore,
    [ApplicationVariable.FinalOfferMonthlyPaymentDescription]: monthlyPaymentDescription,
    [ApplicationVariable.FinalOfferTermDescription]: termDescription,
    [ApplicationVariable.FinalOfferAprDescription]: aprDescription,
    [ApplicationVariable.FinalOfferProductName]: productName,
    [ApplicationVariable.FinalOfferInterestRate]: interestRate && interestRate / 100,
    [ApplicationVariable.FinalOfferDiscountPercent]: discountPercent && discountPercent / 100,
    [ApplicationVariable.FinalOfferDisbursementCount]: disbursementCount,
    [ApplicationVariable.FinalOfferExpirationDate]: expirationDate,
    [ApplicationVariable.FinalOfferCreditLimit]: creditLimit,
    [ApplicationVariable.FinalOfferApplicationName]: applicationName,
    [ApplicationVariable.FinalOfferUpgradePlanDescription]: upgradePlanDescription,
    [ApplicationVariable.FinalOfferUpgradePlanCategory]: upgradePlanCategory,
    [ApplicationVariable.FinalOfferUpgradePlanNumber]: upgradePlanNumber,
    [ApplicationVariable.FinalOfferDiscountsDescription]: upgradeDiscount,
    [ApplicationVariable.FinalOfferPromoDescription]: upgradePromotion,
    ...getAgreedJobAmount(),
  };

  if (offerProvider !== OfferProviderNames.Even) {
    variables[ApplicationVariable.ApplicationApprovalDate] = formatDate(
      new Date().toISOString(),
      DateTimeFormat.Short,
    );
  }

  return variables;
};

const getFinalOfferDesignation = ({
  preApproved,
  preQualified,
}: Pick<IOffer, 'preApproved' | 'preQualified'>): FinalDesignationType => {
  if (preApproved) {
    return FinalDesignationType.PreApproved;
  }
  if (preQualified) {
    return FinalDesignationType.PreQualified;
  }

  return FinalDesignationType.PreApproved;
};
