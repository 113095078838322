import ElectronicConsentTerm from 'components/Terms/ElectronicConsentTerm';
import PageLayout from 'components/PageLayout';
import React from 'react';

const ElectronicConsentPage = () => {
  return (
    <PageLayout>
      <ElectronicConsentTerm />
    </PageLayout>
  );
};

export default ElectronicConsentPage;
