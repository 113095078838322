import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { FormLayoutData } from 'api/Types';
import { BorrowerType } from 'enums/BorrowerType';
import { VariableValue } from 'types/VariableTypes';

export interface ISimplifiedBorrowerParams {
  id: string;
  type: BorrowerType;
  locked: boolean;
  variables: FormLayoutData;
}

export interface IUserShortInfo {
  id: string;
  firstName: string;
  lastName: string;
  imageId?: string;
}

export interface IBorrower {
  id: string;
  type: BorrowerType;
  organizationId: string;
  variables: Record<string, VariableValue>;
  locked: boolean;
  lockStartDate?: Date;
  lockEndDate?: Date;
  lockedBy?: IUserShortInfo | null;
  lockReason?: string;
  createdBy?: IUserShortInfo | null;
  updatedBy?: IUserShortInfo | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface IBorrowersApi {
  getBorrower(): Promise<IBorrower>;
  updateBorrower(variables: FormLayoutData): Promise<IBorrower>;
}

export default class BorrowersRestApi extends BaseApi implements IBorrowersApi {
  public getBorrower(): Promise<IBorrower> {
    return this.fetch('/borrowers/current');
  }

  public updateBorrower(variables: FormLayoutData): Promise<IBorrower> {
    return this.fetch('/borrowers/current', {
      method: FetchMethodType.PUT,
      body: {
        variables,
      },
    });
  }
}
