import React, { useState } from 'react';
import { BackArrowIcon } from 'static/images';
import Popup from 'components/Popup';
import { useTranslation } from 'react-i18next';
import { ButtonsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

import styles from './DisclaimerAndMoreInformation.module.scss';

const DisclaimerAndMoreInformation = ({ disclaimer }: { disclaimer: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t: translate } = useTranslation(TranslationNameSpaces.Buttons);

  return (
    <>
      <div className={styles.container} onClick={() => setIsOpen(true)}>
        {translate(ButtonsTranslationKeys.DisclaimerButton)}
        {''}
        <BackArrowIcon />
      </div>
      <Popup
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={translate(ButtonsTranslationKeys.DisclaimerButton)!}
      >
        {disclaimer}
      </Popup>
    </>
  );
};

export default DisclaimerAndMoreInformation;
