import React from 'react';
import { InfoCircleIcon } from 'static/images';

import styles from './InfoTooltip.module.scss';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { TooltipId } from 'enums/TooltipId';

interface IInfoTooltip {
  tooltip: string;
  id: TooltipId;
}

const InfoTooltip = ({ tooltip, id }: IInfoTooltip) => {
  return (
    <div className={styles.container}>
      <div className={styles.infoIcon} id={id}>
        <InfoCircleIcon />
      </div>
      <Tooltip
        anchorSelect={'#' + id}
        content={tooltip}
        place="bottom"
        className={styles.tooltipContent}
      />
    </div>
  );
};

export default InfoTooltip;
