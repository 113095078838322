import React, { FC } from 'react';
import InputWrapper from 'components/InputWrapper';
import { IInputWrapperProps } from 'components/InputWrapper/InputWrapper';

interface ITextInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    IInputWrapperProps {}

const TextInput: FC<ITextInputProps> = ({
  label,
  error,
  inputIcon,
  wrapperClassName,
  ...props
}) => {
  return (
    <InputWrapper
      error={error}
      wrapperClassName={wrapperClassName}
      inputIcon={inputIcon}
      label={label}
    >
      <input type="text" placeholder={label as string} {...props} />
    </InputWrapper>
  );
};

export default TextInput;
