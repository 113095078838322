import React from 'react';
import Paragraph from 'components/Paragraph';
import { TypographySize } from 'enums/TypographySize';

import styles from './InformationBox.module.scss';

interface IInformationBoxProps {
  content: string[];
}

const InformationBox = ({ content }: IInformationBoxProps) => {
  return (
    <div className={styles.container}>
      {content.map((item) => (
        <Paragraph className={styles.paragraph} key={item} variant={TypographySize.Small}>
          {item}
        </Paragraph>
      ))}
    </div>
  );
};

export default InformationBox;
