import AdvertiserDisclosure from 'components/AdvertiserDisclosure';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import DisclosuresBox from 'components/DisclosuresBox';
import ParticipatingLenderInfo from 'components/InformationBox/ParticipatingLenderInfo';
import Menu from 'components/Menu';
import PageLayout from 'components/PageLayout';
import { LayoutSize } from 'components/PageLayout/PageLayout';
import Title from 'components/Title';
import {
  BankVerificationPageTranslationKeys,
  ButtonsTranslationKeys,
  MenuTranslationKeys,
  SomethingWentWrongPageTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from 'routes/AppRoutes';
import { updateApplication } from 'thunks';
import { getApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import styles from './PlaidBankVerification/PlaidBankVerification.module.scss';

interface ISomethingWentWrongPageProps {
  isNoOffersError?: boolean;
  isDwollaError?: boolean;
  needApplicationsRedirect?: boolean;
  isIntermediaryError?: boolean;
}

const DWOLLA_SUPPORT_LINK = 'https://www.profund.net/support-center';

const SomethingWentWrongPage = ({
  isNoOffersError,
  isDwollaError,
  needApplicationsRedirect = false,
  isIntermediaryError,
}: ISomethingWentWrongPageProps) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { applicationId } = useParams();
  const navigate = useNavigate();

  const { t: translate } = useTranslation([
    TranslationNameSpaces.BankVerificationPage,
    TranslationNameSpaces.SomethingWentWrongPage,
    TranslationNameSpaces.Buttons,
  ]);

  const handleGoToApplications = () => {
    navigate(AppRoutes.Applications, { replace: true });
  };

  const handleGoToMyProfile = () => {
    navigate(AppRoutes.Profile, { replace: true });
  };

  const handleBackToOffers = async () => {
    await dispatchWithUnwrap(
      updateApplication({
        applicationId: applicationId!,
        params: {
          statusId: getApplicationStatusIdByEnvironment(ApplicationStatusName.OfferSelection),
        },
      }),
    );
  };

  const handleGoToSupportCenter = () => {
    window.open(DWOLLA_SUPPORT_LINK, '_blank');

    if (needApplicationsRedirect) {
      handleGoToApplications();
    }
  };

  const getHandleButtonCLick = () => {
    if (isNoOffersError) {
      return handleBackToOffers;
    }

    if (isDwollaError) {
      return handleGoToSupportCenter;
    }

    return handleGoToMyProfile;
  };

  const getButtonText = () => {
    if (isNoOffersError) {
      return ButtonsTranslationKeys.GoToProfileButton;
    }

    if (isDwollaError) {
      return ButtonsTranslationKeys.SupportCenter;
    }

    return ButtonsTranslationKeys.BackToOffers;
  };

  const getPageDescription = () => {
    if (isNoOffersError) {
      return SomethingWentWrongPageTranslationKeys.Description1;
    }

    if (isDwollaError) {
      return SomethingWentWrongPageTranslationKeys.Description3;
    }

    return SomethingWentWrongPageTranslationKeys.Description2;
  };

  const buttonText = translate(getButtonText(), { ns: TranslationNameSpaces.Buttons });

  const description = translate(getPageDescription(), {
    ns: TranslationNameSpaces.SomethingWentWrongPage,
  });

  return (
    <>
      {!isIntermediaryError && <Menu activeMenuItem={MenuTranslationKeys.Applications} />}
      <PageLayout layoutSize={LayoutSize.Big}>
        {!isIntermediaryError && (
          <>
            <AdvertiserDisclosure />
            <Title variant="h1">
              {' '}
              {translate(BankVerificationPageTranslationKeys.Title, {
                ns: TranslationNameSpaces.BankVerificationPage,
              })}
            </Title>
          </>
        )}

        <div className={styles.description}>
          <div className={styles.messageTitle}>
            {' '}
            {translate(SomethingWentWrongPageTranslationKeys.Title, {
              ns: TranslationNameSpaces.SomethingWentWrongPage,
            })}
          </div>
          <div className={styles.message}>{description}</div>
          <Button
            variant={ButtonVariant.Primary}
            size={ButtonSize.Large}
            onClick={getHandleButtonCLick()}
            className={styles.primaryButton}
          >
            {buttonText}
          </Button>
        </div>
        {!isIntermediaryError && (
          <>
            <DisclosuresBox />
            <ParticipatingLenderInfo />
          </>
        )}
      </PageLayout>
    </>
  );
};

export default SomethingWentWrongPage;
