import React, { ChangeEvent, FocusEventHandler, useEffect, useMemo, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { InputLabelsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { PersonalInformationVariable } from 'enums/PersonalInformation';
import { FinancialInformationVariable } from 'enums/FinancialInformationVariable';
import { CoborrowerVariable } from 'enums/CoborrowerVariable';
import { useTranslation } from 'react-i18next';
import TextInput from 'components/TextInput';
import NumberInput from 'components/NumberInput';
import useScript from 'hooks/useScript';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import { VariableValue } from 'types/VariableTypes';
import { getAddressByGeocodeResult } from './getAddressByGeocodeResult';
import styles from './AddressInputs.module.scss';
import { SearchIcon } from 'static/images';
import clsx from 'clsx';
import { ProjectAddressVariable } from 'enums/ProjectAddressVariable';
import { googleApiKeySecret } from 'secrets';

const LATIN_REG_EXP = /[^a-zA-Z]+/g;
const DIGITS_AND_LATIN_WITHOUT_SPACE_REG_EXP = /[^a-zA-Z0-9]+/g;
const LATIN_REG_EXP_WITH_SPACE = /[^a-zA-Z\s]+/g;

const getHomeAddress = (streetNumber: string, streetName: string) => {
  const getStreetNumber = () => streetNumber || '';
  const getStreetName = () => {
    if (!streetName) {
      return '';
    }

    return streetNumber ? ` ${streetName}` : streetName;
  };

  return `${getStreetNumber()}${getStreetName()}`;
};

enum PlacesServiceStatus {
  OK = 'OK',
}

interface IGooglePlacesApiOption {
  placeId: string;
  description: string;
  structuredFormatting: {
    mainText: string;
    secondaryText: string;
  };
}

export interface IAddressType<U> {
  streetName: U;
  streetNumber: U;
  apartmentOrSuite: U;
  city: U;
  state: U;
  zipCode: U;
}

export interface IAddressInputsProps<T> {
  value: IAddressType<string>;
  variable: IAddressType<T>;
  onChange: (event: ChangeEvent) => void;
  setFieldValue: (field: string, value: VariableValue, shouldValidate?: boolean) => void;
  getErrorMessage: (value: T) => string | undefined;
  onBlur?: FocusEventHandler;
  stateAndZipInputsCombined?: boolean;
  isProjectAddress?: boolean;
}

const AddressInputs = <
  K extends
    | CoborrowerVariable
    | FinancialInformationVariable
    | PersonalInformationVariable
    | ProjectAddressVariable,
>({
  value,
  onChange,
  setFieldValue,
  onBlur,
  variable,
  getErrorMessage,
  stateAndZipInputsCombined,
  isProjectAddress,
}: IAddressInputsProps<K>) => {
  const { t: translate } = useTranslation(TranslationNameSpaces.InputLabels);
  const googleMapScript = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${googleApiKeySecret}&libraries=places&callback=Function.prototype`,
  );

  const [homeAddressValue, setHomeAddressValue] = useState(
    getHomeAddress(value.streetNumber, value.streetName),
  );

  const [isFocused, setIsFocused] = useState(false);

  const [isCurrentOption, setIsCurrentOption] = useState<boolean>(true);

  const {
    init,
    suggestions: { status, data },
    setValue: setAutocompleteValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'us' },
      types: ['address'],
    },
    debounce: 300,
    callbackName: 'initAutocomplete',
  });

  useEffect(() => {
    if (googleMapScript.loaded) {
      init();
    }
  }, [googleMapScript.loaded]);

  const handleGooglePlacesSelect = (suggestion: IGooglePlacesApiOption) => async () => {
    const { description } = suggestion;

    const [geocoderResult] = await getGeocode({
      address: description,
      componentRestrictions: { country: 'us' },
    });

    const address = getAddressByGeocodeResult(geocoderResult);

    setFieldValue(variable.streetName, address.streetName || '');
    setFieldValue(variable.streetNumber, address.streetNumber || '');
    setFieldValue(variable.city, address.city || '');
    setFieldValue(variable.state, address.state || '');
    setFieldValue(variable.zipCode, address.zipCode || '');

    setHomeAddressValue(getHomeAddress(address.streetNumber, address.streetName));
    setIsFocused(false);
    setIsCurrentOption(true);
    clearSuggestions();
  };

  const options: IGooglePlacesApiOption[] = useMemo(() => {
    return data.map((suggestion) => {
      return {
        placeId: suggestion.place_id,
        description: suggestion.description,
        structuredFormatting: {
          mainText: suggestion.structured_formatting.main_text,
          secondaryText: suggestion.structured_formatting.secondary_text,
        },
      };
    });
  }, [data]);

  const renderSuggestions = () =>
    options.map((option) => {
      return (
        <li key={option.placeId} onClick={handleGooglePlacesSelect(option)}>
          {option.structuredFormatting.mainText} {option.structuredFormatting.secondaryText}
        </li>
      );
    });

  const ref = useOnclickOutside(() => {
    if (isFocused) {
      if (!isCurrentOption) {
        setFieldValue(variable.streetNumber, '');
        setFieldValue(variable.streetName, '');
        setHomeAddressValue('');
      }

      clearSuggestions();
      setIsFocused(false);
    }
  });

  const handleGooglePlacesInput = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsCurrentOption(false);
    setAutocompleteValue(event.target.value);
    onChange(event);
    setHomeAddressValue(event.target.value);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string,
    regExp: RegExp,
    toUpperCase?: boolean,
  ) => {
    const replacedValue = event.target.value.replace(regExp, '');

    setFieldValue(fieldName, toUpperCase ? replacedValue.toUpperCase() : replacedValue);
  };

  const renderStateAndZipInputs = () => {
    return (
      <>
        <TextInput
          onChange={(event) => handleChange(event, variable.state, LATIN_REG_EXP, true)}
          value={value.state}
          name={variable.state}
          label={translate(InputLabelsTranslationKeys.State)}
          error={getErrorMessage(variable.state)}
          onBlur={onBlur}
          maxLength={2}
          wrapperClassName={clsx(stateAndZipInputsCombined && styles.inputMaxWidthWithRightMargin)}
        />
        <NumberInput
          onChange={onChange}
          value={value.zipCode}
          name={variable.zipCode}
          label={translate(InputLabelsTranslationKeys.ZipCode)}
          error={getErrorMessage(variable.zipCode)}
          onBlur={onBlur}
          allowNegative={false}
          decimalScale={0}
          maxLength={10}
          wrapperClassName={clsx(stateAndZipInputsCombined && styles.inputMaxWidth)}
        />
      </>
    );
  };

  return (
    <>
      <div className={styles.addressInputContainer} ref={ref}>
        <TextInput
          onChange={handleGooglePlacesInput}
          value={homeAddressValue}
          name={variable.streetName}
          inputIcon={<SearchIcon />}
          label={translate(
            isProjectAddress
              ? InputLabelsTranslationKeys.ProjectAddress
              : InputLabelsTranslationKeys.HomeAddress,
          )}
          error={getErrorMessage(variable.streetName)}
          disabled={!googleMapScript.loaded}
          onBlur={onBlur}
          onFocus={() => {
            setIsFocused(true);
          }}
        />
        {status === PlacesServiceStatus.OK && (
          <ul className={styles.addressSuggestionsList}>{renderSuggestions()}</ul>
        )}
      </div>
      <TextInput
        onChange={(event) =>
          handleChange(event, variable.apartmentOrSuite, DIGITS_AND_LATIN_WITHOUT_SPACE_REG_EXP)
        }
        value={value.apartmentOrSuite}
        name={variable.apartmentOrSuite}
        label={translate(InputLabelsTranslationKeys.ApartmentOrSuite)}
        error={getErrorMessage(variable.apartmentOrSuite)}
        onBlur={onBlur}
        maxLength={50}
      />
      <TextInput
        onChange={(event) => handleChange(event, variable.city, LATIN_REG_EXP_WITH_SPACE)}
        value={value.city}
        name={variable.city}
        label={translate(InputLabelsTranslationKeys.City)}
        error={getErrorMessage(variable.city)}
        onBlur={onBlur}
        maxLength={50}
      />
      {stateAndZipInputsCombined ? (
        <div className={styles.addressAndZipInputsCombined}>{renderStateAndZipInputs()}</div>
      ) : (
        renderStateAndZipInputs()
      )}
    </>
  );
};

export default AddressInputs;
