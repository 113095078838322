import {
  ButtonsTranslationKeys,
  TranslationNameSpaces,
  WhatNextPageTranslationKeys,
} from 'enums/Translation';
import { useTranslation } from 'react-i18next';
import FormButtons from 'components/FormButtons';
import { LayoutSize } from 'components/PageLayout/PageLayout';
import PageLayout from 'components/PageLayout';
import { BankVerificationProcessSteps } from 'pages/ApplicationProcess/BankVerificationProcess/BankVerificationProcess';
import WhatNextBankConnectionDashboard from 'components/WhatNextBankConnectionDashboard/WhatNextBankConnectionDashboard';
import styles from './WhatNext.module.scss';
import { BankConnectionType } from 'components/BankConnectionCardWrapper/BankConnectionCardWrapper';
import { useState } from 'react';
import Popup from 'components/Popup';
import ProjectAccountInfoPopup from 'components/ProjectAccountInfoPopupContent';

interface IWhatNextProps {
  onSetStep: (step: BankVerificationProcessSteps) => void;
  onSelectBankConnectionType: (bankConnectionType: BankConnectionType) => void;
  currentBankConnectionType: BankConnectionType | null;
}

const IntermediaryWhatNext = ({
  onSetStep,
  onSelectBankConnectionType,
  currentBankConnectionType,
}: IWhatNextProps) => {
  const [isProjectAccountPopupOpen, setIsProjectAccountPopupOpen] = useState(false);

  const isContinueButtonDisabled =
    !currentBankConnectionType;

  const { t: translate } = useTranslation([
    TranslationNameSpaces.Buttons,
    TranslationNameSpaces.WhatNextPage,
  ]);

  const handleConfirm = () => {
    if (!currentBankConnectionType) {
      return;
    }

    const getPageByStep = () => {
      switch (currentBankConnectionType) {
        case BankConnectionType.Instant:
          return BankVerificationProcessSteps.PlaidBankVerification;
        case BankConnectionType.Standard:
          return BankVerificationProcessSteps.DwollaBankVerification;
        default:
          return BankVerificationProcessSteps.SelectBankVerification;
      }
    };

    onSetStep(getPageByStep());
  };

  return (
    <PageLayout layoutSize={LayoutSize.Big} removePaddingBottom>
      <div className={styles.mainContent}>
        <div className={styles.mainContentTitle}>
          {translate(WhatNextPageTranslationKeys.Title, {
            ns: TranslationNameSpaces.WhatNextPage,
          })}
        </div>
        <WhatNextBankConnectionDashboard
          onSelect={onSelectBankConnectionType}
          currentBankConnectionType={currentBankConnectionType}
        />
      </div>
      <FormButtons
        primaryTitle={translate(ButtonsTranslationKeys.Continue, {
          ns: TranslationNameSpaces.Buttons,
        })}
        secondaryTitle=''
        onPrimaryClick={handleConfirm}
        disablePrimary={isContinueButtonDisabled}
        showSecondaryButton={false}
        containerClassName={styles.buttonsContainer}
      />
      <Popup
        isOpen={isProjectAccountPopupOpen}
        onClose={() => setIsProjectAccountPopupOpen(false)}
        isNotParagraph
      >
        <ProjectAccountInfoPopup />
      </Popup>
    </PageLayout>
  );
};

export default IntermediaryWhatNext;
