import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import {
  ApplicationDetailsPageTranslationKeys,
  ButtonsTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import { ApplicationLabelName } from 'enums/ApplicationLabelId';
import { ApplicationVariable } from 'enums/ApplicationVariable';
import { DateTimeFormat } from 'utils/profund/dateFormat';
import { convertToMonetary } from 'utils/profund/formats';
import { closeAccount, updateApplication } from 'thunks';
import { setSelectedApplication } from 'handlers/portal/applicationsSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { AccountActionType } from 'components/ProjectBalance';
import TitleWithInfoTooltip from 'components/TitleWithInfoTooltip';
import Popup from 'components/Popup';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import PortalAndDwollaTermsCheckbox from 'components/PortalAndDwollaTermsCheckbox';
import PopupButtons from 'components/PopupButtons';
import CloseIcon from 'components/CloseIcon/';
import { getLoadingFields } from 'handlers/selectors';
import { TooltipId } from 'enums/TooltipId';
import { getApplicationLabelIdByEnvironment } from 'utils/applicationLabelsHelpers';

import styles from './CloseAccount.module.scss';

interface ICloseAccountProps {
  handleClose: () => void;
  projectAccountBalance: number | undefined;
  intermediary: string;
  applicationId: string;
  setSuccessMessage: (isMessage: AccountActionType) => void;
}

const CloseAccount = ({
  handleClose,
  projectAccountBalance,
  intermediary,
  applicationId,
  setSuccessMessage,
}: ICloseAccountProps) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const { isUpdateApplicationInProgress, isCloseAccountLoading } = useAppSelector(getLoadingFields);

  const { t: translate } = useTranslation([
    TranslationNameSpaces.ApplicationDetailsPage,
    TranslationNameSpaces.Buttons,
  ]);

  const handleCloseAccount = async () => {
    const updatedApplication = await dispatchWithUnwrap(
      updateApplication({
        applicationId: applicationId as string,
        params: {
          labelsIds: [getApplicationLabelIdByEnvironment(ApplicationLabelName.CloseAccount)],
          variables: {
            [ApplicationVariable.CloseAccountDateAndTime]: DateTime.now().toFormat(
              DateTimeFormat.Long,
            ),
          },
        },
      }),
    );

    await dispatchWithUnwrap(closeAccount({ applicationId }));

    dispatch(setSelectedApplication(updatedApplication));
    setIsOpen(false);
    handleClose();
    setSuccessMessage(AccountActionType.Close);
  };

  return (
    <div>
      <div className={styles.titleContainer}>
        <TitleWithInfoTooltip
          title={translate(ApplicationDetailsPageTranslationKeys.CloseAccountTitle, {
            ns: TranslationNameSpaces.ApplicationDetailsPage,
          })}
          tooltip={translate(ApplicationDetailsPageTranslationKeys.CloseAccountRequestTooltip, {
            ns: TranslationNameSpaces.ApplicationDetailsPage,
          })}
          id={TooltipId.CloseAccount}
        />
        <CloseIcon onClick={handleClose} />
      </div>
      <p className={styles.projectBalanceAmount}>
        {' '}
        {projectAccountBalance ? convertToMonetary(projectAccountBalance) : '—'}
      </p>
      <p className={styles.closeDescription}>
        {translate(ApplicationDetailsPageTranslationKeys.CloseAccountDescriptionPart1, {
          ns: TranslationNameSpaces.ApplicationDetailsPage,
        })}{' '}
        <span className={styles.closeDescriptionAmount}>
          {projectAccountBalance ? convertToMonetary(projectAccountBalance) : '—'}
        </span>{' '}
        {translate(ApplicationDetailsPageTranslationKeys.CloseAccountDescriptionPart2, {
          ns: TranslationNameSpaces.ApplicationDetailsPage,
        })}
      </p>
      <PortalAndDwollaTermsCheckbox checked={checked} setIsChecked={(value) => setChecked(value)} />
      <Button
        variant={ButtonVariant.Primary}
        size={ButtonSize.Large}
        onClick={() => setIsOpen(true)}
        className={styles.closeButton}
        disabledWithinInputAttr={!checked}
      >
        {translate(ButtonsTranslationKeys.AgreeAndContinue, {
          ns: TranslationNameSpaces.Buttons,
        })}
      </Button>
      {isOpen && (
        <Popup isOpen={isOpen} closeIcon={false} isNotParagraph>
          <TitleWithInfoTooltip
            title={translate(ApplicationDetailsPageTranslationKeys.CloseAccountPopupTitle, {
              ns: TranslationNameSpaces.ApplicationDetailsPage,
            })}
            tooltip={translate(ApplicationDetailsPageTranslationKeys.CloseAccountRequestTooltip, {
              ns: TranslationNameSpaces.ApplicationDetailsPage,
            })}
            className={styles.popupTitle}
            id={TooltipId.CloseAccountPopUp}
          />
          <div className={styles.popupContentContainer}>
            <p className={styles.popupParagraph}>
              {translate(ApplicationDetailsPageTranslationKeys.CloseAccountPopupAreYouSure, {
                ns: TranslationNameSpaces.ApplicationDetailsPage,
              })}{' '}
              {intermediary}?
            </p>
            <p className={styles.popupParagraph}>
              {translate(ApplicationDetailsPageTranslationKeys.CloseAccountPopupDescriptionPart1, {
                ns: TranslationNameSpaces.ApplicationDetailsPage,
              })}{' '}
              <span className={styles.popupDescriptionHighlighted}>
                {translate(
                  ApplicationDetailsPageTranslationKeys.CloseAccountPopupDescriptionPart2,
                  {
                    ns: TranslationNameSpaces.ApplicationDetailsPage,
                  },
                )}{' '}
              </span>
              {translate(ApplicationDetailsPageTranslationKeys.CloseAccountPopupDescriptionPart3, {
                ns: TranslationNameSpaces.ApplicationDetailsPage,
              })}{' '}
              <span className={styles.popupDescriptionHighlighted}>
                {translate(
                  ApplicationDetailsPageTranslationKeys.CloseAccountPopupDescriptionPart4,
                  {
                    ns: TranslationNameSpaces.ApplicationDetailsPage,
                  },
                )}{' '}
              </span>
              {translate(ApplicationDetailsPageTranslationKeys.CloseAccountPopupDescriptionPart5, {
                ns: TranslationNameSpaces.ApplicationDetailsPage,
              })}
            </p>
            <PopupButtons
              secondaryTitle={translate(ButtonsTranslationKeys.Cancel, {
                ns: TranslationNameSpaces.Buttons,
              })}
              primaryTitle={translate(ButtonsTranslationKeys.Confirm, {
                ns: TranslationNameSpaces.Buttons,
              })}
              onPrimaryClick={handleCloseAccount}
              onSecondaryClick={() => setIsOpen(false)}
              isPrimaryLoading={isUpdateApplicationInProgress || isCloseAccountLoading}
              isPrimaryDisabled={isUpdateApplicationInProgress || isCloseAccountLoading}
            />
          </div>
        </Popup>
      )}
    </div>
  );
};

export default CloseAccount;
