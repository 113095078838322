import React, { FC } from 'react';
import AuthCode from 'react-auth-code-input';
import Paragraph from 'components/Paragraph';
import { TypographySize } from 'enums/TypographySize';
import clsx from 'clsx';

import styles from './VerificationCodeInput.module.scss';

interface IVerificationCodeInput {
  handleOnChange: (value: string) => void;
  error?: string;
}

const VerificationCodeInput: FC<IVerificationCodeInput> = ({ error, handleOnChange }) => {
  return (
    <>
      <AuthCode
        onChange={handleOnChange}
        allowedCharacters="numeric"
        length={6}
        containerClassName={clsx(styles.inputContainer, error && styles.inputContainerError)}
        inputClassName={clsx(styles.input, error && styles.errorInput)}
      />
      {error && (
        <Paragraph variant={TypographySize.Small} className={styles.errorText}>
          {error}
        </Paragraph>
      )}
    </>
  );
};

export default VerificationCodeInput;
