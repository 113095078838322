import { createAsyncThunk } from '@reduxjs/toolkit';
import PaymentProcessorApi, {
  IVerifyDwollaMicroDepositsBody,
} from 'api/digifi/portal/PaymentProcessorApi';
import { BankVerificationActionType } from 'enums/BankVerificationActionType';

export const createVerifyDwollaMicroDepositsThunk = (api: PaymentProcessorApi) =>
  createAsyncThunk(
    BankVerificationActionType.VerifyDwollaMicroDeposits,
    async (body: IVerifyDwollaMicroDepositsBody) => {
      return api.verifyDwollaMicroDeposits(body);
    },
  );
