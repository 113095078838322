import { Environment } from 'enums/Environment';
import { ProdStrategyId, StrategyId, StrategyIdType, StrategyName } from 'enums/StrategyId';
import { environmentSecret } from 'secrets';

export const getStrategyIdByEnvironment = (strategyName: StrategyName): StrategyIdType => {
  if (environmentSecret !== Environment.Production) {
    return StrategyId[strategyName];
  }

  return ProdStrategyId[strategyName];
};

export const matchStrategyIdByEnvironment = (
  strategyId: string | null,
  strategyName: StrategyName,
): boolean => {
  if (!strategyId) {
    return false;
  }

  if (environmentSecret !== Environment.Production) {
    return strategyId === StrategyId[strategyName];
  }

  return strategyId === ProdStrategyId[strategyName];
};
