import { DateTime } from 'luxon';

export enum DateTimeFormat {
  Long = 'MMM dd, yyyy, h:mm a',
  LongMonthDate = 'MMM dd, yyyy',
  Short = 'MM/dd/yyyy',
}

export const formatDate = (
  date: string = new Date().toISOString(),
  format: DateTimeFormat = DateTimeFormat.Long,
) => {
  return DateTime.fromISO(date).toFormat(format);
};

export const getParsedDate = (date: string) => {
  return date.split('/') as string[];
};
