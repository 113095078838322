import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { IBorrower } from 'api/digifi/los/BorrowersApi';
import { RootState } from 'store';
import { getBorrower } from 'thunks';

const borrowersAdapter = createEntityAdapter<IBorrower>();

const initialState = borrowersAdapter.getInitialState();

const borrowersSlice = createSlice({
  name: 'borrowersSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBorrower.fulfilled, (state, { payload }) => {
      borrowersAdapter.setAll(state, payload);
    });
  },
});

export const { selectAll: selectAllBorrowers } = borrowersAdapter.getSelectors(
  (state: RootState) => state.borrowers,
);

export default borrowersSlice.reducer;
