import { RequestError } from 'errors/RequestError';
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { openToaster } from 'components/Toaster/Toaster';
import { HttpStatusCode } from 'enums/HttpStatusCode';

const rejectWithValueHelper = (
  error: Error,
  thunkApi: BaseThunkAPI<
    unknown,
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown,
    unknown,
    unknown
  >,
  withoutToaster = false,
) => {
  if (error instanceof RequestError) {
    if (error.responseStatus !== HttpStatusCode.Unauthorized && !withoutToaster) {
      openToaster.error(error.responseStatus < 500 ? error.message : undefined);
    }

    return thunkApi.rejectWithValue({
      responseStatus: error.responseStatus,
      message: error.message,
    });
  }

  throw error;
};

export default rejectWithValueHelper;
