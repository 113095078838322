import React from 'react';
import DomPurify from 'dompurify';

interface IOfferDisclaimerProps {
  offerDisclaimer: string;
}

const OfferDisclaimer = ({ offerDisclaimer }: IOfferDisclaimerProps) => {
  const sanitizedOfferDisclaimer = DomPurify.sanitize(offerDisclaimer);

  return <div dangerouslySetInnerHTML={{ __html: sanitizedOfferDisclaimer }} />;
};

export default OfferDisclaimer;
