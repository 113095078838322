import { EnvironmentVariable } from 'enums/EnvironmentVariable';

export type EnvType = {
  [key in EnvironmentVariable]: string;
};

declare global {
  interface Window {
    env: EnvType;
  }
}

export const env: EnvType = {
  ...process.env,
  ...(typeof window !== 'undefined' ? window.env : {}),
} as EnvType;
