import PageLayout, { LayoutSize } from 'components/PageLayout';
import Paragraph from 'components/Paragraph';
import Title from 'components/Title';
import { FinancialInformationVariable } from 'enums/FinancialInformationVariable';
import { FormikProps } from 'formik';
import { useState, useRef, useEffect } from 'react';
import styles from './FinancialInformationPage.module.scss';
import Checkbox from 'components/Checkbox';
import FormButtons from 'components/FormButtons';
import { CoborrowerVariable } from 'enums/CoborrowerVariable';
import AcceptedIncomeSources from 'components/AcceptedIncomeSources';
import CoborrowerInfo from 'components/CoborrowerInfo';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import {
  createOffers,
  getFinancialInformation,
  getPersonalInformation,
  updateApplication,
} from 'thunks';
import {
  getApplicationDataToUpdate,
  getRejectedApplicationDataToUpdate,
  handleSetDataToSessionStorage,
} from './dataMappers';
import { useNavigate, useParams } from 'react-router-dom';
import { StrategyName } from 'enums/StrategyId';
import { useAppSelector } from 'hooks/reduxHooks';
import Loading from 'components/Loading';
import Stepper from 'components/Stepper';
import { useStrategyApi } from 'providers/ApiServiceProvider';
import { IFinancialInformation } from 'handlers/portal/financialInformation';
import { Trans, useTranslation } from 'react-i18next';
import {
  AmountConfirmationPopUpTranslationKeys,
  ButtonsTranslationKeys,
  FinancialInformationPageTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import BorrowerFinancialInfo from 'components/BorrowerFinancialInfo';
import { BorrowerVariable } from 'enums/BorrowerVariable';
import DisclosuresBox from 'components/DisclosuresBox';
import ParticipatingLenderInfo from 'components/InformationBox/ParticipatingLenderInfo';
import { VariableValue } from 'types/VariableTypes';
import { AddressValueKey } from 'enums/AddressValueKey';
import { PersonalInformationVariable } from 'enums/PersonalInformation';
import AdvertiserDisclosure from 'components/AdvertiserDisclosure';
import { convertMonetaryValueToIntegerNumber } from 'utils/profund/formats';
import WaitingExperiencePage from 'pages/ApplicationProcess/WaitingExperience';
import { AppRoutes } from 'routes/AppRoutes';
import {
  getFinancialInformationData,
  getPersonalInformationData,
} from 'handlers/selectors';
import { IUpdateApplicationParams } from 'api/digifi/los/ApplicationsApi';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { getApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';
import { getStrategyIdByEnvironment, matchStrategyIdByEnvironment } from 'utils/strategyIdHelpers';
import Popup from 'components/Popup';
import AmountConfirmationPopUpContent from 'components/AmountConfirmationPopUpContent';
import TitleWithInfoTooltip from 'components/TitleWithInfoTooltip';
import { TooltipId } from 'enums/TooltipId';
import { LocalStorageConstants, LocalStorageKeys } from 'enums/LocalStorage';

const CUSTOMER_COUNTRY = 'United States';
const CONFIRMED_ANNUAL_INCOME_VALUE = 10000;
const ONLY_NUMBERS_REGEX = /[^0-9]/g;

const FinancingInformationPage = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { t: translate } = useTranslation([
    TranslationNameSpaces.FinancialInformationPage,
    TranslationNameSpaces.Buttons,
  ]);
  const { applicationId } = useParams();
  const strategyApi = useStrategyApi();
  const navigate = useNavigate();

  const { data: initialData } = useAppSelector(getFinancialInformationData);
  const { data } = useAppSelector(getPersonalInformationData);

  const borrowerFormRef = useRef<FormikProps<Partial<IFinancialInformation>>>(null);
  const coborrowerFormRef = useRef<FormikProps<Partial<IFinancialInformation>>>(null);
  const annualIncomeInputRef = useRef<HTMLInputElement>(null);

  const [isDataLoad, setIsDataLoad] = useState(false);
  const [isOffersGenerate, setIsOffersGenerate] =
    useState(localStorage.getItem(LocalStorageKeys.isOffersGenerate) === LocalStorageConstants.TRUE);
  const [isDataSaveInProgress, setIsDataSaveInProgress] = useState(false);
  const [isFinishLaterInProgress, setIsFinishLaterInProgress] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isProvidingChecked, setIsProvidingChecked] = useState(false);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [hasProvidingError, setHasProvidingError] = useState(false);
  const [hasAgreementError, setHasAgreementError] = useState(false);
  const [coborrowerFormExpanded, setCoborrowerFormExpanded] = useState(false);
  const [isBorrowerFormValid, setIsBorrowerFormValid] = useState(false);
  const [isCoborrowerFormValid, setIsCoborrowerFormValid] = useState(false);

  console.log(localStorage.getItem(LocalStorageKeys.isOffersGenerate))
  console.log({isOffersGenerate});

  const borrowerData = {
    [FinancialInformationVariable.AnnualIncome]:
      initialData[FinancialInformationVariable.AnnualIncome],
    [FinancialInformationVariable.SocialSecurityNumber]:
      initialData[FinancialInformationVariable.SocialSecurityNumber],
    [FinancialInformationVariable.DateOfBirth]:
      initialData[FinancialInformationVariable.DateOfBirth],
    [FinancialInformationVariable.PropertyStatus]:
      initialData[FinancialInformationVariable.PropertyStatus],
    [FinancialInformationVariable.CreditScore]:
      initialData[FinancialInformationVariable.CreditScore],
    [FinancialInformationVariable.EmploymentStatus]:
      initialData[FinancialInformationVariable.EmploymentStatus],
    [FinancialInformationVariable.PayFrequency]:
      initialData[FinancialInformationVariable.PayFrequency],
    [FinancialInformationVariable.HighestEducationLevel]:
      initialData[FinancialInformationVariable.HighestEducationLevel],
  };

  const coborrowerData = {
    [CoborrowerVariable.FirstName]: initialData[CoborrowerVariable.FirstName],
    [CoborrowerVariable.LastName]: initialData[CoborrowerVariable.LastName],
    [CoborrowerVariable.DateOfBirth]: initialData[CoborrowerVariable.DateOfBirth],
    [CoborrowerVariable.AnnualIncome]: initialData[CoborrowerVariable.AnnualIncome],
    [CoborrowerVariable.StreetName]: initialData[CoborrowerVariable.StreetName],
    [CoborrowerVariable.StreetNumber]: initialData[CoborrowerVariable.StreetNumber],
    [CoborrowerVariable.ApartmentOrSuite]: initialData[CoborrowerVariable.ApartmentOrSuite],
    [CoborrowerVariable.City]: initialData[CoborrowerVariable.City],
    [CoborrowerVariable.State]: initialData[CoborrowerVariable.State],
    [CoborrowerVariable.ZipCode]: initialData[CoborrowerVariable.ZipCode],
    [CoborrowerVariable.Email]: initialData[CoborrowerVariable.Email],
    [CoborrowerVariable.PropertyStatus]: initialData[CoborrowerVariable.PropertyStatus],
    [CoborrowerVariable.Phone]: initialData[CoborrowerVariable.Phone],
    [CoborrowerVariable.SSN]: initialData[CoborrowerVariable.SSN],
  };

  const getIsValidForms = () => {
    if (coborrowerFormExpanded) {
      return isBorrowerFormValid && isCoborrowerFormValid;
    }

    return isBorrowerFormValid;
  };

  const getDataToStoreInSession = () => {
    const values = getValues();

    return {
      [FinancialInformationVariable.AnnualIncome]:
        values[FinancialInformationVariable.AnnualIncome],
      [FinancialInformationVariable.CreditScore]: values[FinancialInformationVariable.CreditScore],
      [CoborrowerVariable.AnnualIncome]: values[CoborrowerVariable.AnnualIncome],
    };
  };

  const isPrimaryButtonDisabled = !getIsValidForms() || !isProvidingChecked || !isAgreementChecked;

  const getValues = () => {
    const borrowerFormValues = borrowerFormRef?.current?.values;
    const coborrowerFormValues = coborrowerFormRef?.current?.values;

    return {
      ...borrowerFormValues,
      ...(coborrowerFormExpanded ? coborrowerFormValues : {}),
    };
  };

  const getErrors = () => {
    const borrowerFormErrors = borrowerFormRef?.current?.errors;
    const coborrowerFormErrors = coborrowerFormRef?.current?.errors;

    return {
      ...borrowerFormErrors,
      ...(coborrowerFormExpanded ? coborrowerFormErrors : {}),
    };
  };

  const handleBackClick = async () => {
    setIsDataSaveInProgress(true);
    const formValues = getValues();
    const formErrors = getErrors();

    try {
      await dispatchWithUnwrap(
        updateApplication({
          applicationId: applicationId!,
          params: {
            statusId: getApplicationStatusIdByEnvironment(
              ApplicationStatusName.PersonalInformation,
            ),
            variables: getApplicationDataToUpdate(
              formValues as IFinancialInformation,
              coborrowerFormExpanded,
              formErrors!,
              false,
            ),
          },
        }),
      );
    } catch {
      setIsDataSaveInProgress(false);
    }
  };

  const handleAgreementCheck = () => {
    setIsAgreementChecked(!isAgreementChecked);
    setHasAgreementError(false);
  };

  const handleProvidingCheck = () => {
    setIsProvidingChecked(!isProvidingChecked);
    setHasProvidingError(false);
  };

  const getEligibilityCheckData = async (variables: Record<string, VariableValue>) => {
    const result = await strategyApi.run({
      applicationId,
      decisions: [
        {
          strategyId: getStrategyIdByEnvironment(StrategyName.EligibilityCheck),
          inputs: variables,
        },
      ],
    });

    return result;
  };

  useEffect(() => {
    const getInitialData = async () => {
      try {
        setIsDataLoad(true);

        await Promise.all([
          dispatchWithUnwrap(getPersonalInformation(applicationId!)),
          dispatchWithUnwrap(getFinancialInformation(applicationId!)),
        ]);
      } finally {
        setIsDataLoad(false);
      }
    };

    getInitialData();
  }, []);

  const handleContinue = async () => {
    const values = getValues();
    const annualIncome = 
      values[FinancialInformationVariable.AnnualIncome]!
        .replace(ONLY_NUMBERS_REGEX, '');

    if (isPrimaryButtonDisabled) {
      borrowerFormRef?.current?.submitForm();

      if (coborrowerFormExpanded) {
        coborrowerFormRef?.current?.submitForm();
      }

      if (!isAgreementChecked) {
        setHasAgreementError(true);
      }

      if (!isProvidingChecked) {
        setHasProvidingError(true);
      }

      return;
    }

    if (Number(annualIncome) >= CONFIRMED_ANNUAL_INCOME_VALUE) {
      await handleSubmit();

      return;
    }

    setIsPopupOpen(true);
  };

  const handleSubmit = async () => {
    try {
      setIsDataLoad(true);

      const formErrors = getErrors();
      const values = getValues();

      handleSetDataToSessionStorage(getDataToStoreInSession() as IFinancialInformation);

      const strategyInputVariable = {
        [BorrowerVariable.DateOfBirth]: values[FinancialInformationVariable.DateOfBirth],
        [CoborrowerVariable.DateOfBirth]: values[CoborrowerVariable.DateOfBirth] || null,
        [BorrowerVariable.HomeAddress]: {
          [AddressValueKey.City]: data[PersonalInformationVariable.City],
          [AddressValueKey.StateOrProvince]: data[PersonalInformationVariable.State],
          [AddressValueKey.ZipOrPostalCode]: data[PersonalInformationVariable.ZipCode],
          [AddressValueKey.StreetName]: data[PersonalInformationVariable.StreetName],
          [AddressValueKey.StreetNumber]: data[PersonalInformationVariable.StreetNumber],
          [AddressValueKey.Country]: CUSTOMER_COUNTRY,
        },
        [CoborrowerVariable.HomeAddress]: coborrowerFormExpanded
          ? {
              [AddressValueKey.City]: values[CoborrowerVariable.City],
              [AddressValueKey.StateOrProvince]: values[CoborrowerVariable.State],
              [AddressValueKey.ZipOrPostalCode]: values[CoborrowerVariable.ZipCode],
              [AddressValueKey.StreetName]: values[CoborrowerVariable.StreetName],
              [AddressValueKey.StreetNumber]: values[CoborrowerVariable.StreetNumber],
              [AddressValueKey.Country]: CUSTOMER_COUNTRY,
            }
          : null,
      };
      const eligibilityCheckData = await getEligibilityCheckData(strategyInputVariable);

      const result = eligibilityCheckData.results?.find((item) =>
        matchStrategyIdByEnvironment(item.strategyId, StrategyName.EligibilityCheck),
      )!;

      const eligibilityCheckPassed = result.passed;

      const validApplicationData: IUpdateApplicationParams = {
        statusId: getApplicationStatusIdByEnvironment(ApplicationStatusName.FinancialInformation),
        variables: {
          ...getApplicationDataToUpdate(
            values as IFinancialInformation,
            coborrowerFormExpanded,
            formErrors,
            true,
          ),
        },
      };

      await dispatchWithUnwrap(
        updateApplication({
          applicationId: applicationId!,
          params: eligibilityCheckPassed
            ? validApplicationData
            : getRejectedApplicationDataToUpdate(),
        }),
      );

      if (eligibilityCheckPassed) {
        const generateOffersBody = {
          applicationId: applicationId!,
          [FinancialInformationVariable.AnnualIncome]: convertMonetaryValueToIntegerNumber(
            values[FinancialInformationVariable.AnnualIncome]!,
          ) as number,
          [FinancialInformationVariable.CreditScore]:
            values[FinancialInformationVariable.CreditScore]!,
          [CoborrowerVariable.AnnualIncome]: convertMonetaryValueToIntegerNumber(
            values[CoborrowerVariable.AnnualIncome],
          ),
          [CoborrowerVariable.SSN]: values[CoborrowerVariable.SSN],
          [FinancialInformationVariable.SocialSecurityNumber]:
            values[FinancialInformationVariable.SocialSecurityNumber]!,
        };

        setIsDataLoad(false);
        setIsOffersGenerate(true);

        await dispatchWithUnwrap(createOffers(generateOffersBody));
      }
    } catch {
      setIsDataLoad(false);
      setIsOffersGenerate(false);
    }
  };

  const handleAnnualEdit = () => {    
    setIsPopupOpen(false);

    annualIncomeInputRef.current?.focus();
  };

  const finishLater = async () => {
    setIsFinishLaterInProgress(true);
    const formValues = getValues();
    const formErrors = getErrors();

    handleSetDataToSessionStorage(getDataToStoreInSession() as IFinancialInformation);

    try {
      await dispatchWithUnwrap(
        updateApplication({
          applicationId: applicationId!,
          params: {
            variables: {
              ...getApplicationDataToUpdate(
                formValues as IFinancialInformation,
                coborrowerFormExpanded,
                formErrors,
              ),
            },
          },
        }),
      );
    } catch {
      return;
    }

    navigate(AppRoutes.Applications);
  };

  useEffect(() => {
    const derivedCoborrowerFormExpanded = () =>
      Object.values(coborrowerData).some((value) => Boolean(value));

    setCoborrowerFormExpanded(derivedCoborrowerFormExpanded);
  }, [initialData]);

  useEffect(() => {
    localStorage.setItem(LocalStorageKeys.isOffersGenerate, isOffersGenerate.toString());
  }, [isOffersGenerate]);

  if (isDataLoad) {
    return <Loading />;
  }

  if (isOffersGenerate) {
    return <WaitingExperiencePage />;
  }

  return (
    <div>
      <Stepper
        currentStep={getApplicationStatusIdByEnvironment(
          ApplicationStatusName.FinancialInformation,
        )}
      />
      <PageLayout layoutSize={LayoutSize.Big}>
        <AdvertiserDisclosure onBackClick={handleBackClick} />
        <Title variant="h1" marginBottom="16">
          {translate(FinancialInformationPageTranslationKeys.Title, {
            ns: TranslationNameSpaces.FinancialInformationPage,
          })}
        </Title>
        <Paragraph marginBottom="16">
          <Trans i18nKey={FinancialInformationPageTranslationKeys.Description}>
            Make sure to enter your{' '}
            <span className={styles.bold}>Annual Household Gross Income</span>. This is your yearly
            income from all sources before taxes and deductions.
          </Trans>
        </Paragraph>
        <AcceptedIncomeSources />
        <BorrowerFinancialInfo
          borrowerData={borrowerData}
          formRef={borrowerFormRef}
          annualIncomeInputRef={annualIncomeInputRef}
          setIsFormValid={setIsBorrowerFormValid}
        />
        <CoborrowerInfo
          isOpen={coborrowerFormExpanded}
          setOpen={setCoborrowerFormExpanded}
          coborrowerData={coborrowerData}
          formRef={coborrowerFormRef}
          setIsFormValid={setIsCoborrowerFormValid}
        />
        <div
          className={
            coborrowerFormExpanded
              ? styles.checkboxesContainerMargin17
              : styles.checkboxesContainerMargin35
          }
        >
          <Checkbox
            label={
              <Trans
                t={translate}
                i18nKey={FinancialInformationPageTranslationKeys.CheckboxLabel1}
                ns={TranslationNameSpaces.FinancialInformationPage}
                components={{
                  Link: (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://fiona.com/learn/partners"
                    />
                  ),
                }}
              />
            }
            isChecked={isProvidingChecked}
            onChange={handleProvidingCheck}
            className={styles.marginBottom36}
            isError={hasProvidingError}
          />
          <Checkbox
            label={
              <Trans
                t={translate}
                i18nKey={FinancialInformationPageTranslationKeys.CheckboxLabel2}
                ns={TranslationNameSpaces.FinancialInformationPage}
                components={{
                  Link: (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://fiona.com/learn/partners"
                    />
                  ),
                }}
              />
            }
            isChecked={isAgreementChecked}
            onChange={handleAgreementCheck}
            className={styles.checkboxAlignTop}
            isError={hasAgreementError}
          />
        </div>
        <FormButtons
          primaryTitle={translate(ButtonsTranslationKeys.AgreeAndContinue, {
            ns: TranslationNameSpaces.Buttons,
          })}
          secondaryTitle={translate(ButtonsTranslationKeys.FinishLater, {
            ns: TranslationNameSpaces.Buttons,
          })}
          onPrimaryClick={handleContinue}
          onSecondaryClick={finishLater}
          disablePrimary={isPrimaryButtonDisabled}
          isPrimaryLoading={isDataSaveInProgress}
          isSecondaryLoading={isFinishLaterInProgress}
        />
        <DisclosuresBox />
        <ParticipatingLenderInfo />
        <Popup
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
        >
          <TitleWithInfoTooltip
            title={translate(AmountConfirmationPopUpTranslationKeys.Title, {
              ns: TranslationNameSpaces.Popups,
            })}
            tooltip={translate(FinancialInformationPageTranslationKeys.AnnualIncomeTooltip, {
              ns: TranslationNameSpaces.FinancialInformationPage,
            })}
            id={TooltipId.CloseAccountPopUp}
          />
          <AmountConfirmationPopUpContent 
            annualIncome={getValues()[FinancialInformationVariable.AnnualIncome]!}  
            onConfirmClick={handleSubmit}
            onEditClick={handleAnnualEdit}
          />
        </Popup>
      </PageLayout>
    </div>
  );
};

export default FinancingInformationPage;
