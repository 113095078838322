export enum ApplicationStatusName {
  NewApplication = 'NewApplication',
  PersonalInformation = 'PersonalInformation',
  FinancialInformation = 'FinancialInformation',
  OfferSelection = 'OfferSelection',
  OfferAgreement = 'OfferAgreement',
  BankVerification = 'BankVerification',
  Approved = 'Approved',
  Rejected = 'Rejected',
}
