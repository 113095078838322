import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { VariableValue } from 'types/VariableTypes';
import { BorrowerType } from 'enums/BorrowerType';
import { IUserShortInfo } from './BorrowersApi';
import { ApplicationStatusIdType } from 'enums/ApplicationStatusId';
import { ApplicationLabelType } from 'enums/ApplicationLabelId';

export type ApplicationStatusPermissions = Record<string, boolean>;

export enum ApplicationStatusType {
  Custom = 'custom',
  Initial = 'initial',
  Approve = 'approve',
  Reject = 'reject',
}

export interface IApplication {
  id: string;
  organizationId: string;
  displayId: string;
  variables: Record<string, VariableValue>;
  status: {
    id: string;
    name: string;
    permissionGroupsAbleToViewApplicationOnBoard: ApplicationStatusPermissions;
    permissionGroupsToEditApplication: ApplicationStatusPermissions;
    permissionGroupsToMoveApplicationIntoStatus: ApplicationStatusPermissions;
    type: ApplicationStatusType;
    archivedAt?: Date;
  };
  borrowerId: string;
  coborrowerIds: string[];
  intermediaryId?: string;
  declineReasons?: string[];
  teamMembers: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    avatarId?: string;
  }[];
  labels: IApplicationLabel[];
  borrowerType: BorrowerType;
  coborrowerTypes: BorrowerType[];
  product: {
    id: string;
    name: string;
    organizationId: string;
    organizationVersion: number | null;
    borrowerTypes: BorrowerType[];
  };
  testing?: boolean;
  createdBy?: IUserShortInfo | null;
  updatedBy?: IUserShortInfo | null;
  createdAt: Date;
  updatedAt: Date;
  approvedAt?: Date;
  rejectedAt?: Date;
  originalApplicationId?: string;
  transitionedToStatusAt?: Date | null;
}

export interface IApplicationLabel {
  id: string;
  name: string;
  color: string;
}

interface IGetBorrowerApplicationsResponse {
  active: IApplication[];
  prior: IApplication[];
}

export interface IUpdateApplicationParams {
  statusId?: ApplicationStatusIdType;
  declineReasons?: string[];
  teamMembers?: string[];
  labelsIds?: ApplicationLabelType[];
  variables?: Record<string, VariableValue>;
}

export interface IApplicationsApi {
  updateApplication(applicationId: string, params: IUpdateApplicationParams): Promise<IApplication>;
  getBorrowerApplications(): Promise<IGetBorrowerApplicationsResponse>;
  findById(id: string): Promise<IApplication>;
}

export default class ApplicationsRestApi extends BaseApi implements IApplicationsApi {
  public updateApplication(
    applicationId: string,
    params: IUpdateApplicationParams,
  ): Promise<IApplication> {
    return this.fetch('/applications/update', {
      method: FetchMethodType.POST,
      body: { applicationId, params },
    });
  }

  public getBorrowerApplications(): Promise<IGetBorrowerApplicationsResponse> {
    return this.fetch('/applications/get-applications');
  }

  public findById(id: string): Promise<IApplication> {
    return this.fetch(`/applications/${id}`);
  }
}
