import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OfferProviderNames } from 'enums/OfferProviderNames';
import { UpgradePlanCategoryKeys } from 'enums/UpgradePlanCategory';
import { isArray } from 'lodash';
import { getOffers, createOffers } from 'thunks';

export interface IOffer {
  id: string;
  loanAmount: number;
  monthlyPayment: number;
  apr: number;
  partnerLogoUrl: string | undefined;
  disclaimer: string;
  termLength: number;
  termsUnit: string;
  preApproved: boolean | null;
  preQualified: boolean | null;
  originatorName: string;
  recommendationScore: number | null;
  link: string;
  offerProvider: OfferProviderNames;
  monthlyPaymentDescription: string | null;
  termDescription: string | null;
  aprDescription: string | null;
  productName: string | null;
  productDescription: string | null;
  interestRate: number | null;
  discountPercent: number | null;
  disbursementCount: string | null;
  expirationDate: string | null;
  creditLimit: number | null;
  applicationName: string | null;
  originationFee: {
    min: number;
    max: number;
  } | null;
  upgradePlanDescription: string | null;
  upgradePlanCategory: UpgradePlanCategoryKeys | null;
  upgradePlanNumber: string | null;
  upgradeDiscount: string | null;
  upgradePromotion: string | null;
}

export interface IOffersInformationState {
  offers: IOffer[] | null;
  currentOffer: IOffer | null;
  createRequestPassed: boolean;
  showOffersWithoutEven: boolean;
}

const initialState: IOffersInformationState = {
  offers: null,
  currentOffer: null,
  createRequestPassed: false,
  showOffersWithoutEven: false,
};

const offersInformationSlice = createSlice({
  name: 'offersInformation',
  initialState,
  reducers: {
    setCurrentOffer: (state, { payload }: PayloadAction<IOffer | null>) => {
      state.currentOffer = payload;
    },
    setShowOffersWithoutEven: (state, { payload }: PayloadAction<boolean>) => {
      state.showOffersWithoutEven = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOffers.fulfilled, (state, { payload }) => {
        if (isArray(payload)) {
          state.offers = state.showOffersWithoutEven
            ? payload.filter((offer) => offer.offerProvider !== OfferProviderNames.Even)
            : payload;
        }
      })
      .addCase(createOffers.fulfilled, (state) => {
        state.createRequestPassed = true;
      });
  },
});

export const { setCurrentOffer, setShowOffersWithoutEven } = offersInformationSlice.actions;

export default offersInformationSlice.reducer;
