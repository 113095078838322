import clsx from 'clsx';
import React, { FC } from 'react';
import ReactLoading, { LoadingProps } from 'react-loading';

import styles from './Loading.module.scss';

const Loading: FC<LoadingProps> = ({ className }) => {
  return <ReactLoading className={clsx(styles.loading, className)} width={80} type="bubbles" />;
};

export default Loading;
