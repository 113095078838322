import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationProcessActionTypes } from 'enums/ApplicationProcessActionTypes';
import ApplicationProcessApi, {
  ICleanApplicationsRequestBody,
} from 'api/digifi/portal/ApplicationProcessApi';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createCleanApplicationsThunk = (api: ApplicationProcessApi) =>
  createAsyncThunk(
    ApplicationProcessActionTypes.CleanApplications,
    async (body: ICleanApplicationsRequestBody, thunkApi) => {
      try {
        return await api.cleanApplications(body);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
