import { createAsyncThunk } from '@reduxjs/toolkit';
import PaymentProcessorApi from 'api/digifi/portal/PaymentProcessorApi';
import { BankVerificationActionType } from 'enums/BankVerificationActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createCreateIntermediaryFundingSourceThunk = (api: PaymentProcessorApi) =>
  createAsyncThunk(
    BankVerificationActionType.CreateIntermediaryFundingSource,
    async (token: string, thunkApi) => {
      try {
        return await api.createIntermediaryFundingSource(token);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
