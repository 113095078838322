import { UpgradePlanCategory, UpgradePlanCategoryKeys } from 'enums/UpgradePlanCategory';

export const parseUpgradePlan = (planCategory: UpgradePlanCategoryKeys, planDescription: string) => {
  switch (planCategory) {
    case UpgradePlanCategory.ZERO_INTEREST_RATE: {
      const [ percentage, , ,numberOfYears ] = planDescription.split(' ');

      return {
        percentage,
        numberOfYears,
      };
    }  
    case UpgradePlanCategory.REDUCED_RATE:
    case UpgradePlanCategory.REDUCED_RATE_ZERO_BILL: {
      const [ percentage, ,numberOfYears ] = planDescription.split(' ');

      return {
        percentage,
        numberOfYears,
      };
    }
    case UpgradePlanCategory.NO_INTEREST_NO_PAYMENT:
    case UpgradePlanCategory.NO_INTEREST_NO_PAYMENT_ZERO_BILL:
    case UpgradePlanCategory.DEFERRED_INTEREST_NO_PAYMENT:
    case UpgradePlanCategory.DEFERRED_INTEREST_NO_PAYMENT_ZERO_BILL: {
      const [ percentage, , , , , ,numberOfMonths ] = planDescription.split(' ');

      return {
        percentage,
        numberOfMonths,
      };
    }
    default: {
      const [ percentage, , ,numberOfMonths ] = planDescription.split(' ');

      return {
        percentage,
        numberOfMonths,
      };
    }
  }
};
