import clsx from 'clsx';
import React from 'react';
import styles from './ToggleSwitch.module.scss';

interface IToggleSwitchProps {
  text: string;
  checked: boolean;
  onChange: () => void;
}

const ToggleSwitch = ({ text, checked, onChange }: IToggleSwitchProps) => {
  return (
    <div className={styles.container}>
      <div onClick={() => onChange()}>
        <label className={clsx(styles.switchLabel, !checked && styles.switchLabelChecked)}>
          <span className={clsx(styles.switchButton, !checked && styles.switchButtonChecked)} />
        </label>
      </div>
      <span className={styles.text}>{text}</span>
    </div>
  );
};

export default ToggleSwitch;
