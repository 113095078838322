import {
  ElectronicNoticesAndCommunicationTranslationKeys,
  GeneralPracticesRegardingUseAndStorageTranslationKeys,
  MobileServicesTranslationKeys,
  ModificationsToServicesTranslationKeys,
  ServicesDescriptionTranslationKeys,
  TranslationNameSpaces,
  YourRegistrationObligationsTranslationKeys,
} from 'enums/Translation';

import { ITermItem } from 'components/Terms/getTermComponent';

const accessAndUseOfTheServicesTermsItems: ITermItem[] = [
  {
    nameSpaceKey: TranslationNameSpaces.AccessAndUseOfTheServices,
    titleKey: ServicesDescriptionTranslationKeys.Title,
    itemsKey: ServicesDescriptionTranslationKeys.Items,
    linkKey: ServicesDescriptionTranslationKeys.Link,
    emailKey: ServicesDescriptionTranslationKeys.Email,
  },
  {
    nameSpaceKey: TranslationNameSpaces.AccessAndUseOfTheServices,
    titleKey: YourRegistrationObligationsTranslationKeys.Title,
    descriptionKey: YourRegistrationObligationsTranslationKeys.Description,
    linkKey: YourRegistrationObligationsTranslationKeys.Link,
  },
  {
    nameSpaceKey: TranslationNameSpaces.AccessAndUseOfTheServices,
    titleKey: ModificationsToServicesTranslationKeys.Title,
    descriptionKey: ModificationsToServicesTranslationKeys.Description,
  },
  {
    nameSpaceKey: TranslationNameSpaces.AccessAndUseOfTheServices,
    titleKey: GeneralPracticesRegardingUseAndStorageTranslationKeys.Title,
    descriptionKey: GeneralPracticesRegardingUseAndStorageTranslationKeys.Description,
  },
  {
    nameSpaceKey: TranslationNameSpaces.AccessAndUseOfTheServices,
    titleKey: MobileServicesTranslationKeys.Title,
    descriptionKey: MobileServicesTranslationKeys.Description,
  },
  {
    nameSpaceKey: TranslationNameSpaces.AccessAndUseOfTheServices,
    titleKey: ElectronicNoticesAndCommunicationTranslationKeys.Title,
    descriptionKey: ElectronicNoticesAndCommunicationTranslationKeys.Description,
    linkKey: ElectronicNoticesAndCommunicationTranslationKeys.Link,
  },
];

export default accessAndUseOfTheServicesTermsItems;
