import Paragraph from 'components/Paragraph';
import React from 'react';
import TermWrapper from './TermWrapper';
import Title from 'components/Title';
import { TypographySize } from 'enums/TypographySize';
import { useTranslation } from 'react-i18next';

interface ITermProps {
  titleKey: string;
  descriptionKey: string;
  nameSpaceKey: string;
}

const Term = ({ titleKey, descriptionKey, nameSpaceKey }: ITermProps) => {
  const { t: translate } = useTranslation(nameSpaceKey);
  return (
    <TermWrapper>
      <Title variant="h3" marginBottom="16">
        {translate(titleKey)}
      </Title>
      <Paragraph variant={TypographySize.Middle}>{translate(descriptionKey)}</Paragraph>
    </TermWrapper>
  );
};

export default Term;
