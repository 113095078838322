import {
  ButtonsTranslationKeys,
  MenuTranslationKeys,
  TranslationNameSpaces,
  WhatNextPageTranslationKeys,
} from 'enums/Translation';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import AdvertiserDisclosure from 'components/AdvertiserDisclosure';
import DisclosuresBox from 'components/DisclosuresBox';
import FormButtons from 'components/FormButtons';
import { LayoutSize } from 'components/PageLayout/PageLayout';
import Logo from 'components/Logo';
import Menu from 'components/Menu';
import PageLayout from 'components/PageLayout';
import ParticipatingLenderInfo from 'components/InformationBox/ParticipatingLenderInfo';
import PartnerLogo from 'components/PartnerLogo';
import PortalAndDwollaTermsCheckbox from 'components/PortalAndDwollaTermsCheckbox';
import WhatNextSteps from 'components/WhatNextSteps';
import { getLoadingFields } from 'handlers/selectors';
import { getApplicationById, updateApplication } from 'thunks';
import { useAppSelector } from 'hooks/reduxHooks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import styles from './WhatNext.module.scss';
import { AppRoutes } from 'routes/AppRoutes';
import { resetSelectedOffer } from 'utils/resetSelectedOffer';
import { BankVerificationProcessSteps } from 'pages/ApplicationProcess/BankVerificationProcess/BankVerificationProcess';
import Loading from 'components/Loading/Loading';
import { ApplicationVariable } from 'enums/ApplicationVariable';
import { OfferProviderNames } from 'enums/OfferProviderNames';
import { VariableValue } from 'types/VariableTypes';
import WhatNextBankConnectionDashboard from 'components/WhatNextBankConnectionDashboard/WhatNextBankConnectionDashboard';
import WhatNextPageDescription from 'components/WhatNextPageDescription/WhatNextPageDescription';
import ProjectAccountTermsCheckbox from 'components/ProjectAccountTermsCheckbox';
import { BankConnectionType } from 'components/BankConnectionCardWrapper/BankConnectionCardWrapper';
import Popup from 'components/Popup';
import ProjectAccountInfoPopup from 'components/ProjectAccountInfoPopupContent';

interface IWhatNextProps {
  onSetStep: (step: BankVerificationProcessSteps) => void;
  onSelectBankConnectionType: (bankConnectionType: BankConnectionType) => void;
  currentBankConnectionType: BankConnectionType | null;
  onShowOffersWithoutEven: () => void;
  canShowOffersWithoutEven: boolean;
  fundedByEven: boolean;
}

const WhatNext = ({
  onSetStep,
  onSelectBankConnectionType,
  currentBankConnectionType,
  onShowOffersWithoutEven,
  canShowOffersWithoutEven,
  fundedByEven,
}: IWhatNextProps) => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [portalAndDwollaTermsChecked, setPortalAndDwollaTermsChecked] = useState(false);
  const [projectAccountTermsChecked, setProjectAccountTermsChecked] = useState(false);
  const [isProjectAccountPopupOpen, setIsProjectAccountPopupOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [applicationVariables, setApplicationVariables] = useState<Record<string, VariableValue>>(
    {},
  );

  const { isUpdateApplicationInProgress, isGetApplicationByIdInProgress } =
    useAppSelector(getLoadingFields);

  const isContinueButtonDisabled =
    !portalAndDwollaTermsChecked ||
    !projectAccountTermsChecked ||
    isUpdateApplicationInProgress ||
    isGetApplicationByIdInProgress ||
    !currentBankConnectionType;

  const { t: translate } = useTranslation([
    TranslationNameSpaces.Buttons,
    TranslationNameSpaces.Links,
    TranslationNameSpaces.WhatNextPage,
  ]);

  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const handleConfirm = () => {
    if (!(portalAndDwollaTermsChecked && projectAccountTermsChecked && currentBankConnectionType)) {
      return;
    }

    const getPageByStep = () => {
      switch (currentBankConnectionType) {
        case BankConnectionType.Instant:
          return BankVerificationProcessSteps.PlaidBankVerification;
        case BankConnectionType.Standard:
          return BankVerificationProcessSteps.DwollaBankVerification;
        default:
          return BankVerificationProcessSteps.SelectBankVerification;
      }
    };

    onSetStep(getPageByStep());
  };

  const handleSaveForLater = () => {
    navigate(AppRoutes.Applications);
  };

  const handleBackClick = async () => {
    await dispatchWithUnwrap(
      updateApplication({
        applicationId: applicationId!,
        params: {
          statusId: getApplicationStatusIdByEnvironment(ApplicationStatusName.OfferSelection),
          variables: resetSelectedOffer(
            applicationVariables[ApplicationVariable.InitialLoanAmount] as number,
          ),
        },
      }),
    );
  };

  const handleGetApplication = async () => {
    try {
      const { variables } = await dispatchWithUnwrap(getApplicationById(applicationId!));
      setApplicationVariables(variables);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    handleGetApplication();
  }, []);

  if (loading) {
    return <Loading />;
  }

  const isBackButtonActive = !fundedByEven;

  return (
    <>
      <Menu activeMenuItem={MenuTranslationKeys.Applications} />
      <PageLayout layoutSize={LayoutSize.Big} removePaddingBottom>
        <AdvertiserDisclosure onBackClick={isBackButtonActive ? handleBackClick : undefined} />
        <Logo>
          <PartnerLogo
            finalOfferProviderName={
              applicationVariables[ApplicationVariable.FinalOfferProviderName] as OfferProviderNames
            }
            logoLink={applicationVariables[ApplicationVariable.FinalFinancialServicesPartnerLogo]}
          />
        </Logo>
        <WhatNextPageDescription
          showSeeOffersLink={canShowOffersWithoutEven}
          setIsProjectAccountPopupOpen={setIsProjectAccountPopupOpen}
          handleSwitchToNotEvenOffersPage={onShowOffersWithoutEven}
        />
        <WhatNextSteps />
        <div className={styles.mainContent}>
          <div className={styles.mainContentTitle}>
            {translate(WhatNextPageTranslationKeys.Title, {
              ns: TranslationNameSpaces.WhatNextPage,
            })}
          </div>
          <div className={styles.mainContentDescription}>
            {translate(WhatNextPageTranslationKeys.Info, {
              ns: TranslationNameSpaces.WhatNextPage,
            })}
          </div>
          <WhatNextBankConnectionDashboard
            onSelect={onSelectBankConnectionType}
            currentBankConnectionType={currentBankConnectionType}
          />
        </div>
        <ProjectAccountTermsCheckbox
          checked={projectAccountTermsChecked}
          onChange={(value) => setProjectAccountTermsChecked(value)}
          openProjectAccountInfoPopup={() => setIsProjectAccountPopupOpen(true)}
        />
        <PortalAndDwollaTermsCheckbox
          checked={portalAndDwollaTermsChecked}
          setIsChecked={(value) => setPortalAndDwollaTermsChecked(value)}
        />
        <FormButtons
          primaryTitle={translate(ButtonsTranslationKeys.AgreeAndContinue, {
            ns: TranslationNameSpaces.Buttons,
          })}
          secondaryTitle={translate(ButtonsTranslationKeys.SaveForLater, {
            ns: TranslationNameSpaces.Buttons,
          })}
          onPrimaryClick={handleConfirm}
          onSecondaryClick={handleSaveForLater}
          disablePrimary={isContinueButtonDisabled}
          isPrimaryLoading={isUpdateApplicationInProgress || isGetApplicationByIdInProgress}
        />
        <DisclosuresBox />
        <ParticipatingLenderInfo />
        <Popup
          isOpen={isProjectAccountPopupOpen}
          onClose={() => setIsProjectAccountPopupOpen(false)}
          isNotParagraph
        >
          <ProjectAccountInfoPopup />
        </Popup>
      </PageLayout>
    </>
  );
};

export default WhatNext;
