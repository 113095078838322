import clsx from 'clsx';
import React, { FC } from 'react';
import Paragraph from 'components/Paragraph';
import { TypographySize } from 'enums/TypographySize';
import { CheckboxIcon, CheckboxIconChecked } from 'static/images';
import styles from './Checkbox.module.scss';
import { useTranslation } from 'react-i18next';
import { ErrorMessagesTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

interface ICheckboxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  isChecked: boolean;
  label?: string | React.ReactElement;
  isError?: boolean;
  inputWrapperClassName?: string;
}

const Checkbox: FC<ICheckboxProps> = ({
  label,
  disabled,
  isChecked,
  isError,
  className,
  inputWrapperClassName,
  ...props
}) => {
  const { t: translate } = useTranslation(TranslationNameSpaces.ErrorMessages);

  return (
    <label className={clsx(styles.label, className)}>
      <div className={clsx(styles.inputWrapper, inputWrapperClassName)}>
        <input className={styles.inputCheckbox} type="checkbox" {...props} />
        {isChecked ? <CheckboxIconChecked /> : <CheckboxIcon />}
      </div>
      <p className={styles.labelText}>{label}</p>
      {isError && (
        <Paragraph variant={TypographySize.Small} className={styles.error}>
          {
            translate(ErrorMessagesTranslationKeys.CheckboxRequired)
          }
        </Paragraph>
      )}
    </label>
  );
};

export default Checkbox;
