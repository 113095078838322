import { createAsyncThunk } from '@reduxjs/toolkit';
import LoanOffersApi, { IGetLoanOffersBody } from 'api/digifi/portal/LoanOffersApi';
import { OffersInformationActionType } from 'enums/OffersInformationActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createGetOffersThunk = (api: LoanOffersApi) =>
  createAsyncThunk(OffersInformationActionType.Get, async (body: IGetLoanOffersBody, thunkApi) => {
    try {
      return await api.get(body);
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
