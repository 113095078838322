import removePhoneNumberCode from 'utils/removePhoneNumberCode';
import { patternFormatter } from 'react-number-format';

export const PHONE_NUMBER_FORMAT = '+1–###–###–####';
export const PHONE_NUMBER_MASK = '_';

const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return '';
  }
  const numberWithoutCode = removePhoneNumberCode(phoneNumber);
  return patternFormatter(numberWithoutCode, {
    format: PHONE_NUMBER_FORMAT,
    mask: PHONE_NUMBER_MASK,
  });
};

export default formatPhoneNumber;
