import React, { FC } from 'react';
import { TypographySize } from 'enums/TypographySize';
import styles from './Paragraph.module.scss';
import clsx from 'clsx';

type ParagraphMarginBottomType = '8' | '16' | '24' | '32';

interface IParagraphProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  variant?: TypographySize;
  marginBottom?: ParagraphMarginBottomType;
}

const getParagraphMarginBottomStyle = (marginBottom: ParagraphMarginBottomType) => {
  switch (marginBottom) {
    case '8':
      return styles.tinyMarginBottom;
    case '16':
      return styles.smMarginBottom;
    case '24':
      return styles.mdMarginBottom;
    case '32':
      return styles.lgMarginBottom;
    default:
      return null;
  }
};

const getParagraphClassName = (
  variant?: TypographySize,
  marginBottom?: ParagraphMarginBottomType,
  className?: string,
) => {
  switch (variant) {
    case TypographySize.Large:
      return clsx(
        styles.lgParagraph,
        marginBottom && getParagraphMarginBottomStyle(marginBottom),
        className,
      );
    case TypographySize.Middle:
      return clsx(
        styles.mdParagraph,
        marginBottom && getParagraphMarginBottomStyle(marginBottom),
        className,
      );
    case TypographySize.Small:
      return clsx(
        styles.smParagraph,
        marginBottom && getParagraphMarginBottomStyle(marginBottom),
        className,
      );
    default:
      return clsx(
        styles.mdParagraph,
        marginBottom && getParagraphMarginBottomStyle(marginBottom),
        className,
      );
  }
};

const Paragraph: FC<IParagraphProps> = ({
  children,
  variant,
  className,
  marginBottom,
  ...props
}) => {
  return (
    <p className={getParagraphClassName(variant, marginBottom, className)} {...props}>
      {children}
    </p>
  );
};

export default Paragraph;
