import Term from './Term';
import TermWithItemList from './TermWithItemList';
import TermWithTags from './TermWithTags';

export interface ITermItem {
  nameSpaceKey: string;
  titleKey: string;
  descriptionKey?: string;
  itemsKey?: string;
  linkKey?: string;
  emailKey?: string;
}

export const getTermComponent = (termItem: ITermItem) => {
  const { titleKey, descriptionKey, emailKey, itemsKey, linkKey, nameSpaceKey } = termItem;

  if (linkKey || emailKey) {
    return (
      <TermWithTags
        nameSpaceKey={nameSpaceKey}
        titleKey={titleKey}
        descriptionKey={descriptionKey}
        emailKey={emailKey}
        linkKey={linkKey}
        itemsKey={itemsKey}
        key={titleKey}
      />
    );
  }

  if (itemsKey) {
    return (
      <TermWithItemList
        key={titleKey}
        titleKey={titleKey}
        itemsKey={itemsKey}
        nameSpaceKey={nameSpaceKey}
      />
    );
  }

  if (descriptionKey) {
    return (
      <Term
        key={titleKey}
        titleKey={titleKey}
        descriptionKey={descriptionKey}
        nameSpaceKey={nameSpaceKey}
      />
    );
  }
};
