export enum ApplicationVariable {
  LoanAmount = 'loan_amount',
  PropertyStatus = 'property_status',
  EmploymentStatus = 'employment_status',
  EmploymentPayFrequency = 'employment_pay_frequency',
  HighestEducationLevel = 'borrower_highest_education_level',
  FrcaConsentOptionComplete = 'frca_consent_option_complete',
  TcpaConsentOptionComplete = 'tcpa_consent_option_complete',
  TcpaConsentOptionCompleteDate = 'tcpa_consent_option_complete_date',
  FrcaConsentOptionCompleteDate = 'frca_consent_option_complete_date',
  BankVerificationComplete = 'bank_verification_complete',
  FinalOfferLenderName = 'final_offer_lender_name',
  FinalOfferId = 'final_offer_id',
  FinalOfferAmount = 'final_offer_amount',
  FinalFinancialServicesPartnerLogo = 'final_financial_services_partner_logo',
  FinalOfferDesignation = 'final_offer_designation',
  FinalOfferMonthlyPaymentAmount = 'final_offer_monthly_payment_amount',
  FinalOfferTermLength = 'final_offer_term_length',
  FinalOfferTermUnit = 'final_offer_term_unit',
  FinalOfferAprAmount = 'final_offer_apr_amount',
  FinalOfferLink = 'final_offer_link',
  FinalOfferDisclaimer = 'final_offer_disclaimer',
  FinalOfferRecommendationScore = 'final_offer_recommendation_score',
  IntermediaryDba = 'intermediary_dba',
  IntermediaryName = 'intermediary_name',
  CloseAccountDateAndTime = 'close_account_date_and_time',
  DwollaFundingSourceBalance = 'dwolla_funding_source_balance',
  DwollaFundingSourceCurrency = 'dwolla_funding_source_currency',
  DwollaDocumentVerificationProcess = 'dwolla_document_verification_process',
  AgreedJobAmount = 'agreed_job_amount',
  RequestFundsAmount = 'request_funds_amount',
  AddFundsAmount = 'add_funds_amount',
  PaymentsHistory = 'payments_history',
  ApplicationApprovalDate = 'application_approval_date',
  FinalOfferProviderName = 'final_offer_provider_name',
  FinalOfferMonthlyPaymentDescription = 'final_offer_monthly_payment_description',
  FinalOfferTermDescription = 'final_offer_term_description',
  FinalOfferAprDescription = 'final_offer_apr_description',
  ProjectAddress = 'project_address',
  FinalOfferProductName = 'final_offer_product_name',
  FinalOfferInterestRate = 'final_offer_interest_rate',
  FinalOfferDiscountPercent = 'final_offer_discount_percent',
  FinalOfferDisbursementCount = 'final_offer_disbursement_count',
  FinalOfferExpirationDate = 'final_offer_expiration_date',
  FinalOfferCreditLimit = 'final_offer_credit_limit',
  FinalOfferApplicationName = 'final_offer_application_name',
  FinalOfferApplicationId = 'final_offer_application_id',
  TelesignRiskScore = 'telesign_risk_score',
  SeonScore = 'seon_score',
  IsPlaidUpdateModeInProgress = 'is_plaid_update_mode_in_progress',
  InitialLoanAmount = 'initial_loan_amount',
  PlaidErrorPayload = 'plaid_error_payload',
  PlaidOnExitPayload = 'plaid_onexit_payload',
  IntermediaryWatercressId = 'intermediary_watercress_id',
  IntermediaryWatercressMerchantStatus = 'intermediary_merchant_status',
  WatercressInitialOffersAmount = 'watercress_initial_offers_amount',
  DwollaMicrodepositsStatus = 'dwolla_microdeposits_status',
  FundedByEven = 'funded_by_even',
  DwollaVerificationCount = 'dwolla_verification_count',
  IntermediaryProfundId = 'intermediary_profund_id',
  IntermediaryUpgradeMerchantStatus = 'intermediary_upgrade_merchant_status',
  UpgradeAccountId = 'upgrade_account_id',
  UpgradeOffersReceivingDate = 'upgrade_offers_receiving_date',
  UpgradeInitialOffersAmount = 'upgrade_initial_offers_amount',
  FinalOfferUpgradePlanDescription = 'final_offer_upgrade_plan_description',
  FinalOfferUpgradePlanCategory = 'final_offer_upgrade_plan_category',
  FinalOfferUpgradePlanNumber = 'final_offer_upgrade_plan_number',
  FinalOfferDiscountsDescription = 'final_offer_discounts_description',
  FinalOfferPromoDescription = 'final_offer_promo_description',
  EvenRequestId = 'even_request_id',
  SalespersonFinalizeOfferEmail = 'salesperson_finalize_offer_email',
}
