import { createSlice } from '@reduxjs/toolkit';
import {
  DEFAULT_EMPLOYMENT_STATUS_VALUE,
  DEFAULT_PROPERTY_STATUS_VALUE,
} from 'components/BorrowerFinancialInfo/constants';
import { CoborrowerVariable } from 'enums/CoborrowerVariable';
import { FinancialInformationVariable } from 'enums/FinancialInformationVariable';
import { getFinancialInformation } from 'thunks';
import { getItemFromSessionStorage } from 'utils/profund/sessionStorage';

export interface IFinancialInformation {
  [FinancialInformationVariable.AnnualIncome]: string;
  [FinancialInformationVariable.SocialSecurityNumber]: string;
  [FinancialInformationVariable.DateOfBirth]: string;
  [FinancialInformationVariable.PropertyStatus]: string | null;
  [FinancialInformationVariable.CreditScore]: string;
  [FinancialInformationVariable.EmploymentStatus]: string | null;
  [FinancialInformationVariable.PayFrequency]: string | null;
  [FinancialInformationVariable.HighestEducationLevel]: string | null;
  [CoborrowerVariable.FirstName]: string;
  [CoborrowerVariable.LastName]: string;
  [CoborrowerVariable.DateOfBirth]: string;
  [CoborrowerVariable.AnnualIncome]: string;
  [CoborrowerVariable.StreetName]: string;
  [CoborrowerVariable.StreetNumber]: string;
  [CoborrowerVariable.ApartmentOrSuite]: string;
  [CoborrowerVariable.City]: string;
  [CoborrowerVariable.State]: string;
  [CoborrowerVariable.ZipCode]: string;
  [CoborrowerVariable.Email]: string;
  [CoborrowerVariable.PropertyStatus]: string | null;
  [CoborrowerVariable.SSN]: string;
  [CoborrowerVariable.Phone]: string;
}

export interface IFinancialInformationState {
  data: IFinancialInformation;
}

const coborrowerInitialState = {
  [CoborrowerVariable.AnnualIncome]: getItemFromSessionStorage(CoborrowerVariable.AnnualIncome),
  [CoborrowerVariable.FirstName]: '',
  [CoborrowerVariable.LastName]: '',
  [CoborrowerVariable.DateOfBirth]: '',
  [CoborrowerVariable.StreetName]: '',
  [CoborrowerVariable.StreetNumber]: '',
  [CoborrowerVariable.ApartmentOrSuite]: '',
  [CoborrowerVariable.City]: '',
  [CoborrowerVariable.State]: '',
  [CoborrowerVariable.ZipCode]: '',
  [CoborrowerVariable.Email]: '',
  [CoborrowerVariable.PropertyStatus]: null,
  [CoborrowerVariable.SSN]: '',
  [CoborrowerVariable.Phone]: '',
};

const initialState: IFinancialInformationState = {
  data: {
    [FinancialInformationVariable.AnnualIncome]: getItemFromSessionStorage(
      FinancialInformationVariable.AnnualIncome,
    ),
    [FinancialInformationVariable.SocialSecurityNumber]: '',
    [FinancialInformationVariable.DateOfBirth]: '',
    [FinancialInformationVariable.PropertyStatus]: DEFAULT_PROPERTY_STATUS_VALUE,
    [FinancialInformationVariable.CreditScore]:
      getItemFromSessionStorage(FinancialInformationVariable.CreditScore) || '',
    [FinancialInformationVariable.EmploymentStatus]: DEFAULT_EMPLOYMENT_STATUS_VALUE,
    [FinancialInformationVariable.PayFrequency]: null,
    [FinancialInformationVariable.HighestEducationLevel]: null,
    ...coborrowerInitialState,
  },
};

const financialInformationSlice = createSlice({
  name: 'financialInformation',
  initialState,
  reducers: {
    removeCoborrower: (state) => {
      state.data = {
        ...state.data,
        ...coborrowerInitialState,
        [CoborrowerVariable.AnnualIncome]: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFinancialInformation.fulfilled, (state, { payload }) => {
      state.data = { ...state.data, ...payload };
    });
  },
});

export const { removeCoborrower } = financialInformationSlice.actions;

export default financialInformationSlice.reducer;
