import React, { FC, useMemo, useState } from 'react';
import styles from './BankConnectionCardWrapper.module.scss';
import { DwollaLogo, InfoCircleIcon, PlaidLogo } from 'static/images';
import Popup from 'components/Popup';
import PlaidPopupContent from './PlaidPopupContent';
import DwollaPopupContent from './DwollaPopupContent';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  BankVerificationPageTranslationKeys,
  DwollaInfoPopupTranslationKeys,
  PlaidInfoPopupTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';

export enum BankConnectionType {
  Instant = 'instant',
  Standard = 'standard',
}

interface IBankConnectionCardProps {
  bankConnectionType: BankConnectionType;
  additionalText?: string;
  showSelect?: boolean;
}

const LOGOS = {
  [BankConnectionType.Instant]: <PlaidLogo />,
  [BankConnectionType.Standard]: <DwollaLogo />,
};

const POPUP_CONTENT = {
  [BankConnectionType.Instant]: <PlaidPopupContent />,
  [BankConnectionType.Standard]: <DwollaPopupContent />,
};

const BankConnectionCardWrapper: FC<IBankConnectionCardProps> = ({
  bankConnectionType,
  additionalText,
  showSelect,
  children,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { t: translate } = useTranslation([
    TranslationNameSpaces.BankVerificationPage,
    TranslationNameSpaces.Popups,
  ]);

  const [connectionType, connectionDescription, popUpTitle] = useMemo(() => {
    const CONNECTION_TYPES = {
      [BankConnectionType.Instant]: translate(
        BankVerificationPageTranslationKeys.InstantConnectionType,
        {
          ns: TranslationNameSpaces.BankVerificationPage,
        },
      ),
      [BankConnectionType.Standard]: translate(
        BankVerificationPageTranslationKeys.StandardConnectionType,
        {
          ns: TranslationNameSpaces.BankVerificationPage,
        },
      ),
    };

    const CONNECTION_DESCRIPTIONS = {
      [BankConnectionType.Instant]: translate(
        BankVerificationPageTranslationKeys.InstantConnectionDescription,
        {
          ns: TranslationNameSpaces.BankVerificationPage,
        },
      ),
      [BankConnectionType.Standard]: translate(
        BankVerificationPageTranslationKeys.StandardConnectionDescription,
        {
          ns: TranslationNameSpaces.BankVerificationPage,
        },
      ),
    };

    const POPUP_TITLES = {
      [BankConnectionType.Instant]: translate(PlaidInfoPopupTranslationKeys.Title, {
        ns: TranslationNameSpaces.Popups,
      }),
      [BankConnectionType.Standard]: translate(DwollaInfoPopupTranslationKeys.Title, {
        ns: TranslationNameSpaces.Popups,
      }),
    };

    return [
      CONNECTION_TYPES[bankConnectionType],
      CONNECTION_DESCRIPTIONS[bankConnectionType],
      POPUP_TITLES[bankConnectionType],
    ];
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={clsx(
          styles.infoContainer,
          additionalText && styles.infoContainerWithAdditionalText,
        )}
      >
        {additionalText ? <div className={styles.additionalText}>{additionalText}</div> : null}
        <InfoCircleIcon onClick={() => setIsPopupOpen(true)} className={styles.infoIcon} />
      </div>
      <div className={styles.description}>
        {showSelect ? (
          <div className={styles.select}>
            {translate(BankVerificationPageTranslationKeys.Select, {
              ns: TranslationNameSpaces.BankVerificationPage,
            })}
          </div>
        ) : null}
        <div className={styles.connectionType}>{connectionType}</div>
        <div className={styles.connectionDescription}>{connectionDescription}</div>
      </div>
      <div className={styles.childrenContainer}>{children}</div>
      <div className={styles.poweredBy}>
        <div>
          {translate(BankVerificationPageTranslationKeys.PoweredBy, {
            ns: TranslationNameSpaces.BankVerificationPage,
          })}
        </div>
        {LOGOS[bankConnectionType]}
      </div>
      <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} title={popUpTitle}>
        {POPUP_CONTENT[bankConnectionType]}
      </Popup>
    </div>
  );
};

export default BankConnectionCardWrapper;
