export enum StrategyId {
  EligibilityCheck = '642a6c7ae5ffdd4b730ed1c9',
  FraudCheck = '642a6c7ee5ffdd4b730f5677',
}

export enum ProdStrategyId {
  EligibilityCheck = '642a6c7de5ffdd4b730f3ffd',
  FraudCheck = '642a6c7de5ffdd4b730f4021',
}

export enum StrategyName {
  EligibilityCheck = 'EligibilityCheck',
  FraudCheck = 'FraudCheck',
}

export type StrategyIdType = StrategyId | ProdStrategyId;
