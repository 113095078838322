export enum FinancialInformationVariable {
  AnnualIncome = 'annualIncome',
  SocialSecurityNumber = 'socialSecurityNumber',
  DateOfBirth = 'dateOfBirth',
  PropertyStatus = 'propertyStatus',
  CreditScore = 'creditScore',
  EmploymentStatus = 'employmentStatus',
  PayFrequency = 'payFrequency',
  HighestEducationLevel = 'highestEducationLevel',
}
