import React, { useContext, useState } from 'react';
import DisclaimerAndMoreInformation from 'components/DisclaimerAndMoreInformation';
import DisclosuresBox from 'components/DisclosuresBox';
import FormButtons from 'components/FormButtons';
import ParticipatingLenderInfo from 'components/InformationBox/ParticipatingLenderInfo';
import PageLayout from 'components/PageLayout';
import { LayoutSize } from 'components/PageLayout/PageLayout';
import Paragraph from 'components/Paragraph';
import AdvertiserDisclosure from 'components/AdvertiserDisclosure';
import {
  ButtonsTranslationKeys,
  MenuTranslationKeys,
  OfferCardTranslationKeys,
  OfferConfirmationPageTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import { resetSelectedOffer } from 'utils/resetSelectedOffer';
import { useTranslation } from 'react-i18next';
import { convertToMonetary, convertToPercentage, termFormatter } from 'utils/profund/formats';
import { IOffer } from 'handlers/portal/offersSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { OfferPagesContext, OfferPagesStep } from 'pages/ApplicationProcess/OffersPage/OffersPage';
import Menu from 'components/Menu';
import Logo from 'components/Logo';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { getLoadingFields, getOffersData } from 'handlers/selectors';
import PartnerLogo from 'components/PartnerLogo';
import { OfferProviderNames } from 'enums/OfferProviderNames';
import { AnnouncementIcon } from 'static/images';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useLocation, useParams } from 'react-router-dom';
import {
  cleanApplications,
  getApplicationById,
  logout,
  updateApplication,
  sendOfferAgreementEmail,
} from 'thunks';
import { ApplicationVariable } from 'enums/ApplicationVariable';
import { getVariablesToUpdateAfterOfferConfirmation } from 'utils/getVariablesToUpdateAfterOfferConfirmation';
import { getVariablesToUpdateForOfferAgreement } from 'utils/getVariablesToUpdateForOfferAgreement';
import { useGetOfferStatusToDisplay } from 'hooks/useGetOfferStatusToDisplay';
import { ApplicationLabelName, ApplicationLabelType } from 'enums/ApplicationLabelId';
import { getApplicationStatusIdByEnvironment, matchApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';

import styles from './OfferConfirmation.module.scss';
import {
  getApplicationLabelIdByEnvironment,
  getApplicationLabelsIds,
  matchApplicationLabelByEnvironment,
} from 'utils/applicationLabelsHelpers';
import { setGlobalLoading } from 'handlers/portal/loadingSlice';
import { IApplication } from 'api/digifi/los/ApplicationsApi';

const OfferDetails = () => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.Buttons,
    TranslationNameSpaces.OfferConfirmationPage,
    TranslationNameSpaces.OffersPage,
  ]);
  const { currentOffer } = useAppSelector(getOffersData);

  if (!currentOffer) {
    return null;
  }

  const offerData = {
    loanAmount: currentOffer.loanAmount,
    monthlyPayment: currentOffer.monthlyPayment,
    termLength: currentOffer.termLength,
    apr: currentOffer.apr,
  };

  const getKeyByVariableName = (value: keyof IOffer) => {
    const dataMap: Partial<Record<keyof IOffer, string>> = {
      loanAmount: translate(OfferCardTranslationKeys.LoanAmount, {
        ns: TranslationNameSpaces.OffersPage,
      })!,
      apr:
        currentOffer.aprDescription ||
        translate(OfferCardTranslationKeys.FixedApr, {
          ns: TranslationNameSpaces.OffersPage,
        })!,
      termLength:
        currentOffer.termDescription ||
        translate(OfferCardTranslationKeys.TermOfLoan, {
          ns: TranslationNameSpaces.OffersPage,
        })!,
      monthlyPayment:
        currentOffer.monthlyPaymentDescription ||
        translate(OfferCardTranslationKeys.MonthlyPayment, {
          ns: TranslationNameSpaces.OffersPage,
        })!,
    };

    return dataMap[value];
  };

  const getFormatterByVariableName = (value: keyof IOffer) => {
    const dataMap: Record<string, (value: number) => string> = {
      loanAmount: convertToMonetary,
      monthlyPayment: convertToMonetary,
      termLength: (termValue: number) => termFormatter(termValue, currentOffer.termsUnit),
      apr: (aprValue: number) => convertToPercentage(aprValue / 100),
    };

    return dataMap[value];
  };

  const isOriginationFeeNeedToDisplay =
    currentOffer.originationFee && currentOffer.originationFee.max > 0;

  return (
    <div>
      <div className={styles.title}>
        {translate(OfferConfirmationPageTranslationKeys.Details, {
          ns: TranslationNameSpaces.OfferConfirmationPage,
        })}
      </div>
      <div className={isOriginationFeeNeedToDisplay ? styles.infoWithOriginationFee : styles.info}>
        {(Object.keys(offerData) as (keyof typeof offerData)[]).map((key) => {
          const formatter = getFormatterByVariableName(key);

          return (
            <div className={styles.detail} key={key}>
              <div className={styles.key}>{getKeyByVariableName(key)}</div>
              <div className={styles.value}>{formatter(currentOffer[key])}</div>
            </div>
          );
        })}
        {isOriginationFeeNeedToDisplay && (
          <div className={styles.detail}>
            <div className={styles.key}>
              {translate(OfferCardTranslationKeys.OriginationFee, {
                ns: TranslationNameSpaces.OffersPage,
              })}
            </div>
            <div className={styles.value}>
              {convertToPercentage(currentOffer.originationFee!.min / 100)} {' - '}
              {convertToPercentage(currentOffer.originationFee!.max / 100)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const OfferConfirmation = () => {
  const { applicationId } = useParams();
  const location = useLocation();
  const webLeadHash = location.hash === '#web-lead'; 

  const { t: translate } = useTranslation();

  const { currentOffer } = useAppSelector(getOffersData);
  const { isUpdateApplicationInProgress, isGetApplicationByIdInProgress } =
    useAppSelector(getLoadingFields);

  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();

  const { disclaimer, partnerLogoUrl, preApproved, preQualified, offerProvider, link } =
    currentOffer! || {};

    const offerCardStatus = useGetOfferStatusToDisplay(preQualified, preApproved);

  const { setCurrentStep } = useContext(OfferPagesContext);

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [isHandleAgreementLoading, setIsHandleAgreementLoading] = useState(false);

  const isOfferFromEven = offerProvider === OfferProviderNames.Even;

  const getApplication = async () => {
    return dispatchWithUnwrap(getApplicationById(applicationId!));
  };

  const handleSaveForLater = () => {
    setCurrentStep(OfferPagesStep.SaveForLater);
  };

  const handleConfirm = async () => {
    try {
      setIsConfirmLoading(true);

      const application = await getApplication();

      const variables = getVariablesToUpdateAfterOfferConfirmation(
        currentOffer!,
        application.variables[ApplicationVariable.AgreedJobAmount] as number | null,
      );

      if (isOfferFromEven) {
        await dispatchWithUnwrap(
          updateApplication({
            applicationId: applicationId!,
            params: {
              statusId: getApplicationStatusIdByEnvironment(ApplicationStatusName.BankVerification),
              variables,
            },
          }),
        );
      } else {
        await dispatchWithUnwrap(
          updateApplication({
            applicationId: applicationId!,
            params: {
              statusId: getApplicationStatusIdByEnvironment(ApplicationStatusName.Approved),
              variables,
              labelsIds: [
                ...(getApplicationLabelsIds(application.labels) as ApplicationLabelType[]),
                getApplicationLabelIdByEnvironment(ApplicationLabelName.PendingLenderDecision),
              ],
            },
          }),
        );

        dispatch(setGlobalLoading(true));
        dispatch(cleanApplications({ applicationId: applicationId! }));
        dispatch(logout());

        window.open(link);
      }
    } finally {
      setIsConfirmLoading(false);
      dispatch(setGlobalLoading(false));
    }
  };

  const getIsAgreementStatus = (application: IApplication) => {
    const isOfferAgreementStatus = matchApplicationStatusIdByEnvironment(
      application.status.id,
      ApplicationStatusName.OfferAgreement,
    );

    return isOfferAgreementStatus;
  }

  const handleAgreement = async () => {
    try {
      setIsHandleAgreementLoading(true);

      const application = await getApplication();

      const isOfferAgreementStatus = getIsAgreementStatus(application);

      if (!isOfferAgreementStatus) {
        const variables = getVariablesToUpdateForOfferAgreement(
          currentOffer!,
          application.variables[ApplicationVariable.AgreedJobAmount] as number | null,
        );

        const oldLabels = getApplicationLabelsIds(application.labels)
          .filter(label => 
            !matchApplicationLabelByEnvironment(label, ApplicationLabelName.WebLeadApplication),
          ) as ApplicationLabelType[];

        await Promise.all([
          dispatchWithUnwrap(
            updateApplication({
              applicationId: applicationId!,
              params: {
                statusId: getApplicationStatusIdByEnvironment(ApplicationStatusName.OfferAgreement),
                variables,
                labelsIds: [
                  ...oldLabels,
                  getApplicationLabelIdByEnvironment(ApplicationLabelName.WebLeadApplication),
                ],
              },
            }),
          ),
          dispatchWithUnwrap(
            sendOfferAgreementEmail(applicationId!),
          ),
        ])
      }

      setCurrentStep(OfferPagesStep.FinalizeTheOffer);
    } finally {
      setIsHandleAgreementLoading(false);
    }
  }

  const isOriginationFeeNeedToDisplay =
    currentOffer?.originationFee && currentOffer.originationFee.max > 0;

  const getPrimaryButtonTitle = () => {
    let buttonKey = ButtonsTranslationKeys.AgreeAndContinue;
    if (isOfferFromEven) buttonKey = ButtonsTranslationKeys.Confirm;
    if (webLeadHash) buttonKey = ButtonsTranslationKeys.ProceedWithOffer;

    return translate(buttonKey, {
      ns: TranslationNameSpaces.Buttons,
    });
  }

  const handleBack = async () => {
    const application = await getApplication();
    
    const isOfferAgreementStatus = getIsAgreementStatus(application);

    if (isOfferAgreementStatus) {
      await dispatchWithUnwrap(
        updateApplication({
          applicationId: applicationId!,
          params: {
            statusId: getApplicationStatusIdByEnvironment(ApplicationStatusName.OfferSelection),
            variables: resetSelectedOffer(
              application.variables[ApplicationVariable.InitialLoanAmount] as number,
              {
                [ApplicationVariable.FinalOfferUpgradePlanDescription]: null,
                [ApplicationVariable.FinalOfferUpgradePlanCategory]: null,
                [ApplicationVariable.FinalOfferUpgradePlanNumber]: null,
              },
            ),
          },
        }),
      );
    }

    setCurrentStep(OfferPagesStep.Offers)
  }

  return (
    <div>
      <Menu activeMenuItem={MenuTranslationKeys.Applications} />
      <PageLayout layoutSize={LayoutSize.Big}>
        <AdvertiserDisclosure 
          onBackClick={handleBack} 
          backButtonTitle={webLeadHash 
            ? translate(ButtonsTranslationKeys.BackToOffers, {
                ns: TranslationNameSpaces.Buttons,
              }) 
            : translate(ButtonsTranslationKeys.Back, { 
                ns: TranslationNameSpaces.Buttons,
              })
          }
        />
        <Logo>
          <PartnerLogo finalOfferProviderName={offerProvider} logoLink={partnerLogoUrl} />
        </Logo>
        <div className={styles.status}>{offerCardStatus}</div>
        {!isOfferFromEven && (
          <div className={styles.labelWrapper}>
            <AnnouncementIcon />
            <p className={styles.label}>
              {translate(OfferConfirmationPageTranslationKeys.LabelText, {
                ns: TranslationNameSpaces.OfferConfirmationPage,
              })}
            </p>
          </div>
        )}
        {isOriginationFeeNeedToDisplay ? (
          <Paragraph marginBottom={isOfferFromEven ? '32' : '8'}>
            {translate(OfferConfirmationPageTranslationKeys.OriginationFeeDescription, {
              ns: TranslationNameSpaces.OfferConfirmationPage,
            })}
          </Paragraph>
        ) : (
          <Paragraph marginBottom={isOfferFromEven ? '32' : '8'}>
            {translate(OfferConfirmationPageTranslationKeys.Description, {
              ns: TranslationNameSpaces.OfferConfirmationPage,
            })}
          </Paragraph>
        )}
        {!isOfferFromEven && (
          <Paragraph marginBottom="32">
            {translate(OfferConfirmationPageTranslationKeys.waterCressDescription, {
              ns: TranslationNameSpaces.OfferConfirmationPage,
            })}
          </Paragraph>
        )}
        <OfferDetails />
        <DisclaimerAndMoreInformation disclaimer={disclaimer} />
        <FormButtons
          primaryTitle={getPrimaryButtonTitle()}
          secondaryTitle={translate(ButtonsTranslationKeys.SaveForLater, {
            ns: TranslationNameSpaces.Buttons,
          })}
          onPrimaryClick={handleConfirm}
          onSecondaryClick={handleSaveForLater}
          isPrimaryLoading={isConfirmLoading}
          disablePrimary={isUpdateApplicationInProgress || isGetApplicationByIdInProgress}
          primaryButtonClassName={styles.submitButton}
        >
          {webLeadHash && (
            <Button
              variant={ButtonVariant.Secondary}
              size={ButtonSize.Large}
              onClick={handleAgreement}
              isLoading={isHandleAgreementLoading}
              disabled={isUpdateApplicationInProgress || isGetApplicationByIdInProgress}
              className={styles.agreementButton}
            >
              Discuss options with contractor
            </Button>
          )}
        </FormButtons>
        <DisclosuresBox />
        <ParticipatingLenderInfo />
      </PageLayout>
    </div>
  );
};

export default OfferConfirmation;
