import React, { FC } from 'react';
import { TypographySize } from 'enums/TypographySize';
import styles from './Subtitle.module.scss';
import clsx from 'clsx';

interface ISubtitleProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  variant?: TypographySize;
}

const getSubtitleClassName = (variant?: TypographySize, className?: string) => {
  switch (variant) {
    case TypographySize.Large:
      return clsx(styles.lgSubtitle, className);
    case TypographySize.Middle:
      return clsx(styles.mdSubtitle, className);
    case TypographySize.Small:
      return clsx(styles.smSubtitle, className);
    default:
      return clsx(styles.mdSubtitle, className);
  }
};

const Subtitle: FC<ISubtitleProps> = ({ children, variant, className, ...props }) => {
  return (
    <p className={getSubtitleClassName(variant, className)} {...props}>
      {children}
    </p>
  );
};

export default Subtitle;
