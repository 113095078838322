import { IManageAccountApi, IManageAccountBody } from 'api/digifi/portal/ManageAccountApi';

import { ManageAccountActionTypes } from 'enums/ManageAccountActionTypes';
import { createAsyncThunk } from '@reduxjs/toolkit';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createReopenAccountThunk = (api: IManageAccountApi) =>
  createAsyncThunk(
    ManageAccountActionTypes.ReopenAccount,
    async (body: IManageAccountBody, thunkApi) => {
      try {
        return await api.reopenAccount(body);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
