import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IApplication } from 'api/digifi/los/ApplicationsApi';
import { getIsReopenAccountTimeExpired } from 'utils/profund/getIsReopenAccountTimeExpired';
import { ApplicationDetailsPageTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { ApplicationLabelName, ApplicationLabelType } from 'enums/ApplicationLabelId';
import { ApplicationVariable } from 'enums/ApplicationVariable';
import ApplicationMonetaryBox from 'components/ApplicationMonetaryBox';
import ManageFunds, { ManageFundsType } from 'components/ManageFunds';
import CloseAccount from 'components/CloseAccount';
import PageNotification from 'components/PageNotification';
import ReopenAccount from 'components/ReopenAccount';
import {
  IPaymentHistoryItem,
  PaymentsHistoryStatus,
  PaymentsHistoryType,
} from 'components/PaymentHistoryItem';
import { MoneyIcon, MoreIcon, RefreshIcon, WalletIcon } from 'static/images';

import styles from './ProjectBalance.module.scss';
import { TooltipId } from 'enums/TooltipId';
import {
  getApplicationLabelIdByEnvironment,
  matchApplicationLabelByEnvironment,
} from 'utils/applicationLabelsHelpers';

export enum AccountActionType {
  Reopen = 'reopen',
  Close = 'close',
}

const getManageMenu = (
  selectedApplication: IApplication,
  projectAccountBalance: number | undefined,
  manageFunds: (type: ManageFundsType) => void,
  setIsCloseAccount: () => void,
  setIsReopenAccount: () => void,
) => {
  const isCloseAccount = selectedApplication.labels.find((item) =>
    matchApplicationLabelByEnvironment(item.id, ApplicationLabelName.CloseAccount),
  );

  if (isCloseAccount) {
    const isCloseAccountInProgress = !getIsReopenAccountTimeExpired(
      selectedApplication.variables[ApplicationVariable.CloseAccountDateAndTime] as
        | string
        | undefined,
    );

    if (isCloseAccountInProgress) {
      return [
        { title: 'Reopen account', icon: <RefreshIcon />, onClick: () => setIsReopenAccount() },
      ];
    }
  }

  if (projectAccountBalance === 0) {
    return [
      {
        title: 'Add Funds',
        icon: <MoneyIcon />,
        onClick: () => manageFunds(ManageFundsType.AddFunds),
      },
    ];
  }

  return [
    {
      title: 'Request Funds',
      icon: <WalletIcon />,
      onClick: () => manageFunds(ManageFundsType.RequestFunds),
    },
    {
      title: 'Add Funds',
      icon: <MoneyIcon />,
      onClick: () => manageFunds(ManageFundsType.AddFunds),
    },
    { title: 'More', icon: <MoreIcon />, onClick: () => setIsCloseAccount() },
  ];
};

interface IProjectBalanceProps {
  projectAccountBalance: number | undefined;
  agreedJobAmount: number | undefined;
  loanAmount: number | undefined;
  hideManage: boolean;
  selectedApplication: IApplication;
  applicationId: string;
  labels: ApplicationLabelType[];
  paymentsHistory: undefined | IPaymentHistoryItem[];
  projectAccountBalanceCurrency?: string;
}

const ProjectBalance = ({
  projectAccountBalance,
  agreedJobAmount,
  hideManage,
  loanAmount,
  selectedApplication,
  applicationId,
  labels,
  paymentsHistory,
  projectAccountBalanceCurrency,
}: IProjectBalanceProps) => {
  const { t: translate } = useTranslation([TranslationNameSpaces.ApplicationDetailsPage]);
  const [isCloseAccount, setIsCloseAccount] = useState(false);
  const [isReopenAccount, setIsReopenAccount] = useState(false);
  const [isManageFunds, setIsManageFunds] = useState(false);
  const [manageType, setManageType] = useState<ManageFundsType | undefined>(undefined);
  const [accountSuccessMessage, setAccountSuccessMessage] = useState<false | AccountActionType>(
    false,
  );

  const getTitleLabel = () => {
    if (labels.includes(getApplicationLabelIdByEnvironment(ApplicationLabelName.RequestFunds))) {
      return translate(ApplicationDetailsPageTranslationKeys.RequestFundsLabel, {
        ns: TranslationNameSpaces.ApplicationDetailsPage,
      });
    }

    if (labels.includes(getApplicationLabelIdByEnvironment(ApplicationLabelName.AddFunds))) {
      return translate(ApplicationDetailsPageTranslationKeys.AddFundsLabel, {
        ns: TranslationNameSpaces.ApplicationDetailsPage,
      });
    }

    if (labels.includes(getApplicationLabelIdByEnvironment(ApplicationLabelName.ReopenAccount))) {
      return translate(ApplicationDetailsPageTranslationKeys.ReopenAccountLabel, {
        ns: TranslationNameSpaces.ApplicationDetailsPage,
      });
    }

    if (labels.includes(getApplicationLabelIdByEnvironment(ApplicationLabelName.CloseAccount))) {
      return translate(ApplicationDetailsPageTranslationKeys.CloseAccountLabel, {
        ns: TranslationNameSpaces.ApplicationDetailsPage,
      });
    }

    return;
  };

  const getAccountSuccessMessage = () => {
    switch (accountSuccessMessage) {
      case AccountActionType.Close:
        return translate(ApplicationDetailsPageTranslationKeys.CloseAccountSuccessMessage, {
          ns: TranslationNameSpaces.ApplicationDetailsPage,
        });

      case AccountActionType.Reopen:
        return translate(ApplicationDetailsPageTranslationKeys.ReopenAccountSuccessMessage, {
          ns: TranslationNameSpaces.ApplicationDetailsPage,
        });

      default:
        return null as never;
    }
  };

  const getAvailableForAddAmount = () => {
    if (!paymentsHistory || !paymentsHistory.length) {
      return loanAmount && isAgreedJobAmount ? loanAmount - agreedJobAmount! : undefined;
    }

    const getSuccessfulTransactions = paymentsHistory.filter(
      (item) =>
        item.type === PaymentsHistoryType.RequestFunds &&
        item.status === PaymentsHistoryStatus.Successful,
    );

    const amounts = getSuccessfulTransactions.map((item) => item.amount);

    const getSuccessfulTransactionsAmount =
      paymentsHistory && paymentsHistory.length
        ? amounts.reduce((previousValue, currentValue) => {
            return previousValue + currentValue;
          }, 0)
        : 0;

    return loanAmount && agreedJobAmount
      ? loanAmount - agreedJobAmount - getSuccessfulTransactionsAmount
      : undefined;
  };

  const isProjectAccountBalance = !!projectAccountBalance || projectAccountBalance === 0;
  const isAgreedJobAmount = !!agreedJobAmount || agreedJobAmount === 0;

  return (
    <ApplicationMonetaryBox
      title={translate(ApplicationDetailsPageTranslationKeys.ProjectAccountBalance, {
        ns: TranslationNameSpaces.ApplicationDetailsPage,
      })}
      tooltip={translate(ApplicationDetailsPageTranslationKeys.ProjectAccountBalanceTooltip, {
        ns: TranslationNameSpaces.ApplicationDetailsPage,
      })}
      amount={projectAccountBalance}
      manageMenu={
        hideManage
          ? undefined
          : getManageMenu(
              selectedApplication,
              projectAccountBalance,
              (type) => {
                setManageType(type);
                setIsManageFunds(true);
              },
              () => setIsCloseAccount(true),
              () => setIsReopenAccount(true),
            )
      }
      label={getTitleLabel()}
      currency={{
        type: projectAccountBalanceCurrency,
        check: true,
      }}
      id={TooltipId.ProjectAccountBalance}
    >
      {isManageFunds && (
        <div className={styles.actionContainer}>
          <ManageFunds
            handleClose={() => setIsManageFunds(false)}
            availableForAddAmount={getAvailableForAddAmount()}
            availableForRequestAmount={isProjectAccountBalance ? projectAccountBalance : undefined}
            applicationId={applicationId}
            manageType={manageType!}
          />
        </div>
      )}
      {isCloseAccount && (
        <div className={styles.actionContainer}>
          <CloseAccount
            handleClose={() => setIsCloseAccount(false)}
            projectAccountBalance={projectAccountBalance}
            intermediary={
              (selectedApplication.variables[ApplicationVariable.IntermediaryDba] ||
                selectedApplication.variables[ApplicationVariable.IntermediaryName]) as string
            }
            applicationId={applicationId}
            setSuccessMessage={setAccountSuccessMessage}
          />
        </div>
      )}
      {isReopenAccount && (
        <div className={styles.actionContainer}>
          <ReopenAccount
            isOpen={isReopenAccount}
            setIsOpen={setIsReopenAccount}
            applicationId={applicationId}
            setSuccessMessage={setAccountSuccessMessage}
          />
        </div>
      )}
      {accountSuccessMessage && (
        <PageNotification
          handleClose={() => setAccountSuccessMessage(false)}
          notification={getAccountSuccessMessage()}
        />
      )}
    </ApplicationMonetaryBox>
  );
};

export default ProjectBalance;
