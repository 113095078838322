import { createAsyncThunk } from '@reduxjs/toolkit';
import { IBorrower, IBorrowersApi } from 'api/digifi/los/BorrowersApi';
import { FormLayoutData } from 'api/Types';
import { BorrowersActionType } from 'enums/BorrowerActionTypes';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createUpdateBorrowerThunk = (api: IBorrowersApi) =>
  createAsyncThunk<IBorrower, { variables: FormLayoutData }>(
    BorrowersActionType.UpdateBorrower,
    async ({ variables }, thunkApi) => {
      try {
        return await api.updateBorrower(variables);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
