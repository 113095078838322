import { createContext, useContext } from 'react';
import StrategyApi from 'api/digifi/portal/StrategyApi';

export interface IApiProviderValue {
  strategyApi: StrategyApi;
}

type Services = StrategyApi;

const ApiContext = createContext<IApiProviderValue>({} as IApiProviderValue);

const getService =
  <ServiceType extends Services>(serviceName: keyof IApiProviderValue) =>
  () => {
    const services = useContext(ApiContext);

    return services[serviceName] as ServiceType;
  };

export const useStrategyApi = getService<StrategyApi>('strategyApi');

const ApiProvider = ApiContext.Provider;

export default ApiProvider;
