import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import {
  ButtonsTranslationKeys,
  MenuTranslationKeys,
  OffersPageTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import DisclosuresBox from 'components/DisclosuresBox';
import { LayoutSize } from 'components/PageLayout/PageLayout';
import Menu from 'components/Menu';
import OffersCategories from 'pages/ApplicationProcess/OffersPage/OffersComponents';
import PageLayout from 'components/PageLayout';
import Paragraph from 'components/Paragraph';
import ParticipatingLenderInfo from 'components/InformationBox/ParticipatingLenderInfo';
import React, { useContext } from 'react';
import Title from 'components/Title';
import { TypographySize } from 'enums/TypographySize';
import { useTranslation } from 'react-i18next';
import styles from './Offers.module.scss';
import { OfferPagesContext, OfferPagesStep } from 'pages/ApplicationProcess/OffersPage/OffersPage';
import AdvertiserDisclosure from 'components/AdvertiserDisclosure';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setShowOffersWithoutEven } from 'handlers/portal/offersSlice';
import { getOffersData } from 'handlers/selectors';

const Offers = () => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.OffersPage,
    TranslationNameSpaces.Buttons,
    TranslationNameSpaces.OfferConfirmationPage,
  ]);

  const { setCurrentStep } = useContext(OfferPagesContext);
  const dispatch = useAppDispatch();
  const { showOffersWithoutEven } = useAppSelector(getOffersData);

  const handleSaveForLater = () => {
    setCurrentStep(OfferPagesStep.SaveForLater);
  };

  const handleSwitchToSeeAllOffers = () => {
    dispatch(setShowOffersWithoutEven(false));
  };

  return (
    <>
      <Menu activeMenuItem={MenuTranslationKeys.Applications} />
      <PageLayout layoutSize={LayoutSize.Small}>
        <AdvertiserDisclosure />
        <Title marginBottom="16" variant="h1">
          {translate(OffersPageTranslationKeys.Title, { ns: TranslationNameSpaces.OffersPage })}
        </Title>
        <Paragraph marginBottom="32" variant={TypographySize.Middle}>
          {translate(OffersPageTranslationKeys.Description, {
            ns: TranslationNameSpaces.OffersPage,
          })}
        </Paragraph>
        {showOffersWithoutEven ? (
          <div className={styles.seeAllOffersButtonContainer}>
            <Button
              variant={ButtonVariant.Primary}
              size={ButtonSize.Middle}
              onClick={handleSwitchToSeeAllOffers}
              className={styles.button}
            >
              {translate(ButtonsTranslationKeys.AllOffers, { ns: TranslationNameSpaces.Buttons })}
            </Button>
          </div>
        ) : null}
        <OffersCategories />
        <div className={styles.buttonWrapper}>
          <Button
            variant={ButtonVariant.Secondary}
            size={ButtonSize.Middle}
            onClick={handleSaveForLater}
          >
            {translate(ButtonsTranslationKeys.SaveForLater, { ns: TranslationNameSpaces.Buttons })}
          </Button>
        </div>
        <DisclosuresBox />
        <ParticipatingLenderInfo />
      </PageLayout>
    </>
  );
};

export default Offers;
