import { createAsyncThunk } from '@reduxjs/toolkit';
import IntermediariesApi from 'api/digifi/portal/IntermediariesApi';
import { openToaster } from 'components/Toaster/Toaster';
import { IntermediaryActionType } from 'enums/IntermediaryActionType';
import { RequestError } from 'errors/RequestError';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

const NOT_FOUND_MESSAGE = 'This Contractor does not exist.';

export const createGetIntermediaryByTokenThunk = (api: IntermediariesApi) =>
  createAsyncThunk(IntermediaryActionType.GetByToken, async (id: string, thunkApi) => {
    try {
      return await api.getByToken(id);
    } catch (error) {
      const { responseStatus } = error as unknown as RequestError;

      openToaster.error(responseStatus < 500 ? NOT_FOUND_MESSAGE : undefined);
      return rejectWithValueHelper(error as Error, thunkApi, true);
    }
  });
