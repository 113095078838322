import { createAsyncThunk } from '@reduxjs/toolkit';
import AccountsRestApi from 'api/digifi/auth/AccountsApi';
import { AccountsActionType } from 'enums/AccountActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createGetCurrentAccountThunk = (api: AccountsRestApi) =>
  createAsyncThunk(AccountsActionType.GetCurrentAccount, async (_, thunkApi) => {
    try {
      return await api.getCurrentAccount();
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
