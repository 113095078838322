import React, { FC, HTMLAttributeAnchorTarget } from 'react';
import clsx from 'clsx';

import styles from './Link.module.scss';

enum LinkType {
  Small = 'small',
  Middle = 'middle',
}

interface ILinkProps {
  href: string;
  type?: LinkType;
  rel?: string;
  target?: HTMLAttributeAnchorTarget;
  className?: string;
}

const getLinkStyle = (type: LinkType) => {
  switch (type) {
    case LinkType.Small:
      return styles.linkSm;

    case LinkType.Middle:
      return styles.linkMd;
  }
};

const Link: FC<ILinkProps> = ({
  children,
  href,
  type = LinkType.Middle,
  rel,
  target,
  className,
}) => {
  return (
    <a
      className={clsx(styles.link, getLinkStyle(type), className)}
      target={target}
      href={href}
      rel={rel}
    >
      {children}
    </a>
  );
};

export default Link;
