import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { IApplication } from 'api/digifi/los/ApplicationsApi';
import { ManageAccountEndpoints } from './PortalEndpoints';

export interface IManageFundsBody {
  applicationId: string;
  amount: number;
}

export interface IManageAccountBody {
  applicationId: string;
}

export interface IManageAccountApi {
  requestFunds(body: IManageFundsBody): Promise<IApplication>;
  addFunds(body: IManageFundsBody): Promise<IApplication>;
  closeAccount(body: IManageAccountBody): Promise<IApplication>;
  reopenAccount(body: IManageAccountBody): Promise<IApplication>;
}

export default class ManageAccountApi extends BaseApi implements IManageAccountApi {
  public requestFunds(body: IManageFundsBody): Promise<IApplication> {
    return this.fetch(ManageAccountEndpoints.RequestFunds, {
      method: FetchMethodType.POST,
      body,
    });
  }

  public addFunds(body: IManageFundsBody): Promise<IApplication> {
    return this.fetch(ManageAccountEndpoints.AddFunds, {
      method: FetchMethodType.POST,
      body,
    });
  }

  public closeAccount(body: IManageAccountBody): Promise<IApplication> {
    return this.fetch(ManageAccountEndpoints.CloseAccount, {
      method: FetchMethodType.POST,
      body,
    });
  }

  public reopenAccount(body: IManageAccountBody): Promise<IApplication> {
    return this.fetch(ManageAccountEndpoints.ReopenAccount, {
      method: FetchMethodType.POST,
      body,
    });
  }
}
