import React, { useEffect, useState } from 'react';
import Title from 'components/Title';
import Paragraph from 'components/Paragraph';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import {
  ButtonsTranslationKeys,
  EligibilityCheckFailedPageTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import { TypographySize } from 'enums/TypographySize';
import { useTranslation } from 'react-i18next';
import PageLayout from 'components/PageLayout';

import styles from './EligibilityCheckFailedPage.module.scss';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'routes/AppRoutes';

const EligibilityCheckFailedPage = () => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.EligibilityCheckFailedPage,
    TranslationNameSpaces.Buttons,
  ]);
  const [requirements, setRequirements] = useState<string[] | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!requirements) {
      const requirementItems = translate(EligibilityCheckFailedPageTranslationKeys.Requirements, {
        returnObjects: true,
        ns: TranslationNameSpaces.EligibilityCheckFailedPage,
      }) as string[];
      setRequirements(requirementItems);
    }
  }, [requirements]);

  if (!requirements) {
    return null;
  }

  return (
    <PageLayout>
      <Title marginBottom="16" variant="h1">
        {translate(EligibilityCheckFailedPageTranslationKeys.Title, {
          ns: TranslationNameSpaces.EligibilityCheckFailedPage,
        })}
      </Title>
      <Paragraph marginBottom="32" variant={TypographySize.Large}>
        {translate(EligibilityCheckFailedPageTranslationKeys.Description, {
          ns: TranslationNameSpaces.EligibilityCheckFailedPage,
        })}
      </Paragraph>
      <ul className={styles.requirementList}>
        {requirements?.map((requirement) => (
          <li className={styles.requirementItem} key={requirement}>
            {requirement}
          </li>
        ))}
      </ul>
      <Button
        variant={ButtonVariant.Primary}
        size={ButtonSize.Large}
        onClick={() => navigate(AppRoutes.Profile)}
      >
        {translate(ButtonsTranslationKeys.GoToProfileButton, { ns: TranslationNameSpaces.Buttons })}
      </Button>
    </PageLayout>
  );
};
export default EligibilityCheckFailedPage;
