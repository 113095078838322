import { getActionType } from './getActionType';
import { getAsyncActionState } from './getAsyncActionState';
import { Action, AsyncThunk } from '@reduxjs/toolkit';
import { ISliceStateWithLoadingFields, LoadingFields } from 'types/SliceWithLoadingFields';

export function matchLoadingActions(...actions: AsyncThunk<any, any, any>[]) {
  const asyncActions = actions
    .map((action) => [action.pending, action.fulfilled, action.rejected])
    .flat();
  return (action: any) => {
    return asyncActions.some((asyncAction) => asyncAction.type.match(action.type));
  };
}

export function getLoadingKeyByAction<A extends string, L extends ISliceStateWithLoadingFields>(
  { type }: Action<string>,
  loadingFields: LoadingFields<A, L>,
): LoadingFields<A, L>[A] {
  const actionState = getAsyncActionState(type);
  const actionType = getActionType<A>(type, actionState);
  return loadingFields[actionType];
}

export function getLoadingValueByAction<A extends string, L extends ISliceStateWithLoadingFields>(
  { type }: Action<string>,
  loadingFields: LoadingFields<A, L>,
): { loadingFieldKey: LoadingFields<A, L>[A]; loadingValue: boolean } {
  const actionState = getAsyncActionState(type);
  const actionType = getActionType<A>(type, actionState);
  const loadingFieldKey = loadingFields[actionType];
  const loadingValue = actionState === 'pending' ? true : false;
  return {
    loadingFieldKey,
    loadingValue,
  };
}
