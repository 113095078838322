import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import {
  ButtonsTranslationKeys,
  EditPhoneModalTranslationKeys,
  MenuTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import ProfileItem, { IProfileItem } from 'components/ProfileItem';
import React, { useEffect, useState } from 'react';

import { BorrowerVariable } from 'enums/BorrowerVariable';
import { ISimplifiedBorrowerParams } from 'api/digifi/los/BorrowersApi';
import { LayoutSize } from 'components/PageLayout/PageLayout';
import Loading from 'components/Loading';
import Menu from 'components/Menu';
import PageLayout from 'components/PageLayout';
import Paragraph from 'components/Paragraph';
import Popup from 'components/Popup';
import { ProfileIcon } from 'static/images';
import Title from 'components/Title';
import { TypographySize } from 'enums/TypographySize';
import { getBorrower } from 'thunks';
import { selectAllBorrowers } from 'handlers/borrowersSlice';
import { useAppSelector } from 'hooks/reduxHooks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useTranslation } from 'react-i18next';
import { SUPPORT_CENTER_LINK } from 'layout/ProFundMainLayout/ProFundMainLayout';

import styles from './Profile.module.scss';
import { getLoadingFields } from 'handlers/selectors';

const getData = (name: string, phone: string, email: string) => {
  return [
    {
      title: 'Name',
      value: name,
    },
    {
      title: 'Phone',
      value: phone,
    },
    {
      title: 'Email',
      value: email,
    },
  ] as IProfileItem[];
};

const getProfileContent = (currentBorrower: ISimplifiedBorrowerParams, editPhone: () => void) => {
  const name = `${currentBorrower.variables[BorrowerVariable.FirstName]} ${
    currentBorrower.variables[BorrowerVariable.LastName]
  }`;
  const phone = currentBorrower.variables[BorrowerVariable.PhoneNumber];
  const email = currentBorrower.variables[BorrowerVariable.Email];

  const data = getData(name, phone as string, email as string) as IProfileItem[];

  return data.map((item, index) => (
    <ProfileItem
      key={item.title}
      title={item.title}
      value={item.value}
      onEditClick={item.title === 'Phone' ? editPhone : item.onEditClick}
      className={index === data.length - 1 ? styles.removeBorder : undefined}
    />
  ));
};

const Profile = () => {
  const [isChangePhoneInProgress, setIsChangePhoneInProgress] = useState(false);
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  useEffect(() => {
    dispatchWithUnwrap(getBorrower());
  }, []);

  const { isGetBorrowerDataInProgress } = useAppSelector(getLoadingFields);

  const [borrower] = useAppSelector(selectAllBorrowers);

  const { t: translate } = useTranslation([
    TranslationNameSpaces.Menu,
    TranslationNameSpaces.EditPhoneModal,
    TranslationNameSpaces.Buttons,
  ]);

  if (isGetBorrowerDataInProgress || !borrower) {
    return <Loading />;
  }

  const getContent = () => {
    return (
      <PageLayout layoutSize={LayoutSize.Big}>
        <div className={styles.titleContainer}>
          <ProfileIcon className={styles.profileIcon} />
          <Title variant="h1">
            {translate(MenuTranslationKeys.Profile, { ns: TranslationNameSpaces.Menu })}
          </Title>
        </div>
        {getProfileContent(borrower, () => setIsChangePhoneInProgress(true))}
      </PageLayout>
    );
  };

  return (
    <div>
      <Menu activeMenuItem={MenuTranslationKeys.Profile} />
      {getContent()}
      <Popup
        isOpen={isChangePhoneInProgress}
        title={
          translate(EditPhoneModalTranslationKeys.Title, {
            ns: TranslationNameSpaces.EditPhoneModal,
          })!
        }
        closeIcon={false}
        isNotParagraph
      >
        <Paragraph variant={TypographySize.Middle} marginBottom="24">
          {translate(EditPhoneModalTranslationKeys.Description, {
            ns: TranslationNameSpaces.EditPhoneModal,
          })}
        </Paragraph>
        <div className={styles.buttonWrapper}>
          <Button
            variant={ButtonVariant.LinkLikeButton}
            size={ButtonSize.LinkLike}
            onClick={() => setIsChangePhoneInProgress(false)}
          >
            {translate(ButtonsTranslationKeys.Cancel, { ns: TranslationNameSpaces.Buttons })}
          </Button>
          <a target="_blank" rel="noopener noreferrer" href={SUPPORT_CENTER_LINK}>
            <Button variant={ButtonVariant.Primary} size={ButtonSize.Large}>
              {translate(ButtonsTranslationKeys.Support, { ns: TranslationNameSpaces.Buttons })}
            </Button>
          </a>
        </div>
      </Popup>
    </div>
  );
};

export default Profile;
