import { IManageAccountApi, IManageFundsBody } from 'api/digifi/portal/ManageAccountApi';

import { ManageAccountActionTypes } from 'enums/ManageAccountActionTypes';
import { createAsyncThunk } from '@reduxjs/toolkit';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createRequestFundsThunk = (api: IManageAccountApi) =>
  createAsyncThunk(
    ManageAccountActionTypes.RequestFunds,
    async (body: IManageFundsBody, thunkApi) => {
      try {
        return await api.requestFunds(body);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
