export enum UpgradePlanCategory {
  NO_INTEREST_NO_PAYMENT = 'NO_INTEREST_NO_PAYMENT',
  NO_INTEREST_YES_PAYMENT = 'NO_INTEREST_YES_PAYMENT',
  REDUCED_RATE = 'REDUCED_RATE',
  ZERO_INTEREST_RATE = 'ZERO_INTEREST_RATE',
  NO_INTEREST_NO_PAYMENT_ZERO_BILL = 'NO_INTEREST_NO_PAYMENT_ZERO_BILL',
  NO_INTEREST_YES_PAYMENT_ZERO_BILL = 'NO_INTEREST_YES_PAYMENT_ZERO_BILL',
  REDUCED_RATE_ZERO_BILL = 'REDUCED_RATE_ZERO_BILL',
  DEFERRED_INTEREST_NO_PAYMENT = 'DEFERRED_INTEREST_NO_PAYMENT',
  DEFERRED_INTEREST_YES_PAYMENT = 'DEFERRED_INTEREST_YES_PAYMENT',
  DEFERRED_INTEREST_NO_PAYMENT_ZERO_BILL = 'DEFERRED_INTEREST_NO_PAYMENT_ZERO_BILL',
  DEFERRED_INTEREST_YES_PAYMENT_ZERO_BILL = 'DEFERRED_INTEREST_YES_PAYMENT_ZERO_BILL',
}

export type UpgradePlanCategoryKeys = keyof typeof UpgradePlanCategory
