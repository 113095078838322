import { convertMonetaryValueToIntegerNumber } from './formats';

export const checkIfValueStartsWithZero = (value: string | undefined) => {
  if (!value) {
    return true;
  }

  const coercedValue = convertMonetaryValueToIntegerNumber(value);

  const firstDigit = coercedValue?.toString()[0];

  return firstDigit !== '0';
};
