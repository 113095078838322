import {
  RequestBody,
  getBasicHeaders,
  stringifyRequestBody,
  getResponseErrorData,
} from 'utils/fetch';
import { AppRoutes } from 'routes/AppRoutes';
import { HttpStatusCode } from 'enums/HttpStatusCode';
import history from 'routes/historyRouter';
import eventBus, { EventKey } from 'utils/eventBus';
import { RequestError } from 'errors/RequestError';

const FILENAME_FROM_HEADER_PATTERN = /filename="([^"]+)"/;

export enum FetchMethodType {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

interface IFetchOptions {
  headers?: Headers;
  method?: FetchMethodType;
  body?: RequestBody;
  resetDefaultHeaders?: boolean;
  checkAuthorization?: boolean;
  responseType?: string;
  includePasswordValidationToken?: boolean;
}

let resignAccessTokenPromise: Promise<boolean> | null = null;

class BaseApi {
  protected async fetch<Body>(url: string, options?: IFetchOptions): Promise<Body> {
    const {
      headers: customHeaders,
      method = FetchMethodType.GET,
      body,
      resetDefaultHeaders,
    } = options || {};

    const headers = resetDefaultHeaders ? new Headers() : getBasicHeaders();

    if (customHeaders) {
      customHeaders.forEach((value: string, header: string) => {
        headers.set(header, value);
      });
    }

    if (resignAccessTokenPromise) {
      await resignAccessTokenPromise;
    }

    const response = await fetch(`/api${url}`, {
      method,
      headers,
      body: stringifyRequestBody(body),
    });

    return this.processResponse(response, url, headers, options);
  }

  protected async download(url: string) {
    const response = await this.fetch<Response>(url, {
      responseType: 'blob',
    });

    const [, filename] =
      response.headers.get('Content-Disposition')?.match(FILENAME_FROM_HEADER_PATTERN) || [];

    return {
      file: await response.blob(),
      filename,
    };
  }

  private async processResponse(
    response: Response,
    url: string,
    headers: Headers,
    options?: IFetchOptions,
  ) {
    if (response.ok) {
      if (options?.responseType === 'blob') {
        return response;
      }

      return response.json();
    }

    return this.handleResponseError(response);
  }

  private async handleResponseError(response: Response) {
    if (response.status === HttpStatusCode.Unauthorized) {
      eventBus.dispatch(EventKey.Logout);
      history.push(AppRoutes.Authorization, { from: history.location });
    } else {
      const { message } = await getResponseErrorData(response);

      throw new RequestError(message as string, response.status);
    }
  }
}

export default BaseApi;
