import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getDisplayVariableValue } from 'utils/profund/getDisplayVariableValue';
import { ButtonsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { EditPenIcon } from 'static/images';

import styles from './ProfileItem.module.scss';

export interface IProfileItem {
  title: string;
  value?: string;
  onEditClick?: () => void;
}

interface IProfileProps extends IProfileItem {
  className?: string;
}

const ProfileItem = ({ title, value, onEditClick, className }: IProfileProps) => {
  const { t: translate } = useTranslation(TranslationNameSpaces.Buttons);

  return (
    <div className={clsx(styles.container, className)}>
      <p className={styles.title}>{title}</p>
      <div className={styles.valueContainer}>
        <p className={styles.value}>{getDisplayVariableValue(value)}</p>
        {onEditClick && (
          <div className={styles.editContainer} onClick={onEditClick}>
            <EditPenIcon />{' '}
            <p className={styles.editTitle}>{translate(ButtonsTranslationKeys.Edit)}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileItem;
