import React, { FC, useState } from 'react';
import clsx from 'clsx';
import Paragraph from 'components/Paragraph';
import { TypographySize } from 'enums/TypographySize';
import InputTooltip from 'components/InputTooltip';
import { TABLET_WIDTH } from 'pages/ApplicationProcess/OffersPage/offersUtils';

import styles from './InputWrapper.module.scss';

export interface IInputWrapperProps {
  label?: string | null;
  error?: string | React.ReactNode;
  inputIcon?: React.ReactElement | React.ReactNode;
  wrapperClassName?: string;
  tooltipText?: string;
  inputIconWrapperClassName?: string;
}

const InputWrapper: FC<IInputWrapperProps> = ({
  children,
  inputIcon,
  label,
  error,
  wrapperClassName,
  tooltipText,
  inputIconWrapperClassName,
}) => {
  const [isShown, setIsShown] = useState(false);

  const inputContainerClasses = clsx(styles.inputContainer, wrapperClassName);

  const handleIconMouseEnter = () => {
    if (window.innerWidth < TABLET_WIDTH) {
      return;
    }
    setIsShown(true);
  };

  const handleIconMouseLeave = () => {
    if (window.innerWidth < TABLET_WIDTH) {
      return;
    }
    setIsShown(false);
  };

  const handleIconClick = () => {
    if (window.innerWidth > TABLET_WIDTH) {
      return;
    }
    setIsShown(true);
  };

  const inputWrapperClasses = clsx(styles.inputWrapper, error && styles.error);

  return (
    <div className={inputContainerClasses}>
      <InputTooltip isShown={isShown} setIsShown={setIsShown}>
        {tooltipText}
      </InputTooltip>
      <div className={inputWrapperClasses}>
        {children} <label>{label}</label>
        {inputIcon && (
          <button
            className={clsx(styles.inputIconWrapper, inputIconWrapperClassName)}
            onMouseLeave={handleIconMouseLeave}
            onMouseEnter={tooltipText ? handleIconMouseEnter : undefined}
            onClick={tooltipText ? handleIconClick : undefined}
          >
            {inputIcon}
          </button>
        )}
      </div>
      {error && (
        <Paragraph variant={TypographySize.Small} className={styles.errorMessage}>
          {error}
        </Paragraph>
      )}
    </div>
  );
};

export default InputWrapper;
