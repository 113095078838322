import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {  getApplicationById, getOffers } from 'thunks';
import Loading from 'components/Loading';
import OffersPage from './OffersPages/Offers';
import NoOffersPage from './OffersPages/NoOffers';
import OfferConfirmationPage from './OffersPages/OfferConfirmation';
import SaveForLaterPage from './OffersPages/SaveForLater';
import FinalizeOffer from './OffersPages/FinalizeOffer';
import { getBorrowerApplicationData, getLoadingFields, getOffersData } from 'handlers/selectors';
import { LocalStorageConstants, LocalStorageKeys } from 'enums/LocalStorage';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { matchApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';
import { ApplicationVariable } from 'enums/ApplicationVariable';
import { setCurrentOffer } from 'handlers/portal/offersSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { setSelectedApplication } from 'handlers/portal/applicationsSlice';

interface IOfferPagesProviderValue {
  setCurrentStep: (step: OfferPagesStep) => void;
  prevStep: OfferPagesStep;
}

export const OfferPagesContext = createContext({} as IOfferPagesProviderValue);

export enum OfferPagesStep {
  Offers = 'offers',
  NoOffers = 'noOffers',
  OfferDetails = 'offerDetails',
  SaveForLater = 'saveForLater',
  FinalizeTheOffer = 'finalizeTheOffer',
}

const getPageByStep = (step: OfferPagesStep) => {
  const pagesBySteps = {
    [OfferPagesStep.Offers]: <OffersPage />,
    [OfferPagesStep.NoOffers]: <NoOffersPage />,
    [OfferPagesStep.OfferDetails]: <OfferConfirmationPage />,
    [OfferPagesStep.SaveForLater]: <SaveForLaterPage />,
    [OfferPagesStep.FinalizeTheOffer]: <FinalizeOffer />,
  };

  return pagesBySteps[step];
};

const OffersPagesContainer = () => {
  const { applicationId } = useParams();
  const navigate = useNavigate();

  const { offers, showOffersWithoutEven, currentOffer } = useAppSelector(getOffersData);
  const { getOffersByApplicationIdLoading } = useAppSelector(getLoadingFields);
  const { selectedApplication } = useAppSelector(getBorrowerApplicationData);
  const [currentStep, setCurrentStep] = useState(OfferPagesStep.Offers);
  const prevStep = useRef(OfferPagesStep.Offers);

  const dispatch = useAppDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  useEffect(() => {
    if (!offers && applicationId) {
      dispatch(getOffers({ applicationId }));
    }
  }, [offers, applicationId]);

  useEffect(() => {
    if (applicationId) {
      dispatch(getOffers({ applicationId }));
    }
  }, [showOffersWithoutEven, applicationId]);

  useEffect(() => {
    if (offers && !offers.length) {
      setCurrentStep(OfferPagesStep.NoOffers);
    }
  }, [offers]);

  const setApplicationAndCurrentOffer = useCallback(async () => {
    const application = await dispatchWithUnwrap(getApplicationById(applicationId!));
    const isOfferAgreementStatus = matchApplicationStatusIdByEnvironment(application.status.id, ApplicationStatusName.OfferAgreement);
    const finalOfferId = application!.variables[ApplicationVariable.FinalOfferId];
    const finalOffer = offers?.find(offer => offer.id === finalOfferId)

    dispatch(setSelectedApplication(application));

    if (isOfferAgreementStatus && finalOffer) {
      dispatch(setCurrentOffer(finalOffer));
      setCurrentStep(OfferPagesStep.OfferDetails);
      prevStep.current = OfferPagesStep.OfferDetails;
      navigate('#web-lead');
    }
  }, [applicationId, offers?.length]);

  useEffect(() => {
    if (applicationId && offers?.length && !selectedApplication) {
      setApplicationAndCurrentOffer();
    }
  }, [offers, applicationId])

  useEffect(() => {
    return () => {
      dispatch(setSelectedApplication(null));
    };
  }, []);

  useEffect(
    () => () => {
      window.scrollTo(0, 0);
    },
    [currentStep],
  );

  useEffect(() => {
    localStorage.setItem(LocalStorageKeys.isOffersGenerate, LocalStorageConstants.FALSE);
  }, []);

  const handleSetCurrentStep = (step: OfferPagesStep) => {
    setCurrentStep((prevState) => {
      prevStep.current = prevState;

      return step;
    });
  };

  const OffersComponent = getPageByStep(currentStep);

  const showLoading = getOffersByApplicationIdLoading || !offers || (!currentOffer && !selectedApplication);

  if (showLoading) {
    return <Loading />;
  }

  return (
    <OfferPagesContext.Provider
      value={{ setCurrentStep: handleSetCurrentStep, prevStep: prevStep.current }}
    >
      {OffersComponent}
    </OfferPagesContext.Provider>
  );
};

export default OffersPagesContainer;
