import * as Yup from 'yup';
import { FinancialInformationVariable } from 'enums/FinancialInformationVariable';
import { useErrorMessages } from 'hooks/useErrorMessages';
import { useTranslation } from 'react-i18next';
import { InputLabelsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { Environment } from 'enums/Environment';
import { SSN_TEST_EXCEPTIONS } from './constants';
import { environmentSecret } from 'secrets';

export const DATE_MATCH_REGEXP = /^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d$/;
export const SSN_MATCH_REGEXP = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
export const TEST_SSN_MATCH_REGEXP = /^\d{3}-?\d{2}-?\d{4}$/;

const isTestEnvironment = environmentSecret === Environment.Development;

export const useBorrowerFinancialInfoValidationSchema = () => {
  const [getMessageForRequiredFields, getMessageForInvalidFields] = useErrorMessages();
  const { t: translate } = useTranslation(TranslationNameSpaces.InputLabels);

  const borrowerFinancialInfoValidationSchema = Yup.object().shape({
    [FinancialInformationVariable.AnnualIncome]: Yup.string().required(
      getMessageForRequiredFields(translate(InputLabelsTranslationKeys.AnnualIncome)),
    ),
    [FinancialInformationVariable.SocialSecurityNumber]: Yup.string()
      .required(getMessageForRequiredFields(translate(InputLabelsTranslationKeys.SSN)))
      .nullable()
      .test({
        message: getMessageForInvalidFields(translate(InputLabelsTranslationKeys.SSN)),
        test: (value) => {
          if (isTestEnvironment && SSN_TEST_EXCEPTIONS.includes(value!)) {
            return TEST_SSN_MATCH_REGEXP.test(value!);
          }

          return SSN_MATCH_REGEXP.test(value!);
        },
      }),
    [FinancialInformationVariable.DateOfBirth]: Yup.string()
      .required(getMessageForRequiredFields(translate(InputLabelsTranslationKeys.DateOfBirth)))
      .nullable()
      .matches(
        DATE_MATCH_REGEXP,
        getMessageForInvalidFields(translate(InputLabelsTranslationKeys.DateOfBirth)),
      ),
    [FinancialInformationVariable.PropertyStatus]: Yup.string()
      .nullable()
      .required(getMessageForRequiredFields(translate(InputLabelsTranslationKeys.PropertyStatus))),
    [FinancialInformationVariable.CreditScore]: Yup.string()
      .nullable()
      .required(getMessageForRequiredFields(translate(InputLabelsTranslationKeys.CreditScore))),
    [FinancialInformationVariable.EmploymentStatus]: Yup.string()
      .nullable()
      .required(
        getMessageForRequiredFields(translate(InputLabelsTranslationKeys.EmploymentStatus)),
      ),
    [FinancialInformationVariable.PayFrequency]: Yup.string()
      .nullable()
      .required(getMessageForRequiredFields(translate(InputLabelsTranslationKeys.PayFrequency))),
    [FinancialInformationVariable.HighestEducationLevel]: Yup.string()
      .nullable()
      .required(
        getMessageForRequiredFields(translate(InputLabelsTranslationKeys.HighestEducationLevel)),
      ),
  });

  return borrowerFinancialInfoValidationSchema;
};
