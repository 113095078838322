export enum PersonalInformationVariable {
  LoanAmount = 'loanAmount',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  StreetName = 'streetName',
  StreetNumber = 'streetNumber',
  ApartmentOrSuite = 'apartmentOrSuite',
  City = 'city',
  State = 'state',
  ZipCode = 'zipCode',
  Email = 'email',
}
