import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthorizationInformation } from 'api/digifi/portal/AuthorizationApi';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { getAuthorizationInformation, logout } from 'thunks';
import { getApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';

export enum AuthorizationStep {
  SignIn = 'SignInPage',
  VerifyCode = 'VerifyCodePage',
  UnableToVerifyPage = 'UnableToVerifyPage',
  ExpiredApplicationPage = 'ExpiredApplicationPage',
}

export interface IAuthorizationState {
  authorizationStep: AuthorizationStep;
  authorizationInformation: IAuthorizationInformation;
}

const initialState: IAuthorizationState = {
  authorizationStep: AuthorizationStep.SignIn,
  authorizationInformation: {
    applicationExpired: false,
    borrowerId: '',
    email: '',
    hasAccount: false,
    phone: '',
    redirectApplicationId: '',
    applicationNotFound: false,
    statusId: getApplicationStatusIdByEnvironment(ApplicationStatusName.NewApplication),
  },
};

const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    setAuthorizationStep: (state, { payload }: PayloadAction<AuthorizationStep>) => {
      state.authorizationStep = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuthorizationInformation.fulfilled, (state, { payload }) => {
        state.authorizationInformation = payload;
      })
      .addCase(logout.fulfilled, (state, { payload }) => {
        if (payload) {
          return state;
        }

        return initialState;
      });
  },
});

export const { setAuthorizationStep } = authorizationSlice.actions;

export default authorizationSlice.reducer;
