import React, { FC } from 'react';
import PageLayout from 'components/PageLayout';
import Title from 'components/Title';
import Paragraph from 'components/Paragraph';
import { TypographySize } from 'enums/TypographySize';
import { useTranslation } from 'react-i18next';
import { AuthorizationProcessTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import styles from './UnableToVerifyPage.module.scss';

const UnableToVerifyPage: FC = () => {
  const { t: translate } = useTranslation([TranslationNameSpaces.AuthorizationProcess]);

  return (
    <PageLayout>
      <div className={styles.pageWrapper}>
        <Title>{translate(AuthorizationProcessTranslationKeys.SignUpTitle)}</Title>
        <Paragraph variant={TypographySize.Large}>
          {translate(AuthorizationProcessTranslationKeys.UnableToVerifyDescription)}
        </Paragraph>
      </div>
    </PageLayout>
  );
};

export default UnableToVerifyPage;
