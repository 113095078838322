import React from 'react';
import CloseIcon from 'components/CloseIcon';

import styles from './PageNotification.module.scss';

interface IPageNotificationProps {
  handleClose: () => void;
  notification: string;
}

const PageNotification = ({ handleClose, notification }: IPageNotificationProps) => {
  return (
    <div className={styles.container}>
      <p className={styles.content}>{notification}</p>
      <CloseIcon onClick={handleClose} className={styles.closeIcon} />
    </div>
  );
};

export default PageNotification;
