export enum PersonalInformationLabel {
  LoanAmount = 'Loan Amount',
  FirstName = 'First Name',
  MiddleName = 'Middle Name',
  LastName = 'Last Name',
  HomeAddress = 'Home Address',
  ApartmentOrSuite = 'Apartment Or Suite',
  City = 'City',
  State = 'State',
  ZipCode = 'ZIP code',
  Email = 'Email',
}
