import AddressInputs from 'components/AddressInputs';
import CollapsiblePanel from 'components/Collapsible';
import InfoTooltip from 'components/InfoTooltip';
import ToggleSwitch from 'components/ToggleSwitch';
import { ProjectAddressVariable } from 'enums/ProjectAddressVariable';
import { TooltipId } from 'enums/TooltipId';
import { PersonalInformationPageTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { Formik, FormikProps } from 'formik';
import { IProjectAddress } from 'handlers/portal/personalInformation';
import useWindowSize from 'hooks/useWindowSize';
import { TABLET_WIDTH } from 'pages/ApplicationProcess/OffersPage/offersUtils';
import React, { RefObject, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ProjectAddress.module.scss';
import { useProjectAddressValidationSchema } from './validationSchema';

interface IProjectAddressProps {
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  formRef: RefObject<FormikProps<IProjectAddress>>;
  value: IProjectAddress;
  setIsFormValid: (value: boolean) => void;
}

const ProjectAddress = ({
  isActive,
  setIsActive,
  value,
  formRef,
  setIsFormValid,
}: IProjectAddressProps) => {
  const { width = 0 } = useWindowSize();

  const isMobile = useMemo(() => {
    return width < TABLET_WIDTH;
  }, [width]);

  const { t: translate } = useTranslation(TranslationNameSpaces.PersonalInformationPage);

  const renderHeaderContent = () => {
    return (
      <div className={styles.headerContent}>
        <ToggleSwitch
          text={translate(PersonalInformationPageTranslationKeys.ProjectAddressToggleText)!}
          checked={isActive}
          onChange={() => {
            setIsActive(!isActive);
          }}
        />
        <div className={styles.tooltip}>
          <InfoTooltip
            tooltip={translate(PersonalInformationPageTranslationKeys.ProjectAddressTooltipText)}
            id={TooltipId.ProjectAddress}
          />
        </div>
      </div>
    );
  };

  const validationSchema = useProjectAddressValidationSchema();

  const renderContent = () => {
    return (
      <Formik
        initialValues={value}
        onSubmit={() => {}}
        validationSchema={validationSchema}
        validateOnMount
        validateOnBlur
        validateOnChange
        enableReinitialize
        innerRef={formRef}
      >
        {({ values, handleBlur, errors, handleChange, isValid, touched, setFieldValue }) => {
          const getErrorMessage = (name: ProjectAddressVariable) =>
            (!!touched[name] && errors[name]) || !!values[name] ? errors[name] : undefined;

          useEffect(() => {
            setIsFormValid(isValid);
          }, [isValid]);

          return (
            <div className={styles.form}>
              <AddressInputs
                onChange={handleChange}
                onBlur={handleBlur}
                setFieldValue={setFieldValue}
                getErrorMessage={getErrorMessage}
                value={{
                  streetName: values[ProjectAddressVariable.StreetName],
                  streetNumber: values[ProjectAddressVariable.StreetNumber],
                  apartmentOrSuite: values[ProjectAddressVariable.ApartmentOrSuite],
                  city: values[ProjectAddressVariable.City],
                  state: values[ProjectAddressVariable.State],
                  zipCode: values[ProjectAddressVariable.ZipCode],
                }}
                variable={{
                  streetName: ProjectAddressVariable.StreetName,
                  streetNumber: ProjectAddressVariable.StreetNumber,
                  apartmentOrSuite: ProjectAddressVariable.ApartmentOrSuite,
                  city: ProjectAddressVariable.City,
                  state: ProjectAddressVariable.State,
                  zipCode: ProjectAddressVariable.ZipCode,
                }}
                stateAndZipInputsCombined={!isMobile}
                isProjectAddress
              />
            </div>
          );
        }}
      </Formik>
    );
  };

  return (
    <CollapsiblePanel
      isActive={isActive}
      headerContent={renderHeaderContent()}
      bodyContent={renderContent()}
      contentClassName={styles.contentClassName}
    />
  );
};

export default ProjectAddress;
