import { createAsyncThunk } from '@reduxjs/toolkit';
import SessionsRestApi from 'api/digifi/auth/SessionsApi';
import { AccountsActionType } from 'enums/AccountActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

interface ILogoutOptions {
  withoutRequest?: boolean;
  withoutStateReset?: boolean;
}

export const createLogoutThunk = (api: SessionsRestApi) =>
  createAsyncThunk(
    AccountsActionType.Logout,
    async (options: ILogoutOptions | undefined, thunkApi) => {
      try {
        if (options?.withoutRequest) {
          return;
        }

        return await api.logout(Boolean(options?.withoutStateReset));
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
