import React from 'react';
import styles from './DwollaBankVerificationForm.module.scss';
import { Formik } from 'formik';
import TextInput from 'components/TextInput';
import { DwollaBankVerificationVariable } from 'enums/DwollaBankVerificationVariable';
import SelectInput from 'components/SelectInput';
import { getSelectedOption } from 'components/BorrowerFinancialInfo/BorrowerFinancialInfo';
import { useTranslation } from 'react-i18next';
import {
  ButtonsTranslationKeys,
  InputLabelsTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import NumberInput from 'components/NumberInput';
import Button, { ButtonSize, ButtonVariant } from 'components/Button/Button';
import { useDwollaBankVerificationValidationSchema } from './validationSchema';

const BANK_ACCOUNT_TYPE_OPTIONS = [
  {
    value: 'checking',
    label: 'Checking',
  },
  {
    value: 'savings',
    label: 'Savings',
  },
];

const initialValues = {
  [DwollaBankVerificationVariable.NameOnAccount]: '',
  [DwollaBankVerificationVariable.BankAccountType]: '',
  [DwollaBankVerificationVariable.BankRoutingNumber]: '',
  [DwollaBankVerificationVariable.BankConfirmRoutingNumber]: '',
  [DwollaBankVerificationVariable.BankAccountNumber]: '',
  [DwollaBankVerificationVariable.BankConfirmAccountNumber]: '',
};

interface IDwollaBankVerificationFormProps {
  onSubmitForm: (values: Record<string, string>) => void;
}

const DwollaBankVerificationForm = ({ onSubmitForm }: IDwollaBankVerificationFormProps) => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.InputLabels,
    TranslationNameSpaces.Buttons,
  ]);

  const dwollaBankVerificationValidationSchema = useDwollaBankVerificationValidationSchema();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validationSchema={dwollaBankVerificationValidationSchema}
      validateOnMount
      validateOnBlur
      validateOnChange
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        isValid,
        validateForm,
      }) => {
        const getErrorMessage = (name: DwollaBankVerificationVariable) =>
          !!touched[name] && errors[name] ? errors[name] : undefined;

        const handleSubmit = async () => {
          if (!isValid) {
            await validateForm(values);

            return;
          }

          const { bankAccountNumber, bankAccountType, bankRoutingNumber, nameOnAccount } = values;

          await onSubmitForm({
            bankAccountNumber,
            bankAccountType,
            bankRoutingNumber,
            nameOnAccount,
          });
        };

        return (
          <>
            <div className={styles.content}>
              <TextInput
                label={translate(InputLabelsTranslationKeys.NameOnAccount, {
                  ns: TranslationNameSpaces.InputLabels,
                })}
                name={DwollaBankVerificationVariable.NameOnAccount}
                error={getErrorMessage(DwollaBankVerificationVariable.NameOnAccount)}
                onChange={handleChange}
                value={values[DwollaBankVerificationVariable.NameOnAccount]}
                onBlur={handleBlur}
              />
              <SelectInput
                selectedOption={getSelectedOption(
                  BANK_ACCOUNT_TYPE_OPTIONS,
                  values[DwollaBankVerificationVariable.BankAccountType] as string,
                )}
                options={BANK_ACCOUNT_TYPE_OPTIONS}
                name={DwollaBankVerificationVariable.BankAccountType}
                onValuePicked={(data) => {
                  setFieldValue(DwollaBankVerificationVariable.BankAccountType, data);
                }}
                errorMessage={getErrorMessage(DwollaBankVerificationVariable.BankAccountType)}
                placeholder={translate(InputLabelsTranslationKeys.BankAccountType, {
                  ns: TranslationNameSpaces.InputLabels,
                })}
              />
              <NumberInput
                label={translate(InputLabelsTranslationKeys.BankRoutingNumber)}
                name={DwollaBankVerificationVariable.BankRoutingNumber}
                onChange={handleChange}
                value={values[DwollaBankVerificationVariable.BankRoutingNumber]}
                error={getErrorMessage(DwollaBankVerificationVariable.BankRoutingNumber)}
                allowNegative={false}
                onBlur={handleBlur}
                mask=""
                allowLeadingZeros
              />
              <NumberInput
                label={translate(InputLabelsTranslationKeys.BankConfirmRoutingNumber, {
                  ns: TranslationNameSpaces.InputLabels,
                })}
                name={DwollaBankVerificationVariable.BankConfirmRoutingNumber}
                onChange={handleChange}
                value={values[DwollaBankVerificationVariable.BankConfirmRoutingNumber]}
                error={getErrorMessage(DwollaBankVerificationVariable.BankConfirmRoutingNumber)}
                allowNegative={false}
                onBlur={handleBlur}
                mask=""
                allowLeadingZeros
              />
              <NumberInput
                label={translate(InputLabelsTranslationKeys.BankAccountNumber, {
                  ns: TranslationNameSpaces.InputLabels,
                })}
                name={DwollaBankVerificationVariable.BankAccountNumber}
                onChange={handleChange}
                value={values[DwollaBankVerificationVariable.BankAccountNumber]}
                error={getErrorMessage(DwollaBankVerificationVariable.BankAccountNumber)}
                allowNegative={false}
                onBlur={handleBlur}
                mask=""
                allowLeadingZeros
              />
              <NumberInput
                label={translate(InputLabelsTranslationKeys.BankConfirmAccountNumber, {
                  ns: TranslationNameSpaces.InputLabels,
                })}
                name={DwollaBankVerificationVariable.BankConfirmAccountNumber}
                onChange={handleChange}
                value={values[DwollaBankVerificationVariable.BankConfirmAccountNumber]}
                error={getErrorMessage(DwollaBankVerificationVariable.BankConfirmAccountNumber)}
                allowNegative={false}
                onBlur={handleBlur}
                mask=""
                allowLeadingZeros
              />
            </div>
            <div className={styles.buttonContainer}>
              <Button
                variant={ButtonVariant.Primary}
                size={ButtonSize.Large}
                onClick={handleSubmit}
                className={styles.button}
                disabled={!isValid}
                isLoading={false}
              >
                {translate(ButtonsTranslationKeys.Confirm, {
                  ns: TranslationNameSpaces.Buttons,
                })}
              </Button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default DwollaBankVerificationForm;
