import { createAsyncThunk } from '@reduxjs/toolkit';
import IntermediariesApi from 'api/digifi/portal/IntermediariesApi';
import { IntermediaryActionType } from 'enums/IntermediaryActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createGetIntermediaryByIdThunk = (api: IntermediariesApi) =>
  createAsyncThunk(IntermediaryActionType.GetById, async (id: string, thunkApi) => {
    try {
      return await api.getById(id);
    } catch (error) {
      return rejectWithValueHelper(error as Error, thunkApi);
    }
  });
