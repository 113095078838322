import { ApplicationStatusIdType } from 'enums/ApplicationStatusId';
import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { IPersonalInformation, IProjectAddress } from 'handlers/portal/personalInformation';
import { IFinancialInformation } from 'handlers/portal/financialInformation';
import { ApplicationLabelType } from 'enums/ApplicationLabelId';

import { ApplicationProcessEndpoints } from './PortalEndpoints';
import { DwollaDocumentVerificationProcess } from 'enums/DocumentVerification';
import { PlaidLinkOnEventMetadata, PlaidLinkStableEvent } from 'react-plaid-link';
import { DwollaMicroDepositsStatus } from 'enums/DwollaMicrodepositsStatus';

interface IGetApplicationStatusResponse {
  applicationStatusId: ApplicationStatusIdType;
  labelsIds: ApplicationLabelType[];
  dwollaCustomerStatus: string;
  dwollaDocumentVerificationProcess: DwollaDocumentVerificationProcess;
  dwollaVerificationCount: number | null;
  dwollaMicroDepositsStatus: DwollaMicroDepositsStatus | null;
  fundedByEven: boolean | null;
}

export interface ICheckOldApplicationsBody {
  applicationId: string;
}
interface ICheckOldApplicationsResponse {
  currentApplicationId: string;
  oldApplicationId?: string;
  applicationStatusId?: ApplicationStatusIdType;
}

export interface IContinueWithApplicationBody {
  applicationId: string;
}

interface IContinueWithApplicationResponse {
  applicationId: string;
  applicationStatusId: ApplicationStatusIdType;
}

export interface IGenerateOffersBody {
  applicationId: string;
  annualIncome: number;
  creditScore: string;
  coownerAnnualIncome: number | null;
  socialSecurityNumber: string;
}
export interface ICleanApplicationsRequestBody {
  applicationId: string;
}

export interface ISavePlaidEventToVariableBody {
  applicationId: string;
  plaidEvent: PlaidLinkStableEvent | string;
  eventMetadata: PlaidLinkOnEventMetadata;
}

interface IApplicationProcessApi {
  getApplicationStatus(applicationId: string): Promise<IGetApplicationStatusResponse>;
  checkOldApplications(
    checkOldApplicationsBody: ICheckOldApplicationsBody,
  ): Promise<ICheckOldApplicationsResponse>;
  continueWithApplication(
    continueWithApplicationBody: IContinueWithApplicationBody,
  ): Promise<IContinueWithApplicationResponse>;
  getPersonalInformation(applicationId: string): Promise<IPersonalInformation>;
  cleanApplications(body: ICleanApplicationsRequestBody): Promise<void>;
}

export default class ApplicationProcessApi extends BaseApi implements IApplicationProcessApi {
  private readonly endpointBase = '/application-process';

  cleanApplications(body: ICleanApplicationsRequestBody): Promise<void> {
    return this.fetch(`${this.endpointBase}${ApplicationProcessEndpoints.CleanApplications}`, {
      method: FetchMethodType.POST,
      body,
    });
  }

  getApplicationStatus(applicationId: string): Promise<IGetApplicationStatusResponse> {
    return this.fetch(
      `${this.endpointBase}${ApplicationProcessEndpoints.GetApplicationStatus}/${applicationId}`,
    );
  }

  checkOldApplications(body: ICheckOldApplicationsBody): Promise<ICheckOldApplicationsResponse> {
    return this.fetch(`${this.endpointBase}${ApplicationProcessEndpoints.CheckOldApplications}`, {
      method: FetchMethodType.POST,
      body,
    });
  }

  continueWithApplication(
    body: IContinueWithApplicationBody,
  ): Promise<IContinueWithApplicationResponse> {
    return this.fetch(
      `${this.endpointBase}${ApplicationProcessEndpoints.ContinueWithApplication}`,
      {
        method: FetchMethodType.POST,
        body,
      },
    );
  }

  getPersonalInformation(applicationId: string): Promise<IPersonalInformation & IProjectAddress> {
    return this.fetch(
      `${this.endpointBase}${ApplicationProcessEndpoints.GetPersonalInformation}/${applicationId}`,
    );
  }

  getFinancialInformation(applicationId: string): Promise<IFinancialInformation> {
    return this.fetch(
      `${this.endpointBase}${ApplicationProcessEndpoints.GetFinancialInformation}/${applicationId}`,
    );
  }

  savePlaidEventToVariable(body: ISavePlaidEventToVariableBody) {
    return this.fetch(
      `${this.endpointBase}${ApplicationProcessEndpoints.SavePlaidEventToVariable}`,
      {
        method: FetchMethodType.POST,
        body,
      },
    );
  }

  sendOfferAgreementEmail(applicationId: string): Promise<IFinancialInformation> {
    return this.fetch(
      `${this.endpointBase}${ApplicationProcessEndpoints.SendOfferAgreementEmail}/${applicationId}`,
    );
  }
}
