import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppRoutes from './routes';
import { store } from './store';
import ApiProvider, { IApiProviderValue } from './providers/ApiServiceProvider';
import Loading from 'components/Loading';
import ErrorBoundary from 'ErrorBoundary';
import StrategyApi from 'api/digifi/portal/StrategyApi';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import './i18n';
import './index.scss';
import { Environment } from 'enums/Environment';
import Toaster from 'components/Toaster';
import { environmentSecret, sentryDsnSecret } from 'secrets';

const strategyApi = new StrategyApi();
const apiProviderValue: IApiProviderValue = {
  strategyApi,
};

Sentry.init({
  enabled: [Environment.Production, Environment.Development].includes(
    environmentSecret as Environment,
  ),
  dsn: sentryDsnSecret,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
  environment: environmentSecret,
});

ReactDOM.render(
  <ErrorBoundary>
    <StrictMode>
      <Provider store={store}>
        <ApiProvider value={apiProviderValue}>
          <Suspense fallback={<Loading />}>
            <AppRoutes />
            <Toaster />
          </Suspense>
        </ApiProvider>
      </Provider>
    </StrictMode>
  </ErrorBoundary>,
  document.getElementById('root'),
);
