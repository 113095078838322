import { createAsyncThunk } from '@reduxjs/toolkit';
import BankVerificationApi, { ISaveBorrowerTokenData } from 'api/digifi/portal/BankVerificationApi';
import { BankVerificationActionType } from 'enums/BankVerificationActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createSaveBorrowerTokenThunk = (api: BankVerificationApi) =>
  createAsyncThunk(
    BankVerificationActionType.SaveBorrowerToken,
    async (data: ISaveBorrowerTokenData, thunkApi) => {
      try {
        return await api.saveBorrowerToken(data);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
