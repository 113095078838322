import BaseApi, { FetchMethodType } from 'api/BaseApi';
import { IOffer } from 'handlers/portal/offersSlice';
import { LoanOffersEndpoints } from './PortalEndpoints';

export interface IGenerateOffersBody {
  applicationId: string;
  annualIncome: number;
  creditScore: string;
  coownerAnnualIncome: number | null;
  socialSecurityNumber: string;
}

export interface IGetLoanOffersBody {
  applicationId: string;
  shouldIgnoreWatercressOffers?: boolean;
}

export default class LoanOffersApi extends BaseApi {
  private readonly endpointBase = '/loan-offers';

  public async create(body: IGenerateOffersBody): Promise<void> {
    return this.fetch(`${this.endpointBase}${LoanOffersEndpoints.Create}`, {
      method: FetchMethodType.POST,
      body,
    });
  }

  public async get({
    applicationId,
    shouldIgnoreWatercressOffers,
  }: IGetLoanOffersBody): Promise<IOffer[]> {
    const params = new URLSearchParams();

    params.append('applicationId', applicationId);

    if (shouldIgnoreWatercressOffers) {
      params.append('shouldIgnoreWatercressOffers', shouldIgnoreWatercressOffers.toString());
    }

    return this.fetch(`${this.endpointBase}?${params}`);
  }
}
