import {
  ApplicationDetailsPageTranslationKeys,
  MenuTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import PageLayout, { LayoutSize } from 'components/PageLayout';
import PaymentHistoryItem, { IPaymentHistoryItem } from 'components/PaymentHistoryItem';
import React, { useEffect, useState } from 'react';
import { convertToMonetary, convertToPercentage } from 'utils/profund/formats';
import { getBorrowerApplicationData, getLoadingFields } from 'handlers/selectors';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'routes/AppRoutes';
import { ApplicationLabelName, ApplicationLabelType } from 'enums/ApplicationLabelId';
import ApplicationMonetaryBox from 'components/ApplicationMonetaryBox';
import { ApplicationVariable } from 'enums/ApplicationVariable';
import BackButton from 'components/BackButton';
import Loading from 'components/Loading';
import Menu from 'components/Menu';
import { OfferProviderNames } from 'enums/OfferProviderNames';
import Paragraph from 'components/Paragraph';
import PartnerLogo from 'components/PartnerLogo';
import ProjectBalance from 'components/ProjectBalance';
import Title from 'components/Title';
import { TypographySize } from 'enums/TypographySize';
import { getApplicationById } from 'thunks';
import { getDateWithTime } from 'utils/getDateWithTime';
import { getIsApplicationActive } from 'utils/profund/getIsApplicationActive';
import { getIsReopenAccountTimeExpired } from 'utils/profund/getIsReopenAccountTimeExpired';
import { setSelectedApplication } from 'handlers/portal/applicationsSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { TooltipId } from 'enums/TooltipId';
import { matchApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { getApplicationLabelIdByEnvironment } from 'utils/applicationLabelsHelpers';

import styles from './ApplicationDetails.module.scss';

enum PaymentStatus {
  Pending = 'Pending',
}

const ApplicationDetails = () => {
  const dispatch = useAppDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const { selectedApplication } = useAppSelector(getBorrowerApplicationData);
  const { isGetApplicationByIdInProgress } = useAppSelector(getLoadingFields);

  const [paymentsHistory, setPaymentsHistory] = useState<undefined | IPaymentHistoryItem[]>(
    undefined,
  );

  const handleBack = () => navigate(AppRoutes.Applications);

  useEffect(() => {
    dispatchWithUnwrap(getApplicationById(applicationId!));
  }, []);

  useEffect(() => {
    try {
      const result = JSON.parse(
        selectedApplication?.variables[ApplicationVariable.PaymentsHistory] as string,
      );
      setPaymentsHistory(result);
    } catch (error) {
      setPaymentsHistory([]);
    }
  }, [selectedApplication?.variables[ApplicationVariable.PaymentsHistory]]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedApplication(null));
    };
  }, []);

  const { t: translate } = useTranslation([TranslationNameSpaces.ApplicationDetailsPage]);

  if (isGetApplicationByIdInProgress || !selectedApplication) {
    return <Loading />;
  }

  if (
    !matchApplicationStatusIdByEnvironment(
      selectedApplication.status.id,
      ApplicationStatusName.Approved,
    )
  ) {
    navigate(AppRoutes.Applications);
  }

  if (
    matchApplicationStatusIdByEnvironment(
      selectedApplication.status.id,
      ApplicationStatusName.OfferAgreement,
    )
  ) {
    navigate(`${AppRoutes.ApplicationProcess}/${applicationId}`);
  }

  const projectAccountBalance = selectedApplication.variables[
    ApplicationVariable.DwollaFundingSourceBalance
  ] as number;

  const projectAccountBalanceCurrency = selectedApplication.variables[
    ApplicationVariable.DwollaFundingSourceCurrency
  ] as string | undefined;

  const agreedJobAmount = selectedApplication.variables[
    ApplicationVariable.AgreedJobAmount
  ] as number;

  const finalOfferProviderName = selectedApplication.variables[
    ApplicationVariable.FinalOfferProviderName
  ] as OfferProviderNames;

  const logoLink =
    selectedApplication.variables[ApplicationVariable.FinalFinancialServicesPartnerLogo];

  const loanAmount = selectedApplication.variables[ApplicationVariable.LoanAmount] as
    | number
    | undefined;

  const labelsIds = selectedApplication.labels.map((item) => item.id) as ApplicationLabelType[];

  const hideManageButton = labelsIds.includes(
    getApplicationLabelIdByEnvironment(ApplicationLabelName.CloseAccount),
  )
    ? getIsReopenAccountTimeExpired(
        selectedApplication.variables[ApplicationVariable.CloseAccountDateAndTime] as string,
      )
    : false;

  const isPriorApplication =
    matchApplicationStatusIdByEnvironment(
      selectedApplication.status.id,
      ApplicationStatusName.Approved,
    ) &&
    !getIsApplicationActive(
      selectedApplication.variables[ApplicationVariable.ApplicationApprovalDate] as string,
    );

  const isPaymentInProgress =
    paymentsHistory && paymentsHistory.length
      ? paymentsHistory.some((item) => item.status === PaymentStatus.Pending)
      : false;

  const labelsManageHide = [
    getApplicationLabelIdByEnvironment(ApplicationLabelName.PendingLenderDecision),
    getApplicationLabelIdByEnvironment(ApplicationLabelName.RequestFunds),
    getApplicationLabelIdByEnvironment(ApplicationLabelName.AddFunds),
    getApplicationLabelIdByEnvironment(ApplicationLabelName.ReopenAccount),
  ].some((label) => labelsIds.includes(label));

  const hideManage =
    isPriorApplication || labelsManageHide || hideManageButton || isPaymentInProgress;

  const needProjectBalance = finalOfferProviderName === OfferProviderNames.Even;

  const getProjectBalance = () => {
    if (needProjectBalance) {
      return (
        <ProjectBalance
          loanAmount={loanAmount}
          agreedJobAmount={agreedJobAmount}
          projectAccountBalance={projectAccountBalance}
          projectAccountBalanceCurrency={projectAccountBalanceCurrency}
          selectedApplication={selectedApplication}
          paymentsHistory={paymentsHistory}
          hideManage={hideManage}
          applicationId={applicationId!}
          labels={labelsIds}
        />
      );
    }

    return null;
  };

  return (
    <div>
      <Menu activeMenuItem={MenuTranslationKeys.Applications} />
      <PageLayout layoutSize={LayoutSize.Big}>
        <BackButton onBackClick={handleBack} />
        <Title variant="h1" marginBottom="8" className={styles.pageTitle}>
          {(selectedApplication.variables[ApplicationVariable.IntermediaryDba] as string) ||
            (selectedApplication.variables[ApplicationVariable.IntermediaryName] as string)}
        </Title>
        <div className={styles.datesContainer}>
          <p className={styles.date}>
            {translate(ApplicationDetailsPageTranslationKeys.UpdatedTitle, {
              ns: TranslationNameSpaces.ApplicationDetailsPage,
            })}{' '}
            {getDateWithTime(selectedApplication.updatedAt as unknown as string)}
          </p>
          <p className={styles.date}>
            {translate(ApplicationDetailsPageTranslationKeys.CreatedTitle, {
              ns: TranslationNameSpaces.ApplicationDetailsPage,
            })}{' '}
            {getDateWithTime(selectedApplication.createdAt as unknown as string)}
          </p>
        </div>
        <div className={styles.status}>{selectedApplication.status.name}</div>
        <div className={styles.monetaryBoxesContainer}>
          {getProjectBalance()}
          <ApplicationMonetaryBox
            title={translate(ApplicationDetailsPageTranslationKeys.AgreedJobAmount, {
              ns: TranslationNameSpaces.ApplicationDetailsPage,
            })}
            amount={agreedJobAmount}
            tooltip={translate(ApplicationDetailsPageTranslationKeys.AgreedJobAmountTooltip, {
              ns: TranslationNameSpaces.ApplicationDetailsPage,
            })}
            className={needProjectBalance ? undefined : styles.monetaryBoxWithoutBoxShadow}
            id={TooltipId.JobAmount}
            titleClassName={needProjectBalance ? undefined : styles.jobAmountTitleFontSize}
          />
        </div>
        <Title className={styles.title} variant="h3" marginBottom="16">
          {translate(ApplicationDetailsPageTranslationKeys.PaymentHistoryTitle, {
            ns: TranslationNameSpaces.ApplicationDetailsPage,
          })}
        </Title>
        {paymentsHistory && paymentsHistory.length ? (
          <div>
            <div className={styles.tableTitle}>
              <p className={styles.tableColumnName}>
                {translate(ApplicationDetailsPageTranslationKeys.PaymentHistoryDate, {
                  ns: TranslationNameSpaces.ApplicationDetailsPage,
                })}
              </p>
              <p className={styles.tableColumnName}>
                {translate(ApplicationDetailsPageTranslationKeys.PaymentHistoryType, {
                  ns: TranslationNameSpaces.ApplicationDetailsPage,
                })}
              </p>
              <p className={styles.tableColumnName}>
                {translate(ApplicationDetailsPageTranslationKeys.PaymentHistoryStatus, {
                  ns: TranslationNameSpaces.ApplicationDetailsPage,
                })}
              </p>
              <p className={styles.tableColumnName}>
                {translate(ApplicationDetailsPageTranslationKeys.PaymentHistoryAmount, {
                  ns: TranslationNameSpaces.ApplicationDetailsPage,
                })}
              </p>
            </div>
            <div className={styles.paymentsHistoryContainer}>
              {paymentsHistory.map((item) => (
                <PaymentHistoryItem
                  date={item.date}
                  amount={item.amount}
                  status={item.status}
                  type={item.type}
                  transactionId={item.transactionId}
                  key={uuid()}
                />
              ))}
            </div>
          </div>
        ) : (
          <Paragraph variant={TypographySize.Middle}>
            {translate(ApplicationDetailsPageTranslationKeys.NoPaymentHistory, {
              ns: TranslationNameSpaces.ApplicationDetailsPage,
            })}
          </Paragraph>
        )}
        <div className={styles.bottomBox}>
          <div className={styles.logo}>
            <PartnerLogo finalOfferProviderName={finalOfferProviderName} logoLink={logoLink} />
          </div>
          <div className={styles.applicationVariables}>
            {selectedApplication.variables[ApplicationVariable.LoanAmount] && (
              <div className={styles.applicationVariablesContainer}>
                <p className={styles.variableName}>
                  {translate(ApplicationDetailsPageTranslationKeys.LoanAmount, {
                    ns: TranslationNameSpaces.ApplicationDetailsPage,
                  })}
                </p>
                <p className={styles.variableValue}>
                  {convertToMonetary(
                    selectedApplication.variables[ApplicationVariable.LoanAmount] as number,
                    0,
                  )}
                </p>
              </div>
            )}
            {selectedApplication.variables[ApplicationVariable.FinalOfferMonthlyPaymentAmount] && (
              <div className={styles.applicationVariablesContainer}>
                <p className={styles.variableName}>
                  {selectedApplication.variables[
                    ApplicationVariable.FinalOfferMonthlyPaymentDescription
                  ] ||
                    translate(ApplicationDetailsPageTranslationKeys.MonthlyPayment, {
                      ns: TranslationNameSpaces.ApplicationDetailsPage,
                    })}
                </p>
                <p className={styles.variableValue}>
                  {convertToMonetary(
                    selectedApplication.variables[
                      ApplicationVariable.FinalOfferMonthlyPaymentAmount
                    ] as number,
                    0,
                  )}
                </p>
              </div>
            )}
            {selectedApplication.variables[ApplicationVariable.FinalOfferTermLength] &&
              selectedApplication.variables[ApplicationVariable.FinalOfferTermUnit] && (
                <div className={styles.applicationVariablesContainer}>
                  <p className={styles.variableName}>
                    {selectedApplication.variables[ApplicationVariable.FinalOfferTermDescription] ||
                      translate(ApplicationDetailsPageTranslationKeys.TermOfLoan, {
                        ns: TranslationNameSpaces.ApplicationDetailsPage,
                      })}
                  </p>
                  <p className={styles.variableValue}>
                    {`${selectedApplication.variables[ApplicationVariable.FinalOfferTermLength]} ${
                      selectedApplication.variables[ApplicationVariable.FinalOfferTermUnit]
                    } `}
                  </p>
                </div>
              )}
            {!!selectedApplication.variables[ApplicationVariable.FinalOfferAprAmount] && (
              <div className={styles.applicationVariablesContainer}>
                <p className={styles.variableName}>
                  {selectedApplication.variables[ApplicationVariable.FinalOfferAprDescription] ||
                    translate(ApplicationDetailsPageTranslationKeys.FixedApr, {
                      ns: TranslationNameSpaces.ApplicationDetailsPage,
                    })}
                </p>
                <p className={styles.variableValue}>
                  {convertToPercentage(
                    selectedApplication.variables[
                      ApplicationVariable.FinalOfferAprAmount
                    ] as number,
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      </PageLayout>
    </div>
  );
};

export default ApplicationDetails;
