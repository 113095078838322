import React, { FC } from 'react';
import TitleWithInfoTooltip from 'components/TitleWithInfoTooltip';
import { IManageButtonMenu } from 'components/ManageButton';
import { addDecimalSeparatorToNumberValue, convertToMonetary } from 'utils/profund/formats';

import styles from './ApplicationMonetaryBox.module.scss';
import clsx from 'clsx';
import { TooltipId } from 'enums/TooltipId';

const DEFAULT_BALANCE_CURRENCY = 'USD';

const getValue = (currency: string | undefined, amount: number) => {
  if (!currency) {
    return addDecimalSeparatorToNumberValue(amount);
  }

  return currency === DEFAULT_BALANCE_CURRENCY
    ? convertToMonetary(amount, 0)
    : `${currency} ${addDecimalSeparatorToNumberValue(amount)}`;
};

interface IApplicationMonetaryBox {
  title: string;
  id: TooltipId;
  amount: number | undefined;
  tooltip?: string | null;
  manageMenu?: IManageButtonMenu[];
  label?: string;
  currency?: {
    type: string | undefined;
    check: boolean;
  };
  className?: string;
  titleClassName?: string;
}

const ApplicationMonetaryBox: FC<IApplicationMonetaryBox> = ({
  title,
  amount,
  tooltip,
  manageMenu,
  label,
  children,
  currency,
  className,
  id,
  titleClassName,
}) => {
  const amountElement = (
    <div className={styles.amount}>
      {amount || amount === 0
        ? currency?.check
          ? getValue(currency?.type, amount)
          : convertToMonetary(amount, 0)
        : '—'}
    </div>
  );

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.titleContainer}>
        <TitleWithInfoTooltip
          title={title}
          tooltip={tooltip}
          manageMenu={manageMenu}
          className={styles.title}
          id={id}
          titleClassName={titleClassName}
        />
        <div className={styles.mobilAmount}>{amountElement}</div>
        {label && <div className={styles.label}>{label}</div>}
      </div>
      <div className={styles.desktopAmount}>{amountElement}</div>
      {children}
    </div>
  );
};

export default ApplicationMonetaryBox;
