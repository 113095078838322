import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { PopupsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import Popup from 'components/Popup';

import styles from './DisclosuresBox.module.scss';

interface IDisclosure {
  title: string;
  content: string;
}

interface IDisclosuresBoxProps {
  content?: IDisclosure[];
  singleDisclosure?: boolean;
  className?: string;
}

const DisclosuresBox = ({ content, singleDisclosure, className }: IDisclosuresBoxProps) => {
  const [currentDisclosure, setCurrentDisclosure] = useState<IDisclosure | null>(null);

  const { t: translate } = useTranslation(TranslationNameSpaces.Popups);

  const aprDisclosureTitle = translate(PopupsTranslationKeys.APRDisclosureTitle);
  const aprDisclosureDescription = translate(PopupsTranslationKeys.APRDisclosureDescription);

  const partnerGuaranteeDisclosureTitle = translate(
    PopupsTranslationKeys.PartnerGuaranteeDisclosureTitle,
  );
  const partnerGuaranteeDisclosureDescription = translate(
    PopupsTranslationKeys.PartnerGuaranteeDisclosureDescription,
  );

  const DISCLOSURES = [
    {
      title: aprDisclosureTitle,
      content: aprDisclosureDescription,
    },
    {
      title: partnerGuaranteeDisclosureTitle,
      content: partnerGuaranteeDisclosureDescription,
    },
  ] as IDisclosure[];

  const currentContent = content || DISCLOSURES;

  return (
    <div
      className={clsx(
        singleDisclosure ? styles.singleDisclosureContainer : styles.container,
        className,
      )}
    >
      <div>
        {currentContent.map((item, index) => (
          <span
            className={styles.disclosureItem}
            onClick={() => setCurrentDisclosure(item)}
            key={item.title}
          >
            {item.title}
            {index === currentContent.length - 1 || currentContent?.length === 0 ? (
              ''
            ) : (
              <span className={styles.separator}>, </span>
            )}
          </span>
        ))}
      </div>
      <Popup
        isOpen={!!currentDisclosure}
        onClose={() => setCurrentDisclosure(null)}
        title={currentDisclosure?.title}
      >
        {currentDisclosure?.content}
      </Popup>
    </div>
  );
};

export default DisclosuresBox;
