export enum EventKey {
  Logout = 'logout',
}

const eventBus = {
  on(event: EventKey, callback: () => void) {
    document.addEventListener(event, () => callback());
  },
  dispatch(event: EventKey) {
    document.dispatchEvent(new CustomEvent(event));
  },
  remove(event: EventKey, callback: () => void) {
    document.removeEventListener(event, callback);
  },
};

export default eventBus;
