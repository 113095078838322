import { IApplicationLabel } from 'api/digifi/los/ApplicationsApi';
import {
  ApplicationLabelId,
  ApplicationLabelName,
  ApplicationLabelType,
  ProdApplicationLabelId,
} from 'enums/ApplicationLabelId';
import { Environment } from 'enums/Environment';
import { environmentSecret } from 'secrets';

export const getApplicationLabelIdByEnvironment = (
  labelName: ApplicationLabelName,
): ApplicationLabelType => {
  if (environmentSecret !== Environment.Production) {
    return ApplicationLabelId[labelName];
  }

  return ProdApplicationLabelId[labelName];
};

export const matchApplicationLabelByEnvironment = (
  labelId: string | null,
  labelName: ApplicationLabelName,
): boolean => {
  if (!labelName) {
    return false;
  }

  if (environmentSecret !== Environment.Production) {
    return labelId === ApplicationLabelId[labelName];
  }

  return labelId === ProdApplicationLabelId[labelName];
};

export const getApplicationLabelsIds = (labels: IApplicationLabel[]) => {
  return labels.map((label) => label.id);
};
