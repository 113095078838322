import { Trans, useTranslation } from 'react-i18next';

import Paragraph from 'components/Paragraph';
import React from 'react';
import TermWrapper from './TermWrapper';
import Title from 'components/Title';
import { TypographySize } from 'enums/TypographySize';

interface ITermWithTagsProps {
  nameSpaceKey: string;
  titleKey: string;
  linkKey?: string;
  emailKey?: string;
  descriptionKey?: string;
  itemsKey?: string;
}

const TermWithTags = ({
  nameSpaceKey,
  titleKey,
  descriptionKey,
  emailKey,
  itemsKey,
  linkKey,
}: ITermWithTagsProps) => {
  const { t: translate } = useTranslation(nameSpaceKey);

  return (
    <TermWrapper>
      <Title variant="h3" marginBottom="16">
        {translate(titleKey)}
      </Title>
      {itemsKey ? (
        (translate(itemsKey, { returnObjects: true }) as string[]).map((item) => (
          <Trans
            key={item}
            t={translate}
            components={{
              P: <Paragraph marginBottom="16" variant={TypographySize.Middle} />,
              Link: <a href={translate(linkKey!)!} />,
              Email: <a href={`emailto:${translate(emailKey!)}`} />,
            }}
          >
            {item}
          </Trans>
        ))
      ) : (
        <Trans
          i18nKey={descriptionKey}
          t={translate}
          components={{
            P: <Paragraph variant={TypographySize.Middle} />,
            Link: <a href={translate(linkKey!)!} />,
            Email: <a href={`emailto:${translate(emailKey!)}`} />,
          }}
        />
      )}
    </TermWrapper>
  );
};

export default TermWithTags;
