export enum ApplicationStatusId {
  NewApplication = '018378dcee03003a0a8c0c20',
  PersonalInformation = '018378dcee03003d0a8c0c20',
  FinancialInformation = '018378dcee03003e0a8c0c20',
  OfferSelection = '018378dcee03003f0a8c0c20',
  BankVerification = '018378dcee0300400a8c0c20',
  Approved = '018378dcee03003b0a8c0c20',
  Rejected = '018378dcee03003c0a8c0c20',
  OfferAgreement = '66d1e9e949b4f7015f22af5e',
}

export enum ProdApplicationStatusId {
  NewApplication = '018571ffc31300010a8c0b2f',
  PersonalInformation = '018571ffee5700010a8c0b2f',
  FinancialInformation = '0185720028fd00010a8c0cd5',
  OfferSelection = '018572006a5200010a8c0cae',
  BankVerification = '01857200934800010a8c0cae',
  Approved = '018571ffc31100010a8c0b2f',
  Rejected = '018571ffc31200010a8c0b2f',
  OfferAgreement = '673df6e784b53780da5c47ae',
}

export type ApplicationStatusIdType = ApplicationStatusId | ProdApplicationStatusId;
