import React, { useState } from 'react';
import CollapsiblePanel from 'components/Collapsible';
import styles from './AcceptedIncomeSources.module.scss';
import { ChevronDownIcon } from 'static/images';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { AcceptedIncomeSourcesTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

export const Source = ({ sourceName, className }: { sourceName: string; className?: string }) => (
  <div className={styles.sourceContainer}>
    <div className={styles.dotContainer}>
      <div className={styles.dot}></div>
    </div>
    <li className={clsx(styles.source, className)}>{sourceName}</li>
  </div>
);

const AcceptedIncomeSources = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.AcceptedIncomeSources);

  const [isActive, setIsActive] = useState(false);

  const OPTIONS = translate(AcceptedIncomeSourcesTranslationKeys.Sources, {
    returnObjects: true,
  }) as string[];

  return (
    <CollapsiblePanel
      headerContent={
        <div className={styles.header} onClick={() => setIsActive(!isActive)}>
          {translate(AcceptedIncomeSourcesTranslationKeys.Title)}{' '}
          <ChevronDownIcon className={clsx(isActive && styles.svgRotate)} />
        </div>
      }
      contentClassName={styles.content}
      isActive={isActive}
      bodyContent={
        <ul className={styles.sources}>
          {OPTIONS.map((sourceName) => {
            return <Source sourceName={sourceName} key={sourceName} />;
          })}
        </ul>
      }
    />
  );
};

export default AcceptedIncomeSources;
