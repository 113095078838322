import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addFunds, requestFunds, updateApplication } from 'thunks';
import { convertToMonetary } from 'utils/profund/formats';
import {
  ApplicationDetailsPageTranslationKeys,
  ButtonsTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import { TypographySize } from 'enums/TypographySize';
import { InputPrefixAndSuffixType } from 'enums/InputPrefixAndSuffixType';
import { ApplicationLabelName, ApplicationLabelType } from 'enums/ApplicationLabelId';
import { ApplicationVariable } from 'enums/ApplicationVariable';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setSelectedApplication } from 'handlers/portal/applicationsSlice';
import NumberInput from 'components/NumberInput';
import TitleWithInfoTooltip from 'components/TitleWithInfoTooltip';
import Paragraph from 'components/Paragraph';
import PortalAndDwollaTermsCheckbox from 'components/PortalAndDwollaTermsCheckbox';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { ModalCloseIcon } from 'static/images';

import styles from './ManageFunds.module.scss';
import SuccessMessage from './SuccessMessage';
import { useErrorMessages } from 'hooks/useErrorMessages';
import { getLoadingFields } from 'handlers/selectors';
import { TooltipId } from 'enums/TooltipId';
import { getApplicationLabelIdByEnvironment } from 'utils/applicationLabelsHelpers';

const REQUEST_FUNDS_MAX_LENGTH = 12;
const MIN_FUND_AMOUNT = 100;

export enum ManageFundsType {
  RequestFunds = 'Request Funds',
  AddFunds = 'Add Funds',
}

interface IRequestFundsProps {
  handleClose: () => void;
  availableForRequestAmount: number | undefined;
  availableForAddAmount: number | undefined;
  applicationId: string;
  manageType: ManageFundsType;
}

const ManageFunds = ({
  handleClose,
  availableForRequestAmount,
  availableForAddAmount,
  applicationId,
  manageType,
}: IRequestFundsProps) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();

  const [checked, setChecked] = useState(false);
  const [amount, setAmount] = useState<undefined | number>(undefined);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);

  const validate = (value: number | undefined) => {
    if (value === undefined) {
      switch (manageType) {
        case ManageFundsType.AddFunds:
          setError(
            getMessageForRequiredFields(
              translate(ApplicationDetailsPageTranslationKeys.AddFundsInputTitle),
            ),
          );
          return;

        case ManageFundsType.RequestFunds:
          setError(
            getMessageForRequiredFields(
              translate(ApplicationDetailsPageTranslationKeys.RequestFundsInputTitle),
            ),
          );
          return;
      }
    }

    if (value < MIN_FUND_AMOUNT) {
      setError(`${getMinAmountError()} ${convertToMonetary(MIN_FUND_AMOUNT, 0)}`);
      return;
    }

    if (manageType === ManageFundsType.RequestFunds) {
      if (value > availableForRequestAmount!) {
        setError(`${getMaxAmountError()} ${convertToMonetary(availableForRequestAmount!)}`);
        return;
      }
    }

    if (manageType === ManageFundsType.AddFunds) {
      if (value > availableForAddAmount!) {
        setError(
          `${translate(ApplicationDetailsPageTranslationKeys.MaxAddFundsAmountLabel, {
            ns: TranslationNameSpaces.ApplicationDetailsPage,
          })} ${convertToMonetary(availableForAddAmount!)}`,
        );

        return;
      }
    }

    setError('');
  };

  useEffect(() => {
    if (amount || error) {
      validate(amount);
    }

    setChecked(false);
  }, [manageType]);

  const { isUpdateApplicationInProgress, isRequestFundsLoading, isAddFundsLoading } =
    useAppSelector(getLoadingFields);

  const { t: translate } = useTranslation([
    TranslationNameSpaces.ApplicationDetailsPage,
    TranslationNameSpaces.Buttons,
  ]);

  const [getMessageForRequiredFields] = useErrorMessages();

  const getMinAmountError = () => {
    switch (manageType) {
      case ManageFundsType.AddFunds:
        return translate(ApplicationDetailsPageTranslationKeys.MinAddFundsAmountLabel, {
          ns: TranslationNameSpaces.ApplicationDetailsPage,
        });

      case ManageFundsType.RequestFunds:
        return translate(ApplicationDetailsPageTranslationKeys.MinRequestAmountLabel, {
          ns: TranslationNameSpaces.ApplicationDetailsPage,
        });
    }
  };

  const getMaxAmountError = () => {
    switch (manageType) {
      case ManageFundsType.AddFunds:
        return translate(ApplicationDetailsPageTranslationKeys.MaxAddFundsAmountLabel, {
          ns: TranslationNameSpaces.ApplicationDetailsPage,
        });

      case ManageFundsType.RequestFunds:
        return translate(ApplicationDetailsPageTranslationKeys.MaxRequestAmountLabel, {
          ns: TranslationNameSpaces.ApplicationDetailsPage,
        });
    }
  };

  const handleChange = (value: number | undefined) => {
    setAmount(value);
    validate(value);
  };

  const handleContinue = async () => {
    const getLabels = (): ApplicationLabelType[] => {
      if (manageType === ManageFundsType.RequestFunds) {
        return [getApplicationLabelIdByEnvironment(ApplicationLabelName.RequestFunds)];
      }

      if (manageType === ManageFundsType.AddFunds) {
        return [getApplicationLabelIdByEnvironment(ApplicationLabelName.AddFunds)];
      }

      return [];
    };

    const getVariables = () => {
      if (manageType === ManageFundsType.RequestFunds) {
        return { [ApplicationVariable.RequestFundsAmount]: amount };
      }

      if (manageType === ManageFundsType.AddFunds) {
        return { [ApplicationVariable.AddFundsAmount]: amount };
      }

      return {};
    };

    switch (manageType) {
      case ManageFundsType.RequestFunds:
        await dispatchWithUnwrap(requestFunds({ amount: amount!, applicationId }));
        break;
      case ManageFundsType.AddFunds:
        await dispatchWithUnwrap(addFunds({ amount: amount!, applicationId }));
        break;
    }

    const updatedApplication = await dispatchWithUnwrap(
      updateApplication({
        applicationId: applicationId as string,
        params: {
          labelsIds: getLabels(),
          variables: {
            ...getVariables(),
          },
        },
      }),
    );

    dispatch(setSelectedApplication(updatedApplication));
    setSuccessMessage(true);
  };

  const getTitle =
    manageType === ManageFundsType.RequestFunds
      ? translate(ApplicationDetailsPageTranslationKeys.RequestFundsInputTitle, {
          ns: TranslationNameSpaces.ApplicationDetailsPage,
        })
      : translate(ApplicationDetailsPageTranslationKeys.AddFundsInputTitle, {
          ns: TranslationNameSpaces.ApplicationDetailsPage,
        });

  const getTooltip =
    manageType === ManageFundsType.RequestFunds
      ? translate(ApplicationDetailsPageTranslationKeys.RequestFundsTooltip, {
          ns: TranslationNameSpaces.ApplicationDetailsPage,
        })
      : translate(ApplicationDetailsPageTranslationKeys.AddFundsTooltip, {
          ns: TranslationNameSpaces.ApplicationDetailsPage,
        });

  const getInputLabel = () => {
    switch (manageType) {
      case ManageFundsType.RequestFunds:
        return translate(ApplicationDetailsPageTranslationKeys.RequestFundsPlaceholder, {
          ns: TranslationNameSpaces.ApplicationDetailsPage,
        });
      case ManageFundsType.AddFunds:
        return translate(ApplicationDetailsPageTranslationKeys.AddFundsInputPlaceholder, {
          ns: TranslationNameSpaces.ApplicationDetailsPage,
        });
    }
  };

  const getContent = successMessage ? (
    <SuccessMessage mangeType={manageType} amount={amount!} />
  ) : (
    <div>
      {manageType === ManageFundsType.RequestFunds && (
        <div className={styles.availableForRequestContainer}>
          <Paragraph variant={TypographySize.Middle}>
            {translate(ApplicationDetailsPageTranslationKeys.AvailableForRequest, {
              ns: TranslationNameSpaces.ApplicationDetailsPage,
            })}
          </Paragraph>
          <p className={styles.availableForRequestAmount}>
            {availableForRequestAmount ? convertToMonetary(availableForRequestAmount) : '—'}
          </p>
        </div>
      )}
      <div className={styles.form}>
        <div className={styles.requestFundsInput}>
          <NumberInput
            label={getInputLabel()}
            prefix={InputPrefixAndSuffixType.Monetary}
            value={amount}
            onValueChange={(values) => {
              handleChange(values.floatValue);
            }}
            error={error}
            thousandSeparator
            allowNegative={false}
            maxLength={REQUEST_FUNDS_MAX_LENGTH}
            decimalScale={0}
            disabled={!availableForRequestAmount}
          />
        </div>
      </div>
      <PortalAndDwollaTermsCheckbox checked={checked} setIsChecked={(value) => setChecked(value)} />
      <Button
        variant={ButtonVariant.Primary}
        size={ButtonSize.Large}
        className={styles.agreeButton}
        disabledWithinInputAttr={
          !checked ||
          !!error ||
          !amount ||
          isUpdateApplicationInProgress ||
          isRequestFundsLoading ||
          isAddFundsLoading
        }
        disabled={
          !checked ||
          !!error ||
          !amount ||
          isUpdateApplicationInProgress ||
          isRequestFundsLoading ||
          isAddFundsLoading
        }
        isLoading={isUpdateApplicationInProgress || isRequestFundsLoading || isAddFundsLoading}
        onClick={handleContinue}
      >
        {translate(ButtonsTranslationKeys.AgreeAndContinue, {
          ns: TranslationNameSpaces.Buttons,
        })}
      </Button>
    </div>
  );

  return (
    <div>
      <div className={styles.titleContainer}>
        <TitleWithInfoTooltip
          title={getTitle}
          tooltip={getTooltip}
          id={
            manageType === ManageFundsType.RequestFunds
              ? TooltipId.RequestFunds
              : TooltipId.AddFunds
          }
        />
        <ModalCloseIcon className={styles.closeIcon} onClick={handleClose} />
      </div>
      {getContent}
    </div>
  );
};

export default ManageFunds;
