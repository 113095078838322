import React from 'react';
import clsx from 'clsx';
import { NumericFormatProps, NumericFormat, PatternFormat } from 'react-number-format';
import InputWrapper from 'components/InputWrapper';
import { IInputWrapperProps } from 'components/InputWrapper/InputWrapper';
import styles from './NumberInput.module.scss';

interface INumberInputProps extends NumericFormatProps, IInputWrapperProps {
  format?: string;
  mask?: string;
  handleChange?: (val: string) => void;
}

const NumberInput = ({
  error,
  label,
  className,
  prefix,
  inputIcon,
  tooltipText,
  format: formatString,
  mask,
  allowNegative,
  thousandSeparator,
  decimalScale = 2,
  handleChange,
  name,
  onBlur,
  allowLeadingZeros,
  wrapperClassName,
  ...props
}: INumberInputProps) => {
  const InputComponent = formatString ? (
    <PatternFormat
      format={formatString!}
      mask={mask}
      placeholder={formatString}
      name={name}
      onBlur={onBlur}
      {...props}
    />
  ) : (
    <NumericFormat
      prefix={prefix}
      placeholder={label as string}
      thousandSeparator={thousandSeparator}
      allowNegative={allowNegative}
      decimalScale={decimalScale}
      name={name}
      onBlur={onBlur}
      allowLeadingZeros={allowLeadingZeros}
      {...props}
    />
  );

  return (
    <InputWrapper
      error={error}
      label={label}
      inputIcon={inputIcon}
      tooltipText={tooltipText}
      wrapperClassName={clsx(wrapperClassName, formatString && styles.inputContainer)}
    >
      {InputComponent}
    </InputWrapper>
  );
};

export default NumberInput;
