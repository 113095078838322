import React, { FC, ReactNode } from 'react';
import styles from './DwollaMicrodepositsPopupContent.module.scss';
import { useTranslation } from 'react-i18next';
import { ButtonsTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';

interface IDwollaMicrodepositsPopupContentProps {
  onButtonClick: () => void;
  description: string | ReactNode;
}

const DwollaMicrodepositsPopupContent: FC<IDwollaMicrodepositsPopupContentProps> = ({ 
  onButtonClick,
  description,
}) => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.Buttons,
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        {description}
      </div>
      <Button
        variant={ButtonVariant.Primary}
        size={ButtonSize.Large}
        onClick={onButtonClick}
      >
        {translate(ButtonsTranslationKeys.Continue, {
          ns: TranslationNameSpaces.Buttons,
        })}
      </Button>
    </div>
  );
};

export default DwollaMicrodepositsPopupContent;
