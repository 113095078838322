import { CreditAuthorizationTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

import Paragraph from 'components/Paragraph';
import React from 'react';
import TermWrapper from 'components/Terms/TermWrapper';
import Title from 'components/Title';
import { TypographySize } from 'enums/TypographySize';
import { useTranslation } from 'react-i18next';

const CreditAuthorizationTerm = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.CreditAuthorization);
  return (
    <>
      <TermWrapper>
        <Title variant="h1">{translate(CreditAuthorizationTranslationKeys.Title)}</Title>
      </TermWrapper>
      <Paragraph variant={TypographySize.Middle}>
        {translate(CreditAuthorizationTranslationKeys.Description)}
      </Paragraph>
    </>
  );
};

export default CreditAuthorizationTerm;
