import {
  IntellectualPropertyRightsTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';

import React from 'react';
import Title from 'components/Title';
import { getTermComponent } from 'components/Terms/getTermComponent';
import intellectualPropertyRightsTermsItems from './intellectualPropertyRightsTermsItems';
import { useTranslation } from 'react-i18next';

const IntellectualPropertyRights = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.IntellectualPropertyRights);
  return (
    <>
      <Title id={TranslationNameSpaces.IntellectualPropertyRights} variant="h1" marginBottom="16">
        {translate(IntellectualPropertyRightsTranslationKeys.Title)}
      </Title>
      {intellectualPropertyRightsTermsItems.map(getTermComponent)}
    </>
  );
};

export default IntellectualPropertyRights;
