import { OfferProviderNames } from 'enums/OfferProviderNames';
import { UpgradePlanCategory } from 'enums/UpgradePlanCategory';
import { IOffer } from 'handlers/portal/offersSlice';

export type OfferCategoryType = 'lowFixedRate' | 'lowPayments' | 'promotional';

export enum PromotionalProductDescription {
  C8023 = 'C8023',
  C5054 = 'C5054',
}

const isProductPromotional = (productDescription: string) => {
  return productDescription === PromotionalProductDescription.C8023 ||
    productDescription === PromotionalProductDescription.C5054;
};

const sortByScoreAndApr = (prevOffer: IOffer, nextOffer: IOffer) => {
  if (prevOffer.apr > nextOffer.apr) {
    return 1;
  }

  if (prevOffer.apr < nextOffer.apr) {
    return -1;
  }

  if (prevOffer.recommendationScore! < nextOffer.recommendationScore!) {
    return 1;
  }

  if (prevOffer.recommendationScore! > nextOffer.recommendationScore!) {
    return -1;
  }

  return 0;
};

export const sortOffersByParameter = (parameter: OfferCategoryType, offers: IOffer[]): IOffer[] => {
  const offersWithoutPromotional = offers.filter((offer) => {
    if (offer.offerProvider === OfferProviderNames.WaterCrees) {
      return !isProductPromotional(offer.productDescription!);
    }

    return true;
  });

  switch (parameter) {
    case 'lowFixedRate':
      return offersWithoutPromotional.sort(sortByScoreAndApr);
    case 'lowPayments':
      return offersWithoutPromotional.sort((prevOffer, nextOffer) => {
        if (prevOffer.monthlyPayment > nextOffer.monthlyPayment) {
          return 1;
        }

        if (prevOffer.monthlyPayment < nextOffer.monthlyPayment) {
          return -1;
        }

        if (prevOffer.recommendationScore! < nextOffer.recommendationScore!) {
          return 1;
        }

        if (prevOffer.recommendationScore! > nextOffer.recommendationScore!) {
          return -1;
        }

        return 0;
      });
    case 'promotional':
      return [...offers].filter((offer) => {
        if (offer.offerProvider === OfferProviderNames.Upgrade) {
          return offer.upgradePlanCategory === UpgradePlanCategory.ZERO_INTEREST_RATE;
        }

        if (offer.offerProvider === OfferProviderNames.Even) {
          return offer.apr <= 10;
        }

        return isProductPromotional(offer.productDescription!);
      }).sort(sortByScoreAndApr);
  }
};

export const TABLET_WIDTH = 712;
