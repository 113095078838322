import React, { FC } from 'react';
import styles from './PopupWrapper.module.scss';

interface IPopupWrapperProps {
  isOpen: boolean;
  onClickOutside?: () => void;
}

const PopupWrapper: FC<IPopupWrapperProps> = ({ isOpen, onClickOutside, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.popup} role="button" tabIndex={0} onClick={onClickOutside}>
      {children}
    </div>
  );
};

export default PopupWrapper;
