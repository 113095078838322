export enum BankVerificationActionType {
  GetBorrowerLinkToken = 'bankVerification/getBorrowerLinkToken',
  GetIntermediaryLinkToken = 'bankVerification/getIntermediaryLinkToken',
  SaveBorrowerToken = 'bankVerification/saveBorrowerToken',
  SaveIntermediaryToken = 'bankVerification/saveIntermediaryToken',
  CreateBorrowerFundingSource = 'bankVerification/createBorrowerFundingSource',
  CreateIntermediaryFundingSource = 'bankVerification/createIntermediaryFundingSource',
  CreateBorrowerDwollaMicroDepositFundingSource = 'bankVerification/createBorrowerDwollaMicroDepositFundingSource',
  VerifyDwollaMicroDeposits = 'bankVerification/verifyDwollaMicroDeposits',
  VerifyIntermediaryDwollaMicroDeposits = 'bankVerification/verifyIntermediaryDwollaMicroDeposits',
}
