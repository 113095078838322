import { AccessAndUseOfTheServicesTranslationKeys, TranslationNameSpaces } from 'enums/Translation';

import React from 'react';
import Title from 'components/Title';
import accessAndUseOfTheServicesTermsItems from './accessAndUseOfTheServicesTermsItems';
import { getTermComponent } from 'components/Terms/getTermComponent';
import { useTranslation } from 'react-i18next';

const AccessAndUseOfTheServicesTerm = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.AccessAndUseOfTheServices);

  return (
    <>
      <Title id={TranslationNameSpaces.AccessAndUseOfTheServices} variant="h1" marginBottom="16">
        {translate(AccessAndUseOfTheServicesTranslationKeys.Title)}
      </Title>
      {accessAndUseOfTheServicesTermsItems.map(getTermComponent)}
    </>
  );
};

export default AccessAndUseOfTheServicesTerm;
