import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ProjectAccountInfoPopupContent.module.scss';
import { TranslationNameSpaces, WhatNextPageTranslationKeys } from 'enums/Translation';
import { Source } from 'components/AcceptedIncomeSources/AcceptedIncomeSources';

const ProjectAccountInfoPopup = () => {
  const { t: translate } = useTranslation([TranslationNameSpaces.WhatNextPage]);

  return (
    <div className={styles.info}>
      <div className={styles.textBlock}>
        {translate(WhatNextPageTranslationKeys.DescriptionPart1, {
          ns: TranslationNameSpaces.WhatNextPage,
        })}
      </div>
      <div className={styles.textBlock}>
        {translate(WhatNextPageTranslationKeys.DescriptionPart2, {
          ns: TranslationNameSpaces.WhatNextPage,
        })}
        {(
          translate(WhatNextPageTranslationKeys.DescriptionPart3, {
            returnObjects: true,
            ns: TranslationNameSpaces.WhatNextPage,
          }) as Array<string>
        ).map((benefit) => (
          <Source sourceName={benefit} className={styles.lineHeight24} key={benefit} />
        ))}
      </div>
      <div>
        {translate(WhatNextPageTranslationKeys.DescriptionPart4, {
          ns: TranslationNameSpaces.WhatNextPage,
        })}
      </div>
    </div>
  );
};

export default ProjectAccountInfoPopup;
