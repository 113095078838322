import { Formik, FormikProps } from 'formik';
import { IFinancialInformation } from 'handlers/portal/financialInformation';
import React, { RefObject, useEffect } from 'react';
import NumberInput from 'components/NumberInput';
import styles from './BorrowerFinancialInfo.module.scss';
import { FinancialInformationVariable } from 'enums/FinancialInformationVariable';
import { InputPrefixAndSuffixType } from 'enums/InputPrefixAndSuffixType';
import { InfoCircleIcon } from 'static/images';
import DateInput from 'components/DateInput';
import SelectInput from 'components/SelectInput';
import { useBorrowerFinancialInfoValidationSchema } from './validationSchema';
import { useTranslatedDropdownOptions } from './constants';
import { IOptionType } from 'components/SelectInput/SelectInput';
import {
  InputLabelsTranslationKeys,
  FinancialInformationPageTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import { useTranslation } from 'react-i18next';

const ANNUAL_INCOME_MAX_LENGTH = 12;

interface IBorrowerFinancialInfo {
  borrowerData: Partial<IFinancialInformation>;
  formRef: RefObject<FormikProps<Partial<IFinancialInformation>>>;
  annualIncomeInputRef: RefObject<HTMLInputElement>;
  setIsFormValid: (value: boolean) => void;
}

export const getSelectedOption = (options: IOptionType[], optionValue: string | null) => {
  return options.find((el) => el.value === optionValue) || null;
};

const BorrowerFinancialInfo = ({
  borrowerData,
  formRef,
  annualIncomeInputRef,
  setIsFormValid,
}: IBorrowerFinancialInfo) => {
  const { t: translate } = useTranslation([
    TranslationNameSpaces.FinancialInformationPage,
    TranslationNameSpaces.InputLabels,
  ]);

  const {
    PROPERTY_INPUT_OPTIONS,
    CREDIT_RATING_OPTIONS,
    EMPLOYMENT_STATUS_OPTIONS,
    PAY_FREQUENCY_OPTIONS,
    HIGHEST_EDUCATION_OPTIONS,
   } = useTranslatedDropdownOptions();

  const borrowerFinancialInfoValidationSchema = useBorrowerFinancialInfoValidationSchema();

  return (
    <Formik
      initialValues={borrowerData}
      onSubmit={() => {}}
      validationSchema={borrowerFinancialInfoValidationSchema}
      validateOnBlur
      validateOnChange
      validateOnMount
      enableReinitialize
      innerRef={formRef}
    >
      {({ values, handleChange, handleBlur, setFieldValue, touched, errors, isValid }) => {
        const getErrorMessage = (name: FinancialInformationVariable) =>
          !!touched[name] && errors[name] ? errors[name] : undefined;

        useEffect(() => {
          setIsFormValid(isValid);
        }, [isValid]);

        return (
          <>
            <div className={styles.content}>
              <NumberInput
                label={translate(InputLabelsTranslationKeys.AnnualIncome, {
                  ns: TranslationNameSpaces.InputLabels,
                })}
                name={FinancialInformationVariable.AnnualIncome}
                prefix={InputPrefixAndSuffixType.Monetary}
                error={getErrorMessage(FinancialInformationVariable.AnnualIncome)}
                onChange={handleChange}
                value={values[FinancialInformationVariable.AnnualIncome]}
                thousandSeparator
                allowNegative={false}
                decimalScale={0}
                onBlur={handleBlur}
                maxLength={ANNUAL_INCOME_MAX_LENGTH}
                tooltipText={
                  translate(FinancialInformationPageTranslationKeys.AnnualIncomeTooltip, {
                    ns: TranslationNameSpaces.FinancialInformationPage,
                  })!
                }
                inputIcon={<InfoCircleIcon />}
                getInputRef={annualIncomeInputRef}
              />
              <NumberInput
                label={translate(InputLabelsTranslationKeys.SSN, {
                  ns: TranslationNameSpaces.InputLabels,
                })}
                name={FinancialInformationVariable.SocialSecurityNumber}
                onChange={handleChange}
                value={values[FinancialInformationVariable.SocialSecurityNumber]}
                error={getErrorMessage(FinancialInformationVariable.SocialSecurityNumber)}
                allowNegative={false}
                onBlur={handleBlur}
                maxLength={11}
                format="###-##-####"
                mask=""
                tooltipText={
                  translate(FinancialInformationPageTranslationKeys.SSNTooltipText, {
                    ns: TranslationNameSpaces.FinancialInformationPage,
                  })!
                }
                inputIcon={<InfoCircleIcon />}
              />
              <DateInput
                label={translate(InputLabelsTranslationKeys.DateOfBirth, {
                  ns: TranslationNameSpaces.InputLabels,
                })}
                name={FinancialInformationVariable.DateOfBirth}
                error={getErrorMessage(FinancialInformationVariable.DateOfBirth)}
                onChange={handleChange}
                value={values[FinancialInformationVariable.DateOfBirth]}
                onBlur={handleBlur}
              />
              <SelectInput
                selectedOption={getSelectedOption(
                  PROPERTY_INPUT_OPTIONS,
                  values[FinancialInformationVariable.PropertyStatus] as string,
                )}
                options={PROPERTY_INPUT_OPTIONS}
                placeholder={translate(InputLabelsTranslationKeys.PropertyStatus, {
                  ns: TranslationNameSpaces.InputLabels,
                })}
                name={FinancialInformationVariable.PropertyStatus}
                onValuePicked={(data) => {
                  setFieldValue(FinancialInformationVariable.PropertyStatus, data);
                }}
                errorMessage={getErrorMessage(FinancialInformationVariable.PropertyStatus)}
              />
              <SelectInput
                errorMessage={getErrorMessage(FinancialInformationVariable.CreditScore)}
                selectedOption={getSelectedOption(
                  CREDIT_RATING_OPTIONS,
                  values[FinancialInformationVariable.CreditScore] as string,
                )}
                options={CREDIT_RATING_OPTIONS}
                placeholder={translate(InputLabelsTranslationKeys.CreditScore, {
                  ns: TranslationNameSpaces.InputLabels,
                })}
                name={FinancialInformationVariable.CreditScore}
                onValuePicked={(data) => {
                  setFieldValue(FinancialInformationVariable.CreditScore, data);
                }}
              />
              <SelectInput
                selectedOption={getSelectedOption(
                  EMPLOYMENT_STATUS_OPTIONS,
                  values[FinancialInformationVariable.EmploymentStatus] as string,
                )}
                options={EMPLOYMENT_STATUS_OPTIONS}
                placeholder={translate(InputLabelsTranslationKeys.EmploymentStatus, {
                  ns: TranslationNameSpaces.InputLabels,
                })}
                name={FinancialInformationVariable.EmploymentStatus}
                onValuePicked={(data) => {
                  setFieldValue(FinancialInformationVariable.EmploymentStatus, data);
                }}
                errorMessage={getErrorMessage(FinancialInformationVariable.EmploymentStatus)}
              />
              <SelectInput
                selectedOption={getSelectedOption(
                  PAY_FREQUENCY_OPTIONS,
                  values[FinancialInformationVariable.PayFrequency] as string,
                )}
                options={PAY_FREQUENCY_OPTIONS}
                placeholder={translate(InputLabelsTranslationKeys.PayFrequency, {
                  ns: TranslationNameSpaces.InputLabels,
                })}
                name={FinancialInformationVariable.PayFrequency}
                onValuePicked={(data) => {
                  setFieldValue(FinancialInformationVariable.PayFrequency, data);
                }}
                errorMessage={getErrorMessage(FinancialInformationVariable.PayFrequency)}
              />
              <SelectInput
                selectedOption={getSelectedOption(
                  HIGHEST_EDUCATION_OPTIONS,
                  values[FinancialInformationVariable.HighestEducationLevel] as string,
                )}
                options={HIGHEST_EDUCATION_OPTIONS}
                placeholder={translate(InputLabelsTranslationKeys.HighestEducationLevel, {
                  ns: TranslationNameSpaces.InputLabels,
                })}
                name={FinancialInformationVariable.HighestEducationLevel}
                onValuePicked={(data) => {
                  setFieldValue(FinancialInformationVariable.HighestEducationLevel, data);
                }}
                errorMessage={getErrorMessage(FinancialInformationVariable.HighestEducationLevel)}
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default BorrowerFinancialInfo;
