import AdvertiserDisclosure from 'components/AdvertiserDisclosure';
import DisclosuresBox from 'components/DisclosuresBox';
import ParticipatingLenderInfo from 'components/InformationBox/ParticipatingLenderInfo';
import Loading from 'components/Loading';
import Menu from 'components/Menu';
import PageLayout from 'components/PageLayout';
import { LayoutSize } from 'components/PageLayout/PageLayout';
import Title from 'components/Title';
import { ApplicationLabelName, ApplicationLabelType } from 'enums/ApplicationLabelId';
import { ApplicationVariable } from 'enums/ApplicationVariable';
import {
  BankVerificationPageTranslationKeys,
  MenuTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import { getLoadingFields } from 'handlers/selectors';
import { useAppSelector } from 'hooks/reduxHooks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PlaidLinkOnEventMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkStableEvent,
  usePlaidLink,
} from 'react-plaid-link';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from 'routes/AppRoutes';
import {
  createBorrowerFundingSource,
  getApplicationById,
  getApplicationStatus,
  getBorrowerLinkToken,
  logout,
  saveBorrowerToken,
  savePlaidEventToVariable,
  updateApplication,
} from 'thunks';
import styles from './PlaidBankVerification.module.scss';
import {
  getApplicationLabelIdByEnvironment,
  getApplicationLabelsIds,
} from 'utils/applicationLabelsHelpers';
import { DwollaCustomerStatus } from 'enums/DwollaCustomerStatus';
import { openToaster } from 'components/Toaster/Toaster';
import { capitalLettersToUpperCase } from 'utils/capitalLettersToUpperCase';

interface IPlaidBankVerificationProps {
  onPlaidVerificationError: () => void;
}

const PlaidBankVerificationPage = ({ onPlaidVerificationError }: IPlaidBankVerificationProps) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');

  const { t: translate } = useTranslation(TranslationNameSpaces.BankVerificationPage);

  const { getOffersByApplicationIdLoading } = useAppSelector(getLoadingFields);

  const handleOnSuccess = async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
    setLoading(true);

    try {
      await dispatchWithUnwrap(
        saveBorrowerToken({
          applicationId: applicationId!,
          token: public_token,
          accountId: metadata.accounts[0].id,
        }),
      );

      if (metadata.accounts[0].verification_status === 'pending_automatic_verification') {
        const application = await dispatchWithUnwrap(getApplicationById(applicationId!));

        await dispatchWithUnwrap(
          updateApplication({
            applicationId: applicationId!,
            params: {
              labelsIds: [
                ...(getApplicationLabelsIds(application.labels) as ApplicationLabelType[]),
                getApplicationLabelIdByEnvironment(ApplicationLabelName.MicroDeposits),
              ],
            },
          }),
        );
      }
      const redirectUrl = await dispatchWithUnwrap(createBorrowerFundingSource(applicationId!));

      const { dwollaCustomerStatus } = await dispatchWithUnwrap(
        getApplicationStatus(applicationId!),
      );

      if (dwollaCustomerStatus !== DwollaCustomerStatus.Document) {
        if (redirectUrl) {
          window.open(redirectUrl, '_self');
        }

        await dispatchWithUnwrap(logout());
        navigate(AppRoutes.Applications, { replace: true });

        return;
      }

      navigate(AppRoutes.Applications);
    } catch (error) {
      onPlaidVerificationError();
      setLoading(false);
    }
  };

  const handleOnEvent = async (
    eventName: PlaidLinkStableEvent | string,
    metadata: PlaidLinkOnEventMetadata,
  ) => {
    if (
      [PlaidLinkStableEvent.ERROR, PlaidLinkStableEvent.EXIT].includes(
        eventName as PlaidLinkStableEvent,
      )
    ) {
      setLoading(true);

      const data = {
        applicationId: applicationId!,
        plaidEvent: eventName,
        eventMetadata: metadata,
        variables: {
          [ApplicationVariable.BankVerificationComplete]: false,
        },
      };

      await dispatchWithUnwrap(savePlaidEventToVariable(data));

      if (eventName === PlaidLinkStableEvent.ERROR) {
        openToaster.error(capitalLettersToUpperCase(metadata.error_message!));
        onPlaidVerificationError();
      }

      onPlaidVerificationError();
    }
  };

  const { open, ready } = usePlaidLink({
    token,
    onSuccess: handleOnSuccess,
    onEvent: handleOnEvent,
  });

  const handleGetLinkToken = async () => {
    try {
      const linkToken = await dispatchWithUnwrap(
        getBorrowerLinkToken({ applicationId: applicationId! }),
      );
      setToken(linkToken);
    } catch {
      onPlaidVerificationError();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready]);

  useEffect(() => {
    handleGetLinkToken();
  }, []);

  if (!ready || loading || getOffersByApplicationIdLoading) {
    return <Loading />;
  }

  return (
    <>
      <Menu activeMenuItem={MenuTranslationKeys.Applications} />
      <PageLayout layoutSize={LayoutSize.Big} className={styles.pageLayoutClassName}>
        <div>
          <AdvertiserDisclosure />
          <Title variant="h1" marginBottom="16">
            {translate(BankVerificationPageTranslationKeys.Title)}
          </Title>
        </div>
        <div>
          <DisclosuresBox />
          <ParticipatingLenderInfo />
        </div>
      </PageLayout>
    </>
  );
};

export default PlaidBankVerificationPage;
