import { createAsyncThunk } from '@reduxjs/toolkit';
import BankVerificationApi  from 'api/digifi/portal/BankVerificationApi';
import { BankVerificationActionType } from 'enums/BankVerificationActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createUploadBankVerificationDocument = (api: BankVerificationApi) =>
  createAsyncThunk(
    BankVerificationActionType.CreateIntermediaryFundingSource,
    async (body: FormData, thunkApi) => {
      try {
        return await api.uploadDocument(body);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
