import Paragraph from 'components/Paragraph';
import React from 'react';
import TermWrapper from './TermWrapper';
import Title from 'components/Title';
import { TypographySize } from 'enums/TypographySize';
import { useTranslation } from 'react-i18next';

interface ITermWithItemListProps {
  titleKey: string;
  itemsKey: string;
  nameSpaceKey: string;
}

const TermWithItemList = ({ itemsKey, titleKey, nameSpaceKey }: ITermWithItemListProps) => {
  const { t: translate } = useTranslation(nameSpaceKey);
  return (
    <TermWrapper>
      <Title variant="h3" marginBottom="16">
        {translate(titleKey)}
      </Title>
      {(translate(itemsKey, { returnObjects: true }) as string[]).map((item) => (
        <Paragraph key={item} variant={TypographySize.Middle} marginBottom="16">
          {item}
        </Paragraph>
      ))}
    </TermWrapper>
  );
};

export default TermWithItemList;
