import { EnvironmentVariable } from 'enums/EnvironmentVariable';
import BaseSecret from './BaseSecret';

class SentryDsnSecret extends BaseSecret {
  constructor() {
    super(EnvironmentVariable.REACT_APP_SENTRY_DSN);
  }
}

export default SentryDsnSecret;
