export enum DocumentVerificationVariable {
  DocumentType = 'verificationDocumentType'
}

export enum DwollaDocumentVerificationProcess {
  Needed = 'Needed',
  Review = 'Review',
  Failed = 'Failed',
  Completed = 'Completed',
}
