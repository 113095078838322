import BaseApi, { FetchMethodType } from 'api/BaseApi';

import { VerificationEndpoints } from './PortalEndpoints';

export interface ISendVerificationCode {
  phone: string;
}

export interface IVerifyCode {
  code: string;
}

interface IVerificationApi {
  sendVerificationCode(
    sendVerificationCodeBody: ISendVerificationCode,
  ): Promise<ISendVerificationCode>;
  verifyCode(verifyCodeBody: IVerifyCode): Promise<{ success: boolean }>;
}

export default class VerificationApi extends BaseApi implements IVerificationApi {
  public sendVerificationCode(body: ISendVerificationCode): Promise<ISendVerificationCode> {
    return this.fetch(VerificationEndpoints.SendVerificationCode, {
      method: FetchMethodType.POST,
      body,
    });
  }

  public verifyCode(body: IVerifyCode): Promise<{ success: boolean }> {
    return this.fetch(VerificationEndpoints.VerifyCode, {
      method: FetchMethodType.POST,
      body,
    });
  }
}
