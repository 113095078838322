import React, { useEffect } from 'react';
import { CommonTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { FooterIcon, ProFundLogo } from 'static/images';
import { Outlet, useNavigate } from 'react-router-dom';

import { AppRoutes } from 'routes/AppRoutes';
import Link from 'components/Link';
import { useTranslation } from 'react-i18next';

import styles from './ProFundMainLayout.module.scss';
import eventBus, { EventKey } from 'utils/eventBus';
import { logout } from 'thunks';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getGlobalLoading } from 'handlers/selectors';
import Loading from 'components/Loading';
import clsx from 'clsx';

export const SUPPORT_CENTER_LINK = 'https://profund.net/support-center';

const MainLayout = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.Common);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const globalLoading = useAppSelector(getGlobalLoading);

  useEffect(() => {
    const logoutCallback = () => dispatch(logout({ withoutRequest: true }));
    eventBus.on(EventKey.Logout, logoutCallback);

    return () => eventBus.remove(EventKey.Logout, logoutCallback);
  });

  const getOutlet = () => {
    if (globalLoading) {
      return (
        <>
          <Loading />
          <div className={clsx(globalLoading && styles.outletLoadingContainer)}>
            <Outlet />
          </div>
        </>
      );
    }

    return <Outlet />;
  };

  return (
    <>
      <div className={styles.appContainer}>
        <header className={styles.header}>
          <ProFundLogo onClick={() => navigate(AppRoutes.Applications)} />
        </header>
        {getOutlet()}
      </div>
      <footer className={styles.footer}>
        <div className={styles.description}>
          <p className={styles.info}>
            {translate(CommonTranslationKeys.FooterDescription, {
              year: new Date().getFullYear(),
            })}
          </p>
          <Link target="_blank" rel="noopener noreferrer" href={SUPPORT_CENTER_LINK}>
            {translate(CommonTranslationKeys.FooterLink)}
          </Link>
        </div>
        <FooterIcon />
      </footer>
    </>
  );
};

export default MainLayout;
