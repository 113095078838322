import { createSlice } from '@reduxjs/toolkit';
import { ApplicationStatusIdType } from 'enums/ApplicationStatusId';
import { ApplicationLabelType } from 'enums/ApplicationLabelId';
import {
  checkOldApplications,
  continueWithApplication,
  getApplicationStatus,
  updateApplication,
} from 'thunks';
import { DwollaDocumentVerificationProcess } from 'enums/DocumentVerification';
import { DwollaMicroDepositsStatus } from 'enums/DwollaMicrodepositsStatus';

export interface IApplicationProcessState {
  applicationInfo: {
    statusId: ApplicationStatusIdType | null;
    labelsIds: ApplicationLabelType[];
    dwollaCustomerStatus: string | null;
    dwollaDocumentVerificationProcess: DwollaDocumentVerificationProcess | null;
    declineReason: string | null;
    fundedByEven: boolean | null;
    dwollaMicroDepositsStatus: DwollaMicroDepositsStatus | null;
    dwollaVerificationCount: number | null;
  };
  oldApplicationInformation: {
    currentApplicationId: string;
    oldApplicationId: string;
  };
}

const initialState: IApplicationProcessState = {
  applicationInfo: {
    statusId: null,
    labelsIds: [],
    dwollaCustomerStatus: null,
    dwollaDocumentVerificationProcess: null,
    fundedByEven: null,
    declineReason: null,
    dwollaMicroDepositsStatus: null,
    dwollaVerificationCount: null,
  },
  oldApplicationInformation: {
    oldApplicationId: '',
    currentApplicationId: '',
  },
};

const applicationProcessSlice = createSlice({
  name: 'applicationProcess',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getApplicationStatus.fulfilled, (state, { payload }) => {
        state.applicationInfo.statusId = payload.applicationStatusId;
        state.applicationInfo.labelsIds = payload.labelsIds;
        state.applicationInfo.dwollaCustomerStatus = payload.dwollaCustomerStatus;
        state.applicationInfo.fundedByEven = payload.fundedByEven;
        state.applicationInfo.dwollaDocumentVerificationProcess =
          payload.dwollaDocumentVerificationProcess;
        state.applicationInfo.dwollaMicroDepositsStatus = payload.dwollaMicroDepositsStatus;
        state.applicationInfo.dwollaVerificationCount = payload.dwollaVerificationCount;
      })
      .addCase(checkOldApplications.fulfilled, (state, { payload }) => {
        if (payload.oldApplicationId) {
          state.oldApplicationInformation.oldApplicationId = payload.oldApplicationId;
        }
        if (payload.applicationStatusId) {
          state.applicationInfo.statusId = payload.applicationStatusId;
        }
        state.oldApplicationInformation.currentApplicationId = payload.currentApplicationId;
      })
      .addCase(continueWithApplication.fulfilled, (state, { payload }) => {
        state.applicationInfo.statusId = payload.applicationStatusId;
        state.oldApplicationInformation.currentApplicationId = payload.applicationId;
      })
      .addCase(updateApplication.fulfilled, (state, { payload }) => {
        state.applicationInfo.statusId = payload.status.id as ApplicationStatusIdType;
        state.applicationInfo.labelsIds = payload.labels.map(
          (item) => item.id,
        ) as ApplicationLabelType[];
      });
  },
});

export default applicationProcessSlice.reducer;
