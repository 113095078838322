import { IApplication } from 'api/digifi/los/ApplicationsApi';
import { getApplicationLabelIdByEnvironment } from './applicationLabelsHelpers';
import { ApplicationLabelName, ApplicationLabelType } from 'enums/ApplicationLabelId';
import { getApplicationStatusIdByEnvironment } from './applicationStatusIdHelpers';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { DwollaMicroDepositsStatus } from 'enums/DwollaMicrodepositsStatus';
import { ApplicationVariable } from 'enums/ApplicationVariable';
import { ApplicationStatusId, ProdApplicationStatusId } from 'enums/ApplicationStatusId';

const hasFundedOutsideOrConnectLaterLabels = (labelIds: string[]) => {
  return labelIds.some((labelId) =>
    [
      getApplicationLabelIdByEnvironment(ApplicationLabelName.FundedOutsideProfund),
      getApplicationLabelIdByEnvironment(ApplicationLabelName.ConnectBankLater),
    ].includes(labelId as ApplicationLabelType),
  );
};

const getHasDwollaMicroDepositsLabel = (labelIds: string[]) => {
  return labelIds.some(
    (labelId) =>
      labelId ===
      getApplicationLabelIdByEnvironment(ApplicationLabelName.DwollaMicroDepositsVerification),
  );
};

export const getIsLastDwollaMicroDepositsAttempt = (
  dwollaMicroDepositsStatus: DwollaMicroDepositsStatus | null,
  dwollaVerificationCount: number | null,
  labelIds: string[],
) => {
  const hasDwollaMicroDepositsLabel = getHasDwollaMicroDepositsLabel(labelIds);

  return (
    dwollaMicroDepositsStatus === DwollaMicroDepositsStatus.TransitionFailed &&
    dwollaVerificationCount === 1 &&
    hasDwollaMicroDepositsLabel
  );
};

export const getShouldResumeByLabels = (
  fundedByEven: boolean | null,
  dwollaMicroDepositsStatus: DwollaMicroDepositsStatus | null,
  labelIds: string[],
) => {
  return (
    fundedByEven && !dwollaMicroDepositsStatus && hasFundedOutsideOrConnectLaterLabels(labelIds)
  );
};

export const getNeedNavigateToApplicationFlow = (application: IApplication) => {
  const labelIds = application.labels.map(({ id }) => id);

  const dwollaMicroDepositsStatus =
    application.variables[ApplicationVariable.DwollaMicrodepositsStatus];
  const fundedByEven = Boolean(application.variables[ApplicationVariable.FundedByEven]);
  const isBankVerificationStatus =
    application.status.id ===
    getApplicationStatusIdByEnvironment(ApplicationStatusName.BankVerification);

  const isApprovedStatus =
    application.status.id === getApplicationStatusIdByEnvironment(ApplicationStatusName.Approved);

  const isLastDwollaMicroDepositsAttempt =
    isApprovedStatus &&
    getIsLastDwollaMicroDepositsAttempt(
      dwollaMicroDepositsStatus as DwollaMicroDepositsStatus,
      application.variables[ApplicationVariable.DwollaVerificationCount] as number,
      labelIds,
    );

  const shouldResumeByLabels =
    isApprovedStatus &&
    getShouldResumeByLabels(
      fundedByEven,
      dwollaMicroDepositsStatus as DwollaMicroDepositsStatus | null,
      labelIds,
    );

  const isActiveStatus = [
    getApplicationStatusIdByEnvironment(ApplicationStatusName.PersonalInformation),
    getApplicationStatusIdByEnvironment(ApplicationStatusName.FinancialInformation),
    getApplicationStatusIdByEnvironment(ApplicationStatusName.OfferSelection),
    getApplicationStatusIdByEnvironment(ApplicationStatusName.OfferAgreement),
    getApplicationStatusIdByEnvironment(ApplicationStatusName.BankVerification),
  ].includes(application.status.id as ApplicationStatusId | ProdApplicationStatusId);

  return (
    isBankVerificationStatus ||
    isLastDwollaMicroDepositsAttempt ||
    shouldResumeByLabels ||
    isActiveStatus
  );
};
