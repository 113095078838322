import { createAsyncThunk } from '@reduxjs/toolkit';
import BankVerificationApi from 'api/digifi/portal/BankVerificationApi';
import { BankVerificationActionType } from 'enums/BankVerificationActionType';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createGetIntermediaryLinkTokenThunk = (api: BankVerificationApi) =>
  createAsyncThunk(
    BankVerificationActionType.GetIntermediaryLinkToken,
    async (token: string, thunkApi) => {
      try {
        return await api.getIntermediaryLinkToken(token);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
