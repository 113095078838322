import { useDispatch } from 'react-redux';
import { AsyncThunkAction, unwrapResult, Dispatch } from '@reduxjs/toolkit';

interface IUnwrappableAction {
  payload: any;
  meta?: any;
  error?: any;
}

interface IAsyncThunkConfig {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
}

const useDispatchWithUnwrap = () => {
  const dispatch = useDispatch();

  return async <Returned, ThunkArg, ThunkApiConfig extends IAsyncThunkConfig>(
    action: AsyncThunkAction<Returned, ThunkArg, ThunkApiConfig>,
  ) => {
    const result = await dispatch(action);

    return unwrapResult(result as unknown as IUnwrappableAction) as Returned;
  };
};

export default useDispatchWithUnwrap;
