import BankVerificationBankConnectionDashboard from 'components/BankVerificationBankConnectionDashboard';
import BankVerificationSelectPageMessage from 'components/BankVerificationSelectPageMessage';
import PageLayout, { LayoutSize } from 'components/PageLayout/PageLayout';
import React from 'react';
import styles from './IntermediarySelectVerification.module.scss';
import { BankVerificationProcessSteps } from 'pages/ApplicationProcess/BankVerificationProcess/BankVerificationProcess';
import AdvertiserDisclosure from 'components/AdvertiserDisclosure';

interface ISelectVerificationProps {
  hasDwollaMicroDepositsError: boolean;
  hasPlaidError: boolean;
  onSetStep: (step: BankVerificationProcessSteps) => void;
}

const IntermediarySelectVerification = ({
  hasDwollaMicroDepositsError,
  hasPlaidError,
  onSetStep,
}: ISelectVerificationProps) => {

  return (
    <div>
      <PageLayout layoutSize={LayoutSize.Big}>
        <AdvertiserDisclosure
          showDisclosure={false}
        />
        <div className={styles.mainContent}>
          <BankVerificationSelectPageMessage />
          <BankVerificationBankConnectionDashboard
            hasPlaidError={hasPlaidError}
            onSetStep={onSetStep}
            hasDwollaMicroDepositsError={hasDwollaMicroDepositsError}
          />
        </div>
      </PageLayout>
    </div>
  );
};

export default IntermediarySelectVerification;
