export enum CoborrowerVariable {
  FirstName = 'coowner_first_name',
  LastName = 'coowner_last_name',
  DateOfBirth = 'coowner_date_of_birth',
  StreetName = 'coowner_street_name',
  StreetNumber = 'coowner_street_number',
  ApartmentOrSuite = 'coowner_apartment_or_suite',
  City = 'coowner_city',
  State = 'coowner_state',
  ZipCode = 'coowner_zip_or_postal_code',
  AnnualIncome = 'coownerAnnualIncome',
  HomeAddress = 'coowner_home_address',
  Email = 'coowner_email',
  Phone = 'coowner_phone',
  PropertyStatus = 'coowner_property_status',
  SSN = 'coowner_personal_id_number',
}
