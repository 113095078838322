import { StylesConfig } from 'react-select';
import { IsMulti } from './SelectInput';

type MyOptionType = {
  label: string;
  value: string;
};

interface ICustomStylesProps {
  hasError: boolean;
}

const getCustomStyles = ({ hasError }: ICustomStylesProps) => {
  const customDropDownStyles: StylesConfig<MyOptionType, IsMulti> = {
    menu: () => ({
      position: 'absolute',
      width: '100%',
      zIndex: 998,
      boxSizing: 'border-box',
      borderRadius: 4,
      marginTop: 8,
      boxShadow: '0px 4px 16px rgba(24, 25, 27, 0.08)',
    }),
    menuList: (base) => ({
      borderRadius: '4px',
      background: '#ffffff',
      ...base,
      '::-webkit-scrollbar': {
        width: '4px',
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: '#ffffff',
        borderRadius: '2px',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#D1D1D1',
        borderRadius: '2px',
        width: '4px',
        height: '82px',
      },
    }),

    option: (provided, { isFocused, isSelected }) => {
      const getBackGroundColor = () => {
        if (isSelected) {
          return '#F0F7F5';
        }

        if (isFocused) {
          return '#FAFAFA';
        }

        return '#ffffff';
      };

      return {
        ...provided,
        height: '48px',
        position: 'relative',
        backgroundColor: getBackGroundColor(),
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        cursor: 'pointer',
      };
    },

    control: (provided, { menuIsOpen }) => {
      const getBorderColor = () => {
        if (hasError) {
          return '#e10808';
        }

        if (menuIsOpen) {
          return '#007A53';
        }

        return '#D1D1D1';
      };

      const styles = {
        ...provided,
        alignItems: 'stretch',
        cursor: 'pointer',
        height: 56,
        minWidth: '155px',
        outline: 'none',
        boxShadow: 'none',
        border: `1px solid ${getBorderColor()}`,
        color: 'red',

        ':hover': {
          border: '1px solid #007A53',
        },
      };

      return styles;
    },

    indicatorsContainer: (provided) => ({
      ...provided,
      alignSelf: 'center',
      width: 24,
      height: 24,
      marginRight: 10,
    }),

    indicatorSeparator: () => ({
      display: 'none',
    }),

    singleValue: (provided) => ({
      ...provided,
      top: 24,
      left: 16,
      margin: 0,
      position: 'absolute',
      fontSize: '16px',
      lineHeight: '150%',
      color: '#18191b',
      fontFamily: '"Roboto", "sans-serif"',
    }),

    valueContainer: (provided) => ({
      ...provided,
      alignItems: 'stretch',
      overflow: 'visible',
      fontSize: '14px',
      lineHeight: '18px',
      position: 'relative',
      padding: 0,
    }),

    input: (provided) => ({
      ...provided,
      position: 'absolute',
      left: 24,
      top: 20,
    }),

    placeholder: () => ({
      display: 'none',
    }),
  };

  return customDropDownStyles;
};

export default getCustomStyles;
