import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthorizationActionTypes } from 'enums/AuthorizationActionTypes';
import AuthorizationApi, { ISearchAccountsRequest } from 'api/digifi/portal/AuthorizationApi';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createSearchAccountsThunk = (api: AuthorizationApi) =>
  createAsyncThunk(
    AuthorizationActionTypes.SearchAccounts,
    async (searchAccountParams: ISearchAccountsRequest, thunkApi) => {
      try {
        return await api.searchAccounts(searchAccountParams);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
