import { createAsyncThunk } from '@reduxjs/toolkit';
import { VerificationActionTypes } from 'enums/VerificationActionsType';
import VerificationApi, { ISendVerificationCode } from 'api/digifi/portal/VerificationApi';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';

export const createSendVerificationCodeThunk = (api: VerificationApi) =>
  createAsyncThunk(
    VerificationActionTypes.SendVerificationCode,
    async ({ phone }: ISendVerificationCode, thunkApi) => {
      try {
        return await api.sendVerificationCode({ phone });
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
