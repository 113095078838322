import AdvertiserDisclosure from 'components/AdvertiserDisclosure';
import FormButtons from 'components/FormButtons';
import Menu from 'components/Menu';
import PageLayout from 'components/PageLayout';
import { LayoutSize } from 'components/PageLayout/PageLayout';
import Title from 'components/Title';
import {
  ButtonsTranslationKeys,
  MenuTranslationKeys,
  SaveForLaterPageTranslationsKeys,
  TranslationNameSpaces,
} from 'enums/Translation';
import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from 'routes/AppRoutes';
import { OfferPagesContext, OfferPagesStep } from 'pages/ApplicationProcess/OffersPage/OffersPage';
import styles from './SaveForLater.module.scss';
import { getApplicationById, logout, updateApplication } from 'thunks';
import { getApplicationStatusIdByEnvironment, matchApplicationStatusIdByEnvironment } from 'utils/applicationStatusIdHelpers';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { resetSelectedOffer } from 'utils/resetSelectedOffer';
import { ApplicationVariable } from 'enums/ApplicationVariable';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

const SaveForLater = () => {
  const navigate = useNavigate();
  const { applicationId } = useParams();

  const { t: translate } = useTranslation([
    TranslationNameSpaces.Buttons,
    TranslationNameSpaces.SaveForLaterPage,
  ]);
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();
  const { setCurrentStep, prevStep } = useContext(OfferPagesContext);

  const [isPrimaryLoading, setIsPrimaryLoading] = useState(false);

  const handleBackToOffers = async () => {
    try {
      setIsPrimaryLoading(true);

      const application = await dispatchWithUnwrap(getApplicationById(applicationId!));

      const isOfferAgreementStatus = matchApplicationStatusIdByEnvironment(
        application.status.id,
        ApplicationStatusName.OfferAgreement,
      );
  
      if (isOfferAgreementStatus) {
        await dispatchWithUnwrap(
          updateApplication({
            applicationId: applicationId!,
            params: {
              statusId: getApplicationStatusIdByEnvironment(ApplicationStatusName.OfferSelection),
              variables: resetSelectedOffer(
                application.variables[ApplicationVariable.InitialLoanAmount] as number,
              ),
            },
          }),
        );
      }
  
      setCurrentStep(OfferPagesStep.Offers);
    } finally {
      setIsPrimaryLoading(false);
    }
  };

  const handleExit = () => {
    dispatch(logout());
    navigate(AppRoutes.Authorization, { replace: true });
  };

  const handleBackClick = () => {
    setCurrentStep(prevStep!);
  };

  return (
    <>
      <Menu activeMenuItem={MenuTranslationKeys.Applications} />
      <PageLayout layoutSize={LayoutSize.Big}>
        <AdvertiserDisclosure onBackClick={handleBackClick} />
        <Title variant="h1" marginBottom="16">
          {translate(SaveForLaterPageTranslationsKeys.Title, {
            ns: TranslationNameSpaces.SaveForLaterPage,
          })}
        </Title>
        <div className={styles.info}>
          <div className={styles.congratulations}>
            <Trans i18nKey={SaveForLaterPageTranslationsKeys.DescriptionPartOne}>
              Congratulations! You’re <span className={styles.bold}>pre-approved</span>! Your offers
              will be saved for up to 30 days from the date you first submitted your request.
            </Trans>
          </div>
          <div>
            {translate(SaveForLaterPageTranslationsKeys.DescriptionPartTwo, {
              ns: TranslationNameSpaces.SaveForLaterPage,
            })}
          </div>
        </div>
        <FormButtons
          primaryTitle={translate(ButtonsTranslationKeys.BackToOffers, {
            ns: TranslationNameSpaces.Buttons,
          })}
          secondaryTitle={translate(ButtonsTranslationKeys.Exit, {
            ns: TranslationNameSpaces.Buttons,
          })}
          onPrimaryClick={handleBackToOffers}
          onSecondaryClick={handleExit}
          isPrimaryLoading={isPrimaryLoading}
        />
      </PageLayout>
    </>
  );
};

export default SaveForLater;
