import { FC, useContext, useState } from 'react';
import Title from 'components/Title';
import Paragraph from 'components/Paragraph';
import SubTitle from 'components/SubTitle';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { TypographySize } from 'enums/TypographySize';
import { ForwardArrowIcon } from 'static/images';
import { useTranslation } from 'react-i18next';
import {
  OfferCardTranslationKeys,
  ButtonsTranslationKeys,
  TranslationNameSpaces,
  UpgradePlanCategoryKeys,
} from 'enums/Translation';
import { IOffer, setCurrentOffer } from 'handlers/portal/offersSlice';
import { convertToMonetary, convertToPercentage, termFormatter } from 'utils/profund/formats';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

import styles from './OfferCard.module.scss';
import { OfferPagesContext, OfferPagesStep } from 'pages/ApplicationProcess/OffersPage/OffersPage';
import PartnerLogo from 'components/PartnerLogo';
import { useGetOfferStatusToDisplay } from 'hooks/useGetOfferStatusToDisplay';
import OfferDisclaimer from 'components/OfferDisclaimer';
import { PromotionalProductDescription } from 'pages/ApplicationProcess/OffersPage/offersUtils';
import { parseUpgradePlan } from 'utils/parseUpgradePlan';
import { useNavigate } from 'react-router-dom';
import { getBorrowerApplicationData } from 'handlers/selectors';
import { matchApplicationLabelByEnvironment } from 'utils/applicationLabelsHelpers';
import { ApplicationLabelName } from 'enums/ApplicationLabelId';

const OfferCard: FC<{ 
  offer: IOffer, 
  isWebLead?: boolean,
 }> = ({ 
  offer, 
  isWebLead = false,
 }) => {
  const {
    disclaimer,
    apr,
    loanAmount,
    monthlyPayment,
    partnerLogoUrl,
    termLength,
    termsUnit,
    preApproved,
    preQualified,
    offerProvider,
    aprDescription,
    termDescription,
    monthlyPaymentDescription,
    originationFee,
    productDescription,
  } = offer;

  const navigate = useNavigate();

  const { t: translate } = useTranslation([
    TranslationNameSpaces.Buttons,
    TranslationNameSpaces.OffersPage,
  ]);

  const offerCardStatus = useGetOfferStatusToDisplay(preQualified, preApproved);

  const [isDisclaimerShown, setIsDisclaimerShown] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { setCurrentStep } = useContext(OfferPagesContext);

  const { selectedApplication } = useAppSelector(getBorrowerApplicationData);

  const handleSelectOffer = () => {
    if (isWebLead || selectedApplication?.labels.some(label => matchApplicationLabelByEnvironment(label.id, ApplicationLabelName.WebLeadApplication))) {
      navigate('#web-lead');
    }
    
    setCurrentStep(OfferPagesStep.OfferDetails);
    dispatch(setCurrentOffer(offer));
  };

  const isOriginationFeeNeedToDisplay = originationFee && originationFee.max > 0;

  const renderProductDescription = () => {
    const getDescription = () => {
      switch (productDescription) {
        case PromotionalProductDescription.C5054:
          return translate(OfferCardTranslationKeys.ProductDescriptionC5054, {
            ns: TranslationNameSpaces.OffersPage,
          });
        case PromotionalProductDescription.C8023:
          return translate(OfferCardTranslationKeys.ProductDescriptionC8023, {
            ns: TranslationNameSpaces.OffersPage,
          });
        default: {
          if (offer.upgradePlanCategory && offer.upgradePlanDescription) {
            const { percentage, numberOfYears, numberOfMonths } = parseUpgradePlan(
              offer.upgradePlanCategory,
              offer.upgradePlanDescription,
            );

            return translate(UpgradePlanCategoryKeys[offer.upgradePlanCategory], {
              ns: TranslationNameSpaces.OffersPage,
              percentage,
              numberOfYears,
              numberOfMonths,
            });
          }

          return '';
        }
      }
    };

    return (
      <Paragraph variant={TypographySize.Small} className={styles.offerCardProductDescription}>
        {getDescription()}
      </Paragraph>
    );
  };

  const renderCard = () => (
    <>
      <div className={styles.offerCardLogoWrapper}>
        <PartnerLogo finalOfferProviderName={offerProvider} logoLink={partnerLogoUrl} />
      </div>
      <Paragraph variant={TypographySize.Large} className={styles.offerCardLabel}>
        {offerCardStatus}
      </Paragraph>
      {renderProductDescription()}
      <Paragraph variant={TypographySize.Small}>
        {translate(OfferCardTranslationKeys.LoanAmount, { ns: TranslationNameSpaces.OffersPage })}
      </Paragraph>
      <Title className={styles.offerCardTitle}>{convertToMonetary(loanAmount)}</Title>
      <div className={styles.offerCardDescription}>
        <div className={styles.offerCardDivider} />
        <ul className={styles.offerCardDescriptionList}>
          <li className={styles.offerCardDescriptionItem}>
            <Paragraph variant={TypographySize.Middle}>
              {monthlyPaymentDescription ||
                translate(OfferCardTranslationKeys.MonthlyPayment, {
                  ns: TranslationNameSpaces.OffersPage,
                })}
            </Paragraph>
            <SubTitle variant={TypographySize.Middle}>{convertToMonetary(monthlyPayment)}</SubTitle>
          </li>
          <li className={styles.offerCardDescriptionItem}>
            <Paragraph variant={TypographySize.Middle}>
              {termDescription ||
                translate(OfferCardTranslationKeys.TermOfLoan, {
                  ns: TranslationNameSpaces.OffersPage,
                })}
            </Paragraph>
            <SubTitle variant={TypographySize.Middle}>
              {termFormatter(termLength, termsUnit)}
            </SubTitle>
          </li>
          <li className={styles.offerCardDescriptionItem}>
            <Paragraph variant={TypographySize.Middle}>
              {aprDescription ||
                translate(OfferCardTranslationKeys.FixedApr, {
                  ns: TranslationNameSpaces.OffersPage,
                })}
            </Paragraph>
            <SubTitle variant={TypographySize.Middle}>{convertToPercentage(apr / 100)}</SubTitle>
          </li>
          {isOriginationFeeNeedToDisplay && (
            <li className={styles.offerCardDescriptionItem}>
              <Paragraph variant={TypographySize.Middle}>
                {translate(OfferCardTranslationKeys.OriginationFee, {
                  ns: TranslationNameSpaces.OffersPage,
                })}
              </Paragraph>
              <SubTitle variant={TypographySize.Middle}>
                {convertToPercentage(originationFee!.min / 100)} {' - '}
                {convertToPercentage(originationFee!.max / 100)}
              </SubTitle>
            </li>
          )}
        </ul>
      </div>
      <div className={styles.buttonWrapper}>
        <Button size={ButtonSize.Large} variant={ButtonVariant.Primary} onClick={handleSelectOffer}>
          {translate(ButtonsTranslationKeys.SelectOfferButton, {
            ns: TranslationNameSpaces.Buttons,
          })}
        </Button>
        <button
          onClick={() => setIsDisclaimerShown(true)}
          className={styles.offerCardDisclaimerButton}
        >
          {translate(ButtonsTranslationKeys.DisclaimerButton, {
            ns: TranslationNameSpaces.Buttons,
          })}{' '}
          {<ForwardArrowIcon />}
        </button>
      </div>
    </>
  );

  const renderDisclaimer = () => (
    <div className={styles.disclaimerWrapper}>
      <Title variant="h4">
        {' '}
        {translate(OfferCardTranslationKeys.DisclaimerAndMoreInformation, {
          ns: TranslationNameSpaces.OffersPage,
        })}
      </Title>
      <Paragraph>
        <OfferDisclaimer offerDisclaimer={disclaimer} />
      </Paragraph>
      <div className={styles.buttonWrapper}>
        <button
          onClick={() => setIsDisclaimerShown(false)}
          className={styles.offerCardDisclaimerButton}
        >
          {translate(ButtonsTranslationKeys.Back, { ns: TranslationNameSpaces.Buttons })}{' '}
          {<ForwardArrowIcon />}
        </button>
      </div>
    </div>
  );

  return (
    <div className={styles.offerCardWrapper}>
      {isDisclaimerShown ? renderDisclaimer() : renderCard()}
    </div>
  );
};

export default OfferCard;
