import { createAsyncThunk } from '@reduxjs/toolkit';
import ApplicationProcessApi from 'api/digifi/portal/ApplicationProcessApi';
import rejectWithValueHelper from 'utils/rejectWithValueHelper';
import { OffersInformationActionType } from 'enums/OffersInformationActionType';

export const createSendOfferAgreementEmail = (api: ApplicationProcessApi) =>
  createAsyncThunk(
    OffersInformationActionType.Send,
    async (applicationId: string, thunkApi) => {
      try {
        return await api.sendOfferAgreementEmail(applicationId);
      } catch (error) {
        return rejectWithValueHelper(error as Error, thunkApi);
      }
    },
  );
