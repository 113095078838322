import { FC, useMemo } from 'react';
import MobileOffers from './MobileOffers';
import DesktopOffers from './DesktopOffers';
import { IOffer } from 'handlers/portal/offersSlice';
import {
  OfferCategoryType,
  sortOffersByParameter,
  TABLET_WIDTH,
} from 'pages/ApplicationProcess/OffersPage/offersUtils';
import useWindowSize from 'hooks/useWindowSize';

const OffersComponentByDeviceWidth: FC<{ 
  offers: IOffer[]; 
  categoryName: OfferCategoryType, 
  isWebLead?: boolean,
}> = ({
  offers,
  categoryName,
  isWebLead,
}) => {
  const { width } = useWindowSize();

  const OffersComponent = useMemo(() => {
    if (width && width < TABLET_WIDTH) {
      return MobileOffers;
    }
    return DesktopOffers;
  }, [width]);

  return <OffersComponent offers={sortOffersByParameter(categoryName, offers)} isWebLead={isWebLead} />;
};

export default OffersComponentByDeviceWidth;
