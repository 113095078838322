export enum AuthorizationEndpoints {
  GetAuthorizationInformation = '/authorization',
  SearchAccounts = '/authorization/search-accounts',
  CreateAccount = '/authorization/create-account',
  RejectApplication = '/authorization/reject-application',
}

export enum VerificationEndpoints {
  SendVerificationCode = '/verification/send-verification-code',
  VerifyCode = '/verification/verify-code',
}

export enum ApplicationProcessEndpoints {
  GetApplicationStatus = '/application-status',
  CheckOldApplications = '/check-old-applications',
  ContinueWithApplication = '/continue-with-application',
  GetPersonalInformation = '/get-personal-information',
  GetFinancialInformation = '/get-financial-information',
  CleanApplications = '/clean-applications',
  SavePlaidEventToVariable = '/save-plaid-event-to-variables',
  SendOfferAgreementEmail = '/send-offer-agreement-email',
}

export enum BankAccountVerificationEndpoints {
  CreateBorrowerLinkToken = '/create-borrower-link-token',
  CreateBorrowerFundingSource = '/create-borrower-funding-source',
  CreateIntermediaryLinkToken = '/create-intermediary-link-token',
  CreateIntermediaryFundingSource = '/create-intermediary-funding-source',
  UploadDocument = '/upload-document',
}

export enum LoanOffersEndpoints {
  Create = '/create',
}

export enum ManageAccountEndpoints {
  RequestFunds = '/manage-account/request-funds',
  AddFunds = '/manage-account/add-funds',
  CloseAccount = '/manage-account/close-account',
  ReopenAccount = '/manage-account/reopen-account',
}
