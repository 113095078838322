import BaseApi, { FetchMethodType } from 'api/BaseApi';

enum BankVerificationEndpoints {
  CreateBorrowerLinkToken = '/create-borrower-link-token',
  CreateIntermediaryLinkToken = '/create-intermediary-link-token',
  SaveBorrowerToken = '/save-borrower-token',
  SaveIntermediaryToken = '/save-intermediary-token',
  UploadDocument = '/upload-document',
}

interface ISaveTokenCommonData {
  token: string;
  accountId: string;
}

export interface ISaveBorrowerTokenData extends ISaveTokenCommonData {
  applicationId: string;
}

export interface ISaveIntermediaryTokenData extends ISaveTokenCommonData {
  intermediaryToken: string;
  isMicroDepositsMode?: boolean;
}

export default class BankVerificationApi extends BaseApi {
  private readonly endpointBase = '/bank-verification';

  public async getBorrowerLinkToken(
    applicationId: string,
    isUpdateMode?: boolean,
  ): Promise<string> {
    const response = await this.fetch<{ link_token: string }>(
      `${this.endpointBase}${BankVerificationEndpoints.CreateBorrowerLinkToken}`,
      {
        method: FetchMethodType.POST,
        body: {
          applicationId,
          isUpdateMode,
        },
      },
    );

    return response.link_token;
  }

  public async getIntermediaryLinkToken(
    token: string,
    isUpdateMode?: boolean,
  ): Promise<string> {
    const response = await this.fetch<{ link_token: string }>(
      `${this.endpointBase}${BankVerificationEndpoints.CreateIntermediaryLinkToken}`,
      {
        method: FetchMethodType.POST,
        body: {
          token,
          isUpdateMode,
        },
      },
    );

    return response.link_token;
  }

  public async saveBorrowerToken(body: ISaveBorrowerTokenData) {
    await this.fetch(`${this.endpointBase}${BankVerificationEndpoints.SaveBorrowerToken}`, {
      method: FetchMethodType.POST,
      body,
    });
  }

  public async saveIntermediaryToken(body: ISaveIntermediaryTokenData) {
    await this.fetch(`${this.endpointBase}${BankVerificationEndpoints.SaveIntermediaryToken}`, {
      method: FetchMethodType.POST,
      body,
    });
  }

  public async uploadDocument(body: FormData) {
    await this.fetch(
      `${this.endpointBase}${BankVerificationEndpoints.UploadDocument}`,
      {
        method: FetchMethodType.POST,
        body,
        resetDefaultHeaders: true,
      },
    );
  }
}
