import { ApplicationsIcon, ProfileIcon, SignOutIcon } from 'static/images';
import Button, { ButtonVariant } from 'components/Button';
import { MenuTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import React, { FC, useState } from 'react';

import { AppRoutes } from 'routes/AppRoutes';
import CollapseWrapper from 'components/CollapseWrapper';
import clsx from 'clsx';
import { logout } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './Menu.module.scss';

interface IMenuProps {
  activeMenuItem: MenuTranslationKeys;
}

interface IMenuItem {
  translationKey: MenuTranslationKeys;
  onClickHandler: () => void;
}

const getMenuItemIcon = (menuItem: MenuTranslationKeys) => {
  switch (menuItem) {
    case MenuTranslationKeys.Applications:
      return <ApplicationsIcon />;
    case MenuTranslationKeys.Profile:
      return <ProfileIcon />;
    case MenuTranslationKeys.SignOut:
      return <SignOutIcon />;
  }
};

const Menu: FC<IMenuProps> = ({ activeMenuItem }) => {
  const { t: translate } = useTranslation(TranslationNameSpaces.Menu);
  const navigate = useNavigate();
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const handleLogout = async () => {
    setIsLogoutLoading(true);
    await dispatchWithUnwrap(logout());
    setIsLogoutLoading(false);
  };

  const menuItems: IMenuItem[] = [
    {
      translationKey: MenuTranslationKeys.Applications,
      onClickHandler: () => navigate(AppRoutes.Applications),
    },
    {
      translationKey: MenuTranslationKeys.Profile,
      onClickHandler: () => navigate(AppRoutes.Profile),
    },
    {
      translationKey: MenuTranslationKeys.SignOut,
      onClickHandler: handleLogout,
    },
  ];

  const renderMenuItems = (menuItem: IMenuItem) => {
    const { translationKey, onClickHandler } = menuItem;
    const isCurrentItem = translationKey === activeMenuItem;

    const activeItemClassName = clsx(isCurrentItem && styles.activeMenuItem);
    const isSignOutButton = translationKey === MenuTranslationKeys.SignOut;

    return (
      <Button
        key={translationKey}
        className={activeItemClassName}
        variant={ButtonVariant.MenuButton}
        onClick={onClickHandler}
        isLoading={isSignOutButton && isLogoutLoading}
      >
        {getMenuItemIcon(translationKey)}
        <p className={styles.menuItemText}>{translate(translationKey)}</p>
      </Button>
    );
  };

  const renderCurrentMenuItem = () => {
    const { onClickHandler, translationKey } = menuItems.find(
      (item) => item.translationKey === activeMenuItem,
    )!;

    return (
      <Button
        className={styles.activeMenuItem}
        variant={ButtonVariant.MenuButton}
        onClick={onClickHandler}
      >
        {getMenuItemIcon(translationKey)}
        <p className={styles.menuItemText}>{translate(translationKey)}</p>
      </Button>
    );
  };

  return (
    <CollapseWrapper activeItem={renderCurrentMenuItem()} items={menuItems.map(renderMenuItems)} />
  );
};

export default Menu;
