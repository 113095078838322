export enum AppRoutes {
  Authorization = '/authorization',
  Invitation = ':applicationId',
  ApplicationProcess = '/application-process',
  OldApplication = '/old-application',
  Applications = '/applications',
  Profile = '/profile',
  TermsOfService = '/terms-of-service',
  CreditAuthorization = '/credit-authorization',
  ElectronicConsent = '/electronic-consent',
  Intermediary = '/intermediary/:token',
  ResetPlaidLink = '/reset-plaid-link-authorization',
}
