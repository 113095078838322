import React, { useEffect, useState } from 'react';
import IntermediaryPlaidBankVerification from './PlaidBankVerification';
import IntermediaryWhatNext from './WhatNext/IntermediaryWhatNext';
import { BankConnectionType } from 'components/BankConnectionCardWrapper/BankConnectionCardWrapper';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import Loading from 'components/Loading';
import SomethingWentWrongPage from 'pages/ApplicationProcess/BankVerificationProcess/SomethingWentWrongPage';
import { IntermediaryVariable } from 'enums/IntermediaryVariable';
import { getIntermediaryByToken } from 'thunks';
import { useParams } from 'react-router-dom';
import IntermediarySelectVerification from './SelectVerification';
import IntermediaryDwollaVerification from './DwollaBankVerification';
import { BankVerificationProcessSteps } from 'pages/ApplicationProcess/BankVerificationProcess/BankVerificationProcess';
import VerifyIntermediaryMicroDeposits from './VerifyMicroDeposits/VerifyIntermediaryMicroDeposits';
import { DwollaMicroDepositsStatus } from 'enums/DwollaMicrodepositsStatus';
import VerificationOnTheWay from './VerificationOnTheWay';
import { IRequestError } from 'errors/RequestError';
import StubWithDescription from './StubWithDescription';
import { IntermediaryBankVerificationTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { useTranslation } from 'react-i18next';

const IntermediaryBankVerificationPage = () => {
  const { token } = useParams();
  const { t: translate } = useTranslation([
    TranslationNameSpaces.IntermediaryBankVerification,
  ]);
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const [step, setStep] = useState(BankVerificationProcessSteps.WhatNext);
  const [bankConnectionType, setBankConnectionType] = useState<BankConnectionType | null>(null);
  const [hasDwollaMicroDepositsError, setHasDwollaMicroDepositsError] = useState(false);
  const [hasPlaidError, setHasPlaidError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankVerificationCompleted, setBankVerificationCompleted] = useState(false);
  const [isDwollaError, setIsDwollaError] = useState(false);
  const [microdepositsStatus, setMicrodepositsStatus] = useState<DwollaMicroDepositsStatus | null>(null);
  const [isIntermediaryNotFound, setIsIntermediaryNotFound] = useState(false);

  const getInitialData = async () => {
    try {
      setLoading(true);

      const { variables } = await dispatchWithUnwrap(getIntermediaryByToken(token!));

      if (!variables) {
        setIsDwollaError(true);
        setLoading(false);

        return;
      }

      if (variables[IntermediaryVariable.BankVerificationComplete]) {
        return setBankVerificationCompleted(true);
      }

      if (variables[IntermediaryVariable.DwollaMicrodepositsStatus] === DwollaMicroDepositsStatus.VerificationFailed) {
        setStep(BankVerificationProcessSteps.PlaidBankVerification);
      }

      setMicrodepositsStatus(variables[IntermediaryVariable.DwollaMicrodepositsStatus] as DwollaMicroDepositsStatus);
    } catch (error) {
      const { responseStatus } = error as IRequestError;

      if (responseStatus < 500) {
        setIsIntermediaryNotFound(true);

        return;
      }

      setIsDwollaError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {    
    getInitialData();
  }, []);  

  const handleSetDwollaMicroDepositsError = () => {
    setHasDwollaMicroDepositsError(true);
    setHasPlaidError(false);
    setStep(BankVerificationProcessSteps.SelectBankVerification);
  };

  const handlePlaidVerificationError = () => {
    setHasPlaidError(true);
    setHasDwollaMicroDepositsError(false);
    setStep(BankVerificationProcessSteps.SelectBankVerification);
  };

  if (loading) {
    return <Loading />;
  }

  if (isDwollaError) {
    return <SomethingWentWrongPage isDwollaError isIntermediaryError />;
  }

  if (isIntermediaryNotFound) {
    return (
      <StubWithDescription 
        description={
          translate(IntermediaryBankVerificationTranslationKeys.Description1, {
            ns: TranslationNameSpaces.IntermediaryBankVerification,
          })} 
      />
    );
  }

  if (bankVerificationCompleted) {
    return (
      <StubWithDescription 
        description={
          translate(IntermediaryBankVerificationTranslationKeys.Description2, {
            ns: TranslationNameSpaces.IntermediaryBankVerification,
          })} 
      />
    );
  }

  if (microdepositsStatus === DwollaMicroDepositsStatus.OnTheWay) {
    return <VerificationOnTheWay />;
  }

  if (microdepositsStatus === DwollaMicroDepositsStatus.PostedToBank) {
    return <VerifyIntermediaryMicroDeposits 
      token={token} 
      setMicrodepositsStatus={setMicrodepositsStatus}
      onSetStep={setStep} 
    />;
  }

  const getCurrentPageByStep = () => {
    const pageByStep = {
      [BankVerificationProcessSteps.WhatNext]: (
        <IntermediaryWhatNext
          onSetStep={setStep}
          currentBankConnectionType={bankConnectionType}
          onSelectBankConnectionType={(value: BankConnectionType) => setBankConnectionType(value)}
        />
      ),
      [BankVerificationProcessSteps.PlaidBankVerification]: (
        <IntermediaryPlaidBankVerification
          token={token!}
          microdepositsStatus={microdepositsStatus!}
          onPlaidVerificationError={handlePlaidVerificationError}
          onSetIsDwollaError={setIsDwollaError}
          onSetStep={setStep}
        />
      ),
      [BankVerificationProcessSteps.DwollaBankVerification]: (
        <IntermediaryDwollaVerification
          onSetDwollaMicroDepositsError={handleSetDwollaMicroDepositsError}
          onSetStep={setStep}
        />
      ),
      [BankVerificationProcessSteps.SelectBankVerification]: (
        <IntermediarySelectVerification
          hasDwollaMicroDepositsError={hasDwollaMicroDepositsError}
          hasPlaidError={hasPlaidError}
          onSetStep={setStep}
        />
      ),
    };

    return pageByStep[step];
  };

  return getCurrentPageByStep();
};

export default IntermediaryBankVerificationPage;
